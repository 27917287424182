import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';
import BackButtonTitle from '../components/BackButtonTitle';
import axios from 'axios';
import dusty from '../img/dusty.svg';
import UserProfile from '../components/UserProfile';
import UserImage from './UserImage';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';


class RankingPage extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        selectedUser: null,
        showModal: false,
        user: this.props.user,
        page: 0,
        hasMore: false,
        users: []
      }
      this.getUsers = this.getUsers.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showProfilePage = this.showProfilePage.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getUsers();
  }

  handleClose(){
    this.setState({selectedUser: null,
      showModal: false});
  }

  showProfilePage(selectedUser){
    this.setState({selectedUser,
      showModal: true});

  }


getUsers(refresh){

    let page = this.state.page;
    let users = this.state.users;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      page=0;
      users=[];
      this.setState({errorMsg, page, users,
        hasMore: true
      });
    } else {
      this.setState({errorMsg,
        hasMore: true
      });
    }


    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    

    axios.get(API_BASE_URL+'/discovery/getUsersRanking?page='+page+'&lang='+i18n.language, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newUsers = response.data;
        if((newUsers && newUsers.length===0) || (newUsers && newUsers[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          users: users.concat(newUsers)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

 
    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.ranking'}></BackButtonTitle>
                <InfiniteScroll
                  dataLength={this.state.users.length}
                  next={this.getUsers}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getUsers(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.users.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Common.info_not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.users.map((user, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.showProfilePage(user)}>
                                <div>
                                  <span>{index+1}</span>
                                  <UserImage user={user} className="list-img"></UserImage>
                                  <span className="link-icon">{user.name+' '+user.surname+' '}</span>
                                </div>
                                <div className="ranking-point-container">
                                  <span><NumberFormat value={user.points} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></span>
                                  <Image src={dusty} alt="Dusty" className="like-info-img center-block no-pointer" fluid/>
                                </div>

                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <UserProfile user={this.state.selectedUser} handleClose={this.handleClose}></UserProfile>
                  )}
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(RankingPage);
