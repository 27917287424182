import React, { PureComponent } from 'react';
import { Row, Col, Container} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_ROLE_ADMIN,  WDL_USER, WDL_COMPANY_NOTIFICATIONS, WDL_COMPANY} from '../Constants.js';
import WDLMenu from './WDLMenu';
import WDLCompanyHeader from './WDLCompanyHeader';
import WDLCompanyMenuItem from './WDLCompanyMenuItem';
import vectors from '../img/wdl/vector.png';
import clients from '../img/wdl/client.png';
import shipment from '../img/wdl/shipment.png';
import invoice from '../img/wdl/invoice.png';
import pallet from '../img/wdl/pallet.png';
import companyInfo from '../img/wdl/company_info.png';
import NumberFormat from 'react-number-format';

import axios from 'axios';

import { withTranslation } from 'react-i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLCompanyHome extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
      var isAdmin = false;
      if(user.role === WDL_ROLE_ADMIN){
        isAdmin = true;
      }
      let notifications = JSON.parse(localStorage.getItem(WDL_COMPANY_NOTIFICATIONS));
      let company = JSON.parse(localStorage.getItem(WDL_COMPANY));
      this.state = {
        companyId: this.props.match.params.companyId,
        company,
        user: user,
        isAdmin: isAdmin,
        notifications,
      }
      this.getCompany = this.getCompany.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if(!this.state.company){
      this.getCompany();
    }

    if(!this.state.notifications || new Date(this.state.notifications.dateMillis).getDay() != new Date().getDay()){
      this.getNotifications();
    }
  }

  
  

getCompany(){
    const requestId = 'getCompanyRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    this.setState({errorMsg, loading: true
    });
    

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId;
    
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let company = response.data;
        localStorage.setItem(WDL_COMPANY, JSON.stringify(company));
        this.setState({
          company, loading: false
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, loading:false});
      }
    });
  }

  getNotifications(){
    const requestId = 'getNotificationsRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    this.setState({errorMsg});
    

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/notifications';
    
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let notifications = response.data;
        localStorage.setItem(WDL_COMPANY_NOTIFICATIONS, JSON.stringify(notifications));
        this.setState({
          notifications
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg});
      }
    });
  }




    render() {
      const { t } = this.props;
        return (

            <div className="wdl-container">
                <WDLMenu user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
                {this.state.company && this.state.notifications &&
                <Container fluid={true} style={{marginTop: 10}}>
                  {this.state.notifications.expiredDurc>0 &&
                  <Row>
                    <Col style={{textAlign: 'right'}}>
                      <a href={"/logistic/company/"+this.state.company.companyId+"/vectors?expired=true"} className="link-icon" style={{color: 'black'}}>
                            <i className="fa fa-warning" aria-hidden="true"></i>
                            <span style={{marginLeft: 10}}>{t('WDL.expired_durc')+': '}</span>
                            <span className={"notifications-to-read"}>
                              <NumberFormat value={this.state.notifications.expiredDurc} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                            </span>
                      </a>
                    </Col>
                  </Row>
                  }
                  {this.state.notifications.expiredInvoices>0 &&
                    <Row>
                      <Col style={{textAlign: 'right'}}>
                        <a href={"/logistic/company/"+this.state.company.companyId+"/invoices/clients?expired=true"} className="link-icon" style={{color: 'black'}}>
                              <i className="fa fa-warning" aria-hidden="true"></i>
                              <span style={{marginLeft: 10}}>{t('WDL.expired_invoices')+': '}</span>
                              <span className={"notifications-to-read"}>
                                <NumberFormat value={this.state.notifications.expiredInvoices} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                              </span>
                        </a>
                      </Col>
                    </Row>
                  }
                  
                </Container>
                }
                {this.state.company && <WDLCompanyHeader company={this.state.company}/>}
                {this.state.company ? 
                  <Container fluid={true}>
                    <Row className="wdl-menu-row">
                      <Col>
                        <WDLCompanyMenuItem image={shipment} text={t('WDL.shipments')} color="#486cb5" link={"/logistic/company/"+this.state.company.companyId+"/shipments"}/>
                      </Col>
                      <Col>
                        <WDLCompanyMenuItem image={clients} text={t('WDL.clients')} color="#247b5d" link={"/logistic/company/"+this.state.company.companyId+"/clients"}/>
                      </Col>
                      <Col>
                        <WDLCompanyMenuItem image={vectors} text={t('WDL.vectors')} color="#ec7b21" link={"/logistic/company/"+this.state.company.companyId+"/vectors"}/>
                      </Col>
                    </Row>
                    <Row className="wdl-menu-row">
                      <Col>
                        <WDLCompanyMenuItem image={invoice} text={t('WDL.invoices')} color="#ba1b13" link={"/logistic/company/"+this.state.company.companyId+"/invoices"}/>
                      </Col>
                      <Col>
                        <WDLCompanyMenuItem image={pallet} text={t('WDL.pallets')} color="#705906" link={"/logistic/company/"+this.state.company.companyId+"/pallets"}/>
                      </Col>
                      <Col>
                        <WDLCompanyMenuItem image={companyInfo} text={t('WDL.company_info')} color="#298ae6" link={"/logistic/company/"+this.state.company.companyId+"/manage"}/>
                      </Col>
                    </Row>

                  </Container>
                  :
                  <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>

                }
            </div>

        );
    }
}

export default withTranslation()(WDLCompanyHome);
