import React, { PureComponent } from 'react';
import logo from '../img/logo.svg';
import {  Navbar, Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class Menu_admin extends PureComponent {
    
    render() {
    
        return (
          <div>
            <Navbar collapseOnSelect expand="lg" className="nav_menu top-menu">
              <Navbar.Brand><Image src={logo} className="menu-logo" /></Navbar.Brand>
            </Navbar>
          </div>
        );
    }
}

export default withTranslation()(Menu_admin);
