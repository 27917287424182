import React, { PureComponent } from 'react'
import axios from 'axios'
import { API_BASE_URL, ACCESS_TOKEN, SEARCH_CITIES } from '../Constants.js';
import { Row, Col, Container, Form, ListGroup, Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GenerateColor } from '../functions/FormUtils.js';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class SearchCities extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      query: '',
      type: SEARCH_CITIES,
      page: 0,
      pageToShow: '',
      selected: null,
      hasMore: false,
      results: [],
      refreshing: false,
    }
    this.getInfo = this.getInfo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }


  getInfo = isNewSearch => {
    const requestId = 'getInfoRequest';
    if (this.state.query.length === 0) {
      axios.cancel(requestId);
      let results = [];
      this.setState({ results, refreshing: false });
    } else {
      if (this.state.refreshing || this.state.query.length >= 2) {
        axios.cancel(requestId);
        const { t } = this.props;
        var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
          }
        };

        this.setState({ refreshing: true });
        let errorMsg = {};

        axios
          .get(
            API_BASE_URL +
            '/discovery/getSearchSuggestions/' +
            this.state.type +
            '/?query=' +
            this.state.query +
            '&page=' +
            this.state.page +
            '&lang=' +
            i18n.language,
            config,
          )
          .then(
            response => {
              if (this._isMounted) {
                let hasMore = true;
                let newResults = response.data;
                if (
                  (newResults && newResults.length === 0) ||
                  (newResults && newResults[0].itemInLastPage)
                ) {
                  hasMore = false;
                }
                let results = this.state.results;

                if (newResults) {
                  if (!isNewSearch) {
                    results = results.concat(newResults);
                  } else {
                    results = newResults;
                  }
                }
                let page = 1 * this.state.page + 1;
                this.setState({
                  hasMore,
                  page,
                  refreshing: false,
                  results,
                });
              }
            },
            error => {
              if (this._isMounted && !axios.isCancel(error)) {
                errorMsg.dataError = t('Errors.generic_error');

                if (error.response && error.response.status) {
                  let status = error.response.status;
                  if (status === 401) {
                    return window.location.href = '/logout';
                  }
                } else {
                  if (error.message != null && error.message === 'Network Error') {
                    errorMsg.dataError = t("Errors.connection_error");
                  }
                }
                this.setState({
                  errorMsg,
                  hasMore: false,
                  refreshing: false,
                });
              }
            },
          );
      } else {
        this.setState({ refreshing: false });
      }
    }
  };

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      page: 0
    },

      () => {
        this.getInfo(true);
      });

  }


  render() {
    const { t } = this.props;
    var placeholderSearch = t('Common.search_city');
    return (
      <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title> {t('Common.discover_question')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid={true}>
              <Form>
                <Form.Group controlId="query" className="form_field">
                  <Form.Control type="text" className="form_field_input dark_text" placeholder={placeholderSearch} name="query" value={this.state.query} onChange={this.handleInputChange} />
                </Form.Group>
                <InfiniteScroll
                  dataLength={this.state.results.length}
                  next={this.getInfo}
                  hasMore={this.state.hasMore}
                  height={150}
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                  <Container fluid={true} className="search-list-container">
                    {
                      this.state.query.length > 0 && this.state.results && (
                        <ListGroup>
                          {(this.state.results.map((result, index) =>
                            <ListGroup.Item key={index}>
                              <Row className="row-link-container" onClick={(e) => this.props.handleSaveCity(result.cityId, result.info?result.info.name:result.name)}>
                                <Col sm={12}>
                                  <i style={{ color: GenerateColor(result.name) }} className="fa fa-map-marker" aria-hidden="true"></i>
                                  <span className="link-icon">{result.name + ', '+ result.countryCode}</span>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      )
                    }
                  </Container>
                </InfiniteScroll>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
            
    )
  }
}


export default withTranslation()(SearchCities);
