import React, { PureComponent } from 'react';
import axios from 'axios';
import { API_BASE_URL} from '../Constants.js';
import Loader from '../components/Loader';

class CheckUser extends PureComponent {
    
    componentDidMount() {
        const token = this.props.match.params.token;
        
        axios.get(API_BASE_URL+'/auth/verifyuser?token='+token)
        .then((response) => {
            return window.location.href = '/user_validated';
        }, (error) => {
          
            if (error.response && error.response.status) {
              let status = error.response.status;
              if(status===409){
                return window.location.href = '/user_already_validated';
              }

            return window.location.href = '/user_not_validated';
          }
        });

    }
    
    render() {
        return  <Loader/>;
      }
    
}

export default CheckUser;
