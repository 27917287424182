import React, { PureComponent } from 'react';
import logo from '../img/wdl/wd_camion.png';
import {Image} from 'react-bootstrap';

class WDLLoader extends PureComponent {
    
    render() {
        return (
            <div className="wdl-loader-container">
              <Image src={logo} alt="Worldust" className="center-block" fluid/>
            </div>


        );
    }
}

export default WDLLoader;
