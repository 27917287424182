import React, { PureComponent } from 'react'
import { Row, Col, Button, ListGroup, Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import RestaurantManagePage from '../components/RestaurantManagePage';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN } from '../Constants.js';


class RestaurantList extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        isAdmin: this.props.isAdmin,
        keyMAP: Math.random(),
        selectedRestaurant: null,
        restaurants: [],
        errorMsg: {},
        successMessage: '',
        showAdminRestaurantModal: false,
      }

      this.showRestaurantInfo = this.showRestaurantInfo.bind(this);
      this.handleAddRestaurantClick = this.handleAddRestaurantClick.bind(this);
      this.handleShowRestaurantModal = this.handleShowRestaurantModal.bind(this);
      this.getRestaurantsByCity = this.getRestaurantsByCity.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.updateRestaurantsList = this.updateRestaurantsList.bind(this);

  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  getRestaurantsByCity() {

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    axios.get(API_BASE_URL+'/restaurant/getAll?cityId='+this.props.cityId, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({
          restaurants: response.data?response.data:[]
        });
      }

    }, (error) => {
      if (this._isMounted) {
        let errorMsg={};
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg});
      }
    });
  }

  handleDeleteRestaurant = (id) => {
    if (this._isMounted) {
      let restaurants = this.state.restaurants.filter(item => item.restaurantId !== id);
      
      this.setState({
        restaurants
      });
    }
  }

  handleClose() {
    this.setState({showAdminRestaurantModal:false});
  }

  updateRestaurantsList(restaurant, isUpdating){
      
    if (this._isMounted) {
      let restaurants = [...this.state.restaurants];
      if(isUpdating){
        for(var i=0; i<restaurants.length; i++){
          if(restaurants[i].restaurantId===restaurant.restaurantId){
            restaurants[i] = restaurant;
            break;
          }
        }
      }else{
        restaurants.push(restaurant);
      }
      this.setState({restaurants});
   }
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.state.isAdmin){
      this.getRestaurantsByCity();
    }
  }


  handleAddRestaurantClick(){
    if(this.state.isAdmin && this.props.adminMode){
      var createdRestaurant = {
        name: "",
        restaurantId: "",
        date: '',
        image: '',
        link: '',
        address: '',
      }; 

      this.handleShowRestaurantModal(createdRestaurant);
    }

  }

  handleShowRestaurantModal(selectedRestaurant) {
    this.setState({selectedRestaurant, showAdminRestaurantModal: true});
  }

  showRestaurantInfo = (restaurant) => {


        this.setState({selectedRestaurant: restaurant,
          successMessage: '',
          errorMsg: {}
         }, this.handleShowRestaurantModal(true));
     


  }



  render() {
    const { t } = this.props;

    return (
      <div className="tab-content-container">
        <div className="tab-content-menu justify-content-md-center text-center">
            <span style={{ borderBottom: 'solid 2px ' + this.props.cityColor }}  className="city-title" onClick={(e) => this.props.showCityInfo()}>{(this.props.cityInfo)?this.props.cityInfo.cityName:""}</span>
        </div>
        <div className="tab-content-body" style={{ height: this.props.height }}>
          <ListGroup className="max-height-container">
            {this.state.restaurants.length===0 && (
              <div className="separator">
                <Row className="justify-content-md-center">
                  <Col sm={12} className="text-center">
                    <span>{t('Restaurant.not_found')}</span>
                  </Col>
                </Row>
              </div>
            )}
            
          {this.state.restaurants && (this.state.restaurants.map(restaurant =>
            <ListGroup.Item key={restaurant.restaurantId}>
                <div className="row-link-container"  onClick={(e) => this.showRestaurantInfo(restaurant)}>
                  
                      <Image fluid={true} src={restaurant.image} className={"list-img"}/>
                      <span className="link-icon unlocked-text">{restaurant.name}</span>
                  </div>
              </ListGroup.Item>
            ))}
            </ListGroup>
            

            {this.state.isAdmin && this.props.cityId && this.props.adminMode && (
              <Button variant={'primary'} type="button" onClick={(e) => this.handleAddRestaurantClick()} className="add-button">
                <i className="fa fa-plus fa-2x" aria-hidden="true"></i>
              </Button>
            )}
          </div>
          {this.state.showAdminRestaurantModal && (
          <RestaurantManagePage restaurant={this.state.selectedRestaurant} showModal={this.state.showAdminRestaurantModal} handleClose={this.handleClose} cityId={this.props.cityId}
            updateRestaurantsList={this.updateRestaurantsList}
            handleDeleteRestaurant={this.handleDeleteRestaurant}></RestaurantManagePage>
          )}
      </div>


    )
  }
}

export default withTranslation()(RestaurantList);
