import React, { PureComponent } from 'react';
import { API_BASE_URL, ACCESS_TOKEN, HOME_TAB, CURRENT_CITY, ADMIN_MODE, MENU_HEIGHT, USER, REVERSE_GEOCODING_URL} from '../Constants.js';

import CityPage from '../components/CityPage';
import Loader from '../components/Loader';
import L from 'leaflet';
import AttractionManagePage from '../components/AttractionManagePage';
import AttractionPage from '../components/AttractionPage';

import { Row, Col, Container, Tab, Nav} from 'react-bootstrap';
import {geolocated} from 'react-geolocated';
import WorldustMap from '../components/WorldustMap';
import AttractionsList from '../components/AttractionsList';
import RestaurantList from '../components/RestaurantList';

import {GenerateColor} from '../functions/FormUtils.js';
import Discoveries from '../components/Discoveries';
import PlatesList from '../components/PlatesList';

import UserAppManage from '../components/UserAppManage';
import axios from 'axios';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import PlatePage from '../components/PlatePage';
import PlateManagePage from '../components/PlateManagePage';

class MenuDiscovery extends PureComponent {

    _isMounted = false;

    constructor(props) {
        super(props);
        const height = window.innerHeight-MENU_HEIGHT;

        let currentKey="map";
        if(localStorage.getItem(HOME_TAB)!=null){
          currentKey=localStorage.getItem(HOME_TAB);
        }
        let adminMode=false;
        if(localStorage.getItem(ADMIN_MODE)!=null){
          adminMode=localStorage.getItem(ADMIN_MODE);
          if(adminMode==="true"){
            adminMode=true;
          }else{
            adminMode=false;
          }
        }

        let position = [10.459911746224176, -32.89786068722606];
        let userPosition = null;
        this.state = {
            tab:currentKey,
            cityId: this.props.cityId,
            cityInfo: null,
            mapKey: Math.random(),
            attractions: [],
            plates: [],
            height: height,
            user: this.props.user,
            isAdmin: this.props.isAdmin,
            isAdvertiser: this.props.isAdvertiser,
            adminMode,
            position: position,
            userPosition: userPosition,
            loading: false,
            cityColor: 'white',
            showCityModal: false,
            errorMsg: {},
            buttonSearchMap: 'all',
            selectedAttraction: null,
            showAdminAttractionModal: false,
            showAttractionModal: false,
            showPlateModal: false,
            selectedPlate: null,
            showAdminPlateModal: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.getMapDiscoveries = this.getMapDiscoveries.bind(this);
        this.updateUserPosition = this.updateUserPosition.bind(this);
        this.getUserPosition = this.getUserPosition.bind(this);
        this.showCityInfo = this.showCityInfo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateAttractionList = this.updateAttractionList.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.updatePlateInfo = this.updatePlateInfo.bind(this);
        this.updatePlateList = this.updatePlateList.bind(this);
        this.handleDeletePlate = this.handleDeletePlate.bind(this);
        this.handleChangeSearchOnMap = this.handleChangeSearchOnMap.bind(this);
        this.handleShowAttractionModal = this.handleShowAttractionModal.bind(this);
        this.handleShowPlateModal = this.handleShowPlateModal.bind(this);
        this.enableAdminMode = this.enableAdminMode.bind(this);
        this.saveNewAttraction = this.saveNewAttraction.bind(this);
        this.wishCallback = this.wishCallback.bind(this);
        this.wishCallbackPlates = this.wishCallbackPlates.bind(this);
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      const height = window.innerHeight-MENU_HEIGHT;
      this.setState({ height: height });
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener("resize", this.updateDimensions.bind(this));
      if(!this.state.cityId && this.props.isGeolocationAvailable && this.props.isGeolocationEnabled) {
        this.updateUserPosition();
      }else{
        this.getMapDiscoveries();
      }
    }

    wishCallback(wished) {
      let attractions = [...this.state.attractions];
      for(var i=0; i<attractions.length; i++){
        if(attractions[i].attractionId === this.state.selectedAttraction.attractionId){
          attractions[i].wished = wished;
          break;
        }
      }
      this.setState({attractions});
    }

    wishCallbackPlates(wished) {
      let plates = [...this.state.plates];
      for(var i=0; i<plates.length; i++){
        if(plates[i].attractionId === this.state.selectedAttraction.attractionId){
          plates[i].wished = wished;
          break;
        }
      }
      this.setState({plates});
    }
    

    

    saveNewAttraction(attraction, disableLoading){

      let errorMsg = {...this.state.errorMsg}

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      data["attractionId"]=attraction.attractionId;
      data["cityId"]=this.state.cityId;
      data["name"]=attraction.name;
      data["image"]=attraction.image;
      data["imageData"]=attraction.imageData;
      data["latitude"]=attraction.latitude;
      data["longitude"]=attraction.longitude;
      data["level"]=attraction.level;
      data["closed"]=attraction.closed;

      const { t } = this.props;
      errorMsg.dataError = '';

      this.setState({errorMsg,
        loading: disableLoading?false:true,
        successMessage: ''
      });

      axios.post(API_BASE_URL+'/attraction/admin/save?lang='+i18n.language, data, config)
      .then((response) => {
        if (this._isMounted) {
          
          let newAttraction = response.data;
          newAttraction['position'] = [newAttraction.latitude, newAttraction.longitude];
          newAttraction['nameInfo'] = (newAttraction.info)?newAttraction.info.name: '';
          newAttraction['description'] = (newAttraction.info)?newAttraction.info.description: '';
          newAttraction['languageId'] = (newAttraction.info)?newAttraction.info.languageId: '';
          newAttraction['linkInfo'] = (newAttraction.info)?newAttraction.info.linkInfo: '';
          newAttraction['userAttraction'] = (newAttraction.userAttraction)?newAttraction.userAttraction: null;
          newAttraction['inactive']=false;
          var isUpdating = false;
          if(attraction.attractionId!==null && attraction.attractionId!==""){
            isUpdating = true;
          }

          const { t } = this.props;
          this.setState({loading: false,
            selectedAttraction: newAttraction,
            successMessage: t('Attractions.saved')}, 
            this.updateAttractionList(newAttraction, isUpdating));
        }

      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });
    }

    handleChangeSearchOnMap(value) {
      let attractions = [...this.state.attractions];
      var i=0;
      if(value==="all"){
        for(i=0; i<attractions.length; i++){
          attractions[i].inactive=false;
        }
      }else if(value==="unlocked"){
        for(i=0; i<attractions.length; i++){
          if(attractions[i].userAttraction){
            attractions[i].inactive=false;
          }else{
            attractions[i].inactive=true;
          }
        }
      }else if(value==="to_unlock"){
        for(i=0; i<attractions.length; i++){
          if(attractions[i].userAttraction){
            attractions[i].inactive=true;
          }else{
            attractions[i].inactive=false;
          }
        }
      }
  
      this.setState({attractions,
        buttonSearchMap: value});
    }

    getAddressInfo(position){
      if(position){
      let errorMsg = {};
  
      axios
      .get(REVERSE_GEOCODING_URL+'?latitude='+position[0]+'&longitude='+position[1]+'&localityLanguage=en')
      .then(
        response => {
          if (this._isMounted) {
            try{
              var data = response.data;
              var cityInfo = {};
              cityInfo['state'] = data.principalSubdivision?data.principalSubdivision:data.countryName;
              cityInfo['country'] = data.countryCode;
              var cityData = data.localityInfo.administrative;
              var cityNames = [];
              if(data && cityData && cityData.length>0){
                for(var i=0; i<cityData.length; i++){
                  cityNames.push(cityData[i].name);
                }
                cityInfo['cityNames'] = cityNames;
                if(data.city!==""){
                    cityInfo['cityName'] =  data.city;
                } else {
                  if(data.principalSubdivision) {
                    cityInfo['cityName'] =  data.principalSubdivision;
                  } else {
                    cityInfo['cityName'] =  data.locality;
                  }
                }
                
                this.getMapDiscoveries(cityInfo);
              } else{
                this.getMapDiscoveries();
              }
            }catch(e){
              this.getMapDiscoveries();
            }
           
          }
        },
        error => {
          if (this._isMounted) {
            errorMsg.dataError = i18n.t('Errors.generic_error');
            this.setState({errorMsg});
          }
        },
      );
      }
    }

    updateUserPosition() {
      let component = this;

      navigator.geolocation.getCurrentPosition(function(position) {
        if (component._isMounted) {
          let userPosition = [position.coords.latitude, position.coords.longitude];
          if(userPosition!=null){
            
            component.setState({
                userPosition,
                position: userPosition,
                mapKey: Math.random(),
              }, component.getAddressInfo(userPosition));

            }else{
              component.getMapDiscoveries();
            }

        }
      });
    }

    getUserInfo(){
      this._isMounted = true;

      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {...this.state.errorMsg}

      errorMsg.dataError ='';
      this.setState({errorMsg
      });

      axios.get(API_BASE_URL+'/user/getOne/'+this.state.user.userId, config)
      .then((response) => {
        if (this._isMounted) {
          localStorage.setItem(USER, JSON.stringify(response.data));
          let userInfo = response.data;

          this.setState({
            user: userInfo
          });
        }

      }, (error) => {
        if (this._isMounted) {

          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg});
        }
      });
    }

    showCityInfo() {
      let cityInfo = this.state.cityInfo;
      if(cityInfo){

        this.setState({
          showCityModal: true});
      }

    }

    handleClose() {
        this.setState({
          showCityModal: false, 
          showAdminAttractionModal: false,
          showAdminPlateModal: false,
          showAttractionModal: false,
          showPlateModal: false,
          selectedAttraction: null,
          selectedPlate: null,
        });
    }

    getUserPosition() {
      localStorage.removeItem(CURRENT_CITY);
      this.setState({cityId: null}, this.updateUserPosition());
    }

    handleSelect(key) {
      if(key==='profile' || key==='social'){
        this.getUserInfo();
      }

      localStorage.setItem(HOME_TAB, key);
      let tab = key;
      this.setState({tab});
    }


    updatePlateInfo(plate){
      let plates = [...this.state.plates];
      for(var i=0; i<plates.length; i++){
        if(plates[i].attractionId===plate.attractionId){
          plates[i].nameInfo=plate.nameInfo;
          plates[i].description=plate.description;
          plates[i].linkInfo=plate.linkInfo;
          break;
        }
      }
      this.setState({plates});
    }

    updateAttractionList(attraction, isUpdating){
      if (this._isMounted) {
        let attractions = [...this.state.attractions];
        let imageAttr;
        if(attraction.image || attraction.userAttraction.image){
          imageAttr = attraction.userAttraction? attraction.userAttraction.image: attraction.image;
        }else{
            imageAttr = '../img/marker-default.png';
        }
        attraction['imageIcon'] = imageAttr;

        attraction['pointerIcon'] = new L.Icon({
          className : "marker-lev-"+attraction.level+" "+ (attraction.userAttraction ? "unlocked-attraction-lev-"+attraction.level : "locked-attraction"),
          iconUrl: imageAttr,
          iconRetinaUrl: imageAttr,
          popupAnchor: [10, -44],
          iconSize: [60, 60]
        });
          if(isUpdating){
            for(var i=0; i<attractions.length; i++){
              if(attractions[i].attractionId===attraction.attractionId){
                attractions[i] = attraction;
                break;
              }
            }
          }else{
            attractions.push(attraction);
          }
        
        this.setState({attractions});
      }
    }

    updatePlateList(plate, isUpdating){
      if (this._isMounted) {
        let plates = [...this.state.plates];
        let imageAttr;
        if (plate.image || plate.userAttraction.image) {
          imageAttr = plate.userAttraction ? plate.userAttraction.image : plate.image;
        } else {
          imageAttr = '../img/marker-default.png';
        }
        plate['imageIcon'] = imageAttr;
        if(isUpdating){
          for(var i=0; i<plates.length; i++){
            if(plates[i].attractionId===plate.attractionId){
              plates[i] = plate;
              break;
            }
          }
        }else{
          plates.push(plate);
        }

        this.setState({plates});
     }
    }

    handleDelete = (id) => {
      if (this._isMounted) {
        let attractions = this.state.attractions.filter(item => item.attractionId !== id);
          
        this.setState({
          selectedAttraction: null,
          showAdminAttractionModal: false,
          attractions
        });
      }
    }

    handleDeletePlate = (id) => {
      if (this._isMounted) {
        let plates = this.state.plates.filter(item => item.attractionId !== id);
        
        this.setState({
          plates
        });
      }
    }


    getMapDiscoveries(currentCityInfo){
      var data = null;

      let position = this.state.userPosition?this.state.userPosition:this.state.position;
      
      if(this.state.cityId!=null){
        data={};
        data.cityId = this.state.cityId;
        data.lang = i18n.language;
      }else if(currentCityInfo!=null){
        data={};
        data.cityNames = currentCityInfo.cityNames;
        data.state = currentCityInfo.state;
        data.country = currentCityInfo.country;
        data.lang = i18n.language;
      }
      if(data){
      
        const { t } = this.props;
        var config = {
            headers: {
              'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
          }
        };

        let errorMsg = {...this.state.errorMsg}

        errorMsg.dataError ='';
        var cityName = '';
        if(currentCityInfo) {
          cityName = currentCityInfo.cityName;
        }

        var cityColor = GenerateColor(cityName);
        this.setState({errorMsg,
          cityInfo: currentCityInfo?currentCityInfo: this.state.cityInfo,
          cityColor,
          loading: true
        });

        

        axios.post(API_BASE_URL+'/discovery/getMapDiscoveries', data, config)
        .then((response) => {
          if (this._isMounted) {
            let cityDiscovery=null;
            let cityInfo=currentCityInfo;
            let attractions=[];
            let plates=[];
            let cityId = null;
            if(response!==null && response.data!==null && response.data!==""){
                cityDiscovery=response.data;
                if(cityDiscovery.attractions){
                  attractions=cityDiscovery.attractions;
                  if(attractions){
                    for(var i=0; i<attractions.length; i++){
                      var attraction = attractions[i];
                      attraction['position'] = [attraction.latitude, attraction.longitude];
                      attraction['nameInfo'] = (attraction.info)?attraction.info.name: '';
                      attraction['description'] = (attraction.info)?attraction.info.description: '';
                      attraction['languageId'] = (attraction.info)?attraction.info.languageId: '';
                      attraction['linkInfo'] = (attraction.info)?attraction.info.linkInfo: '';
                      attraction['userAttraction'] = (attraction.userAttraction)?attraction.userAttraction: null;
                      attraction['inactive']=false;
                      let imageAttr;
                      if(attraction.image || (attraction.userAttraction && attraction.userAttraction.image)){
                          imageAttr = attraction.userAttraction? attraction.userAttraction.image: attraction.image;
                      }else{
                          imageAttr = '../img/marker-default.png';
                      }
                      attraction['imageIcon'] = imageAttr;
                    }
                  }
                }
                if(cityDiscovery.plates){
                  plates=cityDiscovery.plates;
                  if(plates){
                    for(var i=0; i<plates.length; i++){
                      var plate = plates[i];
                      plate['nameInfo'] = (plate.info)?plate.info.name: '';
                      plate['description'] = (plate.info)?plate.info.description: '';
                      plate['languageId'] = (plate.info)?plate.info.languageId: '';
                      plate['linkInfo'] = (plate.info)?plate.info.linkInfo: '';
                      plate['userAttraction'] = (plate.userAttraction)?plate.userAttraction: null;
                      let imageAttr;
                      if(plate.image || plate.userAttraction){
                          imageAttr = plate.userAttraction? plate.userAttraction.image: plate.image;
                      }else{
                          imageAttr = '../img/marker-default.png';
                      }
                      plate['imageIcon'] = imageAttr;

                    }
                  }

                }
                if(cityDiscovery.cityId!=null){
                  cityInfo = {};
                  cityInfo['cityId'] = cityDiscovery.cityId;
                  cityInfo['latitude'] = cityDiscovery.latitude;
                  cityInfo['longitude'] = cityDiscovery.longitude;
                  cityInfo['cityName'] = (cityDiscovery.info)?cityDiscovery.info.name:cityDiscovery.name;
                  cityInfo['state'] = cityDiscovery.state;
                  cityInfo['country'] = cityDiscovery.countryCode;
                  cityInfo['description'] = (cityDiscovery.info)?cityDiscovery.info.description:'';
                  cityInfo['linkInfo'] = (cityDiscovery.info)?cityDiscovery.info.linkInfo:'';
                  cityId = cityDiscovery.cityId;
                  cityColor = 'white';
        
                  if(cityDiscovery.name) {
                    cityColor = GenerateColor(cityInfo.cityName);
                  }
                }

                if(this.state.cityId && cityDiscovery.latitude && cityDiscovery.longitude){
                  position = [cityDiscovery.latitude, cityDiscovery.longitude];
                }
            }

            var mapKey = Math.random();
            this.setState({cityId,
              cityInfo,
              cityColor,
              mapKey,
              attractions,
              plates,
              errorMsg,
              position,
              loading: false
            });
          }
        }, (error) => {
          if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
                return window.location.href = '/logout';            
              }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
        });

      }


    }

    enableAdminMode() {
      var adminMode = !this.state.adminMode;
      localStorage.setItem(ADMIN_MODE, adminMode);
      this.setState({adminMode});
    }


    handleShowAttractionModal(selectedAttraction, isAdmin) {
      if(isAdmin){
        this.setState({selectedAttraction, showAdminAttractionModal: true, showAttractionModal: false});
      }else{
        this.setState({selectedAttraction, showAdminAttractionModal: false, showAttractionModal: true});
      }
      
    }

    handleShowPlateModal(selectedPlate, isAdmin) {
      if(isAdmin){
        this.setState({selectedPlate, showAdminPlateModal: true, showPlateModal: false});
      }else{
        this.setState({selectedPlate, showAdminPlateModal: false, showPlateModal: true});
      }
      
    }

    render() {

         return (
           <Tab.Container id="menu-discovery" defaultActiveKey="map" activeKey={this.state.tab} onSelect={k =>this.handleSelect(k)}>
             {this.state.loading && (
               <Loader></Loader>
             )}

             {this.state.showCityModal && (
               <CityPage city={this.state.cityInfo} showModal={this.state.showCityModal} handleClose={this.handleClose}></CityPage>
             )}

             <Row className="tab-menu">
               <Col sm={12}>
                 <Nav className="tabs-item-menu">
                   <Nav.Item>
                     <Nav.Link eventKey="map"><i className="fa fa-map-o" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                   <Nav.Item>
                     <Nav.Link eventKey="attractions"><i className="fa fa-university" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                   <Nav.Item>
                     <Nav.Link eventKey="plate"><i className="fa fa-cutlery" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                   {this.props.isAdmin &&
                   <Nav.Item>
                     <Nav.Link eventKey="restaurant"><i className="fa fa-shopping-basket" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                    }
                   <Nav.Item>
                     <Nav.Link eventKey="social"><i className="fa fa-globe" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                   <Nav.Item>
                     <Nav.Link eventKey="profile" ><i className="fa fa-user-circle-o" aria-hidden="true"></i>
                     </Nav.Link>
                   </Nav.Item>
                 </Nav>
               </Col>
             </Row>
             <Row className="tab-menu-content">
               <Col sm={12}>
                 <Tab.Content >
                   <Tab.Pane eventKey="map" >
                     <Container fluid={true} className="no-space-container">
                         <Row className="justify-content-md-center">
                           <Col sm={12} >
                             <WorldustMap key={this.state.mapKey} 
                               user={this.state.user} 
                               isAdmin={this.state.isAdmin} 
                               height={this.state.height} 
                               cityInfo={this.state.cityInfo} 
                               attractions={this.state.attractions}
                               cityId={this.state.cityId} 
                               position={this.state.position} 
                               cityColor={this.state.cityColor}
                               userPosition={this.state.userPosition} 
                               getUserPosition={this.getUserPosition} 
                               showCityInfo={this.showCityInfo}
                               handleChangeSearchOnMap={this.handleChangeSearchOnMap} 
                               buttonSearchMap={this.state.buttonSearchMap}
                               saveNewAttraction={this.saveNewAttraction} 
                               adminMode={this.state.adminMode} 
                               enableAdminMode={this.enableAdminMode}
                               handleShowAttractionModal={this.handleShowAttractionModal} 
                               selectedAttraction={this.state.selectedAttraction}
                               >
                             </WorldustMap>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                   <Tab.Pane eventKey="attractions">
                     <Container fluid={true} className="no-space-container">
                       <Row className="justify-content-md-center">
                         <Col sm={12} >
                           <AttractionsList 
                             key={this.state.mapKey} 
                             user={this.state.user} 
                             isAdmin={this.state.isAdmin} 
                             height={this.state.height} 
                             cityInfo={this.state.cityInfo} 
                             attractions={this.state.attractions}
                             cityId={this.state.cityId} 
                             position={this.state.position} 
                             cityColor={this.state.cityColor}
                             showCityInfo={this.showCityInfo}
                             handleShowAttractionModal={this.handleShowAttractionModal} 
                             adminMode={this.state.adminMode}>
                           </AttractionsList>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                   <Tab.Pane eventKey="plate">
                     <Container fluid={true} className="no-space-container">
                       <Row className="justify-content-md-center">
                         <Col sm={12} >
                           <PlatesList 
                             key={this.state.mapKey} 
                             user={this.state.user} 
                             isAdmin={this.state.isAdmin} 
                             height={this.state.height} 
                             cityInfo={this.state.cityInfo} 
                             plates={this.state.plates}
                             cityId={this.state.cityId} 
                             cityColor={this.state.cityColor}
                             showCityInfo={this.showCityInfo} 
                             updatePlateInfo={this.updatePlateInfo}
                             updatePlateList={this.updatePlateList} 
                             handleDeletePlate={this.handleDeletePlate}
                             handleShowPlateModal={this.handleShowPlateModal}
                             adminMode={this.state.adminMode}>
                           </PlatesList>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                   <Tab.Pane eventKey="restaurant">
                     <Container fluid={true} className="no-space-container">
                       <Row className="justify-content-md-center">
                         <Col sm={12} >
                           <RestaurantList 
                             key={this.state.mapKey} 
                             user={this.state.user} 
                             isAdmin={this.state.isAdmin} 
                             height={this.state.height} 
                             cityInfo={this.state.cityInfo} 
                             cityId={this.state.cityId} 
                             cityColor={this.state.cityColor}
                             showCityInfo={this.showCityInfo} 
                             adminMode={this.state.adminMode}>
                           </RestaurantList>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                   <Tab.Pane eventKey="social">
                     <Container fluid={true} className="no-space-container">
                       <Row className="justify-content-md-center">
                         <Col sm={12} >
                           <Discoveries 
                             user={this.state.user} 
                             isAdmin={this.state.isAdmin} 
                             key="discovery" 
                             cityId={this.state.cityId} 
                             height={this.state.height}>
                           </Discoveries>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                   <Tab.Pane eventKey="profile">
                     <Container fluid={true} className="no-space-container">
                       <Row className="justify-content-md-center">
                         <Col sm={12} >
                           <UserAppManage 
                            user={this.state.user} 
                            handleChangeCity={this.props.handleChangeCity} 
                            isAdmin={this.state.isAdmin} 
                            isAdvertiser={this.state.isAdvertiser} 
                            getUserInfo={this.getUserInfo} 
                            key="profile_opt" 
                            height={this.state.height}>
                          </UserAppManage>
                         </Col>
                       </Row>
                     </Container>
                   </Tab.Pane>
                 </Tab.Content>
               </Col>
             </Row>
             {this.state.showAdminAttractionModal && (
                <AttractionManagePage key={this.state.selectedAttraction.attractionId} attraction={this.state.selectedAttraction} showModal={this.state.showAdminAttractionModal} handleClose={this.handleClose} cityId={this.state.cityId}
                  saveNewAttractionInfo={this.saveNewAttractionInfo} saveNewAttraction={this.saveNewAttraction}
                  handleDelete={this.handleDelete} updateAttractionList={this.updateAttractionList}/>
              )}
              {this.state.showAttractionModal && (
                <AttractionPage 
                  attraction={this.state.selectedAttraction} 
                  showModal={this.state.showAttractionModal} 
                  handleClose={this.handleClose}
                  wishCallback={this.wishCallback}
                />
              )}
              {this.state.showAdminPlateModal && (
              <PlateManagePage plate={this.state.selectedPlate} showModal={this.state.showAdminPlateModal} handleClose={this.handleClose} cityId={this.state.cityId}
                updatePlateList={this.updatePlateList} updatePlateInfo={this.updatePlateInfo}
                handleDeletePlate={this.handleDeletePlate}></PlateManagePage>
              )}
              {this.state.showPlateModal && (
                <PlatePage plate={this.state.selectedPlate} showModal={this.state.showPlateModal} handleClose={this.handleClose}></PlatePage>
              )}
           </Tab.Container>

         );
       

    }
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
})(withTranslation()(MenuDiscovery));
