import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Table, Button} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';

import moment from 'moment';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import Select from 'react-select';

import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLShipments extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  _moment_locales = {
    en: 'en-gb',
    fr: 'fr',
    it: 'it',
    es: 'es'
  }

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }

      
      const { t } = this.props;
      let momentLocale = moment().locale(this._moment_locales[i18n.language]).localeData();
      let locale = {
        daysOfWeek: momentLocale._weekdaysShort,
        monthNames: momentLocale._monthsShort,
      };

      const search = props.location.search;
      const clientId = new URLSearchParams(search).get('clientId');
      const vectorId = new URLSearchParams(search).get('vectorId');
      const currentQuery = new URLSearchParams(search).get('query');
      const startDateMillis = new URLSearchParams(search).get('startDate');
      const endDateMillis = new URLSearchParams(search).get('endDate');
      let startDate = null;
      let endDate = null;
      if(startDateMillis) {
        startDate = new Date(Number(startDateMillis));
      } else {
        startDate = startOfMonth(new Date());
      }

      if(endDateMillis) {
        endDate = new Date(Number(endDateMillis));
      } else {
        endDate = endOfMonth(new Date());
      }

      this.state = {
        companyId: this.props.match.params.companyId,
        user: user,
        isAdmin: isAdmin,
        query: currentQuery?currentQuery: '',
        shipments: [],
        vectors: [{label: t('Common.select'), value: null}],
        clients: [{label: t('Common.select'), value: null}],
        startDate,
        endDate,
        locale,
        totals: {
          numbers: 0,
          payment: 0,
          commission: 0,
        },
        selectedClient: null,
        selectedVector: null,
        viewHeight: window.innerHeight,
        clientId,
        vectorId,
        page: 0,
        hasMore: false,
      }
      this.getShipments = this.getShipments.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
      this.getCompanyInfo = this.getCompanyInfo.bind(this);
      this.handleSelectVector = this.handleSelectVector.bind(this);
      this.handleSelectClient = this.handleSelectClient.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.calculateTotals = this.calculateTotals.bind(this);


  }

  handleChangeDateRange(start, end, label) {
    this.setState({startDate: moment(start).toDate(), endDate: moment(end).toDate()})
  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getCompanyInfo();
  }

  calculateTotals() {
    let shipments = this.state.shipments;
    let totals = {
      numbers: shipments.length,
      payment: 0,
      commission: 0,
    }

    for(let i=0; i<shipments.length; i++) {
      totals.payment+=shipments[i].payment;
      totals.commission+=shipments[i].commission;
    }

    this.setState({totals});
  }

  getCompanyInfo() {
    const requestId = 'getCompanyRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    this.setState({errorMsg, loading: true});
    

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/getAllInfo';
    
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let companyInfo = response.data;

        let vectors = this.state.vectors.concat(companyInfo.vectors.map((item) => ({label: item.name, value: item.vectorId})));
        
        let selectedVector=null;
        if(this.state.vectorId){
          selectedVector = vectors.find(vector => vector.value == this.state.vectorId);
        }

        let clients = this.state.clients.concat(companyInfo.clients.map((item) => ({label: item.name, value: item.clientId})));
        
        let selectedClient=null;
        if(this.state.clientId){
          selectedClient = clients.find(client => client.value == this.state.clientId);
        }
        this.setState({
          vectors,
          clients,
          loading: false,
          selectedClient,
          selectedVector,
        }, ()=>this.getShipments());
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, loading:false});
      }
    });
  }


  handleSelectVector(item) {
    this.setState({selectedVector: item});
  }


  handleSelectClient(item) {
    this.setState({selectedClient: item});
  }


  getShipments(refresh){
    const requestId = 'getShipmentsRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let shipments = this.state.shipments;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      page = 0;
      shipments=[];
      this.setState({errorMsg, shipments, query, page,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment'+'?page='+page+'&startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }

    if(this.state.selectedVector?.value){
      url += '&vectorId=' + this.state.selectedVector.value;
    }
    
    if(this.state.selectedClient?.value){
      url += '&clientId=' + this.state.selectedClient.value;
    }

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newShipments = response.data;
        if((newShipments && newShipments.length===0) || (newShipments && newShipments[0].itemInLastPage)){
          hasMore=false;
        }
        

        this.setState({hasMore,
          page: page+1,
          shipments: shipments.concat(newShipments),
        }, ()=>!hasMore && this.calculateTotals());
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

  

  onFormSubmit(e) {
    e.preventDefault();
    let url = '/logistic/company/'+this.state.companyId+'/shipments'+'?startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();

    if(this.state.selectedClient?.value){
      url += '&clientId=' + this.state.selectedClient.value;
    }

    if(this.state.selectedVector?.value){
      url += '&vectorId=' + this.state.selectedVector.value;
    }

    if (this.state.query && this.state.query.length>0) {
      url += '&query='+this.state.query;
    }
    return window.location.href=url;
  }


    render() {
      const { t } = this.props;
      let shipmentStates = [
        t('WDL.shipment_state_missing'), 
        t('WDL.shipment_state_scanned'),
        t('WDL.shipment_state_send'),
        t('WDL.shipment_state_original')
      ];
        return (
              
          <div className="wdl-container">
            <InfiniteScroll
                    dataLength={this.state.shipments.length}
                    next={this.getShipments}
                    hasMore={this.state.hasMore}
                    refreshFunction={(e) => this.getShipments(true)}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={10}
                    pullDownToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    releaseToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    height={this.state.viewHeight}
                    className="white-background"
                    loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                  >
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} link={'/logistic/company/'+this.state.companyId} title={'WDL.shipments'}></WDLBackButtonTitle>
              
              <Container fluid={true}>
                <Row className="justify-content-md-center">
                  <Col md={11}>
                    <Row className="justify-content-md-center" style={{marginBottom: 80, marginTop: 30}}>
                      <Col className="text-center form-group">
                        <Button style={{backgroundColor: '#486cb5'}} variant="primary" as={Link} to={"/logistic/company/"+this.state.companyId+"/shipments/add"} className="btn-login" >{t('WDL.add_shipment')}</Button>
                      </Col>
                    </Row>
                    <Row className="form_field wdl-search-container">
                      <Col>
                      <Form onSubmit={this.onFormSubmit}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                            <DateRangePicker
                              initialSettings={{ startDate: this.state.startDate, 
                                endDate: this.state.endDate, 
                                locale: this.state.locale,
                                autoApply: true, 
                              }}
                              onCallback={this.handleChangeDateRange}
                            >
                              <input type="text" className="form-control" />
                            </DateRangePicker>
                          </Form.Group>
                          <Col></Col>
                        </Row>
                        <Row>
                          <Form.Group as={Col}  controlId="query" className="form_field">
                            <Form.Label className="form_field_label">{t('WDL.search')}</Form.Label>
                            <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.text_ph')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                          </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group as={Col} controlId="client" className="form_field">
                          <Form.Label className="form_field_label">{t('WDL.client')}</Form.Label>
                          <Select
                            value={this.state.selectedClient || ''}
                            onChange={this.handleSelectClient}
                            options={this.state.clients}
                            placeholder={t('Common.select')}
                          />                        
                        </Form.Group>
                        <Form.Group as={Col} controlId="vector" className="form_field">
                          <Form.Label className="form_field_label">{t('WDL.vector')}</Form.Label>
                          <Select
                            value={this.state.selectedVector || ''}
                            onChange={this.handleSelectVector}
                            options={this.state.vectors}
                            placeholder={t('Common.select')}
                          />                        
                        </Form.Group>
                        </Row>
                        <Button style={{padding: 10}} variant="primary" type="submit">
                          <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                        </Button>
                      </Form>
                      </Col>
                    </Row>

                      

                          <Table striped borderless hover responsive className="wdl-table">
                            <thead>
                              <tr style={{backgroundColor: '#486cb5', color: 'white'}}>
                                <th>{t('WDL.number')}</th>
                                <th>{t('WDL.date')}</th>
                                <th>{t('WDL.name')}</th>
                                <th>{t('WDL.client')}</th>
                                <th>{t('WDL.vector')}</th>
                                <th>{t('WDL.commission')}</th>
                                <th>{t('WDL.payment')}</th>
                                <th>{t('WDL.shipment_state')}</th>
                              </tr>
                            </thead>
                            <tbody>
                                {this.state.shipments && this.state.shipments.map((shipment, index) => (
                                <tr key={index} >
                                  <td>
                                    <a href={'/logistic/company/'+this.state.companyId+'/shipments/manage/'+shipment.shipmentId}>{shipment.number} </a>
                                  </td>
                                  <td>
                                    {shipment.shipDateMillis &&
                                      <span>{
                                        format(
                                          new Date(shipment.shipDateMillis),
                                          'dd/MM/yyyy',
                                          {locale: this._locales[i18n.language]}
                                        )}</span>
                                        }
                                  </td>
                                  <td>
                                    <span>{shipment.name} </span>
                                  </td>
                                  <td>
                                    <span>{shipment.client?.name} </span>
                                  </td>
                                  <td>
                                    <span>{shipment.vector?.name} </span>
                                  </td>
                                  <td>
                                    <span>{'€'}<NumberFormat value={shipment.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                  </td>
                                  <td>
                                    <span>{'€'}<NumberFormat value={shipment.payment} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                  </td>
                                  <td>
                                    <span>{shipmentStates[shipment.status]} </span>
                                  </td>
                                </tr>
                              ))}
                              
                            </tbody>
                            {this.state.shipments.length>0 && !this.state.hasMore &&
                            <tfoot>
                                <tr className="wdl-total">
                                  <td>
                                    <span>{this.state.totals.numbers}</span>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                    <span>{'€'}<NumberFormat value={this.state.totals.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                  </td>
                                  <td>
                                    <span>{'€'}<NumberFormat value={this.state.totals.payment} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                  </td>
                                  <td>
                                  </td>
                                </tr>
                              </tfoot>
                              }
                          </Table>
                          {(this.state.shipments.length===0) &&
                          
                            <div className="separator info-not-found-container">
                              <Row className="justify-content-md-center">
                                <Col sm={12} className="text-center">
                                  <span>{t('WDL.info_not_found')}</span>
                                </Col>
                              </Row>
                            </div>
                          }
                        
                            
                    
                  </Col>
                  </Row>
              </Container>
              
            </InfiniteScroll>
            </div>
            
        );
    }
}

export default withTranslation()(WDLShipments);
