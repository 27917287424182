import React, { PureComponent } from 'react';
import logo from '../img/wdl/wd_camion_full.png';
import { Row, Col, Container, Image } from 'react-bootstrap';

class WDLLogoSection extends PureComponent {
    
    render() {
        return (
          <Container>
            <Row className="logo-container-top">
              <Col xs={3} md={2}></Col>
              <Col xs={6} md={8}>
                <Image src={logo} fluid />
              </Col>
              <Col xs={3} md={2}></Col>
            </Row>
            <Row className="logo-container-bottom">
              <Col xs={4} md={4}></Col>
              <Col xs={4} md={4}>
                <h4 style={{
                    color: 'black',
                    fontWeight: 'bold'
                }}>Logistic</h4>
              </Col>
            </Row>
          </Container>
        );
    }
}

export default WDLLogoSection;
