import React, { PureComponent } from 'react';
import { Col, Form, Container, Modal, Button} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';

import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import Loader from './Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import { withTranslation } from 'react-i18next';

class RestaurantManagePage extends PureComponent {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
          cityId: this.props.cityId,
          restaurant: this.props.restaurant,
          loading: false,
          errorMsg: {},
          successMessage: ''
        }
        this.handleShowRestaurantInfo = this.handleShowRestaurantInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeRestaurantData = this.handleChangeRestaurantData.bind(this);
        this.handleUploadFileImg = this.handleUploadFileImg.bind(this);
        this.deleteRestaurant = this.deleteRestaurant.bind(this);
        this.saveNewRestaurant = this.saveNewRestaurant.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
    }

    handleChangeLanguage (e) {
      let target = e.target;
      let value = target.value;
      this.getRestaurantInfo(this.state.restaurant.restaurantId, value);
    };

    handleShowRestaurantInfo() {
        this.setState({
          'showRestaurantInfo': !this.state.showRestaurantInfo}, this.getRestaurantInfo(this.state.restaurant.restaurantId, this.state.languages[0]));
    }

    

    handleUploadFileImg = (event) => {
        let target = event.target;
        let restaurant = {...this.state.restaurant};
        this.setState({restaurant});
        var fileInput = false
          if(event.target.files[0]) {
              fileInput = true
          }
          if(fileInput) {
              Resizer.imageFileResizer(
                  target.files[0],
                  600,
                  600,
                  'JPEG',
                  100,
                  0,
                  uri => {
                    restaurant.imageData = uri;
                    this.setState({restaurant});
                  },
                  'base64'
              );
          }
      };

    

    handleSubmit(e){
      e.preventDefault();
      let restaurant = {...this.state.restaurant};
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      if(!restaurant.imageData && !restaurant.image){
        errorMsg.dataError = t('Errors.invalid_field');
        this.setState({errorMsg});
      }else{
        this.saveNewRestaurant(restaurant);
      }
    }

    saveNewRestaurant(restaurant){

      let errorMsg = {...this.state.errorMsg}

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      data["restaurantId"]=restaurant.restaurantId;
      data["cityId"]=this.state.cityId;
      data["name"]=restaurant.name;
      data["image"]=restaurant.image;
      data["imageData"]=restaurant.imageData;
      data["address"]=restaurant.address;
      data["link"]=restaurant.link;
      data["phoneNumber"]=restaurant.phoneNumber;
      const { t } = this.props;
      errorMsg.dataError = '';
      this.setState({errorMsg,
        loading: true,
        successMessage: ''
      });

      axios.post(API_BASE_URL+'/restaurant/admin/save', data, config)
      .then((response) => {
        if (this._isMounted) {
          
          let newRestaurant = response.data;
          var isUpdating = false;
          if(restaurant.restaurantId!==null && restaurant.restaurantId!==""){
            isUpdating = true;
          }
          const { t } = this.props;
          this.setState({loading: false,
            restaurant: newRestaurant,
            successMessage: t('Restaurant.saved')}, this.props.updateRestaurantsList(newRestaurant, isUpdating));
        }

      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });
    }


    handleChangeRestaurantData(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let restaurant = {...this.state.restaurant};

        restaurant[name] = value;
        this.setState({
          restaurant
        });

    }

    deleteRestaurant = (event, id) => {

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      this.setState({errorMsg,
        loading: true
      });

      axios.post(API_BASE_URL+'/restaurant/admin/delete/'+id, data, config)
      .then((response) => {
        if (this._isMounted) {
          this.props.handleDeleteRestaurant(id);
          
          this.setState({
            loading: false
          }, this.props.handleClose());
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });

    }

    render() {
      const { t } = this.props;
        return (

          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              {this.state.restaurant.restaurantId==="" && (<Modal.Title>  {t('Admin.add_restaurant')}</Modal.Title>)}
              {this.state.restaurant.restaurantId!=="" && (<Modal.Title>  {t('Admin.edit_restaurant')}
              <Button variant={(this.state.showRestaurantInfo ? 'primary' : 'secondary')} style={{ marginLeft: '10px' }} onClick={(e) => this
                .handleShowRestaurantInfo()}><i className="fa fa-info-circle" aria-hidden="true"></i></Button>
                </Modal.Title>)}
            </Modal.Header>
            <Modal.Body className="app_aside">
              {this.state.loading && (
                <Loader></Loader>
              )}
              <Container fluid={true}>
                  <Form onSubmit={this.handleSubmit}>
                    <input type="hidden" name="restaurantId" value={this.state.restaurant.restaurantId} ></input>
                    <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="name" className="form_field">
                           <Form.Label className="form_field_label">{t('Restaurant.name')}</Form.Label>
                           <Form.Control type="text" className="form_field_input" placeholder={t('Restaurant.name')} name="name" value={this.state.restaurant.name} onChange={this.handleChangeRestaurantData} required/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                    <Form.Group controlId="image" className="form_field">
                      <Form.Label className="form_field_label">{t('Restaurant.image')}</Form.Label>
                      <Form.Control type="file" name="image" onChange={this.handleUploadFileImg}/>
                    </Form.Group>
                    <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="address" className="form_field">
                           <Form.Label className="form_field_label">{t('Restaurant.address')}</Form.Label>
                           <Form.Control type="text" className="form_field_input" placeholder={t('Restaurant.address')} name="address" value={this.state.restaurant.address} onChange={this.handleChangeRestaurantData}/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                     <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="link" className="form_field">
                           <Form.Label className="form_field_label">{'Link'}</Form.Label>
                           <Form.Control type="text" className="form_field_input" placeholder={'Link'} name="link" value={this.state.restaurant.link} onChange={this.handleChangeRestaurantData}/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                     <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="phoneNumber" className="form_field">
                           <Form.Label className="form_field_label">{'Phone Number'}</Form.Label>
                           <Form.Control type="text" className="form_field_input" placeholder={'Phone Number'} name="phoneNumber" value={this.state.restaurant.phoneNumber} onChange={this.handleChangeRestaurantData}/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                    <Form.Group className="form_field">
                      <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                      <SuccessMessage valid={true} message={this.state.successMessage} />
                  </Form.Group>
                    <Form.Row>
                       <Col className="text-center">
                         <Button variant="primary" type="submit">{t('Restaurant.save')}</Button>
                       </Col>
                       <Col className="text-center">
                         <Button type="button" variant="secondary" onClick={(e) => this.deleteRestaurant(e, this.state.restaurant.restaurantId)}>
                           {t('Admin.delete_restaurant')}
                         </Button>
                       </Col>
                     </Form.Row>
                   </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.handleClose}>
                {t('Restaurant.close')}
              </Button>
            </Modal.Footer>
          </Modal>

        );
    }
}

export default withTranslation()(RestaurantManagePage);
