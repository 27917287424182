import React, { PureComponent } from 'react';
import axios from 'axios';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import "react-datepicker/dist/react-datepicker.css";
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { enUS, fr, it, es } from 'date-fns/esm/locale'

import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button } from 'react-bootstrap';


class WDLManageContract extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
        
        this.state = {
          companyId: this.props.match.params.companyId,
          company: null,
          user: user,
          isAdmin: isAdmin,
          loading: false,
          errorMsg: {},
          successMessage: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCompany = this.getCompany.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      this.getCompany();
    }

    


    getCompany(){
      const requestId = 'getCompanyRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true
      });
      
  
      let url = API_BASE_URL+'/logistic/company/'+this.state.companyId;
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let company = response.data;
          
          this.setState({
            company,
            loading: false,
          });
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    






    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let company = {...this.state.company};
        company[name]= value;
        this.setState({
          company,
          successMessage: '', 
          errorMsg: {}
        });


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = this.state.company;
      if(this.state.fileName!==''){
        data["imageData"]=this.state.imageData;
      }

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true, successMessage: ''});

      axios.put(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/contract', data, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({company: response.data, imageData: null, fileName: '', successMessage: t('WDL.saved'), loading: false });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };



    render() {
        const { t } = this.props;
        let formValid = this.state.company?.contractHeader || this.state.company?.transportRules || this.state.company?.contractFooter;
        return (
          <div className="wdl-container">
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} title={'WDL.transport_contract'}></WDLBackButtonTitle>
             <Container fluid={true}>
                {
                !this.state.company? (
                  this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :
                  <div className="separator">
                    <Row className="justify-content-md-center">
                      <Col sm={12} className="text-center">
                        <span>{t('WDL.info_not_found')}</span>
                      </Col>
                    </Row>
                  </div>
                ):
                <div>
                  {this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :
                  <Row className="justify-content-md-center">
                    <Col md={8} className="form_center">
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col md={12} className="text-center wdl-message-container">
                            <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                            <SuccessMessage valid={true} message={this.state.successMessage} />
                            </Col>
                          </Row>
                          <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="contract_header" className="form_field">
                              <Form.Label className="form_field_label">{t('WDL.contract_header')}</Form.Label>
                              <textarea
                                className="form-control"
                                id="contractHeader"
                                name="contractHeader"
                                rows="2"
                                onChange={this.handleChange}
                                value={this.state.company?.contractHeader || ''}
                                />
                            </Form.Group>
                            <Form.Group controlId="transportRules" className="form_field">
                              <Form.Label className="form_field_label">{t('WDL.transport_rules')}</Form.Label>
                              <textarea
                                className="form-control"
                                id="transportRules"
                                name="transportRules"
                                rows="5"
                                onChange={this.handleChange}
                                value={this.state.company?.transportRules || ''}
                                />
                            </Form.Group>
                            <Form.Group controlId="contractFooter" className="form_field">
                              <Form.Label className="form_field_label">{t('WDL.contract_footer')}</Form.Label>
                              <textarea
                                className="form-control"
                                id="contractFooter"
                                name="contractFooter"
                                rows="2"
                                onChange={this.handleChange}
                                value={this.state.company?.contractFooter || ''}
                                />
                            </Form.Group>
                            <Form.Row>
                              <Col className="text-center">
                                <Button variant="primary" type="submit" className="btn-login" disabled={!formValid}>{t('WDL.save')}</Button>
                              </Col>
                            </Form.Row>
                            
                          </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  }
                  
                  </div>
                  }
                  
              </Container>
            </div>




        );
    }
}

export default withTranslation()(WDLManageContract);
