import React, { PureComponent } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';

import moment from 'moment';
import { enUS, fr, it, es } from 'date-fns/esm/locale'

import { WDL_START_YEAR} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form, Button } from 'react-bootstrap';
import i18n from 'i18next';
import Select from 'react-select';

class WDLMonthSelector extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }
  
    _moment_locales = {
      en: 'en-gb',
      fr: 'fr',
      it: 'it',
      es: 'es'
    }

    constructor(props) {
        super(props);
        let years = [];
        let currentYear = this.props.year?this.props.year:Number(moment().format('YYYY'));
        let currentMonth = this.props.month?this.props.month:new Date().getMonth()+1;
        for(var i=WDL_START_YEAR; i<=currentYear; i++){
          years.push({label: i, value: i});
        }
        let months = [];
        let momentLocale = moment().locale(this._moment_locales[i18n.language]).localeData();
        let monthNames = momentLocale._months;
        for(var i=0; i<monthNames.length; i++){
          months.push({label: (monthNames[i]).charAt(0).toUpperCase() + monthNames[i].slice(1), value: i+1})
        }
        
        this.state = {
          months,
          years,
          selectedMonth: months.filter(item=>item.value===currentMonth),
          selectedYear: years.filter(item=>item.value===currentYear),
        };

        this.onMonthChangeHandler = this.onMonthChangeHandler.bind(this);
        this.onYearChangeHandler = this.onYearChangeHandler.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
    }

    
    
  onMonthChangeHandler = (item) => {
      this.setState({selectedMonth: item}, ()=>this.props.onSelectMonth(item.value));
  };

  onYearChangeHandler = (item) => {
    this.setState({selectedYear: item}, ()=>this.props.onSelectYear(item.value));
  };
  
    render() {
        const { t } = this.props;
        return (
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="form_field_label">{t('WDL.month')}</Form.Label>
                <Select
                value={this.state.selectedMonth}
                onChange={this.onMonthChangeHandler}
                options={this.state.months}
              />   
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="form_field_label">{t('WDL.year')}</Form.Label>
               <Select
                value={this.state.selectedYear}
                onChange={this.onYearChangeHandler}
                options={this.state.years}
              />   
            </Form.Group>
          </Row>
          
        );
    }
}

export default withTranslation()(WDLMonthSelector);
