import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal} from 'react-bootstrap';

import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class ObjectivePage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            objective: this.props.objective
        };

    }


    render() {
      const { t } = this.props;
        return (
          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="attraction-title">
                  {this.state.objective.name}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <NumberFormat value={this.state.objective.points} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} renderText={value => (
                    t("Profile.points")+": "+value
                  )}/>
                  
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="attraction-description">
                      {this.state.objective.description}
                    </p>
                  </Col>
                </Row>

                </Container>
            </Modal.Body>

          </Modal>


        );
    }
}

export default withTranslation()(ObjectivePage);
