import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Menu_admin from '../components/Menu_admin';
import Loader from '../components/Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import { API_BASE_URL, ACCESS_TOKEN, USER} from '../Constants.js';
import SearchCities from '../components/SearchCities';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal, Image } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Resizer from 'react-image-file-resizer';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'

import i18n from 'i18next';


class ManageAdvertising extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

    constructor() {
        super();
        let user = JSON.parse(localStorage.getItem(USER));

        this.state = {
            advId: "",
            user,
            text: '',
            image: '',
            imageData: null,
            cityId: '',
            cityName: '',
            fileName: '',
            formValid: false,
            link: '',
            date: '',
            advertising_list: [],
            errorMsg: {},
            successMessage: '',
            showModal: false,
            loading: false
        };

        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.handleShowCityModal = this.handleShowCityModal.bind(this);
        this.handleClearCity = this.handleClearCity.bind(this);
        this.handleSaveCity = this.handleSaveCity.bind(this);
    }

    handleShowCityModal(){
      this.setState({showCityModal: true});
    }

    handleClearCity() {
      this.setState({cityId: '', cityName: ''})
    }
    
    handleSaveCity(cityId, cityName){
      this.setState({cityId, cityName, showCityModal: false});
    }

    componentDidMount() {
      this._isMounted = true;
      this.updateTableData();
    }

    componentWillUnmount(){
      this._isMounted = false;
    }


    resetState(){
      this.setState({
          advId: "",
          text: '',
          image: '',
          imageData: null,
          fileName: '',
          cityId: '',
          cityName: '',
          formValid: false,
          link: '',
          date: '',
          errorMsg: {},
          successMessage: '',
          showModal: false,
          loading: false
        });
    }

    handleUploadFile = (event) => {
      let target = event.target;
      let value =  target.value;

      this.setState({successMessage: '', fileName: value});
      var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                600,
                600,
                'JPEG',
                100,
                0,
                uri => {
                  this.setState({
                    imageData: uri
                  }, ()=> this.validateForm());
                },
                'base64'
            );
        }
    };


    
    

  updateTableData = () => {
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}

    errorMsg.dataError ='';
    this.setState({errorMsg,
      loading: true

    });

    axios.get(API_BASE_URL+'/advertising/getAll/'+this.state.user.userId+'?lang='+i18n.language, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({ advertising_list: response.data,
          loading: false });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  }



  validateForm = () => {
    const {text, image, imageData} = this.state;
    this.setState({
      formValid: text.trim().length>0 && (image!=="" || imageData)
    });
  }




  handleEdit(row) {
    var advertising = row;
    
    this.setState({
      advId: advertising.advId,
      text: advertising.text,
      image: advertising.image,
      cityId: advertising.city?advertising.city.cityId:'',
      cityName: (advertising.city)?advertising.city.info.name:'',
      imageData: null,
      fileName: '',
      formValid: true,
      link: advertising.link,
      date: '',
      errorMsg: {},
      successMessage: '',
      showModal: false,
      loading: false
    });

  }

  deleteConfirm(e) {
    let advId = this.state.advId;
    let errorMsg = {};
    const { t } = this.props;

    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    this.setState({errorMsg,
      loading: true});

    axios.post(API_BASE_URL+'/advertising/delete/'+advId, null, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({
          advId: "",
          text: '',
          image: '',
          cityId: '',
          cityName: '',
          imageData: null,
          fileName: '',
          formValid: false,
          link: '',
          errorMsg: {},
          date: '',
          successMessage: '',
          showModal: false,
          loading: false
        }, this.updateTableData);
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });



  }

  handleDelete(row) {
    var advertising = row;
    
    this.setState({
      advId: advertising.advId,
      text: advertising.text,
      image: advertising.image,
      imageData: null,
      cityId: advertising.cityId?advertising.cityId:'',
      cityName: (advertising.city)?advertising.city.info.name:'',
      fileName: '',
      formValid: true,
      link: advertising.link,
      errorMsg: {},
      successMessage: '',
      showModal: true,
      loading: false
    });


  }

  handleClose(){
    this.setState({showModal: false, showCityModal: false });
  }

    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        }, ()=>this.validateForm());


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["advId"]=(this.state.advId==="")?null:this.state.advId;
      data["text"]=this.state.text;
      if(this.state.cityId!==''){
        data["cityId"]=this.state.cityId;
      }
      if(this.state.fileName!==''){
        data["imageData"]=this.state.imageData;
      }
      data["link"]=this.state.link;
      

      let errorMsg = {};
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      
      this.setState({errorMsg,
        loading: true});

      axios.post(API_BASE_URL+'/advertising/save?lang='+i18n.language, data, config)
      .then((response) => {
        if (this._isMounted) {
          var advertising = response.data;
    
          this.setState({
            advId: advertising.advId,
            text: advertising.text,
            image: advertising.image,
            cityId: advertising.cityId?advertising.cityId:'',
            cityName: (advertising.city)?advertising.city.info.name:'',
            imageData: null,
            fileName: '',
            formValid: true,
            link: advertising.link,
            errorMsg: {},
            successMessage: t('Advertising.saved'),
            showModal: false,
            loading: false
          }, this.updateTableData);
          
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };



    render() {
        const { SearchBar } = Search;
        const { t } = this.props;

        const columns = [{
          dataField: 'text',
          text: t('Advertising.text')
        },{
          dataField: 'image',
          text: t('Advertising.image'),
          formatter: (cell, row, rowIndex) => (
            
            <Image src={cell} className="adv-image"/>
          )
        }, {
          dataField: 'cityName',
          text: t('City.cities')
        }, {
          dataField: 'date',
          text: t('Advertising.date'),
          formatter: (cell, row, rowIndex) => (
            format(
              new Date(cell),
              'd LLLL uuuu p',
              {locale: this._locales[i18n.language]}
            )
          )
        },
        {
          dataField: 'id',
          text: t('Common.actions'),
          formatter: (cell, row, rowIndex) => (
            <div className="buttons-container">
              <Button variant="primary" type="button" onClick={(e) => this.handleEdit(row)}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </Button>
              <Button variant="primary" type="button" onClick={(e) => this.handleDelete(row)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </Button>


            </div>
          )
        }];

          return (
            <Container fluid={true} className="menu-container">
            {this.state.loading && (
              <Loader></Loader>
            )}
            <Menu_admin></Menu_admin>
              <Container fluid={true} className="title-bar-page">
                <Row>
                  <Col md={2}>
                    <Link to="/letMeDiscover">
                      <div className="back-button"><i className="fa fa-chevron-left fa-2x " aria-hidden="true"></i></div>
                    </Link>
                  </Col>
                  <Col md={8} className="text-center">
                    <h5 className="title-text">{t('Admin.manage_advertising')}</h5>
                  </Col>
                </Row>
              </Container>
              <Container fluid={true} className="tab-container">
                <Row className="justify-content-md-center">
                  <Col sm={8} className="form_center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="text" className="form_field">
                        <Form.Label className="form_field_label">{t('Advertising.text')}</Form.Label>
                        <Form.Control as="textarea" rows="7" maxLength="1200" placeholder={t('Advertising.text_ph')} name="text" value={this.state.text} onChange={this.handleChange}  />
                      </Form.Group>
                      <Row>
                        <Col md={12} className="text-center">
                          {(this.state.image || this.state.imageData)?
                            <Image src={(this.state.imageData)? this.state.imageData:this.state.image} className={"adv-image"}/>
                            :
                            <div className="empty-image"></div>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="text-center hidden-filechooser">
                          <input type="file" id="image_profile" value={this.state.fileName} onChange={this.handleUploadFile} />
                          <label htmlFor="image_profile" title={t('Profile.upload_profile_image')}><i className="fa fa-camera fa-2x" aria-hidden="true"></i></label>
                        </Col>
                      </Row>
                      <Form.Group controlId="link" className="form_field">
                        <Form.Label className="form_field_label">{t('Advertising.link')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('Advertising.link_ph')} name="link" value={this.state.link} onChange={this.handleChange}/>
                      </Form.Group>
                       <Form.Row className="justify-content-md-center">
                          <Col sm={6}>
                            <Form.Group controlId="cityName" className="form_field">
                              <Form.Control type="text" className="form_field_input dark_text" placeholder={t('City.cities')} name="cityName" value={this.state.cityName} readOnly/>
                            </Form.Group>
                          </Col>
                        <Col sm={3}>
                          <Button variant="primary" className="btn-login" onClick={this.handleShowCityModal}>
                            {t('City.cities')}
                          </Button>
                        </Col>
                        <Col sm={3}>
                          <Button variant="secondary" className="btn-login" onClick={this.handleClearCity}>
                            {t('Admin.delete_city')}
                          </Button>
                        </Col>
                        </Form.Row>
                      
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                      </Form.Group>
                      <Form.Row>
                        <Col className="text-center">
                          <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('Common.save')}</Button>
                        </Col>
                        <Col className="text-center">
                          <Button variant="secondary" type="button" className="btn-login" onClick={this.resetState}>{t('Common.clear')}</Button>
                        </Col>

                       </Form.Row>
                     </Form>
                   </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={12} className="form_center">
                    <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                    <ToolkitProvider
                      keyField="advId"
                      data={ this.state.advertising_list }
                      columns={ columns }
                      search

                      >
                      {
                        props => (
                          <div className="wd-tables-container">
                            <SearchBar { ...props.searchProps } />
                            <hr />
                            <BootstrapTable pagination={ paginationFactory()}
                              { ...props.baseProps } 
                            />
                          </div>
                        )
                      }
                      </ToolkitProvider>
                    </Col>
                  </Row>
                </Container>
                <SearchCities handleSaveCity={this.handleSaveCity} showModal={this.state.showCityModal} handleClose={this.handleClose} user={this.state.user}></SearchCities>
                <Modal show={this.state.showModal} animation={false} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>  {t('Advertising.delete_title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="app_aside modal-message">
                      {t('Advertising.delete_confirm')}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={this.deleteConfirm}>
                      {t('Common.confirm')}
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                      {t('Common.close')}
                    </Button>
                  </Modal.Footer>
                </Modal>
            </Container>
          );
        

    }
}

export default withTranslation()(ManageAdvertising);
