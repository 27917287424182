import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT, SEARCH_HEIGHT} from '../Constants.js';
import BackButtonTitle from '../components/BackButtonTitle';
import UserImage from './UserImage';
import axios from 'axios';
import UserProfile from '../components/UserProfile';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withTranslation } from 'react-i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class Voyagers extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        query: '',
        selectedVoyager: null,
        showModal: false,
        user: this.props.user,
        page: 0,
        hasMore: false,
        voyagers: []
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.getVoyagers = this.getVoyagers.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showProfilePage = this.showProfilePage.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getVoyagers();
  }

  
  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      voyagers: [],
      page: 0
    },   () => {
      this.getVoyagers();
    });
  }


  handleClose(){
    this.setState({selectedVoyager: null,
      showModal: false});
  }

  showProfilePage(selectedVoyager){
    this.setState({selectedVoyager,
      showModal: true});

  }


getVoyagers(refresh){
    const requestId = 'getVoyagersRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let voyagers = this.state.voyagers;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
      
    if(refresh){
      query='';
      page=0;
      voyagers=[];
      this.setState({errorMsg, page, query, voyagers,
        hasMore: true
      });
    } else {
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/voyager/getAll/'+this.state.user.userId+'?page='+page;

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }



    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newVoyagers = response.data;
        if((newVoyagers && newVoyagers.length===0) || (newVoyagers && newVoyagers[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          voyagers: voyagers.concat(newVoyagers)
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body className="white-modal">
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.voyagers'}></BackButtonTitle>
                <Form>
                  <Form.Group controlId="query" className="form_field">
                    <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.search')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                  </Form.Group>
                </Form>
                <InfiniteScroll
                  dataLength={this.state.voyagers.length}
                  next={this.getVoyagers}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getVoyagers(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT-SEARCH_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.voyagers.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Profile.voyagers_not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.voyagers && this.state.voyagers.map((voyager, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.showProfilePage(voyager)}>
                              <UserImage key={index+"_img"} user={voyager} className="list-img"></UserImage>
                              <span className="link-icon">{voyager.name+' '+voyager.surname}</span>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <UserProfile currentUserId={this.state.user.userId} user={this.state.selectedVoyager} handleClose={this.handleClose}></UserProfile>
                  )}
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(Voyagers);
