import React from 'react';
import { ACCESS_TOKEN } from '../Constants.js';
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem(ACCESS_TOKEN)
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/loginDiscover'
        }} />
  )} />
)

export default PrivateRoute;
