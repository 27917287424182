import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Table, Button} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import NumberFormat from 'react-number-format';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';

import moment from 'moment';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import WDLMonthSelector from './WDLMonthSelector';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import Select from 'react-select';

import axiosCancel from 'axios-cancel';
import { Thumbnail } from 'react-bootstrap';
axiosCancel(axios, {
  debug: false
});
class WDLVectorsInvoices extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  _moment_locales = {
    en: 'en-gb',
    fr: 'fr',
    it: 'it',
    es: 'es'
  }

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }

      
      let momentLocale = moment().locale(this._moment_locales[i18n.language]).localeData();
      let locale = {
        daysOfWeek: momentLocale._weekdaysShort,
        monthNames: momentLocale._monthsShort,
      };

      let currentYear = this.props.year?this.props.year:Number(moment().format('YYYY'));
      let currentMonth = this.props.month?this.props.month:new Date().getMonth()+1;
      this.state = {
        companyId: this.props.match.params.companyId,
        user: user,
        isAdmin: isAdmin,
        hasMore: false,
        vectorsInvoices: [],
        currentMonth,
        currentYear,
        locale,
        totals: {
          numbers: 0,
          payment: 0,
        },
        viewHeight: window.innerHeight,
        errorMsg: {},
        successMessage: '',
        changed: new Set([]),
      }
      this.getVectorsInvoices = this.getVectorsInvoices.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChangeVectorInvoice = this.handleChangeVectorInvoice.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  handleChangeDateRange(start, end, label) {
    this.setState({startDate: moment(start).toDate(), endDate: moment(end).toDate()}, ()=>this.getCompanyInfo(false))
  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getVectorsInvoices();
  }

  


  getVectorsInvoices(){
    const requestId = 'getVectorsInvoicesRequest';
    axios.cancel(requestId);
    let vectorsInvoices = [];
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    this.setState({errorMsg,
      hasMore: true
    });
    
    
    
    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/vector/payments'+'?monthNumber='+this.state.currentMonth+'&yearNumber='+this.state.currentYear;
    
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newVectorsInvoices = response.data;
        if((newVectorsInvoices && newVectorsInvoices.length===0) || (newVectorsInvoices && newVectorsInvoices[0].itemInLastPage)){
          hasMore=false;
        }
        let totals = {
          numbers: newVectorsInvoices.length,
          payment: 0,
        }

        for(let i=0; i<newVectorsInvoices.length; i++) {
          totals.payment+=newVectorsInvoices[i].payment;
        }

        this.setState({hasMore,
          vectorsInvoices: vectorsInvoices.concat(newVectorsInvoices),
          totals,
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

  
  

  handleSubmit(e) {
    e.preventDefault();
    var data = this.state.vectorsInvoices.filter(item=>this.state.changed.has(item.vectorId));
    let errorMsg = {...this.state.errorMsg}
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };
    errorMsg.appError ='';
    this.setState({errorMsg, loading: true, successMessage: ''});
    axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/vector/savePaymentsInfo', data, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({successMessage: t('WDL.saved'), loading: false, changed: new Set([]) });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.getVectorsInvoices();
  }
  

  handleChangeVectorInvoice(index, value) {
    let vectorsInvoices = [...this.state.vectorsInvoices];
    if(vectorsInvoices.length>index) {
      
      (vectorsInvoices[index]).notes = value;
      let changed = this.state.changed;
      changed.add(vectorsInvoices[index].vectorId);
      this.setState({vectorsInvoices, changed, successMessage: '', errorMsg: {}});
    }
  }



    render() {
      const { t } = this.props;
      
        return (
              
          <div className="wdl-container">
            <InfiniteScroll
                    dataLength={this.state.vectorsInvoices.length}
                    next={this.getVectorsInvoices}
                    hasMore={false}
                    refreshFunction={(e) => this.getVectorsInvoices()}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={10}
                    pullDownToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    releaseToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    height={this.state.viewHeight}
                    className="white-background"
                    loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                  >
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle toSave={this.state.changed.size>0} history={this.props.history} link={'/logistic/company/'+this.state.companyId+'/invoices'} title={'WDL.invoice_vectors'}></WDLBackButtonTitle>
              
              <Container fluid={true}>
                <Row className="justify-content-md-center">
                    <Col md={11}>
                  <Row className="form_field wdl-search-container">
                    <Col>
                    <Form onSubmit={this.onFormSubmit}>
                      <WDLMonthSelector month={this.state.currentMonth} year={this.state.currentYear} onSelectMonth={(month)=>this.setState({currentMonth: month})} onSelectYear={(year)=>this.setState({currentYear: year})}/>
                      <Button style={{padding: 10}} variant="primary" type="submit">
                        <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                      </Button>
                    </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                    <Form onSubmit={this.handleSubmit}>
                        
                        <Form.Group className="form_field wdl-message-container">
                            <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                            <SuccessMessage valid={true} message={this.state.successMessage} />
                          </Form.Group>

                        {this.state.loading && 
                            <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                        }
                        
                            <Table striped borderless hover responsive className="wdl-table">
                              <thead>
                                <tr style={{backgroundColor: '#065870', color: 'white'}}>
                                  <th>{t('WDL.vector')}</th>
                                  <th>{t('WDL.balance')}</th>
                                  <th>{t('WDL.notes')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {this.state.vectorsInvoices && this.state.vectorsInvoices.map((invoice, index) => (
                                  <tr key={index} >
                                    
                                    <td>
                                      <span>{invoice.name} </span>
                                    </td>
                                    <td>
                                      <span>{'€'}<NumberFormat value={invoice.payment} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                    </td>
                                    <td>
                                      <Form.Group controlId={"notes_"+index}>
                                        <Form.Control type="text" name={"notes_"+index} value={invoice.notes || ''} onChange={(e)=>this.handleChangeVectorInvoice(index, e.target.value)}/>
                                      </Form.Group>
                                    </td>
                                  </tr>
                                ))}
                                
                              </tbody>
                              {this.state.vectorsInvoices.length>0 &&
                              <tfoot>
                                  <tr className="wdl-total">
                                    <td>
                                      <NumberFormat value={this.state.totals.numbers} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                    {'€'}<NumberFormat value={this.state.totals.payment} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                    </td>
                                    
                                  </tr>
                                </tfoot>
                                }
                            </Table>
                            {(this.state.vectorsInvoices.length===0) &&
                          
                          <div className="separator info-not-found-container">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('WDL.info_not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        }
                        {this.state.vectorsInvoices.length>0 &&
                          <Form.Row style={{marginBottom: 100, marginTop: 50}}>
                              <Col className="text-center">
                                <Button style={{backgroundColor: '#065870'}} variant="primary" type="submit" className="btn-login" disabled={this.state.changed.size===0}>{t('WDL.save')}</Button>
                              </Col>
                            </Form.Row>
                          }
                          </Form>
                      </Col>
                    </Row>
                    </Col>
                  </Row>
              </Container>
              
            </InfiniteScroll>
            </div>
            
        );
    }
}

export default withTranslation()(WDLVectorsInvoices);
