import React, { PureComponent } from 'react'
import axios from 'axios'
import { API_BASE_URL, ACCESS_TOKEN, SEARCH_USERS, SEARCH_ATTRACTIONS, SEARCH_PLATES, SEARCH_CITIES } from '../Constants.js';
import { Row, Col, Container, Form, Button, ListGroup, ButtonGroup, Modal, Image } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserImage from './UserImage';
import { GenerateColor } from '../functions/FormUtils.js';
import PlatePage from '../components/PlatePage';
import AttractionPage from '../components/AttractionPage';
import UserProfile from './UserProfile';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});

class Search extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      isAdmin: this.props.isAdmin,
      query: '',
      type: SEARCH_CITIES,
      page: 0,
      pageToShow: '',
      selected: null,
      hasMore: false,
      results: [],
      refreshing: false,
    }
    this.getInfo = this.getInfo.bind(this);
    this.showUserInfo = this.showUserInfo.bind(this);
    this.showAttrInfo = this.showAttrInfo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeSearchType = this.handleChangeSearchType.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleChangeSearchType(type) {
    let results = [];
    this.setState({ type, results, query: '' });
  }

  showUserInfo(user) {
    this.setState({
      selected: user,
      pageToShow: 'user_profile'
    });
  }

  showAttrInfo(attraction, pageToShow) {
    this.setState({
      selected: attraction,
      pageToShow
    });
  }


  getInfo = isNewSearch => {
    const requestId = 'getInfoRequest';
    if (this.state.query.length === 0) {
      axios.cancel(requestId);
      let results = [];
      this.setState({ results, refreshing: false , hasMore:false});
    } else {
      if (this.state.refreshing || this.state.query.length >= 2) {
        axios.cancel(requestId);
        const { t } = this.props;
        var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
          },
          requestId: requestId,
        };

        this.setState({ refreshing: true });
        let errorMsg = {};

        axios
          .get(
            API_BASE_URL +
            '/discovery/getSearchSuggestions/' +
            this.state.type +
            '/?query=' +
            this.state.query +
            '&page=' +
            this.state.page +
            '&lang=' +
            i18n.language,
            config,
          )
          .then(
            response => {
              if (this._isMounted) {
                let hasMore = true;
                let newResults = response.data;
                if (
                  (newResults && newResults.length === 0) ||
                  (newResults && newResults[0].itemInLastPage)
                ) {
                  hasMore = false;
                }
                let results = this.state.results;

                if (newResults) {
                  if (!isNewSearch) {
                    results = results.concat(newResults);
                  } else {
                    results = newResults;
                  }
                }
                let page = 1 * this.state.page + 1;
                this.setState({
                  hasMore,
                  page,
                  refreshing: false,
                  results,
                });
              }
            },
            error => {
              if (this._isMounted && !axios.isCancel(error)) {
                errorMsg.dataError = t('Errors.generic_error');

                if (error.response && error.response.status) {
                  let status = error.response.status;
                  if (status === 401) {
                    return window.location.href = '/logout';
                  }
                } else {
                  if (error.message != null && error.message === 'Network Error') {
                    errorMsg.dataError = t("Errors.connection_error");
                  }
                }
                this.setState({
                  errorMsg,
                  hasMore: false,
                  refreshing: false,
                });
              }
            },
          );
      } else {
        this.setState({ refreshing: false });
      }
    }
  };

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      page: 0
    },

      () => {
        this.getInfo(true);
      });

  }


  handleClose() {
    this.setState({ pageToShow: '' });
  }

  render() {
    const { t } = this.props;
    var placeholderSearch;
    if (this.state.type === SEARCH_CITIES) {
      placeholderSearch = t('Common.search_city');
    } else if (this.state.type === SEARCH_ATTRACTIONS) {
      placeholderSearch = t('Common.search_attractions');
    } else if (this.state.type === SEARCH_PLATES) {
      placeholderSearch = t('Common.search_plates');
    } else {
      placeholderSearch = t('Common.search_voyagers');
    }
    return (
      <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title> {t('Common.discover_question')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid={true}>
              <Form>
              <Form.Group controlId="showAttractions" className="form_field" >
                <Form.Row>
                  <Col>
                    <Form.Label style={{ marginRight: '20px' }}>{t('Common.search_filters')}</Form.Label>
                    <ButtonGroup>
                      <Button active={this.state.type === SEARCH_CITIES} onClick={(e) => this.handleChangeSearchType(SEARCH_CITIES)}>
                        <i className="fa fa-map-o fa-2x" aria-hidden="true"></i>
                      </Button>
                      <Button active={this.state.type === SEARCH_ATTRACTIONS} onClick={(e) => this.handleChangeSearchType(SEARCH_ATTRACTIONS)}>
                        <i className="fa fa-university fa-2x" aria-hidden="true"></i>
                      </Button>
                      <Button active={this.state.type === SEARCH_PLATES} onClick={(e) => this.handleChangeSearchType(SEARCH_PLATES)}>
                        <i className="fa fa-cutlery fa-2x" aria-hidden="true"></i>
                      </Button>
                      <Button active={this.state.type === SEARCH_USERS} onClick={(e) => this.handleChangeSearchType(SEARCH_USERS)}>
                        <i className="fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="query" className="form_field">
                <Form.Control type="text" className="form_field_input dark_text" placeholder={placeholderSearch} name="query" value={this.state.query} onChange={this.handleInputChange} />
              </Form.Group>
              <InfiniteScroll
                dataLength={this.state.results.length}
                next={this.getInfo}
                hasMore={this.state.hasMore}
                height={150}
                loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
              >

                <Container fluid={true} className="search-list-container">
                  {
                    this.state.query.length > 0 && this.state.results && (
                      <ListGroup>
                        {this.state.type === SEARCH_CITIES && (this.state.results.map((result, index) =>
                          <ListGroup.Item key={index}>
                            <Row className="row-link-container" onClick={(e) => this.props.goToCity(result.cityId)}>
                              <Col sm={12}>
                                <i style={{ color: GenerateColor(result.name) }} className="fa fa-map-marker" aria-hidden="true"></i>
                                <span className="link-icon">{result.name + ', '+ result.countryCode}</span>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                        {this.state.type === SEARCH_ATTRACTIONS && (this.state.results.map((result, index) =>
                          <ListGroup.Item key={index}>
                            <Row className="row-link-container" onClick={(e) => this.showAttrInfo(result, 'attraction')}>
                              <Row>
                                <Col className="list-image-container">
                                  <Image fluid={true} src={result.userAttraction? result.userAttraction.image : result.image} className={"list-img marker-lev-" + result.level} />
                                </Col>
                                <Col className="info-list-container">
                                  <Row>
                                    <Col>
                                      <span className="link-icon">{(result.info) ? result.info.name : result.name}</span>

                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <span className="city-info-label">{result.city.info ? result.city.info.name + ', '+result.city.countryCode : result.city.name+', ' + result.city.countryCode}</span>

                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Row>
                          </ListGroup.Item>
                        ))}
                        {this.state.type === SEARCH_PLATES && (this.state.results.map((result, index) =>
                          <ListGroup.Item key={index}>
                            <Row className="row-link-container" onClick={(e) => this.showAttrInfo(result, 'plate')}>
                              <Row>
                                <Col className="list-image-container">
                                  <Image fluid={true} src={result.userAttraction? result.userAttraction.image : result.image} className={"list-img marker-lev-" + result.level} />
                                </Col>
                                <Col className="info-list-container">
                                  <Row>
                                    <Col>
                                      <span className="link-icon">{(result.info) ? result.info.name : result.name}</span>

                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <span className="city-info-label">{result.city.info ? result.city.info.name + ', '+result.city.countryCode : result.city.name+', ' + result.city.countryCode}</span>

                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Row>
                          </ListGroup.Item>
                        ))}
                        {this.state.type === SEARCH_USERS && (this.state.results.map((result, index) =>
                          <ListGroup.Item key={index}>
                            <Row className="row-link-container" onClick={(e) => this.showUserInfo(result)}>
                              <Col sm={12}>
                                <UserImage user={result} className="list-img"></UserImage>
                                <span className="link-icon">{result.name + ' ' + (result.surname!==' '? result.surname+' ': '')}</span>
                                {this.state.user.userId!==result.userId && result.isVoyager && (
                                  <i className="fa fa-suitcase voyager-icon" aria-hidden="true"></i>
                                )}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )
                  }

                  {this.state.pageToShow === "user_profile" && (
                    <UserProfile user={this.state.selected} handleClose={this.handleClose}></UserProfile>
                  )}
                  {this.state.pageToShow === "attraction" && (
                    <AttractionPage attraction={this.state.selected} user={this.props.user} showModal={true} handleClose={this.handleClose}></AttractionPage>
                  )}
                  {this.state.pageToShow === "plate" && (
                    <PlatePage plate={this.state.selected} user={this.props.user} showModal={true} handleClose={this.handleClose}></PlatePage>
                  )}
                </Container>
              </InfiniteScroll>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
}


export default withTranslation()(Search);
