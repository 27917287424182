import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';
import BackButtonTitle from '../components/BackButtonTitle';
import LevelPage from '../components/LevelPage';
import ObjectivePage from '../components/ObjectivePage';
import UserDiscoveryPage from '../components/UserDiscoveryPage';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import getTime from 'date-fns/getTime';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'

class Notifications extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  constructor(props) {
      super(props);
      this.state = {
        page: 0,
        hasMore: false,
        pageToShow: '',
        selected: null,
        notifications: [],
        errorMsg: {},
      }
      this.getNotifications = this.getNotifications.bind(this);
      this.showNotificationDetail = this.showNotificationDetail.bind(this);
      this.showDiscoveryInfo = this.showDiscoveryInfo.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleCloseInfo = this.handleCloseInfo.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getNotifications();
  }

  showDiscoveryInfo = () => {
  this.setState({
      showModalInfo: true,
     });
  }


getNotifications(refresh){

    let page = this.state.page;
    let notifications = this.state.notifications;
    let errorMsg = {...this.state.errorMsg};
    errorMsg.dataError ='';
    
    if(refresh){
      page=0;
      notifications=[];
      this.setState({errorMsg, page, notifications,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }
   

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    

    axios.get(API_BASE_URL+'/discovery/getNotifications?page='+page+'&lang='+i18n.language, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newNotifications = response.data;
        if((newNotifications && newNotifications.length===0) || (newNotifications && newNotifications[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: this.state.page+1,
          notifications: notifications.concat(newNotifications)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

  handleClose(){
    this.setState({selected: null,
      pageToShow: '',
      showModalInfo: false
      });
  }

  handleCloseInfo(){
    this.setState({
      showModalInfo: false,
      });
  }

  readNotification(notification){

    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    axios.post(API_BASE_URL+'/discovery/readNotification/'+notification.notificationId, null, config)
    .then((response) => {
      if (this._isMounted) {
        let notifications = [...this.state.notifications];
        for(var i=0; i<notifications.length; i++){
          if(notifications[i].notificationId===notification.notificationId){
            notifications[i].toRead = false;
            break;
          }
        }
        this.setState({notifications}, this.props.getNotificationsToRead);
      }
    });
  }

  showNotificationDetail(notification){

    if(notification.userAttraction){
      
      this.setState({selected: notification.userAttraction,
        pageToShow: 'discovery',
      }, this.readNotification(notification));
    }else if(notification.objective){
      this.setState({selected: notification.objective,
        pageToShow: 'objective',
      }, this.readNotification(notification));
    }else if(notification.level){
      this.setState({selected: notification.level,
        pageToShow: 'level',
      }, this.readNotification(notification));
    }else {
      this.readNotification(notification);
    }

  }


    render() {

      const { t } = this.props;
        return (
          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
              <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.notifications'}></BackButtonTitle>

                <InfiniteScroll
                  dataLength={this.state.notifications.length}
                  next={this.getNotifications}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getNotifications(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.notifications.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Profile.no_notifications')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.notifications && this.state.notifications.map((notification, index) => (
                          <ListGroup.Item key={index}>
                            <div className={"row-link-container "+(notification.toRead===true ? "to-read" : "")} onClick={(e) => this.showNotificationDetail(notification)}>
                                {notification.image? <Image fluid={true} src={notification.image} className={"list-img"}/> : notification.level? <i className="fa fa-level-up fa-2x list-img" aria-hidden="true"/>: <i className="fa fa-level-up fa-2x list-img" aria-hidden="true"/>}
                                <span className="link-icon">{notification.text+" "}</span><br/>
                                <span className="notification-date">
                                {format(
                                  new Date(notification.date),
                                  'd LLLL uuuu p',
                                  {locale: this._locales[i18n.language]}
                                )}
                                </span>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.pageToShow === 'discovery' &&  (
                    <UserDiscoveryPage selectedAttractionId={this.state.selected.attractionId}
                     selectedUserId={this.state.selected.userId} user={this.props.user} handleClose={this.handleClose}></UserDiscoveryPage>
                  )}
                  {this.state.pageToShow === 'objective' &&  (
                    <ObjectivePage objective={this.state.selected} user={this.props.user} showModal={true} handleClose={this.handleClose}></ObjectivePage>
                  )}
                  {this.state.pageToShow === 'level' &&  (
                    <LevelPage level={this.state.selected} user={this.props.user} showModal={true} handleClose={this.handleClose}></LevelPage>
                  )}
                </Container>
                </InfiniteScroll>
                
            </Modal.Body>
          </Modal>
        );
    }
}

export default withTranslation()(Notifications);
