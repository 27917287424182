import React from 'react';
import { Redirect } from 'react-router-dom';
import { CURRENT_CITY, ACCESS_TOKEN, USER, HOME_TAB} from '../Constants';


function Logout () {
    localStorage.removeItem(CURRENT_CITY);
    localStorage.removeItem(HOME_TAB);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER);
    return <Redirect to="/" push={true} />;
}

export default Logout;
