import React, { PureComponent } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';

import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import { API_BASE_URL} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button} from 'react-bootstrap';
import queryString from 'query-string';


class ResetPasswordForm extends PureComponent {
      _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            password: '', passwordValid: false,
            confirmpassword: '', confirmpasswordValid: false,
            formValid: false,
            token: '',
            loading: false,
            errorMsg: {},
            successMessage: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      let search = window.location.search;
      const values = queryString.parse(search);
      this.setState({token: values.token});
      this._isMounted = true;
    }

    validateForm = () => {
      const {passwordValid, confirmpasswordValid} = this.state;
      this.setState({
        formValid: passwordValid && confirmpasswordValid
      })
    }


    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';
      if(name==="password"){

        let passwordValid = true;
        errorMsg.password = '';
        if (value.length < 6) {
          passwordValid = false;
          errorMsg.password = t('Errors.field_dimension', {number: 6})
        }

        this.setState({passwordValid, errorMsg}, this.validateForm);

        let confirmpasswordValid = true;
        errorMsg.confirmpassword = '';
        if (value !== this.state.confirmpassword) {
          confirmpasswordValid = false;
          errorMsg.confirmpassword = t('Errors.invalid_confirm_password');
        }

        this.setState({confirmpasswordValid, errorMsg}, this.validateForm);

      }else if(name==="confirmpassword"){

        let confirmpasswordValid = true;
        errorMsg.confirmpassword = '';
        if (value !== this.state.password) {
          confirmpasswordValid = false;
          errorMsg.confirmpassword = t('Errors.invalid_confirm_password');
        }

        this.setState({confirmpasswordValid, errorMsg}, this.validateForm);

      }

    }

    handleChange(e) {
      this.setState({ successMessage: ''});

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        },   this.validateFields(name, value));

    }

    handleSubmit = (event) => {
      event.preventDefault();
      var data = {};
      data["token"]=this.state.token;
      data["newPassword"]=this.state.password;

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;


      errorMsg.appError ='';
      this.setState({errorMsg, successMessage:'', loading: true});

      axios.post(API_BASE_URL+'/auth/reset_password', data)
      .then((response) => {
        if(this._isMounted){
          this.setState({ successMessage: t('Messages.password_changed_successfully'),
            password: '', confirmpassword: '', confirmpasswordValid: false, passwordValid: false, formValid: false, errorMsg:'',
           loading: false });
        }
      }, (error) => {
        if(this._isMounted){
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
          }
      });
    };




    render() {
        const { t } = this.props;

        return (
          <Container fluid={true}>
            {this.state.loading && (
              <Loader></Loader>
            )}

            <Row>
              <Col sm={12} className="form_center">
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="password" className="form_field">
                      <Form.Label className="form_field_label">{t('Profile.newpassword')}</Form.Label>
                      <Form.Control type="password" className="form_field_input" placeholder={t('Profile.newpassword_ph')} name="password" value={this.state.password} onChange={this.handleChange} />
                      <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
                    </Form.Group>
                    <Form.Group controlId="confirmpassword" className="form_field">
                      <Form.Label className="form_field_label">{t('SignUpForm.confirm_password')}</Form.Label>
                      <Form.Control type="password" className="form_field_input" placeholder={t('SignUpForm.confirm_password_ph')} name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} />
                      <ValidationMessage valid={this.state.confirmpasswordValid} message={this.state.errorMsg.confirmpassword}/>
                    </Form.Group>
                    <Form.Group className="form_field">
                      <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                      <SuccessMessage valid={true} message={this.state.successMessage} />
                    </Form.Group>
                    <Form.Row>
                       <Col className="text-center">
                        <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid && !this.state.token}>{t('Profile.change_password')}</Button>
                       </Col>
                     </Form.Row>
                   </Form>
                   </Col>
                </Row>
              </Container>


        );
    }
}

export default withTranslation()(ResetPasswordForm);
