import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';

import {ValidationMessage} from '../functions/FormUtils.js';
import { API_BASE_URL} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import i18n from 'i18next';


class SignUpForm extends PureComponent {

      _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            name: '', nameValid: false,
            surname: '', surnameValid: false,
            email: '', emailValid: false,
            password: '', passwordValid: false,
            confirmpassword: '', confirmpasswordValid: false,
            hasAgreed: false, hasAgreedValid: false,
            formValid: false,
            showModal: false,
            errorMsg: {},
            disableRegistration: true,
            isSignedUp: false,
            lang: i18n.language,
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.showTerms = this.showTerms.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }

    showTerms(){
      this.setState({showModal: true});
    }

    handleClose(){
      this.setState({showModal: false});
    }

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }
        this.setState({nameValid, errorMsg}, this.validateForm);

      }if(name==="surname"){
        let surnameValid = true;
        errorMsg.surname = '';
        if(value.startsWith(" ")) {
          surnameValid = false;
          errorMsg.surname = t('Errors.invalid_field')
        } else if (value.trim().length < 3) {
          surnameValid = false;
          errorMsg.surname = t('Errors.field_dimension', {number: 3});
        }

        this.setState({surnameValid, errorMsg}, this.validateForm);

      }else if(name==="email"){

        let emailValid = true;
        errorMsg.email = '';
        // checks for format _@_._
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
          emailValid = false;
          errorMsg.email = t('Errors.invalid_email');
        }

        this.setState({emailValid, errorMsg}, this.validateForm)

      }else if(name==="password"){


        let passwordValid = true;
        errorMsg.password = '';
        if(value.includes(" ")) {
          passwordValid = false;
          errorMsg.password = t('Errors.invalid_field')
        } else if (value.length < 6) {
          passwordValid = false;
          errorMsg.password = t('Errors.field_dimension', {number: 6})
        }

        this.setState({passwordValid, errorMsg}, this.validateForm);

        let confirmpasswordValid = true;
        errorMsg.confirmpassword = '';
        if (value !== this.state.confirmpassword) {
          confirmpasswordValid = false;
          errorMsg.confirmpassword = t('Errors.invalid_confirm_password');
        }

        this.setState({confirmpasswordValid, errorMsg}, this.validateForm);

      }else if(name==="confirmpassword"){

        let confirmpasswordValid = true;
        errorMsg.confirmpassword = '';
        if (value !== this.state.password) {
          confirmpasswordValid = false;
          errorMsg.confirmpassword = t('Errors.invalid_confirm_password');
        }

        this.setState({confirmpasswordValid, errorMsg}, this.validateForm);

      }else if(name==="hasAgreed"){

        let hasAgreedValid = true;
        errorMsg.hasAgreed = '';
        if (!value) {
          hasAgreedValid = false;
          errorMsg.hasAgreed = t('Errors.unconfirmed_terms');
        }

        this.setState({hasAgreedValid, errorMsg}, this.validateForm);

      }

    }





  validateForm = () => {
    const {nameValid, surnameValid, emailValid, passwordValid, confirmpasswordValid} = this.state;
    this.setState({
      formValid: nameValid && surnameValid && emailValid && passwordValid && confirmpasswordValid
    })
  }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        }, this.validateFields(name, value));


    }

    handleSubmit(e) {
        e.preventDefault();
        const { t } = this.props;

        if(this.state.hasAgreed){
          let errorMsg = {...this.state.errorMsg}

          this.setState({loading: true, lang: i18n.language});

          axios.post(API_BASE_URL+'/auth/signup', this.state)
          .then((response) => {
              if (this._isMounted) {
                this.setState({ isSignedUp: true, loading: false });
              }
          }, (error) => {
            if (this._isMounted) {
              errorMsg.appError = t('Errors.signup_error');
              if (error.response && error.response.status) {
                let status = error.response.status;
                if(status===406){
                    errorMsg.appError = t("Errors.invalid_email_send");
                } else if (status === 409) {
                  errorMsg.appError = t('Errors.existing_email');
                }
              }
              this.setState({errorMsg, loading: false});
            }
          });

        }else {
          let errorMsg = {...this.state.errorMsg}

          errorMsg.hasAgreed = t('Errors.unconfirmed_terms');
          let hasAgreedValid = false;

          this.setState({hasAgreedValid, errorMsg, loading: false}, this.validateForm);

        }

    }

    render() {
        const { t } = this.props;

        if (this.state.isSignedUp) {
          return <Redirect to = {{ pathname: "/sign-up-confirm" }} />;
        }
        return (
          <Container fluid={true}>
            {this.state.loading && (
              <Loader></Loader>
            )}
            <Row>
              <Col sm={12} className="form_center">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group controlId="email" className="form_field">
                    <Form.Label className="form_field_label">{t('SignUpForm.email')}</Form.Label>
                    <Form.Control type="text" className="form_field_input" placeholder={t('SignUpForm.email_ph')} name="email" value={this.state.email} onChange={this.handleChange}/>
                    <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email}/>
                  </Form.Group>
                  <Form.Group controlId="name" className="form_field">
                    <Form.Label className="form_field_label">{t('SignUpForm.name')}</Form.Label>
                    <Form.Control type="text" className="form_field_input" placeholder={t('SignUpForm.name_ph')} name="name" value={this.state.name} onChange={this.handleChange}/>
                    <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                  </Form.Group>
                  <Form.Group controlId="surname" className="form_field">
                    <Form.Label className="form_field_label">{t('SignUpForm.surname')}</Form.Label>
                    <Form.Control type="text" className="form_field_input" placeholder={t('SignUpForm.surname_ph')} name="surname" value={this.state.surname} onChange={this.handleChange}/>
                    <ValidationMessage valid={this.state.surnameValid} message={this.state.errorMsg.surname} />
                  </Form.Group>
                  <Form.Group controlId="password" className="form_field">
                    <Form.Label className="form_field_label">{t('SignUpForm.password')}</Form.Label>
                    <Form.Control type="password" className="form_field_input" placeholder={t('SignUpForm.password_ph')} name="password" value={this.state.password} onChange={this.handleChange} />
                    <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
                  </Form.Group>
                  <Form.Group controlId="confirmpassword" className="form_field">
                    <Form.Label className="form_field_label">{t('SignUpForm.confirm_password')}</Form.Label>
                    <Form.Control type="password" className="form_field_input" placeholder={t('SignUpForm.confirm_password_ph')} name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} />
                    <ValidationMessage valid={this.state.confirmpasswordValid} message={this.state.errorMsg.confirmpassword}/>
                  </Form.Group>

                  <Form.Group controlId="hasAgreed" className="form_field">
                    <Form.Check type="checkbox">
                      <Form.Check.Input type="checkbox" name="hasAgreed" value={this.state.hasAgreed} onChange={this.handleChange}/>
                      <Link to="/terms-of-service" >
                        <Form.Check.Label>{t('SignUpForm.agreement')} <span className="form_field_terms_link pointer">{t('SignUpForm.terms')}</span></Form.Check.Label>
                      </Link>
                      
                    </Form.Check>
                    <ValidationMessage valid={this.state.hasAgreedValid} message={this.state.errorMsg.hasAgreed} />
                  </Form.Group>
                  <Form.Group className="form_field">
                    <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                  </Form.Group>
                  <Form.Row>
                     <Col sm={6} className="text-center btn-responsive-container" style={{ marginTop: 10 }}>
                      <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid || this.state.disableRegistration}>{t('SignUpForm.signup')}</Button>
                     </Col>
                     <Col sm={6} className="text-center btn-responsive-container" style={{ marginTop: 10 }}>
                       <Button variant="primary" as={Link} to="/loginDiscover" className="btn-login">{t('SignUpForm.already_member')}</Button>
                    </Col>
                   </Form.Row>
                 </Form>
                 
              </Col>
            </Row>
          </Container>

        );
    }
}

export default withTranslation()(SignUpForm);
