import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Image} from 'react-bootstrap';
import i18n from 'i18next';
import LanguageChooser from '../components/LanguageChooser';
import logo from '../img/logo_desc.svg';
import { CONTACT_EMAIL, INSTAGRAM_URL, FACEBOOK_URL} from '../Constants.js';

class TermsOfService extends PureComponent {

    render() {
        const { t } = this.props;

        return (
          <div className="welcome-page-modal">
            <div>
            <Container fluid={true} className="tab-container" style={{padding: '0px'}}>
                <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                    <Col>
                        <a href={'/'}>
                        <Image src={logo} alt="Worldust" className="center-block logo-site-small" fluid/>
                        </a>
                    </Col>
                        <Col className="site-language">
                        <LanguageChooser isSite={true}/>
                    </Col>
                </Row> 
                  <Row className="site-row">
                    <Col md={12} className="text-center" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                      <h5 className="title-text">{t("Common.terms")}</h5>
                    </Col>
                  </Row>
                </Container>
                <Container fluid={true} className="tab-container white-background" >
                    <Row className="justify-content-md-center">
                      {
                        i18n.language == 'it'? (
                          <Col sm={8} className="message-column" style={{textAlign: 'left', marginTop: '20px'}}>
                            <p style={{fontWeight: 'bold'}}>
                                Informativa privacy resa ai sensi degli articoli 13 e 14 del Gdpr 2016/679
                                (General Data Protection Regulation)
                            </p>
                            <p>
                                Gentile Signore/a, ai sensi dell’art. 13 del Regolamento UE 2016/679 ed in
                                relazione alle informazioni di cui si entrerà in possesso, ai fini della
                                tutela delle persone e altri soggetti in materia di trattamento di dati
                                personali, si informa quanto segue:
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Finalità del Trattamento
                            </p>
                            <p>
                                I dati da Lei forniti verranno utilizzati allo scopo e per il fine di
                                garantire la fornitura, la personalizzazione e il miglioramento dei nostri
                                servizi offerti.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Modalità del Trattamento
                            </p>
                            <p>
                                Le modalità con la quale verranno trattati i dati personali contemplano
                                strumenti informatici e telematici con modalità idonee a garantirne la
                                sicurezza e la riservatezza, in conformità alle disposizioni previste
                                dall’articolo 32 GDPR.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Tipologia di dati trattati
                            </p>
                            <p>
                                Saranno trattati i dati che permettono l'identificazione diretta come i
                                dati anagrafici (ad esempio: nome e cognome), le immagini e l’email. Si
                                specifica la possibilità di rifiutare il consenso ai singoli trattamenti
                                quali quelli a fini di marketing diretto.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Base giuridica del trattamento
                            </p>
                            <p>
                                Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una
                                delle seguenti condizioni:
                            </p>
                            <ol>
                                <li>
                                    l’Utente ha prestato il consenso per una o più finalità specifiche;
                                </li>
                                <li>
                                    il trattamento è necessario all’esecuzione di un contratto con l’Utente
                                    e/o all’esecuzione di misure precontrattuali;
                                </li>
                                <li>
                                    il trattamento è necessario per adempiere un obbligo legale al quale è
                                    soggetto il Titolare;
                                </li>
                                <li>
                                    il trattamento è necessario per l’esecuzione di un compito di interesse
                                    pubblico o per l’esercizio di pubblici poteri di cui è investito il
                                    Titolare;
                                </li>
                                <li>
                                    il trattamento è necessario per il perseguimento del legittimo
                                    interesse del Titolare o di terzi.
                                </li>
                            </ol>
                            <p>
                                È comunque sempre possibile richiedere al Titolare di chiarire la concreta
                                base giuridica di ciascun trattamento ed in particolare di specificare se
                                il trattamento
                            </p>
                            <p>
                                sia basato sulla legge, previsto da un contratto o necessario per
                                concludere un contratto.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Conferimento dei dati
                            </p>
                            <p>
                                Il conferimento dei dati per le finalità di cui al punto 1 sono facoltativi
                                e l’eventuale rifiuto dell’autorizzazione comporta l’impossibilità di
                                usufruire dei servizi previsti.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Comunicazione e diffusione dei dati
                            </p>
                            <p>
                                I dati forniti non saranno soggetti a comunicazione né a diffusione.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Dati su I cookie
                            </p>
                            <p>
                                I cookie sono piccoli file di testo che i siti visitati dall'utente inviano
                                e registrano sul suo computer o dispositivo mobile, per essere poi
                                ritrasmessi agli stessi siti alla successiva visita. Proprio grazie ai
                                cookie un sito ricorda le azioni e preferenze dell'utente (come, ad
                                esempio, i dati di login, la lingua prescelta, le dimensioni dei caratteri,
                                altre impostazioni di visualizzazione, ecc.) in modo che non debbano essere
                                indicate nuovamente quando l'utente torni a visitare detto sito o navighi
                                da una pagina all'altra di esso. I cookie, quindi, sono usati per eseguire
                                autenticazioni informatiche, monitoraggio di sessioni e memorizzazione di
                                informazioni riguardanti le attività degli utenti che accedono ad un sito e
                                possono contenere anche un codice identificativo unico che consente di
                                tenere traccia della navigazione dell'utente all'interno del sito stesso
                                per finalità statistiche o pubblicitarie. Nel corso della navigazione su un
                                sito, l'utente può ricevere sul suo computer o dispositivo mobile anche
                                cookie di siti o di web server diversi da quello che sta visitando (c.d.
                                cookie di "terze parti"). Alcune operazioni non potrebbero essere compiute
                                senza l'uso dei cookie, che in certi casi sono quindi tecnicamente
                                necessari per lo stesso funzionamento del sito. Usiamo cookie al fine di
                                mostrarti contenuti pertinenti e migliorare la tua esperienza. Il periodo
                                di memorizzazione di un cookie sul tuo computer o dispositivo mobile varia
                                a seconda che si tratti di cookie "persistenti" o di "sessione". I cookie
                                di sessione vengono memorizzati sul dispositivo fino al termine della
                                navigazione. I cookie persistenti restano sul tuo computer o dispositivo
                                mobile fino alla loro scadenza o all'eliminazione.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Titolare del Trattamento
                            </p>
                            <p>
                                Il titolare del trattamento dei dati personali è il Dott. Alessandro
                                Cacciotti con domicilio in Colleferro (RM), alla Via Donatello, n. 3.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Periodo di conservazione
                            </p>
                            <p>
                                Memorizziamo i dati finché è necessario per fornire i nostri servizi o
                                finché l'account non viene eliminato, in base a quale evento si verifica
                                per primo. Si tratta di una determinazione caso per caso che varia in base
                                a elementi come natura dei dati, motivo per cui vengono raccolti e trattati
                                ed esigenze di conservazione legali oppure operative pertinenti. Quando
                                elimini il tuo account, eliminiamo gli elementi che hai pubblicato, come
                                foto e aggiornamenti di stato, e non potrai più recuperare queste
                                informazioni. Le informazioni che le altre persone hanno condiviso su di te
                                non fanno parte del tuo account e non verranno eliminate. Se non desideri
                                eliminare l'account, ma vuoi smettere temporaneamente di usare i Prodotti,
                                puoi scegliere di disattivarlo.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Diritti dell’interessato
                            </p>
                            <p>
                                In ogni momento, Lei potrà esercitare, ai sensi degli articoli dal 15 al 22
                                del Regolamento UE n. 2016/679, il diritto di:
                            </p>
                            <ol>
                                <li>
                                    chiedere la conferma dell’esistenza o meno di propri dati personali;
                                </li>
                                <li>
                                    ottenere le indicazioni circa le finalità del trattamento, le categorie
                                    dei dati personali, i destinatari o le categorie di destinatari a cui i
                                    dati personali sono stati o saranno comunicati e, quando possibile, il
                                    periodo di conservazione;
                                </li>
                                <li>
                                    ottenere la rettifica e la cancellazione dei dati;
                                </li>
                                <li>
                                    ottenere la limitazione del trattamento;
                                </li>
                                <li>
                                    ottenere la portabilità dei dati, ossia riceverli da un titolare del
                                    trattamento, in un formato strutturato, di uso comune e leggibile da
                                    dispositivo automatico, e trasmetterli ad un altro titolare del
                                    trattamento senza impedimenti;
                                </li>
                                <li>
                                    opporsi al trattamento in qualsiasi momento ed anche nel caso di
                                    trattamento per finalità di marketing diretto;
                                </li>
                                <li>
                                    opporsi ad un processo decisionale automatizzato relativo alle persone
                                    fisiche, compresa la profilazione;
                                </li>
                                <li>
                                    chiedere al titolare del trattamento l’accesso ai dati personali e la
                                    rettifica o la cancellazione degli stessi o la limitazione del
                                    trattamento che lo riguardano o di opporsi al loro trattamento, oltre
                                    al diritto alla portabilità dei dati;
                                </li>
                                <li>
                                    revocare il consenso in qualsiasi momento senza pregiudicare la liceità
                                    del trattamento basata sul consenso prestato prima della revoca;
                                </li>
                                <li>
                                    proporre reclamo a un’autorità di controllo.
                                </li>
                            </ol>
                            <p>
                                Può esercitare i Suoi diritti con richiesta apposita esplicitata
                                all’indirizzo email info@worldust.com
                            </p>
                            </Col>
                        )
                      : (
                          <Col sm={8} className="message-column" style={{textAlign: 'left'}}>
                            <p style={{fontWeight: 'bold'}}>
                                Privacy policy made pursuant to articles 13 and 14 of Gdpr 2016/679
                                (General Data Protection Regulation)
                            </p>
                            <p>
                                Dear Sir/Madam, pursuant to art. 13 of EU Regulation 2016/679 and in
                                relation to the information that you will come into possession of, for the
                                protection of persons and other subjects regarding the processing of
                                personal data, we inform you as follows:
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Purposes of the processing
                            </p>
                            <p>
                                The data provided by you will be used for the purpose and to ensure the
                                provision, customization and improvement of our services.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Treatment modalities
                            </p>
                            <p>
                                The methods by which personal data will be processed include computer and
                                telematic tools with suitable methods to ensure their security and
                                confidentiality, in accordance with the provisions of Article 32 GDPR.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Type of data processed
                            </p>
                            <p>
                                Data that allow direct identification such as personal data (for example:
                                name and surname), images and email will be processed. It is specified the
                                possibility to refuse consent to individual processing such as those for
                                direct marketing purposes.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Legal basis of the processing
                            </p>
                            <p>
                                The Owner processes Personal Data relating to the User if one of the
                                following conditions is met:
                            </p>
                            <ol>
                                <li>
                                    the User has given consent for one or more specific purposes;
                                </li>
                                <li>
                                    the processing is necessary for the execution of a contract with the
                                    User and/or the execution of pre-contractual measures;
                                </li>
                                <li>
                                    the processing is necessary to fulfil a legal obligation to which the
                                    Owner is subject;
                                </li>
                                <li>
                                    the processing is necessary for the performance of a task in the public
                                    interest or for the exercise of public authority vested in the
                                    Controller;
                                </li>
                                <li>
                                    processing is necessary for the pursuit of the legitimate interest of
                                    the Controller or of third parties.
                                </li>
                            </ol>
                            <p>
                                However, it is always possible to ask the Data Controller to clarify the
                                concrete legal basis of each processing and in particular to specify
                                whether the processing is based on law, provided for in a contract or
                                necessary to conclude a contract.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Provision of data
                            </p>
                            <p>
                                The provision of data for the purposes referred to in point 1 are optional
                                and any refusal to grant authorisation will make it impossible to use the
                                services provided.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Communication and dissemination of data
                            </p>
                            <p>
                                The data provided will not be subject to communication or dissemination.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Data on cookies
                            </p>
                            <p>
                                Cookies are small text files that sites visited by you send and record on
                                your computer or mobile device, and are then retransmitted to the same
                                sites the next time you visit. It is precisely thanks to cookies that a
                                site remembers the user's actions and preferences (such as, for example,
                                login details, the language chosen, font size, other display settings,
                                etc.) so that they do not have to be indicated again when the user returns
                                to that site or navigates from one page of it to another. Cookies,
                                therefore, are used to perform computer authentication, session tracking
                                and storage of information regarding the activities of users accessing a
                                site and may also contain a unique identification code that allows you to
                                track your navigation within the site itself for statistical or advertising
                                purposes. During navigation on a site, the user may also receive on his
                                computer or mobile device cookies from sites or web servers other than the
                                one he is visiting (so-called "third party" cookies). Some operations could
                                not be carried out without the use of cookies, which in some cases are
                                therefore technically necessary for the site to function itself. We use
                                cookies in order to show you relevant content and improve your experience.
                                The length of time a cookie is stored on your computer or mobile device
                                varies depending on whether it is a "persistent" or "session" cookie.
                                Session cookies are stored on your device until you finish browsing.
                                Persistent cookies remain on your computer or mobile device until they
                                expire or are deleted.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Data Controller
                            </p>
                            <p>
                                The owner of the treatment of personal data is Dr. Alessandro Cacciotti
                                with domicile in Colleferro (RM), Via Donatello, n. 3.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Period of storage
                            </p>
                            <p>
                                We store data as long as is necessary to provide our services or until the
                                account is deleted, whichever event occurs first. This is a case-by-case
                                determination that varies based on factors such as the nature of the data,
                                the reason why it is collected and processed and the relevant legal or
                                operational storage requirements. When you delete your account, we delete
                                items that you have posted, such as photos and status updates, and you will
                                no longer be able to retrieve this information. Information that other
                                people have shared about you is not part of your account and will not be
                                deleted. If you do not wish to delete your account, but wish to temporarily
                                stop using the Products, you can choose to deactivate it.
                            </p>
                            <p style={{fontWeight: 'bold'}}>
                                Rights of the interested party
                            </p>
                            <p>
                                At any time, you may exercise, in accordance with articles 15 to 22 of EU
                                Regulation no. 2016/679, the right to:
                            </p>
                            <ol>
                                <li>
                                    ask for confirmation of the existence or not of your personal data;
                                </li>
                                <li>
                                    obtain information on the purposes of the processing, the categories of
                                    personal data, the recipients or categories of recipients to whom the
                                    personal data have been or will be communicated and, when possible, the
                                    period of storage;
                                </li>
                                <li>
                                    obtain the rectification and erasure of the data;
                                </li>
                                <li>
                                    obtain the restriction of processing;
                                </li>
                                <li>
                                    to obtain the portability of the data, i.e. to receive them from a data
                                    controller, in a structured, commonly used and machine-readable format,
                                    and to transmit them without hindrance to another controller;
                                </li>
                                <li>
                                    oppose the processing at any time and also in the case of processing
                                    for direct marketing purposes;
                                </li>
                                <li>
                                    object to automated decision-making relating to natural persons,
                                    including profiling;
                                </li>
                                <li>
                                    to ask the data controller for access to personal data and the
                                    rectification or erasure or limitation of the processing concerning
                                    him/her or to object to their processing, as well as the right to data
                                    portability;
                                </li>
                                <li>
                                    withdraw consent at any time without prejudice to the lawfulness of the
                                    processing based on the consent given before the withdrawal;
                                </li>
                                <li>
                                    lodge a complaint with a supervisory authority.
                                </li>
                            </ol>
                            <p>
                                You may exercise your rights by making a specific request to the email
                                address info@worldust.com
                            </p>
                            </Col>
                        )
                      }
                      
                    </Row>
                    
                </Container>
            </div>
            <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={4} className="centered-cell">
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col>
                      <p className="centered-text footer-text">
                        {t('Site.contacts')}<br/>
                        <span className="simple-footer-text">
                          {CONTACT_EMAIL}   
                        </span>  
                      </p>
                      <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                        <Col className="social-container">
                        <a href={FACEBOOK_URL} target="_blank">
                          <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                        <a href={INSTAGRAM_URL} target="_blank">
                          <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
                        </a>
                        
                      </Col>
                      </Row>
                    </Col>
                      
                  </Row>
                  
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col className="footer-text">
                        {t('Common.more_info')}<br/>
                        <a href={'/terms-of-service'}>
                          <span className="simple-footer-text">
                            {t('Common.terms')}   
                          </span>
                        </a>
                        <a href={'/faq'}>
                          <span className="simple-footer-text">
                            FAQ   
                          </span>
                        </a>
                        <a href={'/about_us'}>
                          <span className="simple-footer-text">
                          {t('Common.aboutUs')}    
                          </span>
                        </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={12} className="centered-cell" style={{color: 'white', marginTop: '10px',  marginBottom: '10px'}}>
                  {t('Site.all_right_reserved')}
                </Col>
              </Row>
          </div>




        );
    }
}

export default withTranslation()(TermsOfService);
