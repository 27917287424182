import React, { PureComponent } from 'react'
import { API_BASE_URL, ACCESS_TOKEN } from '../Constants.js';

import axios from 'axios';
import { Row, Col, Container } from 'react-bootstrap';
import UserDiscovery from '../components/UserDiscovery';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import PlatePage from '../components/PlatePage';
import AttractionPage from '../components/AttractionPage';
import i18n from 'i18next';
import getTime from 'date-fns/getTime';

class Discoveries extends PureComponent {
  _isMounted = false;

  constructor(props) {
      super(props);


      this.state = {
        discoveries: [],
        page: 0,
        selectedDiscovery: null,
        showModal: false,
        errorMsg: {},
        voyagersFilter: false,
        cityFilter: false,
        hasMore: false,
        logout: false,
        successMessage: '',
        minDate: getTime(new Date()),
      }

      this.handleClose = this.handleClose.bind(this);
      this.getDiscoveriesList = this.getDiscoveriesList.bind(this);
      this.showDiscoveryInfo = this.showDiscoveryInfo.bind(this);
      this.addVoyagerFilter = this.addVoyagerFilter.bind(this);
      this.addCityFilter = this.addCityFilter.bind(this);

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.getDiscoveriesList();

  }

  addVoyagerFilter() {
    let voyagersFilter = this.state.voyagersFilter;
    this.setState({voyagersFilter: !voyagersFilter}, () => {
      this.getDiscoveriesList(true);
    });
  }

  addCityFilter() {
    let cityFilter = this.state.cityFilter;
    this.setState({cityFilter: !cityFilter}, () => {
      this.getDiscoveriesList(true);
    });
  }

  

  showDiscoveryInfo = (discovery) => {
    
    this.setState({selectedDiscovery: discovery,
      showModal: true,
     });

  }

  handleClose(){
    this.setState({selectedDiscovery: null,
      showModal: false,
      errorMsg: {},
      successMessage: ''});
  }

  getDiscoveriesList(refresh){

    let page = this.state.page;
    let discoveries = this.state.discoveries;
    let minDate = this.state.minDate;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      page=0;
      discoveries=[];
      minDate = getTime(new Date());
      this.setState({errorMsg,  
        page,
        discoveries,
        minDate,
        hasMore: true,
      });
  
    }else{
      this.setState({errorMsg,  
        hasMore: true,
      });
  
    }

    
    let url = API_BASE_URL+'/discovery/getDiscoveries?';
    let defParams = 'page='+page+'&lang='+i18n.language+'&minDate='+minDate;

    if (this.state.voyagersFilter && this.state.cityFilter && this.props.cityId) {
      url = API_BASE_URL+'/discovery/getDiscoveriesByCityAndVoyagers?cityId='+this.props.cityId+'&';
    }else{
      if (this.state.voyagersFilter) {
        url = API_BASE_URL+'/discovery/getDiscoveriesByVoyagers?';
      }else if(this.state.cityFilter && this.props.cityId){
        url = API_BASE_URL+'/discovery/getDiscoveriesByCity?cityId='+this.props.cityId+'&';
      }
    }
    
    url+=defParams;

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newDiscoveries = response.data;
        if((newDiscoveries && newDiscoveries.length===0) || (newDiscoveries && newDiscoveries[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          discoveries: discoveries.concat(newDiscoveries)
        });
      }

    }, (error) => {
      if (this._isMounted) {
        let logout = false;
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
              logout=true;
              return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false, logout});
      }
    });
  }




  render() {
    const { t } = this.props;
      
    return (
      <div className="tab-content-container">
        <div className="tab-content-menu justify-content-md-center text-center">
            {this.props.cityId && (
              <i className={this.state.cityFilter? "fa fa-map-marker city-filter-btn icon_pressed" : "fa fa-map-marker city-filter-btn icon_unpressed"} aria-hidden="true" onClick={(e) => this.addCityFilter()}></i>
            )}
            <span className="city-title">{t('Profile.discoveries')}</span>
            <i className={this.state.voyagersFilter? "fa fa-suitcase voyager-filter-btn icon_pressed" : "fa fa-suitcase voyager-filter-btn icon_unpressed"} aria-hidden="true" onClick={(e) => this.addVoyagerFilter()}></i>
        </div>
        <div className="tab-content-body" style={{ height: this.props.height}}>
          {this.state.discoveries.length===0 && (
            <div className="separator">
              <Row className="justify-content-md-center">
                <Col sm={12} className="text-center">
                  <span>{t('Discovery.not_found')}</span>
                </Col>
              </Row>
            </div>
          )}
          {this.state.discoveries.length>0 && (
          <InfiniteScroll
            dataLength={this.state.discoveries.length}
            next={this.getDiscoveriesList}
            hasMore={this.state.hasMore}
            pullDownToRefresh={true}
            pullDownToRefreshContent={
              <div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>
            }
            releaseToRefreshContent={
              <div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>
            }
            pullDownToRefreshThreshold={50}
            refreshFunction={(e) => this.getDiscoveriesList(true)}
            height={this.props.height}
            loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
          >
            <Container fluid={true} className="infinite-scroll-container">
              <Row className="justify-content-md-center">
                <Col md={6} className="no-space-container">
                  {this.state.discoveries.map((discovery, index) => (
                    <UserDiscovery key={index} discovery={discovery} user={this.props.user} discoveryInfo={this.showDiscoveryInfo}></UserDiscovery>
                  ))}
                </Col>
              </Row>
            </Container>
          </InfiniteScroll>
        )}
        </div>
        {this.state.showModal && this.state.selectedDiscovery && !this.state.selectedDiscovery.isAttraction && (
            <PlatePage plate={this.state.selectedDiscovery.plate} showModal={this.state.showModal} handleClose={this.handleClose} showUserAttraction={false}></PlatePage>
          )}
          {this.state.showModal && this.state.selectedDiscovery && this.state.selectedDiscovery.isAttraction && (
            <AttractionPage attraction={this.state.selectedDiscovery.attraction} showModal={this.state.showModal} handleClose={this.handleClose}></AttractionPage>
          )}
      </div>


    )
  }
}

export default withTranslation()(Discoveries);
