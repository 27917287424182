import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Menu_admin from '../components/Menu_admin';
import Loader from '../components/Loader';
import ManageCityInfo from '../pages/ManageCityInfo';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import { API_BASE_URL, ACCESS_TOKEN, REVERSE_GEOCODING_URL} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Select from 'react-select';


class ManageCities extends PureComponent {

  _isMounted = false;

    constructor() {
        super();

        this.state = {
            cityId: "",
            name: '',
            state: '',
            country: '', 
            countryValid: false,
            latitude: '', 
            latitudeValid: false,
            longitude: '', 
            longitudeValid: false,
            tripadvisorQuery: '',
            tripadvisorQueryValid: false,
            formValid: false,
            cities: [],
            countries: [],
            errorMsg: {},
            successMessage: '',
            tableKey: Math.random(),
            showModal: false,
            showCityInfo: false,
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.showCityInfo = this.showCityInfo.bind(this);
        this.handleShowCityInfo = this.handleShowCityInfo.bind(this);
        this.getCurrentCityInfo = this.getCurrentCityInfo.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.getAddressInfo = this.getAddressInfo.bind(this);


    }


    resetState(){
      this.setState({
            cityId: "",
            name: '',
            state: '',
            country: '', 
            countryValid: false,
            latitude: '', 
            latitudeValid: false,
            longitude: '', 
            longitudeValid: false,
            tripadvisorQuery: '',
            tripadvisorQueryValid: false,
            formValid: false,
            errorMsg: {},
            successMessage: '',
            showModal: false,
            showCityInfo: false,
            loading: false
        });
    }

    handleChangeCountry = selectedOption => {
        this.setState(
          { country: selectedOption }, this.validateFields("country", selectedOption)
        );
      };

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';


      if(name==="country"){
        let countryValid = true;
        errorMsg.country = '';
        if (value.length === 0) {
          countryValid = false;
          errorMsg.country = t('Errors.empty_field');
        }

        this.setState({countryValid, errorMsg}, this.validateForm);

      }

      if(name==="longitude"){
        let longitudeValid = true;
        errorMsg.longitude = '';
        if (value.length === 0) {
          longitudeValid = false;
          errorMsg.longitude = t('Errors.empty_field');
        }else if(isNaN(value)){
          longitudeValid = false;
          errorMsg.longitude = t('Errors.invalid_field')
        }

        this.setState({longitudeValid, errorMsg}, this.validateForm);

      }

      if(name==="latitude"){
        let latitudeValid = true;
        errorMsg.latitude = '';
        if (value.length === 0) {
          latitudeValid = false;
          errorMsg.latitude = t('Errors.empty_field');
        }else if(isNaN(value)){
          latitudeValid = false;
          errorMsg.latitude = t('Errors.invalid_field')
        }

        this.setState({latitudeValid, errorMsg}, this.validateForm);

      }

      if(name==="tripadvisorQuery"){
        let tripadvisorQueryValid = true;
        errorMsg.tripadvisorQueryValid = '';
        if (value.length > 0 && !value.startsWith("/")) {
          tripadvisorQueryValid = false;
          errorMsg.tripadvisorQueryValid = t('Errors.invalid_field');
        }

        this.setState({tripadvisorQueryValid, errorMsg}, this.validateForm);

      }

    }


    getCountries(){
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {...this.state.errorMsg}

      errorMsg.dataError ='';
      this.setState({errorMsg,
        loading: true

      });

      axios.get(API_BASE_URL+'/city/admin/getAllCountries', config)
      .then((response) => {
        if (this._isMounted) {
          var countries = response.data;
          this.setState({countries}, this.updateTableData);
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });
    }


    componentDidMount() {
      this._isMounted = true;
      this.getCountries();
    }

    componentWillUnmount(){
      this._isMounted = false;
    }

    getAddressInfo(){
      
      
      const { t } = this.props;
      let errorMsg = {};
  

      this.setState({ errorMsg, name: '',
        state: '',
        country: '', countryValid: false,
        loading: true});

      var position = [];
      position.push(this.state.latitude);
      position.push(this.state.longitude);

      axios
      .get(REVERSE_GEOCODING_URL+'?latitude='+position[0]+'&longitude='+position[1]+'&localityLanguage=en')
      .then(
        response => {
          
          if (this._isMounted) {
            try{
              var data = response.data;
              var cityInfo = {};
              cityInfo['state'] = data.principalSubdivision?data.principalSubdivision:data.countryName;
              cityInfo['country'] = data.countryCode;
              var cityData = data.localityInfo.administrative;
              var cityNames = ''
              if(data && cityData && cityData.length>0){
                for(var i=0; i<cityData.length; i++){
                  cityNames+=cityData[i].name+'___';
                }
                cityInfo['name'] =  cityNames;
              }
              
              var currentCountry = null;
              var countryValid = false;
              var formValid = false;
              let countries = this.state.countries;
              if(countries){
                for(var i=0; i<countries.length; i++){
                  if(countries[i].value.toLowerCase() === cityInfo.country.toLowerCase()){
                    currentCountry = countries[i];
                    countryValid=true;
                    formValid=true;
                    break;
                  }
                }
              }

              this.setState({
                name: cityInfo.name,
                state: cityInfo.state,
                country: currentCountry,
                countryValid,
                formValid,
                loading: false
              });

            }catch(e){
              this.setState({
                loading: false
              });

            }
           
          }
        },
        error => {
          if (this._isMounted) {
            errorMsg.dataError = t('Errors.generic_error');
  
            if (error.response && error.response.status) {
              let status = error.response.status;
              if (status === 401) {
                return window.location.href = '/logout';            
              }
            } else {
              if (error.message != null && error.message === 'Network Error') {
                errorMsg.dataError = t('Errors.connection_error');
              }
            }
            this.setState({errorMsg, loading: false});
          }
        },
      );
      
    }

    getCurrentCityInfo() {
      if(this.state.latitudeValid && this.state.longitudeValid){
        
          this.getAddressInfo();
      }

    }

  updateTableData = () => {
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}

    errorMsg.dataError ='';
    this.setState({errorMsg,
      loading: true

    });

    axios.get(API_BASE_URL+'/city/getAll', config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({ cities: response.data,
          loading: false });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  }



  validateForm = () => {
    const {countryValid, latitudeValid, longitudeValid, tripadvisorQueryValid} = this.state;
    this.setState({
      formValid: countryValid && latitudeValid && longitudeValid && tripadvisorQueryValid
    });
  }


  showCityInfo(show){
    this.setState({
      showCityInfo: show
    });
  }


  handleShowCityInfo(row){
    var city = row;
    let countries = this.state.countries;
    let country = null;
    for(var j=0; j<countries.length; j++){
      if(countries[j].value.trim() === city.countryCode.trim()){
        country = countries[j];
        break;
      }
    }
    this.setState({
      cityId: city.cityId,
      name: city.name,
      state: city.state,
      country: country, 
      countryValid: true,
      latitude: city.latitude, 
      latitudeValid: true,
      longitude: city.longitude, 
      longitudeValid: true,
      tripadvisorQuery: city.tripadvisorQuery,
      tripadvisorQueryValid: true,
      formValid: true,
      errorMsg: {},
      successMessage: '',
      showModal: false,
      showCityInfo: true
    });
  }


  handleEdit(row) { 
    var city = row;
    let countries = this.state.countries;
    let country = null;
    for(var j=0; j<countries.length; j++){
      if(countries[j].value.trim() === city.countryCode.trim()){
        country = countries[j];
        break;
      }
    }
    this.setState({
      cityId: city.cityId,
      name: city.name,
      state: city.state,
      country: country, 
      countryValid: true,
      latitude: city.latitude, 
      latitudeValid: true,
      longitude: city.longitude, 
      longitudeValid: true,
      tripadvisorQuery: city.tripadvisorQuery?city.tripadvisorQuery:'',
      tripadvisorQueryValid: true,
      formValid: true,
      errorMsg: {},
      successMessage: '',
      showModal: false
    });

  }

  deleteConfirm(e) {
    let cityId = this.state.cityId;
    let errorMsg = {};
    const { t } = this.props;

    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    this.setState({errorMsg,
      loading: true});

    axios.post(API_BASE_URL+'/city/admin/delete/'+cityId, null, config)
    .then((response) => {
      if (this._isMounted) {

        let cities = this.state.cities.filter(item => item.cityId !== cityId);
        this.setState({
          cities,
          tableKey: Math.random(), 
          cityId: "",
          name: '',
          state: '',
          country: '', 
          countryValid: false,
          latitude: '', 
          latitudeValid: false,
          longitude: '', 
          longitudeValid: false,
          tripadvisorQuery: '',
          tripadvisorQueryValid: false,
          formValid: false,
          errorMsg: {},
          successMessage: '',
          showModal: false,
          loading: false
        });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });



  }

  handleDelete(row) {
    var city = row;
    let countries = this.state.countries;
    let country = null;
    for(var j=0; j<countries.length; j++){
      if(countries[j].value.trim() === city.countryCode.trim()){
        country = countries[j];
        break;
      }
    }
    this.setState({
      cityId: city.cityId,
      name: city.name,
      state: city.state,
      country: country, 
      countryValid: true,
      latitude: city.latitude, 
      latitudeValid: true,
      longitude: city.longitude, 
      longitudeValid: true,
      tripadvisorQuery: city.tripadvisorQuery,
      tripadvisorQueryValid: true,
      formValid: true,
      errorMsg: {},
      successMessage: '',
      showModal: true
    });

  }

  handleClose(){
    this.setState({showModal: false });
  }

    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        }, this.validateFields(name, value));


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["cityId"]=(this.state.cityId==="")?null:this.state.cityId;
      data["name"]=this.state.name;
      data["state"]=this.state.state;
      data["countryCode"]=this.state.country.value;
      data["latitude"]=this.state.latitude;
      data["longitude"]=this.state.longitude;
      data["tripadvisorQuery"]=this.state.tripadvisorQuery;

      let errorMsg = {};
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      
      this.setState({errorMsg,
        loading: true});

      axios.post(API_BASE_URL+'/city/admin/save', data, config)
      .then((response) => {
        if (this._isMounted) {
          let newCity = response.data;
          let cities = [...this.state.cities];
          if(data.cityId){
            for(var j=0; j<cities.length; j++){
              if(cities[j].cityId === data.cityId){
                cities[j] = newCity;
                break;
              }
            }
          }else{
            cities.push(newCity);
          }
          this.setState({cities, tableKey: Math.random(), successMessage: t('City.saved'),
            loading: false });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };



    render() {
        const { SearchBar } = Search;
        const { t } = this.props;

        const columns = [{
          dataField: 'cityId',
          text: 'ID'
        }, {
          dataField: 'name',
          text: t('City.name')
        }, {
          dataField: 'state',
          text: t('City.state')
        },
        {
          dataField: 'countryName',
          text: t('City.country')
        },
        {
          dataField: 'id',
          text: t('Common.actions'),
          formatter: (cell, row, rowIndex) => (
            <div className="buttons-container">
              <Button variant="primary" type="button" onClick={(e) => this.handleEdit(row)}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </Button>
              <Button variant="primary" type="button" onClick={(e) => this.handleShowCityInfo(row)}>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </Button>
              <Button variant="primary" type="button" onClick={(e) => this.handleDelete(row)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </Button>


            </div>
          )
        }];


        if(this.state.showCityInfo){
          return (<ManageCityInfo back={this.showCityInfo} cityId={this.state.cityId} cityName={this.state.name}></ManageCityInfo>);
        }else{
          return (
            <Container fluid={true} className="menu-container">
            {this.state.loading && (
              <Loader></Loader>
            )}
            <Menu_admin></Menu_admin>
              <Container fluid={true} className="title-bar-page">
                <Row>
                  <Col md={2}>
                    <Link to="/letMeDiscover">
                      <div className="back-button"><i className="fa fa-chevron-left fa-2x " aria-hidden="true"></i></div>
                    </Link>
                  </Col>
                  <Col md={8} className="text-center">
                    <h5 className="title-text">{t('Admin.manage_city')}</h5>
                  </Col>
                </Row>
              </Container>
              <Container fluid={true} className="tab-container">
                <Row className="justify-content-md-center">
                  <Col sm={8} className="form_center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="cityId" className="form_field">
                        <Form.Label className="form_field_label">Id</Form.Label>
                        <Form.Control type="number" className="form_field_input" placeholder={"Id"} name="cityId" value={this.state.cityId} readOnly />
                      </Form.Group>
                      <Form.Group controlId="latitude" className="form_field">
                        <Form.Label className="form_field_label">{t('Common.latitude')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('Common.latitude')} name="latitude" value={this.state.latitude} onChange={this.handleChange}  />
                        <ValidationMessage valid={this.state.latitudeValid} message={this.state.errorMsg.latitude} />
                      </Form.Group>
                      <Form.Group controlId="longitude" className="form_field">
                        <Form.Label className="form_field_label">{t('Common.longitude')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('Common.longitude')} name="longitude" value={this.state.longitude} onChange={this.handleChange}  />
                        <ValidationMessage valid={this.state.longitudeValid} message={this.state.errorMsg.longitude} />
                      </Form.Group>
                      <Form.Row>
                        <Col className="text-center">
                          <Button variant="primary" type="button" className="btn-login" onClick={this.getCurrentCityInfo} disabled={!(this.state.latitudeValid && this.state.longitudeValid)}>{t('City.find_city')}</Button>
                        </Col>

                       </Form.Row>
                      <Form.Group controlId="name" className="form_field">
                        <Form.Label className="form_field_label">{t('City.name')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('City.name')} name="name" value={this.state.name} required onChange={this.handleChange}/>
                      </Form.Group>
                      <Form.Group controlId="state" className="form_field">
                        <Form.Label className="form_field_label">{t('City.state')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('City.state')} name="state" value={this.state.state} required readOnly />
                      </Form.Group>
                      <Form.Group controlId="country" className="form_field">
                        <Form.Label className="form_field_label">{t('City.country')}</Form.Label>
                          <Select
                              value={this.state.country}
                              onChange={this.handleChangeCountry}
                              options={this.state.countries}
                              isDisabled={true}
                            />
                          <ValidationMessage valid={this.state.countryValid} message={this.state.errorMsg.country} />
                      </Form.Group>
                      <Form.Group controlId="name" className="form_field">
                        <Form.Label className="form_field_label">{'Tripadvisor'}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={'Tripadvisor query'} name="tripadvisorQuery" value={this.state.tripadvisorQuery} required onChange={this.handleChange}/>
                        <ValidationMessage valid={this.state.tripadvisorQueryValid} message={this.state.errorMsg.tripadvisorQueryValid} />
                      </Form.Group>
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                      </Form.Group>
                      <Form.Row>
                        {this.state.cityId!=="" && (
                          <Col className="text-center">
                            <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('Common.save')}</Button>
                          </Col>
                        )}
                        {this.state.cityId==="" && (
                          <Col className="text-center">
                            <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('City.create')}</Button>
                          </Col>
                        )}
                        <Col className="text-center">
                          <Button variant="secondary" type="button" className="btn-login" onClick={this.resetState}>{t('Common.clear')}</Button>
                        </Col>

                       </Form.Row>
                     </Form>
                   </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={12} className="form_center">
                    <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                    <ToolkitProvider
                      keyField="cityId"
                      data={ this.state.cities }
                      columns={ columns }
                      search

                      >
                      {
                        props => (
                          <div className="wd-tables-container">
                            <SearchBar { ...props.searchProps } />
                            <hr />
                            <BootstrapTable key={this.state.tableKey} pagination={ paginationFactory()}
                              { ...props.baseProps }
                            />
                          </div>
                        )
                      }
                      </ToolkitProvider>
                    </Col>
                  </Row>
                </Container>
                <Modal show={this.state.showModal} animation={false} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>  {this.state.name}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="app_aside modal-message">
                      {t('City.delete_confirm')}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={this.deleteConfirm}>
                      {t('Common.confirm')}
                    </Button>
                    <Button variant="secondary" onClick={this.handleClose}>
                      {t('Common.close')}
                    </Button>
                  </Modal.Footer>
                </Modal>
            </Container>
          );
        }


    }
}

export default withTranslation()(ManageCities);
