import React, { PureComponent } from 'react';

import { Row, Col, Container, Modal, Button } from 'react-bootstrap';


import { withTranslation } from 'react-i18next';

class WDLBackButtonTitle extends PureComponent {

    constructor(props) {
      super(props);
      
      this.state = {
        showModal: false,
      }
      this.handleClose = this.handleClose.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleSave() {
    this.setState({showModal: false});
    if(!this.props.link){
      return this.props.history?.goBack();
    } else {
      return window.location.href = this.props.link;
    }
    
  }

  handleClose() {
    this.setState({showModal: false});
  }

  handleButtonClick() {
    if(this.props.toSave) {
      this.setState({showModal: true});
    } else {
      if(!this.props.link){
        return this.props.history?.goBack();
      } else {
        return window.location.href = this.props.link;
      }
    }
    
  }

    render() {
        const { t } = this.props;
        return (
          <Container fluid={true} className="wdl-title-bar-page">
            <Row>
              <Col md={12} className="text-center">
                <a onClick={this.handleButtonClick} >
                  <i className="fa fa-chevron-left fa-2x wdl-back-button" aria-hidden="true"></i>
                </a>
                <span className="wdl-fake-modal-title">{t(this.props.title)}</span>
              </Col>
            </Row>
            {
              this.props.toSave &&

              <Modal show={this.state.showModal} animation={false} onHide={this.handleClose}>
                <Modal.Header className="wdl" closeButton>
                </Modal.Header>
                <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}}>
                    {t('Common.exit_without_save')}
                </Modal.Body>
                <Modal.Footer className="wdl double-buttons-footer">
                  <Button variant="secondary" onClick={this.handleClose}>
                    {t('Common.clear')}
                  </Button>
                  <Button variant="primary" onClick={this.handleSave}>
                    {t('Common.confirm')}
                  </Button>
                </Modal.Footer>
              </Modal>
            }
            
          </Container>
        );
    }
}

export default withTranslation()(WDLBackButtonTitle);
