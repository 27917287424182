import React, { PureComponent } from 'react';
import logo from '../img/logo.svg';
import {Image} from 'react-bootstrap';

class Loader extends PureComponent {
    
    render() {
        return (
            <div className="loader-container">
              <Image src={logo} alt="Worldust" className="center-block" fluid/>
            </div>


        );
    }
}

export default Loader;
