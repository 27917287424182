import React, { PureComponent } from 'react';
import SignInForm from '../components/SignInForm';
import LogoSection from '../components/LogoSection';
import {NavLink } from 'react-router-dom';
import { Row, Col, Container} from 'react-bootstrap';
import LanguageChooser from '../components/LanguageChooser';
import { withTranslation } from 'react-i18next';
import {CONTACT_EMAIL} from '../Constants.js';

class Login extends PureComponent {


    render() {
        const { t } = this.props;

        return (
          <Container fluid={true}>
            <Row className="maxHeight">
                <Col sm={6} className="app_aside">
                  <LogoSection></LogoSection>
                </Col>
                <Col sm={6} className="app_form">
                    <div className="page_switcher">
                      <div>
                        <LanguageChooser></LanguageChooser>
                      </div>
                      <div className="page_switcher_container">
                        <NavLink  exact to="/loginDiscover" activeClassName="page_switcher_item--Active" className="page_switcher_item"> {t('Login_page.signin')}</NavLink>
                        <NavLink to="/sign-up-discover" activeClassName="page_switcher_item--Active" className="page_switcher_item">{t('Login_page.signup')}</NavLink>
                      </div>

                    </div>

                    <div className="form_title">
                        <NavLink exact to="/loginDiscover" activeClassName="form_title_link--Active" className="form_title_link">{t('Login_page.signin')}</NavLink> {t('Login_page.or')} <NavLink to="/sign-up-discover" activeClassName="form_title_link--Active" className="form_title_link">{t('Login_page.signup')}</NavLink>
                    </div>
                    <SignInForm></SignInForm>
                    <div className="contact-container">
                      <span>{CONTACT_EMAIL}</span>
                    </div>
                </Col>
              </Row>
              
            </Container>
        );
    }
}

export default withTranslation()(Login);
