import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';

import axios from 'axios';
import PlatePage from '../components/PlatePage';
import InfiniteScroll from 'react-infinite-scroll-component';
import BackButtonTitle from '../components/BackButtonTitle';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';


class WishedPlatesPage extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        selectedPlate: null,
        showModal: false,
        page: 0,
        hasMore: false,
        wished_plates: []
      }
      this.getWishedPlates = this.getWishedPlates.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showPlatePage = this.showPlatePage.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getWishedPlates();
  }

  handleClose(){
    this.setState({selectedPlate: null,
      showModal: false});
  }

  showPlatePage(selectedPlate){
    this.setState({selectedPlate,
      showModal: true});

  }


getWishedPlates(refresh){

    let page = this.state.page;
    let wished_plates = this.state.wished_plates;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      page=0;
      wished_plates=[];
      this.setState({errorMsg, page, wished_plates,
        hasMore: true
      });
    } else {
      this.setState({errorMsg, 
        hasMore: true
      });
    }

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    

    axios.get(API_BASE_URL+'/discovery/getWishedPlates?page='+page+'&lang='+i18n.language, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newWishedPlates = response.data;
        if((newWishedPlates && newWishedPlates.length===0) || (newWishedPlates && newWishedPlates[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: this.state.page+1,
          wished_plates: wished_plates.concat(newWishedPlates)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
                <BackButtonTitle handleClose={this.props.handleClose} title={'Discovery.plates_to_taste'}></BackButtonTitle>
                <InfiniteScroll
                  dataLength={this.state.wished_plates.length}
                  next={this.getWishedPlates}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getWishedPlates(true)}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefresh={true}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.wished_plates.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Plate.not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.wished_plates && this.state.wished_plates.map((wishedPlate, index) => (
                          <ListGroup.Item key={index}>
                          <div className="row-link-container"  onClick={(e) => this.showPlatePage(wishedPlate)}>
                          <Row>
                            <Col className="list-image-container">
                              <Image fluid={true} src={wishedPlate.image} className={"list-img"}/>
                            </Col>
                            <Col className="info-list-container">
                              <Row>
                                <Col>
                                  <span className="link-icon">{(wishedPlate.nameInfo)?wishedPlate.nameInfo: wishedPlate.name}</span>
                         
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                <span className="city-info-label">{wishedPlate.city.info ? wishedPlate.city.info.name+ ', '+ wishedPlate.city.countryCode : wishedPlate.city.name+ ', '+ wishedPlate.city.countryCode}</span>
                              </Col>
                              </Row>
                            </Col>
                          </Row>
                          </div>
                        </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <PlatePage plate={this.state.selectedPlate} user={this.props.user} showModal={this.state.showModal} handleClose={this.handleClose}></PlatePage>
                  )}
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(WishedPlatesPage);
