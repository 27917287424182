import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Table, Button} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import NumberFormat from 'react-number-format';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';

import moment from 'moment';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import Select from 'react-select';

import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLPallets extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  _moment_locales = {
    en: 'en-gb',
    fr: 'fr',
    it: 'it',
    es: 'es'
  }

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }

      
      const { t } = this.props;
      let momentLocale = moment().locale(this._moment_locales[i18n.language]).localeData();
      let locale = {
        daysOfWeek: momentLocale._weekdaysShort,
        monthNames: momentLocale._monthsShort,
      };

      const search = props.location.search;
      const clientId = new URLSearchParams(search).get('clientId');
      const vectorId = new URLSearchParams(search).get('vectorId');
      const currentQuery = new URLSearchParams(search).get('query');
      const startDateMillis = new URLSearchParams(search).get('startDate');
      const endDateMillis = new URLSearchParams(search).get('endDate');
      let startDate = null;
      let endDate = null;
      if(startDateMillis) {
        startDate = new Date(Number(startDateMillis));
      } else {
        startDate = startOfMonth(new Date());
      }

      if(endDateMillis) {
        endDate = new Date(Number(endDateMillis));
      } else {
        endDate = endOfMonth(new Date());
      }

      this.state = {
        companyId: this.props.match.params.companyId,
        user: user,
        isAdmin: isAdmin,
        shipments: [],
        vectors: [{label: t('Common.select'), value: null}],
        clients: [{label: t('Common.select'), value: null}],
        startDate,
        endDate,
        locale,
        totals: {
          numbers: 0,
          epal: 0,
          chep: 0,
          tickets: 0,
          lost: 0,
        },
        selectedClient: null,
        selectedVector: null,
        viewHeight: window.innerHeight,
        clientId,
        vectorId,
        errorMsg: {},
        successMessage: '',
        blob: null,
        contractName: null,
        changed: new Set([]),
        query: currentQuery?currentQuery: '',
        loadingPdf: false,
        page: 0,
        hasMore: false,
        selectAll: vectorId?true:false,
      }
      this.getShipments = this.getShipments.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
      this.getCompanyInfo = this.getCompanyInfo.bind(this);
      this.handleSelectVector = this.handleSelectVector.bind(this);
      this.handleSelectClient = this.handleSelectClient.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSelectShipment = this.handleSelectShipment.bind(this);
      this.handleSelectAllShipment = this.handleSelectAllShipment.bind(this);
      this.calculateTotals = this.calculateTotals.bind(this);
      this.handleChangePallets = this.handleChangePallets.bind(this);
      this.getPdf = this.getPdf.bind(this);
      this.generateContractName = this.generateContractName.bind(this);


  }

  handleChangeDateRange(start, end, label) {
    this.setState({startDate: moment(start).toDate(), endDate: moment(end).toDate()}, ()=>this.getCompanyInfo(false))
  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getCompanyInfo(true);
  }

  generateContractName() {
    const { t } = this.props;
    let vector = this.state.selectedVector;
    let contractName = t("WDL.pallets")+"-";
    
    contractName += vector.label+"-";
    
    contractName +=  format(
      new Date(),
      'dd-MM-yyyy',
      {locale: this._locales[i18n.language]}
    );

    contractName += ".pdf";

    return contractName;
  }


  getPdf() {
    var data = this.state.shipments.filter(item=>item.selected===true);
    let errorMsg = {...this.state.errorMsg}
    const { t } = this.props;
    var config = {
      responseType: 'blob',
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };
    errorMsg.appError ='';
    this.setState({errorMsg, loadingPdf: true, successMessage: '', blob: null,});

    axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.selectedVector.value+'/getPalletsPdf?lang='+i18n.language, data, config)
    .then((response) => {
      if (this._isMounted) {
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        this.setState({loadingPdf: false, blob: {url: fileURL, name: this.generateContractName()}}, ()=>this.pdfRef.click())
        window.URL.revokeObjectURL(fileURL);
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }

        }
        this.setState({errorMsg,
          loadingPdf: false});
      }
    });
  }


  handleSelectVector(item) {
    this.setState({selectedVector: item});
  }


  handleSelectClient(item) {
    this.setState({selectedClient: item});
  }


  calculateTotals() {
    let totals = {
      numbers: 0,
      epal: 0,
      chep: 0,
      tickets: 0,
      lost: 0,
    }

    for(let i=0; i<this.state.shipments.length; i++) {
      if(!this.state.vectorId || this.state.shipments[i].selected===true){
        totals.numbers+=1;
        totals.epal+=this.state.shipments[i].epal;
        totals.chep+=this.state.shipments[i].chep;
        totals.tickets+=this.state.shipments[i].epalTicket;
        totals.lost+=this.state.shipments[i].epalLost;
      }
    }
    this.setState({totals});
  }

  getCompanyInfo(refresh) {
    const requestId = 'getCompanyRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    const { t } = this.props;
    
    
    this.setState({errorMsg, loading: refresh?true:false, vectors: [{label: t('Common.select'), value: null}], clients: [{label: t('Common.select'), value: null}]});
    

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/getAllInfo'+'?startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();
    
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let companyInfo = response.data;

        let vectors = this.state.vectors.concat(companyInfo.vectors.map((item) => ({label: item.name, value: item.vectorId})));
        
        let selectedVector=null;
        if(this.state.vectorId){
          selectedVector = vectors.find(vector => vector.value == this.state.vectorId);
        }

        let clients = this.state.clients.concat(companyInfo.clients.map((item) => ({label: item.name, value: item.clientId})));
        
        let selectedClient=null;
        if(this.state.clientId){
          selectedClient = clients.find(client => client.value == this.state.clientId);
        }
        this.setState({
          vectors,
          clients,
          loading: false,
          selectedClient,
          selectedVector,
        }, ()=>refresh && this.getShipments());
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, loading:false});
      }
    });
  }


  getShipments(refresh){
    const requestId = 'getShipmentsRequest';
    axios.cancel(requestId);
    let query = this.state.query;
    let page = this.state.page;
    let shipments = this.state.shipments;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      page = 0;
      shipments=[];
      this.setState({errorMsg, shipments, query, page,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment'+'?page='+page+'&startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }

    if(this.state.selectedVector?.value){
      url += '&vectorId=' + this.state.selectedVector.value;
    }
    
    if(this.state.selectedClient?.value){
      url += '&clientId=' + this.state.selectedClient.value;
    }

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newShipments = response.data;
        if((newShipments && newShipments.length===0) || (newShipments && newShipments[0].itemInLastPage)){
          hasMore=false;
        }
        if(this.state.selectAll) {
          for(var i=0; i<newShipments.length; i++) {
            newShipments[i].selected = true;
          }
        }

        this.setState({hasMore,
          page: page+1,
          shipments: shipments.concat(newShipments),
          selectAll: this.state.vectorId?true:false,
        }, ()=>!hasMore && this.calculateTotals());
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    let url = '/logistic/company/'+this.state.companyId+'/pallets'+'?startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();

    if(this.state.selectedClient?.value){
      url += '&clientId=' + this.state.selectedClient.value;
    }

    if(this.state.selectedVector?.value){
      url += '&vectorId=' + this.state.selectedVector.value;
    }

    if (this.state.query && this.state.query.length>0) {
      url += '&query='+this.state.query;
    }
    return window.location.href=url;
  }

  

  handleSubmit(e) {
    e.preventDefault();
    var data = this.state.shipments.filter(item=>this.state.changed.has(item.shipmentId));
    let errorMsg = {...this.state.errorMsg}
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };
    errorMsg.appError ='';
    this.setState({errorMsg, loading: true, successMessage: ''});

    axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/savePallets', data, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({successMessage: t('WDL.saved'), loading: false, changed: new Set([]) });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  }

  handleSelectShipment(event, index) {
    let value = event.target.checked;
    if(this.state.shipments.length>index); {
        let shipments = [...this.state.shipments];
        shipments[index].selected = value;
        this.setState({
          shipments,
          selectAll: false,
        }, ()=>!this.hasMore && this.calculateTotals())
    }
  }

  handleSelectAllShipment(value) {
    let shipments = [...this.state.shipments];
    for(var i=0; i<shipments.length; i++) {
      shipments[i].selected = value;
    }
    this.setState({
      selectAll: value,
      shipments,
    }, ()=>!this.hasMore && this.calculateTotals())
  }

  handleChangePallets(index, name, value) {
    let shipments = [...this.state.shipments];
    if(shipments.length>index) {
      if(value<0){
        value = 0;
      }
      (shipments[index])[name] = Number(value);
      let changed = this.state.changed;
      changed.add(shipments[index].shipmentId);
      this.setState({shipments, changed, successMessage: '', errorMsg: {}}, ()=>this.calculateTotals());
    }
  }



    render() {
      const { t } = this.props;
      
        return (
              
          <div className="wdl-container">
            <InfiniteScroll
                    dataLength={this.state.shipments.length}
                    next={this.getShipments}
                    hasMore={this.state.hasMore}
                    refreshFunction={(e) => this.getShipments(true)}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={10}
                    pullDownToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    releaseToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    height={this.state.viewHeight}
                    className="white-background"
                    loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                  >
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle toSave={this.state.changed.size>0} history={this.props.history} link={'/logistic/company/'+this.state.companyId} title={'WDL.pallets'}></WDLBackButtonTitle>
              
              <Container fluid={true}>
                <Row className="justify-content-md-center">
                    <Col md={11}>
                  <Row className="form_field wdl-search-container">
                    <Col>
                    <Form onSubmit={this.onFormSubmit}>
                      <Row>
                        <Form.Group as={Col}>
                          <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                          <DateRangePicker
                            initialSettings={{ startDate: this.state.startDate, 
                              endDate: this.state.endDate, 
                              locale: this.state.locale,
                              autoApply: true, 
                            }}
                            onCallback={this.handleChangeDateRange}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Form.Group>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Form.Group as={Col}  controlId="query" className="form_field">
                          <Form.Label className="form_field_label">{t('WDL.search')}</Form.Label>
                          <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.text_ph')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                        </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group as={Col} controlId="client" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.client')}</Form.Label>
                        <Select
                          value={this.state.selectedClient || ''}
                          onChange={this.handleSelectClient}
                          options={this.state.clients}
                          placeholder={t('Common.select')}
                        />                        
                      </Form.Group>
                      <Form.Group as={Col} controlId="vector" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.vector')}</Form.Label>
                        <Select
                          value={this.state.selectedVector || ''}
                          onChange={this.handleSelectVector}
                          options={this.state.vectors}
                          placeholder={t('Common.select')}
                        />                        
                      </Form.Group>
                      </Row>
                      <Button style={{padding: 10}} variant="primary" type="submit">
                        <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                      </Button>
                    </Form>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                    <Form onSubmit={this.handleSubmit}>
                        
                        <Form.Group className="form_field wdl-message-container">
                            <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                            <SuccessMessage valid={true} message={this.state.successMessage} />
                          </Form.Group>

                        {this.state.loading && 
                            <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                        }
                        {this.state.vectorId && this.state.shipments.length>0 &&
                        <div>
                          {this.state.loadingPdf ? (
                            <div className="scroll-loader">
                              <div className="lds-ripple wdl">
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          ) : (
                            <Row>
                              <Col md={12} style={{textAlign: 'right', marginBottom: 20}}>
                                <a className="file-url" onClick={this.getPdf} >
                                  <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true">
                                    <span className="link-icon locked-text file-name">
                                    {t('WDL.download_pdf')}
                                    </span>
                                  </i>
                                </a>
                                
                                  <a style={{visibility: 'hidden'}} target="_blank" ref={m => { this.pdfRef = m; }} download={this.state.blob?.name} href={this.state.blob?.url}/>
                                
                              </Col>
                            </Row>
                          )}
                        </div>
                        
                        }
                            <Table striped borderless hover responsive className="wdl-table">
                              <thead>
                                <tr style={{backgroundColor: '#705906', color: 'white'}}>
                                  {this.state.vectorId &&
                                <th>
                                  <Form.Group controlId="selectAll" className="form_field">
                                    <Form.Check type="checkbox">
                                      <Form.Check.Input type="checkbox" name="selectAll" checked={this.state.selectAll || false} value={this.state.selectAll || false} onChange={(e)=>this.handleSelectAllShipment(e.target.checked)}/>
                                    </Form.Check>
                                  </Form.Group>
                                  </th>
                                  }
                                  <th>{t('WDL.number')}</th>
                                  <th>{t('WDL.date')}</th>
                                  <th>{t('WDL.name')}</th>
                                  <th>{t('WDL.client')}</th>
                                  <th>{t('WDL.vector')}</th>
                                  <th>{t('WDL.epal')}</th>
                                  <th>{t('WDL.chep')}</th>
                                  <th>{t('WDL.epal_lost')}</th>
                                  <th>{t('WDL.epal_tickets')}</th>
                                  <th>{t('WDL.balance')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {this.state.shipments && this.state.shipments.map((shipment, index) => (
                                  <tr key={index} >
                                    {this.state.vectorId &&
                                    <td>
                                      <Form.Group controlId={"select_"+index} className="form_field">
                                        <Form.Check type="checkbox">
                                          <Form.Check.Input type="checkbox" name={"select_"+index} checked={shipment.selected?true:false}  onChange={e=>this.handleSelectShipment(e, index)}/>
                                        </Form.Check>
                                      </Form.Group>
                                      </td>
                                      }
                                    <td>
                                      <a href={'/logistic/company/'+this.state.companyId+'/shipments/manage/'+shipment.shipmentId}>{shipment.number} </a>
                                    </td>
                                    <td>
                                      {shipment.shipDateMillis &&
                                        <span>{
                                          format(
                                            new Date(shipment.shipDateMillis),
                                            'dd/MM/yyyy',
                                            {locale: this._locales[i18n.language]}
                                          )}</span>
                                          }
                                    </td>
                                    <td>
                                      <span>{shipment.name} </span>
                                    </td>
                                    <td>
                                      <span>{shipment.client?.name} </span>
                                    </td>
                                    <td>
                                      <span>{shipment.vector?.name} </span>
                                    </td>
                                    <td>
                                      <Form.Group controlId={"epal_"+index}>
                                        <Form.Control type="number" name={"epal_"+index} value={Number(shipment.epal).toString()} onChange={(e)=>this.handleChangePallets(index, 'epal', e.target.value)}/>
                                        <Form.Label className="wdl-pallets-label">{t('WDL.epal')}</Form.Label>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group controlId={"chep_"+index}>
                                        <Form.Control type="number" name={"chep_"+index} value={Number(shipment.chep).toString()} onChange={(e)=>this.handleChangePallets(index, 'chep', e.target.value)}/>
                                        <Form.Label className="wdl-pallets-label">{t('WDL.chep')}</Form.Label>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group controlId={"epalLost_"+index}>
                                        <Form.Control type="number" name={"epalLost_"+index} value={Number(shipment.epalLost).toString()} onChange={(e)=>this.handleChangePallets(index, 'epalLost', e.target.value)}/>
                                        <Form.Label className="wdl-pallets-label">{t('WDL.epal_lost')}</Form.Label>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group controlId={"epalTicket_"+index}>
                                        <Form.Control type="number" name={"epalTicket_"+index} value={Number(shipment.epalTicket).toString()} onChange={(e)=>this.handleChangePallets(index, 'epalTicket', e.target.value)}/>
                                        <Form.Label className="wdl-pallets-label">{t('WDL.epal_tickets')}</Form.Label>
                                      </Form.Group>
                                    </td>
                                    
                                    <td>
                                      <NumberFormat style={{fontWeight: 'bold'}} value={shipment.epal-shipment.epalTicket-shipment.epalLost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                  </tr>
                                ))}
                                
                              </tbody>
                              {this.state.shipments.length>0 && !this.hasMore &&
                              <tfoot>
                                  <tr className="wdl-total">
                                    <td>
                                      <NumberFormat value={this.state.totals.numbers} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    {this.state.vectorId &&
                                    <td>
                                    </td>
                                    }
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                      <NumberFormat value={this.state.totals.epal} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                      <NumberFormat value={this.state.totals.chep} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                      <NumberFormat value={this.state.totals.lost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                      <NumberFormat value={this.state.totals.tickets} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                    <td>
                                      <NumberFormat value={this.state.totals.epal-this.state.totals.tickets-this.state.totals.lost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                    </td>
                                  </tr>
                                </tfoot>
                                }
                            </Table>
                            {(this.state.shipments.length===0) &&
                          
                          <div className="separator info-not-found-container">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('WDL.info_not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        }
                        {this.state.shipments.length>0 &&
                          <Form.Row style={{marginBottom: 100, marginTop: 50}}>
                              <Col className="text-center">
                                <Button style={{backgroundColor: '#705906'}} variant="primary" type="submit" className="btn-login" disabled={this.state.changed.size===0}>{t('WDL.save')}</Button>
                              </Col>
                            </Form.Row>
                          }
                          </Form>
                      </Col>
                    </Row>
                    </Col>
                  </Row>
              </Container>
              
            </InfiniteScroll>
            </div>
            
        );
    }
}

export default withTranslation()(WDLPallets);
