import React, { PureComponent } from 'react';
import axios from 'axios';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import Loader from '../components/Loader';
import BackButtonTitle from '../components/BackButtonTitle';

import { API_BASE_URL, ACCESS_TOKEN, USER, LANGUAGES} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal } from 'react-bootstrap';
import i18n from 'i18next';


class Settings extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            language: this.props.user.lang,
            user: this.props.user,
            loading: false,
            languages: LANGUAGES,
            errorMsg: {},
            successMessage: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
    }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        });


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["email"]=this.state.user.email;
      data["lang"]=this.state.language;

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true});

      axios.post(API_BASE_URL+'/user/saveSettings', data, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({ successMessage: t('Settings.saved'), loading: false });
          localStorage.setItem(USER, JSON.stringify(response.data));
          i18n.changeLanguage(response.data.lang);
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg, loading: false});
        }
      });
    };


    render() {
        const { t } = this.props;

        return (
          <Modal show={true} className="fake-page-modal" animation={false} onHide={this.props.handleClose}>
            <Modal.Body>
              <Container fluid={true} className="menu-container">
                {this.state.loading && (
                  <Loader></Loader>
                )}
                <BackButtonTitle handleClose={this.props.handleClose} title={'Common.settings'}></BackButtonTitle>

                <Container fluid={true} className="tab-container">
                  <Row className="justify-content-md-center">
                    <Col md={6} className="form_center">
                      <Row>
                        <Col md={12}>
                          <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="languageId" className="form_field">
                             <Form.Label className="form_field_label">{t('Common.select_app_language')}</Form.Label>
                             <Form.Control as="select" name="language" value={this.state.language} onChange={this.handleChange} required>
                               {this.state.languages.map(lang =>
                                 <option key={lang} value={lang}>{lang}</option>
                               )}
                             </Form.Control>
                           </Form.Group>
                            <Form.Group className="form_field">
                              <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                              <SuccessMessage valid={true} message={this.state.successMessage} />
                            </Form.Group>
                            <Form.Row>
                               <Col className="text-center">
                                <Button variant="primary" type="submit" className="btn-login" disabled={!(this.state.language)}>{t('Common.save')}</Button>
                               </Col>
                             </Form.Row>
                           </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Modal.Body>
          </Modal>


        );
    }
}

export default withTranslation()(Settings);
