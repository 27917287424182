import React, { PureComponent } from 'react';

import { Image, Container, Row, Col } from 'react-bootstrap';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import i18n from 'i18next';

import { withTranslation } from 'react-i18next';

class WDLCompanyHeader extends PureComponent {
  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }
    render() {
        const { t } = this.props;
        let company = this.props.company;
        return (
          
          <Container fluid className={"wdl-company-header"}>
            <Row>
              <Col>
                <Image fluid={true} src={company.image} className={"wdl-header-img"}/>
                <span>{company.name}</span>
              </Col>
            </Row>
            </Container>
          
        );
    }
}

export default withTranslation()(WDLCompanyHeader);
