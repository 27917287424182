import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';

import { API_BASE_URL, ACCESS_TOKEN, USER} from '../Constants.js';
import {ValidationMessage} from '../functions/FormUtils.js';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';


class SignInForm extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            email: '', emailValid: false,
            password: '', passwordValid: false,
            formValid: false,
            errorMsg: {},
            isSignedUp: false,
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }


    validateFields = (name, value) => {
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      errorMsg.appError = '';
      if (name === 'email') {
        let emailValid = true;
        errorMsg.email = '';
        // checks for format _@_._
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          emailValid = false;
          errorMsg.email = i18n.t('Errors.invalid_email');
        }
  
        this.setState({emailValid, errorMsg}, this.validateForm);
      }else if(name==="password"){

        let passwordValid = true;
        errorMsg.password = '';
        if (value.length < 6) {
          passwordValid = false;
          errorMsg.password = t('Errors.field_dimension', {number: 6})
        }

        this.setState({passwordValid, errorMsg}, this.validateForm);

      }

    }


  validateForm = () => {
    const {emailValid, passwordValid} = this.state;
    this.setState({
      formValid: emailValid && passwordValid
    })
  }


    handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        },   this.validateFields(name, value));

    }

    handleSubmit(e) {
        e.preventDefault();
        let errorMsg = {...this.state.errorMsg}
        const { t } = this.props;
        this.setState({errorMsg,
          loading: true
        });

        axios.post(API_BASE_URL+'/auth/signin', this.state)
        .then((response) => {
          if (this._isMounted) {
            localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);
            localStorage.setItem(USER, JSON.stringify(response.data.user));
            i18n.changeLanguage(response.data.user.lang);

            this.setState({ isSignedUp: true,
            loading: false});
          }
        }, (error) => {
          if (this._isMounted) {
            errorMsg.appError = t('Errors.login_error');
            if (error.response && error.response.status) {
              let status = error.response.status;
              if(status===401){
                  errorMsg.appError = t("Errors.user_not_authorized");
              }else if(status===403){
                errorMsg.appError = t("Messages.user_not_validated");
              }

            }
            this.setState({errorMsg, loading: false});
          }
        });

    }

    render() {

      const { t } = this.props;

      if (this.state.isSignedUp) {
        return <Redirect to = {{ pathname: "/letMeDiscover" }} />;
      }

      return (
         <Container fluid={true}>
           {this.state.loading && (
             <Loader></Loader>
           )}

           <Row>
             <Col sm={12} className="form_center">
               <Form onSubmit={this.handleSubmit}>
                 <Form.Group controlId="email" className="form_field">
                   <Form.Label className="form_field_label">{t('SignInForm.email')}</Form.Label>
                   <Form.Control type="text" className="form_field_input" placeholder={t('SignInForm.email_ph')} name="email" value={this.state.email} onChange={this.handleChange}  />
                   <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                 </Form.Group>
                 <Form.Group controlId="password" className="form_field">
                   <Form.Label className="form_field_label">{t('SignInForm.password')}</Form.Label>
                   <Form.Control type="password" className="form_field_input" placeholder={t('SignInForm.password_ph')} name="password" value={this.state.password} onChange={this.handleChange} />
                   <ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
                 </Form.Group>
                 <Form.Group className="form_field">
                   <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                 </Form.Group>
                 <Form.Row>
                    <Col sm={6} className="text-center btn-responsive-container" style={{ marginTop: 10 }}>
                      <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('SignInForm.login')}</Button>
                    </Col>
                    <Col sm={6} className="text-center btn-responsive-container" style={{ marginTop: 10 }}>
                      <Button variant="primary" as={Link} to="/sign-up-discover" className="btn-login">{t('SignInForm.registration')}</Button>
                     </Col>
                  </Form.Row>
                  <Form.Row style={{ marginTop: 40 }}>
                     <Col sm={12} className="text-center">
                      <Link to="/forgot_password" className="form_field_link">{t('SignInForm.forgot_password')}</Link>
                     </Col>
                   </Form.Row>
                </Form>
              </Col>
           </Row>
         </Container>

        );

    }
}

export default withTranslation()(SignInForm);
