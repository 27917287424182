import React, { PureComponent } from 'react'
import { Row, Col, Button, ListGroup, Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';


class PlatesList extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);

      let cityId = null;
      if(this.props.cityId){
        cityId = this.props.cityId;
      }

      this.state = {
        cityId: cityId,
        isAdmin: this.props.isAdmin,
        keyMAP: Math.random(),
        selectedPlate: null,
        languages: [],
        errorMsg: {},
        successMessage: ''
      }

      this.showPlateInfo = this.showPlateInfo.bind(this);
      this.handleAddPlateClick = this.handleAddPlateClick.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }



  componentDidMount() {
    this._isMounted = true;
  }


  handleAddPlateClick(){
    if(this.state.isAdmin && this.props.adminMode){
      var createdPlate = {
        name: "",
        attractionId: "",
        date: '',
        image: '',
        nameInfo: '', nameInfoValid: false,
        description: '',
        languageId: '',
        linkInfo: ''
      }; 

      this.props.handleShowPlateModal(createdPlate, true);
    }

  }

  showPlateInfo = (plate) => {


      if(this.props.adminMode){
        this.setState({selectedPlate: plate,
          successMessage: '',
          errorMsg: {}
         }, this.props.handleShowPlateModal(plate, true));
      }else{
        this.setState({selectedPlate: plate,
          successMessage: '',
          errorMsg: {}
         }, this.props.handleShowPlateModal(plate, false));
      }


  }



  render() {
    const { t } = this.props;

    return (
      <div className="tab-content-container">
        <div className="tab-content-menu justify-content-md-center text-center">
            <span style={{ borderBottom: 'solid 2px ' + this.props.cityColor }}  className="city-title" onClick={(e) => this.props.showCityInfo()}>{(this.props.cityInfo)?this.props.cityInfo.cityName:""}</span>
        </div>
        <div className="tab-content-body" style={{ height: this.props.height }}>
          <ListGroup className="max-height-container">
            {this.props.plates.length===0 && (
              <div className="separator">
                <Row className="justify-content-md-center">
                  <Col sm={12} className="text-center">
                    <span>{t('Plate.not_found')}</span>
                  </Col>
                </Row>
              </div>
            )}
            
          {this.props.plates && (this.props.plates.map(plate =>
            <ListGroup.Item key={plate.attractionId}>
                <div className="row-link-container"  onClick={(e) => this.showPlateInfo(plate)}>
                  {
                    !plate.userAttraction? (
                      <Image fluid={true} src={plate.imageIcon} className={"list-img locked-plate"}/>
                    ) : (
                      <Image fluid={true} src={plate.imageIcon} className={"list-img"}/>
                    )
                  }
                  {
                    !plate.userAttraction? (
                      <span className="link-icon locked-text">{(plate.nameInfo)?plate.nameInfo: plate.name}<i className={"fa fa-check button-icon wished-check "+(!plate.wished ? "hidden" : "")} aria-hidden="true"></i></span>
                    ) : (
                      <span className="link-icon unlocked-text">{(plate.nameInfo)?plate.nameInfo: plate.name}</span>
                    )
                  }
                </div>
              </ListGroup.Item>
            ))}
            </ListGroup>
            

            {this.state.isAdmin && this.props.cityInfo && this.props.cityInfo.cityId && this.props.adminMode && (
              <Button variant={'primary'} type="button" onClick={(e) => this.handleAddPlateClick()} className="add-button">
                <i className="fa fa-plus fa-2x" aria-hidden="true"></i>
              </Button>
            )}
          </div>
      </div>


    )
  }
}

export default withTranslation()(PlatesList);
