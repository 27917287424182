import React, { PureComponent } from 'react';
import dusty from '../img/dusty.svg';
import { Row, Col, Container, Image} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import logo from '../img/logo_desc.svg';
import mobile from '../img/sito_mobile.png';
import sitoAttractionList from '../img/sito_attraction_list.png';
import sitoPlateList from '../img/sito_plate_list.png';
import playStore from '../img/play-store.png';
import appleStore from '../img/apple-store.png';
import sitoAttractionPage from '../img/sito_attraction_page.png';
import sitoAttractionPageInfo from '../img/sito_attraction_page_info.png';
import descriptionUnlock from '../img/description_unlock.jpg';
import sitoSocial from '../img/sito_social.png';
import sitoCountry from '../img/sito_country.png';
import sitoUnlocked from '../img/sito_unlocked.png';
import sitoSkin from '../img/sito_skin.png';

import LanguageChooser from '../components/LanguageChooser';
import { CONTACT_EMAIL, INSTAGRAM_URL, FACEBOOK_URL, PLAY_STORE_URL, APPLE_STORE_URL } from '../Constants.js';

class Home extends PureComponent {
    
    render() {
      const { t } = this.props;

        return (
            <Container fluid={true} className="site-container">
               <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col className="site-language">
                  <LanguageChooser isSite={true}/>
                </Col>
               </Row> 
               <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row">
                    <Col className="centered-cell">
                      <Image src={logo} alt="Worldust" className="center-block logo-site" fluid/>
                    </Col>
                    <Row className="site-row">
                      <Col className="centered-cell">
                        <a target="_blank"  rel="noopener noreferrer" href={PLAY_STORE_URL}>
                          <Image src={playStore} alt="Worldust" className="center-block btn" style={{maxHeight: '50px'}} fluid/>
                        </a>
                      </Col>
                      <Col className="centered-cell">
                        <a target="_blank"  rel="noopener noreferrer" href={APPLE_STORE_URL}>
                          <Image src={appleStore} alt="Worldust" className="center-block btn" style={{maxHeight: '50px'}} fluid/>
                        </a>
                      </Col> 
                    </Row>
                  </Row>
                  
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text">
                    {t('Site.map_title')} <br/>
                    <span className="simple-text">
                    {t('Site.map_text')}
                      
                    </span>  
                  </p> 
                  
                </Col>
                <Col sm={4} className="centered-cell"> 
                  <Image src={mobile} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: '#8fa2b2' }}> 
                <Col sm={4} className="centered-cell">
                  <Image src={sitoAttractionList} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text" style={{ color: 'white' }}>
                    {t('Site.list_attraction_title')}<br/>
                    <span className="simple-text" style={{ color: 'white' }}>
                    {t('Site.list_attraction_body')}
                    </span>  
                  </p> 
                </Col>
                <Col sm={4} className="centered-cell">
                  <Image src={sitoPlateList} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                
              </Row>
              <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col className="centered-cell">
                  <Image src={sitoAttractionPage} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text">
                    {t('Site.list_attraction_info_title')}
                    <br/>
                    <span className="simple-text">
                      {t('Site.list_attraction_info_body')}
                    </span>  
                  </p> 
                  
                </Col>
                <Col sm={4} className="centered-cell"> 
                  <Image src={sitoAttractionPageInfo} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: '#8fa2b2' }}> 
                <Col sm={4} className="centered-cell">
                  <Image src={descriptionUnlock} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text" style={{ color: 'white' }}>
                    {t('Site.unlock_title')}
                    <br/>
                    <span className="simple-text" style={{ color: 'white' }}>
                    {t('Site.unlock_body')}
                    </span>  
                  </p> 
                </Col>
                <Col sm={4} className="centered-cell">
                  <Image src={dusty} alt="Worldust" className="center-block logo-site" fluid/>
                </Col>
                
              </Row>
              <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col className="centered-cell">
                  <Image src={sitoSocial} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text">
                  {t('Site.social_title')}<br/>
                    <span className="simple-text">
                    {t('Site.social_body')}   
                  
                    </span>  
                  </p> 
                  
                </Col>
                <Col sm={4} className="centered-cell"> 
                  <Image src={sitoUnlocked} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: '#8fa2b2' }}> 
                <Col sm={4} className="centered-cell">
                  <Image src={sitoSkin} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
                <Col sm={4} className="centered-cell">
                  <p className="centered-text site-text" style={{ color: 'white' }}>
                    {t('Site.skin_title')}
                    <br/>
                    <span className="simple-text" style={{ color: 'white' }}>
                    {t('Site.skin_body')}
                    </span>  
                  </p> 
                </Col>
                <Col sm={4} className="centered-cell"> 
                  <Image src={sitoCountry} alt="Worldust" className="center-block smartphone" fluid/>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={4} className="centered-cell">
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col>
                      <p className="centered-text footer-text">
                        {t('Site.contacts')}<br/>
                        <span className="simple-footer-text">
                          {CONTACT_EMAIL}   
                        </span>  
                      </p>
                      <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                        <Col className="social-container">
                        <a href={FACEBOOK_URL} target="_blank">
                          <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                        <a href={INSTAGRAM_URL} target="_blank">
                          <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
                        </a>
                        
                      </Col>
                      </Row>
                    </Col>
                      
                  </Row>
                  
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col className="footer-text">
                        {t('Common.more_info')}<br/>
                        <a href={'/terms-of-service'}>
                          <span className="simple-footer-text">
                            {t('Common.terms')}   
                          </span>
                        </a>
                        <a href={'/faq'}>
                          <span className="simple-footer-text">
                            FAQ   
                          </span>
                        </a>
                        <a href={'/about_us'}>
                          <span className="simple-footer-text">
                          {t('Common.aboutUs')}    
                          </span>
                        </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={12} className="centered-cell" style={{color: 'white', marginTop: '10px',  marginBottom: '10px'}}>
                  {t('Site.all_right_reserved')}
                </Col>
              </Row>
            </Container>

        );
    }
}

export default withTranslation()(Home);
