import React, { PureComponent } from 'react';
import axios from 'axios';
import dustyGlasses from '../img/dusty_glasses.jpg';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WDLMenu from '../wdl/WDLMenu';
import WDLBackButtonTitle from '../wdl/WDLBackButtonTitle';
import WDLFileManager from '../wdl/WDLFileManager';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import i18n from 'i18next';
import WDLCompanyMenuItem from './WDLCompanyMenuItem';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN, WDL_COMPANY} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Image, Tabs, Tab } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';
import contract from '../img/wdl/contract.png';


class WDLManageCompany extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
        
        this.state = {
          companyId: this.props.match.params.companyId,
          company: null,
          user: user,
          isAdmin: isAdmin,
          nameValid: true,
          emailValid: true,
          pecValid: true,
          imageData: null,
          fileName: '',
          loading: false,
          formValid: true,
          errorMsg: {},
          successMessage: '',
          selectedTab: 'info',
        };
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCompany = this.getCompany.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      this.getCompany();
    }

    handleDateChange(date) {
      let company = {...this.state.company};
      if(date) {
        company.durcExpirationDateMillis = date.getTime();
      } else {
        company.durcExpirationDateMillis = null;
      }

      this.setState({company, successMessage: '', errorMsg: {}});
    }


    getCompany(){
      const requestId = 'getCompanyRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true
      });
      
  
      let url = API_BASE_URL+'/logistic/company/'+this.state.companyId;
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let company = response.data;
          
          this.setState({
            company,
            loading: false,
          });
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg}, this.validateForm);

      }
      
      

      if(name==="email"){

        let emailValid = true;
        errorMsg.email = '';
        // checks for format _@_._
        if (value.length>0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
          emailValid = false;
          errorMsg.email = t('Errors.invalid_email');
        }

        this.setState({emailValid, errorMsg}, this.validateForm);
      }

      if(name==="pec"){

        let pecValid = true;
        errorMsg.pec = '';
        // checks for format _@_._
        if (value.length>0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
          pecValid = false;
          errorMsg.pec = t('Errors.invalid_email');
        }

        this.setState({pecValid, errorMsg}, this.validateForm);
      }

    }





  validateForm = () => {
    const {nameValid, emailValid, pecValid} = this.state;
    this.setState({
      formValid: nameValid && emailValid && pecValid
    })
  }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let company = {...this.state.company};
        company[name]= value;
        this.setState({
          company,
          successMessage: '', 
          errorMsg: {}
        }, this.validateFields(name, value));


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = this.state.company;
      if(this.state.fileName!==''){
        data["imageData"]=this.state.imageData;
      }

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true, successMessage: ''});

      axios.put(API_BASE_URL+'/logistic/company/'+this.state.companyId, data, config)
      .then((response) => {
        if (this._isMounted) {
          localStorage.setItem(WDL_COMPANY, JSON.stringify(response.data));
          this.setState({company: response.data, imageData: null, fileName: '', successMessage: t('WDL.saved'), loading: false });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };

    handleUploadFile = (event) => {
      let target = event.target;
      let value =  target.value;

      this.setState({ successMessage: ''});
      this.setState({fileName: value});
      var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                600,
                600,
                'JPEG',
                100,
                0,
                uri => {
                  this.setState({
                    imageData: uri
                  });
                },
                'base64'
            );
        }
    };


    



    render() {
        const { t } = this.props;
        
        return (
          <div className="wdl-container">
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} title={'WDL.company_info'}></WDLBackButtonTitle>
             <Container fluid={true}>
                {
                !this.state.company? (
                  this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :
                  <div className="separator">
                    <Row className="justify-content-md-center">
                      <Col sm={12} className="text-center">
                        <span>{t('WDL.info_not_found')}</span>
                      </Col>
                    </Row>
                  </div>
                ):
                <div>
                  <Row className="justify-content-md-center">
                      <Col md={8} className="form_center">
                        <Tabs
                            id="vector-tabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(k) => this.setState({selectedTab: k})}
                            className="mb-3"
                          >
                          <Tab eventKey="info" title={t('WDL.info')}>
                            { this.state.loading ? 
                              <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                            :
                            <Row>
                              <Col md={12}>
                                <Row>
                                  <Col md={12} className="text-center wdl-message-container">
                                  <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                                  <SuccessMessage valid={true} message={this.state.successMessage} />
                                  </Col>
                                </Row>
                                <Row>
                                <Col md={12} className="text-center">
                                    <Image src={(this.state.imageData)?this.state.imageData:this.state.company?.image?this.state.company?.image:dustyGlasses} className="wdl-profile-image"/>
                                </Col>
                                </Row>
                                <Row>
                                  <Col md={12} className="text-center hidden-filechooser">
                                    <input type="file" id="image_profile" value={this.state.fileName} onChange={this.handleUploadFile} />
                                    <label htmlFor="image_profile" title={t('Profile.upload_profile_image')}><i className="fa fa-camera fa-2x" aria-hidden="true"></i></label>
                                  </Col>
                                </Row>
                                <Form onSubmit={this.handleSubmit}>
                                  <Form.Group controlId="name" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.name')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="name" value={this.state.company?.name} onChange={this.handleChange}/>
                                    <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                                  </Form.Group>
                                  <Form.Group controlId="email" className="form_field">
                                    <Form.Label className="form_field_label">{'E-mail'}</Form.Label>
                                    <Form.Control type="text" plaintext  className="form_field_input" name="email" value={this.state.company?.email || ''} onChange={this.handleChange}/>
                                    <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                                  </Form.Group>
                                  <Form.Group controlId="phone" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.phone')}</Form.Label>
                                    <Form.Control type="text" plaintext  className="form_field_input" name="phone" value={this.state.company?.phone || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group controlId="pec" className="form_field">
                                    <Form.Label className="form_field_label">{'PEC'}</Form.Label>
                                    <Form.Control type="text" plaintext  className="form_field_input" name="pec" value={this.state.company?.pec || ''} onChange={this.handleChange}/>
                                    <ValidationMessage valid={this.state.pecValid} message={this.state.errorMsg.pec} />
                                  </Form.Group>
                                  <Form.Group controlId="pIva" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.p_iva')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="pIva" value={this.state.company?.pIva || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group controlId="fiscalCode" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.cf')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="fiscalCode" value={this.state.company?.fiscalCode || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  
                                  <Row>
                                    <Form.Group as={Col} controlId="address" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.address')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="address" value={this.state.company?.address || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="city" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.city')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="city" value={this.state.company?.city || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  </Row>
                                  <Row>
                                    <Form.Group as={Col} controlId="cap" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.cap')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="cap" value={this.state.company?.cap || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="province" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.province')}</Form.Label>
                                      <Form.Control maxLength={2} type="text" className="form_field_input" name="province" value={this.state.company?.province || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="country" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.country')}</Form.Label>
                                      <Form.Control maxLength={2} type="text" className="form_field_input" name="country" value={this.state.company?.country || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  </Row>
                                  <Form.Group controlId="iban" className="form_field">
                                    <Form.Label className="form_field_label">{'IBAN'}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="iban" value={this.state.company?.iban || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group controlId="banc" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.banc')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="banc" value={this.state.company?.banc || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group controlId="rea" className="form_field">
                                      <Form.Label className="form_field_label">{'R.E.A.'}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="rea" value={this.state.company?.rea || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  <Form.Group controlId="taxRegime" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.tax_regime')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input" name="taxRegime" value={this.state.company?.taxRegime || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.durc_expiration')}</Form.Label>
                                    <DatePicker
                                      locale={this._locales[i18n.language]}
                                      onChange={this.handleDateChange}
                                      selected={(this.state.company?.durcExpirationDateMillis)?new Date(this.state.company?.durcExpirationDateMillis):null}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  </Form.Group>
                                  <div className="bc_data">
                                    <h5 style={{textAlign: 'center'}}>{t('WDL.bc_data')}</h5>
                                    <Form.Group controlId="bcEmail" className="form_field">
                                      <Form.Label className="form_field_label">{'E-mail'}</Form.Label>
                                      <Form.Control type="text" plaintext  className="form_field_input" name="bcEmail" value={this.state.company?.bcEmail || ''} onChange={this.handleChange}/>
                                     </Form.Group>
                                    <Form.Group controlId="bcPhone" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.phone')}</Form.Label>
                                      <Form.Control type="text" plaintext  className="form_field_input" name="bcPhone" value={this.state.company?.bcPhone || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group controlId="bcFax" className="form_field">
                                      <Form.Label className="form_field_label">{'FAX'}</Form.Label>
                                      <Form.Control type="text" plaintext  className="form_field_input" name="bcFax" value={this.state.company?.bcFax || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  </div>
                                  <Form.Row>
                                    <Col className="text-center">
                                      <Button variant="primary" type="submit" className="btn-login" disabled={!(this.state.formValid)}>{t('WDL.save')}</Button>
                                    </Col>
                                  </Form.Row>
                                  
                                </Form>
                              </Col>
                            </Row>
                            }
                            
                            </Tab>
                            <Tab eventKey="documents" title={t('WDL.documents')}>
                              <WDLFileManager user={this.state.user} apiUrl={'/logistic/company/'+this.state.companyId+'/uploads'}/>
                            </Tab>

                            <Tab eventKey="contract" title={t('WDL.transport_contract')}>
                              <Row className="justify-content-md-center">
                                  <Col md={6} className="text-center" style={{marginTop: 50}}>
                                    <WDLCompanyMenuItem maxWidth={300} image={contract} text={t('WDL.transport_contract')} color="#b714ae" link={"/logistic/company/"+this.state.company.companyId+"/contract"}/>
                                  </Col>
                                </Row>
                            </Tab>
                          </Tabs>
                      </Col>
                    </Row>
                  </div>
                  }
                  
              </Container>
            </div>




        );
    }
}

export default withTranslation()(WDLManageCompany);
