import React, { PureComponent } from 'react';
import dusty from '../img/dusty.svg';
import dustySleep from '../img/dusty_sleep.svg';
import {Image} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class DustyHelper extends PureComponent {

    render() {

        if(this.props.wakeDusty){
          return (
              <div className="dusty-helper-container" onClick={this.props.handleClick}>
                <Image src={dusty} alt="Dusty" className="center-block" fluid/>
              </div>
          );
        }else{
          return (
              <div className="dusty-helper-container" onClick={this.props.handleClick}>
                <Image src={dustySleep} alt="Dusty Sleep" className="center-block shake" fluid/>
              </div>
          );
        }

    }
}

export default withTranslation()(DustyHelper);
