import React, { PureComponent } from 'react';
import dusty from '../img/dusty.svg';
import {Link} from 'react-router-dom';
import { Row, Col, Container, Image} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class DustyMessage extends PureComponent {
    
    render() {
        const { t } = this.props;
        return (
            <Container fluid={true}>
              <Row className="justify-content-md-center logo-container-top">
                <Col sm={4} className="message-column">
                  <div className="message-container">
                    <div className="dusty_message" >
                      {t(this.props.message)}
                    </div>
                  </div>
                  <Link to="/" className="btn btn-primary mr-20">Home</Link>
                </Col>
                <Col sm={6}><Image src={dusty} alt="Dusty" className="center-block" fluid/></Col>
              </Row>
            </Container>

        );
    }
}

export default withTranslation()(DustyMessage);
