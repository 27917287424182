import React, { PureComponent } from 'react';

import { Row, Col, Container } from 'react-bootstrap';


import { withTranslation } from 'react-i18next';

class BackButtonTitle extends PureComponent {

    render() {
        const { t } = this.props;

        return (
          <Container fluid={true} className="title-bar-page">
            <Row>
              <Col md={12} className="text-center">
                <i className="fa fa-chevron-left fa-2x back-button" onClick={(e) => this.props.handleClose()} aria-hidden="true"></i>
                <span className="fake-modal-title">{t(this.props.title)}</span>
              </Col>
            </Row>
          </Container>
        );
    }
}

export default withTranslation()(BackButtonTitle);
