import React, { PureComponent } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

import { API_BASE_URL, WDL_ACCESS_TOKEN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Modal, Button } from 'react-bootstrap';


class WDLFileManager extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
          fileData: null,
          fileName: '',
          loading: false,
          errorMsg: {},
          formData: null,
          files: [],
          successMessage: '',
          selectedFile: false,
        };

        this.handleUploadFileToS3 = this.handleUploadFileToS3.bind(this);
        this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      this.getFiles();
    }

    handleDelete = () => {
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg});
      let data = {...this.state.selectedFile};
      axios.post(API_BASE_URL+this.props.apiUrl+'/delete', data, config)
      .then((response) => {
        if (this._isMounted) {
          let files = this.state.files.filter(item => item.name !== data.name);
          this.setState({
            files,
            selectedFile: null,
          });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg});
        }
      });
    };

    handleUploadFileToS3 = (formData) => {
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true,});
      axios.post(API_BASE_URL+this.props.apiUrl, formData, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({fileName: '', loading: false})
          this.getFiles();
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg, loading: false});
        }
      });
    };

    getFiles(){
      const requestId = 'getFileRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true
      });
      
  
      let url = API_BASE_URL+this.props.apiUrl;
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let files = response.data;
          
          this.setState({
            files,
            loading: false,
          });
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    
    onFileChangeHandler = (e) => {
      
      const formData = new FormData();
      for(let i = 0; i< e.target.files.length; i++) {
          formData.append('files', e.target.files[i])
      }
      let value =  e.target.value;

      this.setState({fileName: value});
      this.handleUploadFileToS3(formData);
  };


  handleClose(){
    this.setState({selectedFile: null });
  }

  handleShowDeleteModal(selectedFile){
    this.setState({selectedFile});
  }


    render() {
        const { t } = this.props;
        
        return (
          <div className="container">
            <Row className="justify-content-md-center">
                <Col md={12} style={{marginBottom: 40}}>
                    <div className="form-group files color">
                      <label>{t('WDL.upload_file')}</label>
                      <input type="file" className="form-control" value={this.state.fileName} name="file" multiple onChange={this.onFileChangeHandler}/>
                    </div>
                </Col>
            </Row>
            {this.state.files && this.state.files?.length>0 &&
            <Row className="justify-content-md-center">
                <Col md={12} style={{marginBottom: 40}}>
                  <h6>{t('WDL.file_uploaded')}</h6>
                </Col>
            </Row>
            }
            {this.state.loading ? (
                  <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>

                ):
              <Row className="justify-content-md-center">
                  <Col md={12} className="form_center">
                      {this.state.files && (this.state.files.map((file, index) =>
                      <Row className="justify-content-md-center" key={index}>
                      <Col md={12} className="file-manager-container">
                            <div className="file-item-image">
                              <a className="file-url" href={file.url} target="_blank">
                                <i className="fa fa-file fa-2x" aria-hidden="true"><span className="link-icon locked-text file-name">{file.name}</span></i>
                              </a>
                            </div>
                            <div className="file-item-buttons">
                              <Button type="button" variant="secondary" onClick={(e) => this.handleShowDeleteModal(file)}>
                              <i className="fa fa-remove" aria-hidden="true"></i>
                              </Button>
                              
                            </div>
                          
                          </Col>
                      </Row>
                        ))}
                        </Col>
                    </Row>
              }
              <Modal show={this.state.selectedFile} animation={false} onHide={this.handleClose}>
                      <Modal.Header className="wdl"  closeButton>
                      </Modal.Header>
                      <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}}>
                          {t('WDL.file_delete_confirm')}
                      </Modal.Body>
                      <Modal.Footer className="wdl">
                        <Button variant="primary" onClick={this.handleDelete}>
                          {t('Common.confirm')}
                        </Button>
                      </Modal.Footer>
                    </Modal>
            </div>
          
        );
    }
}

export default withTranslation()(WDLFileManager);
