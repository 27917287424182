import React, { PureComponent } from 'react';
import UserDiscovery from '../components/UserDiscovery';
import axios from 'axios';
import BackButtonTitle from '../components/BackButtonTitle';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Modal } from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';
import i18n from 'i18next';

class UserDiscoveryPage extends PureComponent {
    _isMounted = false;
    constructor(props) {
      super(props);
  
      this.state = {
        discovery: null,
      };
  
      this.initDiscoveries = this.initDiscoveries.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      this.initDiscoveries();
    }

    initDiscoveries() {
      let attractionId = this.props.selectedAttractionId;
      let userId = this.props.selectedUserId;
      const { t } = this.props;

      let url =
        API_BASE_URL +
        '/discovery/getDiscovery/' +
        userId +
        '/' +
        attractionId +
        '?lang=' +
        i18n.language;
  
      var config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
      };
      let errorMsg = {};
  
      axios.get(url, config).then(
        response => {
          if (this._isMounted) {
            let discovery = response.data;
            this.setState({discovery});
          }
        },
        error => {
          if (this._isMounted) {
            errorMsg.dataError = t('Errors.generic_error');
 
            if (error.response && error.response.status) {
              let status = error.response.status;
              if(status===401){
               return window.location.href = '/logout';            
              }
            }else{
              if(error.message!=null && error.message==='Network Error'){
                  errorMsg.dataError = t("Errors.connection_error");
              }
            }
            this.setState({errorMsg});
            }
        },
      );
    }


    render() {

        return (
          <Modal show={true} className="fake-page-modal" animation={false} onHide={this.props.handleClose}>
            <Modal.Body>
              <Container fluid={true} className="menu-container">
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.discoveries'}></BackButtonTitle>
                <Container fluid={true} className="tab-container">
                  <Row className="justify-content-md-center">
                    <Col md={6} className="no-space-container">
                      {this.state.discovery && (
                        <UserDiscovery discovery={this.state.discovery} user={this.props.user}></UserDiscovery>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Container>
            </Modal.Body>
          </Modal>


        );
    }
}

export default withTranslation()(UserDiscoveryPage);
