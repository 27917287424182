import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';
import {GenerateColor} from '../functions/FormUtils.js';

import axios from 'axios';
import BackButtonTitle from '../components/BackButtonTitle';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class UnlockedCities extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        selectedCity: null,
        showModal: false,
        user: this.props.user,
        page: 0,
        hasMore: false,
        cities: []
      }
      this.getUnlockedCities = this.getUnlockedCities.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getUnlockedCities();
  }




getUnlockedCities(refresh){

    let page = this.state.page;
    let cities = this.state.cities;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
   
    if(refresh){
      page=0;
      cities=[];
      this.setState({errorMsg, page, cities,
        hasMore: true
      });
    } else {
      this.setState({errorMsg,
        hasMore: true
      });
    }


    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    

    axios.get(API_BASE_URL+'/city/getAll/'+this.state.user.userId+'?page='+page+'&lang='+i18n.language, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newUnlockedCities = response.data;
        if((newUnlockedCities && newUnlockedCities.length===0) || (newUnlockedCities && newUnlockedCities[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: this.state.page+1,
          cities: cities.concat(newUnlockedCities)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.unloked_cities'}></BackButtonTitle>
                <InfiniteScroll
                  dataLength={this.state.cities.length}
                  next={this.getUnlockedCities}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getUnlockedCities(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.cities.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('City.not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.cities && this.state.cities.map((city, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.props.handleChangeCity(city.cityId)}>
                              <i style={{ color: GenerateColor(city.name) }} className="fa fa-map-marker" aria-hidden="true"></i>
                              <span className="link-icon">{(city.info)?city.info.name+ ', '+ city.countryCode: city.name+ ', '+ city.countryCode}</span>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(UnlockedCities);
