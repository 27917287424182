import React, { PureComponent } from 'react';
import axios from 'axios';
import dustyGlasses from '../img/dusty_glasses.jpg';
import dusty from '../img/dusty.svg';
import Loader from '../components/Loader';
import Objectives from './Objectives';
import UnlockedCities from './UnlockedCities';
import UnlockedAttractions from './UnlockedAttractions';
import UnlockedPlates from './UnlockedPlates';
import Levels from './Levels';
import Voyagers from './Voyagers';
import ProfileDiscoveries from './ProfileDiscoveries';
import Advertising_list from './Advertising_list';

import { API_BASE_URL, ACCESS_TOKEN, USER, ROLE_ADVERTISER, ROLE_ADMIN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Button, Image, Modal, ListGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';


class UserProfile extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user,
            currentUserId: null,
            pageToShow: '',
            loading: false,
            errorMsg: {},
            successMessage: '',
        };
        this.showPage = this.showPage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addVoyager = this.addVoyager.bind(this);
        this.removeVoyager = this.removeVoyager.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      let user = JSON.parse(localStorage.getItem(USER));
        
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {}

      this.setState({errorMsg, currentUserId: user.userId, loading: true
      });

      axios.get(API_BASE_URL+'/user/getOne/'+this.state.user.userId, config)
      .then((response) => {
        if (this._isMounted) {

          let userInfo = response.data;
          this.setState({
            user: userInfo, loading: false
          });
        }

      }, (error) => {
        if (this._isMounted) {

          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading: false});
        }
      });

    }



    showPage(pageName){
      this.setState({pageToShow: pageName});
    }

    handleClose(){
      this.setState({pageToShow: ''});
    }

    addVoyager(){
      let user = {...this.state.user};
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;
  
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
  
  
      this.setState({errorMsg: {},
        successMessage: '',
        loading: true
      });
  
      axios.post(API_BASE_URL+'/voyager/save', user, config)
      .then((response) => {
        if (this._isMounted) {
          user.isVoyager=true;
          this.setState({user, loading: false});
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg, loading: false});
          }
        });
    }
  
  
    removeVoyager(){
      let user = {...this.state.user};
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;
  
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
  
  
      this.setState({errorMsg: {},
        successMessage: '', loading: true
      });
  
      var data = null;
  
      axios.post(API_BASE_URL+'/voyager/delete/'+user.userId, data, config)
      .then((response) => {
        if (this._isMounted) {
          user.isVoyager=false;
          this.setState({user, loading: false});
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg, loading: false});
          }
        });
    }

    render() {
       const { t } = this.props;
        return (
          <Modal show={true} className="fake-page-modal" animation={false} onHide={this.props.handleClose}>
            <Modal.Body>
              <Container fluid={true} className="menu-container">
                {this.state.loading && (
                  <Loader></Loader>
                )}

                <Container fluid={true} className="title-bar-page">
                  <Row>
                    <Col md={12} className="text-center">
                      <i className="fa fa-chevron-left fa-2x back-button" onClick={(e) => this.props.handleClose()} aria-hidden="true"></i>
                      <span className="fake-modal-title">{this.state.user.name+" "+this.state.user.surname}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <span  onClick={(e) => this.showPage('levels')} className={"pointer user-text-level-"+this.state.user.level}>{t('Level.'+this.state.user.level)}</span>
                    </Col>
                  </Row>
                  <div className="user-stat-container">
                    <div className="user-ranking-container">
                      <i className="fa fa-list-ol" aria-hidden="true"></i>
                      <span className="user-ranking">{" "+this.state.user.rankingNumber}</span>
                    </div>
                    <div className="user-points-container">
                      <span className="user-points"><NumberFormat value={this.state.user.points} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></span>
                      <Image src={dusty} alt="Dusty" className="like-info-img center-block no-pointer" fluid/>
                    </div>
                  </div>
                </Container>

                <Container fluid={true} className="tab-container" style={{ paddingBottom: 20 }}>
                  <div className="text-center">
                      <Image src={this.state.user.image? this.state.user.image:dustyGlasses} className="profile-image"/>
                  </div>


                  <Row>
                    <Col md={12} className="text-center" style={{ paddingBottom: 20 }}>
                      {this.state.currentUserId!==this.state.user.userId && !this.state.user.isVoyager && (
                        <Button variant={'secondary'} onClick={(e) => this.addVoyager()} disabled={this.state.loading}>
                          <i className="fa fa-plus" aria-hidden="true"></i>{" "+t('Profile.add_voyager')}
                        </Button>
                      )}
                      {this.state.currentUserId!==this.state.user.userId && this.state.user.isVoyager && (
                        <Button variant={'primary'} onClick={(e) => this.removeVoyager()} disabled={this.state.loading}>
                          <i className="fa fa-plus" aria-hidden="true"></i>{" "+t('Profile.added_voyager')}
                        </Button>
                      )}
                    </Col>
                  </Row>

                  <div className="tab-content-body" >
                    <ListGroup className="max-height-container manage-item-container">
                      {(this.state.user.role==ROLE_ADVERTISER || this.state.user.role==ROLE_ADMIN) && (
                        <ListGroup.Item>
                          <div className="row-link-container" onClick={(e) => this.showPage('advertising')}>
                              <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                              <span className="link-icon">{t('Advertising.show')}</span>
                          </div>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('discoveries')}>
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.discoveries')}</span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('voyagers')}>
                          <i className="fa fa-users" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.voyagers')}</span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('unloked_cities')}>
                          <i className="fa fa-map-marker" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.unloked_cities')}</span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('unloked_attractions')}>
                          <i className="fa fa-university" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.unloked_attractions')}</span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('unloked_plates')}>
                          <i className="fa fa-cutlery" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.unloked_plates')}</span>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div className="row-link-container" onClick={(e) => this.showPage('objectives')}>
                          <i className="fa fa-certificate" aria-hidden="true"></i>
                          <span className="link-icon">{t('Profile.objectives')}</span>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                    
                    {this.state.pageToShow==="discoveries" && (
                      <ProfileDiscoveries user={this.state.user} handleClose={this.handleClose}></ProfileDiscoveries>
                    )}
                    {this.state.pageToShow==="voyagers" && (
                      <Voyagers user={this.state.user} handleClose={this.handleClose}></Voyagers>
                    )}
                    {this.state.pageToShow==="unloked_cities" && (
                      <UnlockedCities user={this.state.user} handleClose={this.handleClose} handleChangeCity={this.props.handleChangeCity}></UnlockedCities>
                    )}
                    {this.state.pageToShow==="unloked_attractions" && (
                      <UnlockedAttractions user={this.state.user} handleClose={this.handleClose}></UnlockedAttractions>
                    )}
                    {this.state.pageToShow==="unloked_plates" && (
                      <UnlockedPlates user={this.state.user} handleClose={this.handleClose}></UnlockedPlates>
                    )}
                    {this.state.pageToShow==="objectives" && (
                      <Objectives user={this.state.user} handleClose={this.handleClose} getNotificationsToRead={this.getNotificationsToRead}></Objectives>
                    )}
                    {this.state.pageToShow==="levels" && (
                      <Levels user={this.state.user} handleClose={this.handleClose} ></Levels>
                    )}
                    {this.state.pageToShow==="advertising" && (
                      <Advertising_list user={this.state.user} handleClose={this.handleClose}></Advertising_list>
                    )}

                  </div>

                </Container>
              </Container>
            </Modal.Body>
          </Modal>




        );
    }
}

export default withTranslation()(UserProfile);
