import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';
import BackButtonTitle from './BackButtonTitle';

import axios from 'axios';
import UserDiscovery from './UserDiscovery';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withTranslation } from 'react-i18next';
import getTime from 'date-fns/getTime'

class Advertising_list extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        selectedDiscovery: null,
        selectedDiscoveryCity: null,
        showModalInfo: false,
        user: this.props.user,
        page: 0,
        hasMore: false,
        discoveries: [],
        minDate: getTime(new Date()),
      }
      this.getUserDiscoveries = this.getUserDiscoveries.bind(this);
      this.handleCloseInfo = this.handleCloseInfo.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getUserDiscoveries();
  }

  handleCloseInfo(){
    this.setState({
      showModalInfo: false
    });
  }



getUserDiscoveries(refresh){

    let page = this.state.page;
    let discoveries = this.state.discoveries;
    let minDate = this.state.minDate;
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    if(refresh){
      page=0;
      discoveries=[];
      minDate = getTime(new Date());
      this.setState({errorMsg, 
        page,
        discoveries,
        hasMore: true,
        minDate
      });
    }else{
      this.setState({errorMsg, 
        hasMore: true
      });
    }

    

    axios.get(API_BASE_URL+'/advertising/getAllDiscoveryAdv/'+this.state.user.userId+'?page='+page, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newUserDiscoveries = response.data;
        if((newUserDiscoveries && newUserDiscoveries.length===0) || (newUserDiscoveries && newUserDiscoveries[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          discoveries: discoveries.concat(newUserDiscoveries)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }

  showDiscoveryInfo = (discovery) => {
    var selectedDiscoveryCity = {};
    var cityDiscovery = discovery.city;
    selectedDiscoveryCity['cityId'] = cityDiscovery.cityId;
    selectedDiscoveryCity['latitude'] = cityDiscovery.latitude;
    selectedDiscoveryCity['longitude'] = cityDiscovery.longitude;
    selectedDiscoveryCity['cityName'] = (cityDiscovery.info)?cityDiscovery.info.name:cityDiscovery.name;
    selectedDiscoveryCity['state'] = cityDiscovery.state;
    selectedDiscoveryCity['country'] = cityDiscovery.countryCode;
    selectedDiscoveryCity['description'] = (cityDiscovery.info)?cityDiscovery.info.description:'';
    selectedDiscoveryCity['linkInfo'] = (cityDiscovery.info)?cityDiscovery.info.linkInfo:'';
    this.setState({selectedDiscovery: discovery, selectedDiscoveryCity,
      showModalInfo: true,
     });

  }


    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
                <BackButtonTitle handleClose={this.props.handleClose} title={'Advertising.list'}></BackButtonTitle>
                  {this.state.discoveries.length===0 && (
                    <div className="separator">
                      <Row className="justify-content-md-center">
                        <Col sm={12} className="text-center">
                          <span>{t('Advertising.not_found')}</span>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {this.state.discoveries.length>0 && (
                    <InfiniteScroll
                      dataLength={this.state.discoveries.length}
                      next={this.getUserDiscoveries}
                      hasMore={this.state.hasMore}
                      refreshFunction={(e) => this.getUserDiscoveries(true)}
                      pullDownToRefresh={true}
                      pullDownToRefreshThreshold={10}
                      pullDownToRefreshContent={
                        <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                      }
                      releaseToRefreshContent={
                        <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                      }
                      height={window.innerHeight-MODAL_MENU_HEIGHT}
                      className="white-background"
                      loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                    >
                      <Container fluid={true} className="infinite-scroll-container">
                        <Row className="justify-content-md-center">
                          <Col md={6} className="no-space-container">
                            {this.state.discoveries.map((discovery, index) => (
                              <UserDiscovery key={index} discovery={discovery} user={this.props.user} discoveryInfo={this.showDiscoveryInfo}></UserDiscovery>
                            ))}
                          </Col>
                        </Row>
                      </Container>
                    </InfiniteScroll>
                )}
            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(Advertising_list);
