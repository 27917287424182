import React, { PureComponent, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import DustyMessage from './pages/DustyMessage';
import Logout from './functions/Logout';
import CheckUser from './components/CheckUser';
import Loader from './components/Loader';
import AppPage from './pages/AppPage';
import Home from './pages/Home';
import TermsOfService from './components/TermsOfService';

import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import ManageCities from './pages/ManageCities';
import ManageUsers from './pages/ManageUsers';
import ManageAdvertising from './pages/ManageAdvertising';
import ManageSkins from './pages/ManageSkins';
import ManageTextures from './pages/ManageTextures';

import FAQ from './pages/FAQ';

import PrivateRoute from './components/PrivateRoute';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import AboutUs from './pages/AboutUs';

import WDLPrivateRoute from './wdl/WDLPrivateRoute';
import WDLLogin from './wdl/WDLLogin';
import WDLLogout from './wdl/WDLLogout';
import WDLCompanyPage from './wdl/WDLCompanyHome';
import WDLCompanyList from './wdl/WDLCompanyList';
import WDLManageCompany from './wdl/WDLManageCompany';
import WDLVectors from './wdl/WDLVectors';
import WDLManageVector from './wdl/WDLManageVector';
import WDLClients from './wdl/WDLClients';
import WDLManageClient from './wdl/WDLManageClient';
import WDLShipments from './wdl/WDLShipments';
import WDLPallets from './wdl/WDLPallets';
import WDLManageShipment from './wdl/WDLManageShipment';
import WDLManageContract from './wdl/WDLManageContract';
import WDLInvoices from './wdl/WDLInvoices';
import WDLGenerateInvoices from './wdl/WDLGenerateInvoices';
import WDLManageInvoice from './wdl/WDLManageInvoice';
import WDLInvoicesPage from './wdl/WDLInvoicesPage';
import WDLVectorsInvoices from './wdl/WDLVectorsInvoices';


class App extends PureComponent {
  render() {
    return (
      <div id="app">
        <Suspense fallback={(<Loader></Loader>)}>
          <Router>
              <Route path="/loginDiscover" component={Login}></Route>
              <Route path="/terms-of-service" component={TermsOfService}></Route>
              <Route path="/forgot_password" component={ForgotPassword}></Route>
              <Route path="/faq" component={FAQ}></Route>
              <Route path="/about_us" component={AboutUs}></Route>
              <Route path="/reset_password" component={ResetPassword}></Route>
              <Route path="/checkUser/:token" component={CheckUser}/> 
              <Route path="/user_validated" render={(props) => <DustyMessage {...props} message={"Messages.user_validated"} />}></Route>
              <Route path="/user_already_validated" render={(props) => <DustyMessage {...props} message={"Messages.user_already_validated"} />}></Route>
              <Route path="/user_not_validated" render={(props) => <DustyMessage {...props} message={"Messages.user_not_validated"} />}></Route>
              <Route path="/sign-up-discover" component={SignUp}></Route>
              <Route exact path="/" component={Home}></Route>
              <PrivateRoute path="/letMeDiscover" component={AppPage}></PrivateRoute>
              <PrivateRoute path="/manage_cities" component={ManageCities}></PrivateRoute>
              <PrivateRoute path="/manage_users" component={ManageUsers}></PrivateRoute>
              <PrivateRoute path="/manage_advertising" component={ManageAdvertising}></PrivateRoute>
              <PrivateRoute path="/manage_skins" component={ManageSkins}></PrivateRoute>
              <PrivateRoute path="/manage_textures" component={ManageTextures}></PrivateRoute>
              <Route path="/sign-up-confirm" render={(props) => <DustyMessage {...props} message={"Messages.email_sent"} />}></Route>
              <Route path="/logout" component={Logout}></Route>

              <WDLPrivateRoute exact path="/logistic" component={WDLCompanyList}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId" component={WDLCompanyPage}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/manage" component={WDLManageCompany}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/vectors" component={WDLVectors}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/vectors/add" component={WDLManageVector}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/vectors/manage/:vectorId" component={WDLManageVector}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/clients" component={WDLClients}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/clients/add" component={WDLManageClient}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/clients/manage/:clientId" component={WDLManageClient}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/shipments" component={WDLShipments}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/shipments/add" component={WDLManageShipment}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/shipments/manage/:shipmentId" component={WDLManageShipment}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/contract" component={WDLManageContract}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/invoices" component={WDLInvoicesPage}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/invoices/clients" component={WDLInvoices}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/invoices/clients/generate" component={WDLGenerateInvoices}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/client/:clientId/invoices/add" component={WDLManageInvoice}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/invoices/clients/manage/:invoiceId" component={WDLManageInvoice}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/invoices/vectors" component={WDLVectorsInvoices}></WDLPrivateRoute>
              <WDLPrivateRoute exact path="/logistic/company/:companyId/pallets" component={WDLPallets}></WDLPrivateRoute>
              
              <Route path="/logistic/login" component={WDLLogin}></Route>
              <Route path="/logistic/logout" component={WDLLogout}></Route>
              
          </Router>
        </Suspense>
      </div>

    );
  }
}

export default App;
