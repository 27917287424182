import React, { PureComponent } from 'react';
import Menu from '../components/Menu';
import MenuDiscovery from '../components/MenuDiscovery';
import Welcome from '../components/Welcome';
import { CURRENT_CITY, HOME_TAB, USER, ROLE_ADMIN, ROLE_ADVERTISER } from '../Constants.js';

import { Container} from 'react-bootstrap';

import { withTranslation } from 'react-i18next';

class AppPage extends PureComponent {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem(USER));
        var isAdmin = false;
        var isAdvertiser = false;
        if(user.role === ROLE_ADMIN){
          isAdmin = true;
        }else if(user.role === ROLE_ADVERTISER){
          isAdvertiser = true;
        }
        let currentCityId = localStorage.getItem(CURRENT_CITY);
        this.state = {
            cityId: currentCityId? currentCityId: null,
            keyCity: Math.random(),
            user: user,
            isAdmin: isAdmin,
            isAdvertiser: isAdvertiser,
            showModal: false
        };

        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
      this.setState({showModal: false});
    }


    handleChangeCity = (cityId) => {
      localStorage.removeItem(HOME_TAB);
      localStorage.setItem(CURRENT_CITY, cityId);
      this.setState(
        { cityId: cityId, keyCity: Math.random() }
      );
    };

    componentDidMount() {
      var showModal = false;
      let user = {...this.state.user};
      if(user.firstAccess){
        showModal = true;
        user.firstAccess=false;
        localStorage.setItem(USER, JSON.stringify(user));
      }

      this.setState(
        { showModal, user}
      );

    }

    render() {
        return (
            <Container fluid={true} className="no-space-container">
              <Menu handleChangeCity={this.handleChangeCity} user={this.state.user} isAdmin={this.state.isAdmin}></Menu>
              <MenuDiscovery handleChangeCity={this.handleChangeCity} key={this.state.keyCity} cityId={this.state.cityId} user={this.state.user} isAdmin={this.state.isAdmin} isAdvertiser={this.state.isAdvertiser}></MenuDiscovery>
              {this.state.showModal && (
                <Welcome user={this.state.user} showModal={this.state.showModal} handleClose={this.handleClose}></Welcome>
              )}
           </Container>
        );
    }
}

export default withTranslation()(AppPage);
