import React from 'react';
import { Redirect } from 'react-router-dom';
import { WDL_ACCESS_TOKEN, WDL_USER} from '../Constants';


function WDLLogout () {
    localStorage.removeItem(WDL_ACCESS_TOKEN);
    localStorage.removeItem(WDL_USER);
    return <Redirect to="/logistic" push={true} />;
}

export default WDLLogout;
