import React, { PureComponent } from 'react';
import axios from 'axios';
import Menu from '../components/Menu';
import Loader from '../components/Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import { API_BASE_URL, ACCESS_TOKEN, LANGUAGES} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button } from 'react-bootstrap';


class ManageCityInfo extends PureComponent {
    constructor() {
        super();

        this.state = {
            cityId: '',
            cityName: '',
            name: '', nameValid: false,
            description: '',
            languageId: '',
            linkInfo: '',
            languages: [],
            formValid: false,
            errorMsg: {},
            successMessage: '',
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);

    }


    handleChangeLanguage (e) {
      let target = e.target;
      let value = target.value;
      this.getCityInfo(this.state.cityId, value);
    };


    resetState(){
      this.setState({
          name: '', nameValid: false,
          description: '',
          linkInfo: '',
          formValid: false,
          errorMsg: {},
          successMessage: '',
          loading: false
        });
    }

    getCityInfo = (cityId, lang) => {

      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {}

      errorMsg.dataError ='';
      this.setState({errorMsg,
        successMessage: '',
        loading: true,
        languageId: lang
      });

      axios.get(API_BASE_URL+'/city/getInfo/'+cityId+'/'+lang, config)
      .then((response) => {
          let cityInfo = response.data;
          if(cityInfo!==null && cityInfo!==""){
            this.setState({
              name: cityInfo.name, nameValid: true,
              description: cityInfo.description,
              linkInfo: cityInfo.linkInfo,
              formValid: true,
              loading: false
            });
          }else{
            this.setState({
              name: '', nameValid: false,
              description: '',
              linkInfo: '',
              formValid: false,
              loading: false
            });
          }

      }, (error) => {
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
        this.setState({errorMsg,
          loading: false});
      });
    }

    validateFields = (name, value) => {
      const { t } = this.props;
      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if (value.length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg}, this.validateForm);

      }


    }


    componentDidMount() {
      this.setState({
        cityId: this.props.cityId,
        cityName: this.props.cityName,
        languageId: LANGUAGES[0],
        languages: LANGUAGES
      }, this.getCityInfo(this.props.cityId, LANGUAGES[0]));
    }

  validateForm = () => {
    const {nameValid} = this.state;
    this.setState({
      formValid: nameValid
    })
  }

  handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
          [name]: value
        }, this.validateFields(name, value));


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["cityId"]=this.state.cityId;
      data["languageId"]=this.state.languageId;
      data["name"]=this.state.name;
      data["description"]=this.state.description;
      data["linkInfo"]=this.state.linkInfo;
      let errorMsg = {};
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      this.setState({errorMsg,
        loading: true});

      axios.post(API_BASE_URL+'/city/admin/saveInfo', data, config)
      .then((response) => {
          this.setState({ successMessage: t('City.saved'),
            loading: false });
      }, (error) => {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      });
    };


    render() {
        const { t } = this.props;
        return (
          <Container fluid={true} className="menu-container">
            {this.state.loading && (
              <Loader></Loader>
            )}
            <Menu></Menu>
              <Container fluid={true} className="title-bar-page">
                <Row>
                  <Col md={2}>
                    <div className="back-button" onClick={(e) => this.props.back(false)}><i className="fa fa-chevron-left fa-2x " aria-hidden="true"></i></div>
                  </Col>
                  <Col md={8} className="text-center">
                      <h5 className="title-text">{this.state.cityName}</h5>
                  </Col>
                </Row>
              </Container>
            <Container fluid={true} className="tab-container">
              <Row className="justify-content-md-center">
                <Col sm={8} className="form_center">
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="languageId" className="form_field" >
                      <Form.Label className="form_field_label">{t('Common.languages')}</Form.Label>
                      <Form.Control as="select" name="languageId" required value={this.state.languageId} onChange={this.handleChangeLanguage}>
                        {this.state.languages.map(lang =>
                          <option key={lang} value={lang}>{lang}</option>
                        )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Label className="form_field_label">{t('City.name')}</Form.Label>
                    <Form.Group controlId="name" className="form_field">
                      <Form.Control type="text" className="form_field_input" placeholder={t('City.name')} name="name" value={this.state.name} onChange={this.handleChange}  />
                      <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                    </Form.Group>
                    <Form.Group controlId="description" className="form_field">
                      <Form.Label className="form_field_label">{t('City.description')}</Form.Label>
                      <Form.Control as="textarea" placeholder={t('City.description')} maxLength="800" name="description" value={this.state.description} onChange={this.handleChange}  />
                    </Form.Group>
                    <Form.Group controlId="linkInfo" className="form_field">
                      <Form.Label className="form_field_label">{t('Common.link')}</Form.Label>
                      <Form.Control type="text" className="form_field_input" placeholder={t('Common.link')} name="linkInfo" value={this.state.linkInfo} onChange={this.handleChange}  />
                    </Form.Group>
                    <Form.Group className="form_field">
                      <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                      <SuccessMessage valid={true} message={this.state.successMessage} />
                    </Form.Group>
                    <Form.Row>
                      <Col className="text-center">
                        <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('Common.save')}</Button>
                      </Col>
                      <Col className="text-center">
                        <Button variant="primary" type="button" className="btn-login" onClick={this.resetState}>{t('Common.clear')}</Button>
                      </Col>

                     </Form.Row>
                   </Form>
                 </Col>
              </Row>
            </Container>
          </Container>
        );

    }


}

export default withTranslation()(ManageCityInfo);
