import React, { PureComponent } from 'react';
import { Col, Form, Container, Modal, Button} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, LANGUAGES, TRIPADVISOR_URL} from '../Constants.js';
import i18n from 'i18next';

import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import Loader from '../components/Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import { withTranslation } from 'react-i18next';

class PlateManagePage extends PureComponent {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
          cityId: this.props.cityId,
          showDescription: false,
          showPlateInfo: false,
          plate: this.props.plate,
          loading: false,
          languageId: LANGUAGES[0],
          languages: LANGUAGES,
          errorMsg: {},
          successMessage: '',
          showAssignAttractionModal: false,
          successMessage: '',
          assignedId: null,
        }
        this.handleShowDescription = this.handleShowDescription.bind(this);
        this.handleShowPlateInfo = this.handleShowPlateInfo.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePlateData = this.handleChangePlateData.bind(this);
        this.handleUploadFileImg = this.handleUploadFileImg.bind(this);
        this.deletePlate = this.deletePlate.bind(this);
        this.handleSubmitInfo = this.handleSubmitInfo.bind(this);
        this.saveNewPlate = this.saveNewPlate.bind(this);
        this.saveNewPlateInfo = this.saveNewPlateInfo.bind(this);
        this.handleSubmitAssignToUser = this.handleSubmitAssignToUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
    }

    handleShowAssignModal() {
      this.setState({showAssignAttractionModal: !this.state.showAssignAttractionModal});
    }

    handleChangeLanguage (e) {
      let target = e.target;
      let value = target.value;
      this.getPlateInfo(this.state.plate.attractionId, value);
    };

    handleShowPlateInfo() {
        this.setState({
          showPlateInfo: !this.state.showPlateInfo}, this.getPlateInfo(this.state.plate.attractionId, this.state.languages[0]));
    }

    handleSubmitInfo(e){
      e.preventDefault();
      let plate = {...this.state.plate};

      this.saveNewPlateInfo(plate);
    }

    saveNewPlateInfo(plate){
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      data["attractionId"]=plate.attractionId;
      data["languageId"]=this.state.languageId;
      data["description"]=plate.description;
      data["name"]=plate.nameInfo;

      this.setState({errorMsg: {},
          successMessage: '',
        loading: true
      });

      axios.post(API_BASE_URL+'/plate/admin/saveInfo', data, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({loading: false, successMessage: t('Plate.saved')},
          this.props.updatePlateInfo(plate));
        }


      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
        });

    }

    getPlateInfo = (attrId, lang) => {

      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {}

      errorMsg.dataError ='';
      this.setState({errorMsg,
        successMessage: '',
        loading: true,
        languageId: lang
      });

      axios.get(API_BASE_URL+'/plate/getInfo/'+attrId+'/'+lang, config)
      .then((response) => {
        if (this._isMounted) {
          let attrInfo = response.data;
          let plate = {...this.state.plate};
          if(attrInfo!==null && attrInfo!==""){
            plate.nameInfo=attrInfo.name;
            plate.description=attrInfo.description;

          }else{
            plate.nameInfo='';
            plate.description='';

          }
          this.setState({
            plate,
            loading: false
          });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    }

    handleUploadFileImg = (event) => {
        let imageSize = 600;
        if(this.state.showAssignAttractionModal){
          imageSize = 1280;
        }
        let target = event.target;
        let plate = {...this.state.plate};
        this.setState({plate});
        var fileInput = false
          if(event.target.files[0]) {
              fileInput = true
          }
          if(fileInput) {
              Resizer.imageFileResizer(
                  target.files[0],
                  imageSize,
                  imageSize,
                  'JPEG',
                  100,
                  0,
                  uri => {
                    plate.imageData = uri;
                    this.setState({plate});
                  },
                  'base64'
              );
          }
      };

    handleShowDescription(){
      var showDescription = !this.state.showDescription;
      this.setState({showDescription});
    }

    handleSubmit(e){
      e.preventDefault();
      let plate = {...this.state.plate};
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      if(!plate.imageData && !plate.image){
        errorMsg.dataError = t('Errors.invalid_field');
        this.setState({errorMsg});
      }else{
        this.saveNewPlate(plate);
      }
    }

    saveNewPlate(plate){

      let errorMsg = {...this.state.errorMsg}

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      data["attractionId"]=plate.attractionId;
      data["cityId"]=this.state.cityId;
      data["name"]=plate.name;
      data["date"]=plate.date;
      data["image"]=plate.image;
      data["imageData"]=plate.imageData;
      const { t } = this.props;
      errorMsg.dataError = '';
      this.setState({errorMsg,
        loading: true,
        successMessage: ''
      });

      axios.post(API_BASE_URL+'/plate/admin/save?lang='+i18n.language, data, config)
      .then((response) => {
        if (this._isMounted) {
          
          let newPlate = response.data;
          newPlate['nameInfo'] = (newPlate.info)?newPlate.info.name: '';
          newPlate['description'] = (newPlate.info)?newPlate.info.description: '';
          newPlate['languageId'] = (newPlate.info)?newPlate.info.languageId: '';
          newPlate['userAttraction'] = (newPlate.userAttraction)?newPlate.userAttraction: null;

          var isUpdating = false;
          if(plate.attractionId!==null && plate.attractionId!==""){
            isUpdating = true;
          }

          const { t } = this.props;
          this.setState({loading: false,
            plate: newPlate,
            successMessage: t('Plate.saved')}, this.props.updatePlateList(newPlate, isUpdating));
        }

      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });
    }


    handleChangePlateData(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let plate = {...this.state.plate};

        plate[name] = value;
        this.setState({
          plate
        });

    }

    deletePlate = (event, id) => {

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      this.setState({errorMsg,
        loading: true
      });

      axios.post(API_BASE_URL+'/plate/admin/delete/'+id, data, config)
      .then((response) => {
        if (this._isMounted) {
          this.props.handleDeletePlate(id);
          
          this.setState({
            loading: false
          }, this.props.handleClose());
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });

    }

    handleSubmitAssignToUser(e){
      e.preventDefault();
      let errorMsg = {...this.state.errorMsg}
      let plate = {...this.state.plate};
      const { t } = this.props;
      if(!plate.imageData){
        errorMsg.dataError = t('Errors.invalid_field');
        this.setState({errorMsg});
      }else{
          var config = {
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
            }
          };
          var data = {};
          data.userId = this.state.assignedId;
          data.attractionId = plate.attractionId;
          data.imageData = plate.imageData;
          data.city = plate.city;
          const { t } = this.props;
          errorMsg.dataError = '';

          this.setState({errorMsg,
            loading: true,
            successMessage: ''
          });

          axios.post(API_BASE_URL+'/plate/admin/assignUserPlate', data, config)
          .then((response) => {
            if (this._isMounted) {
              
              
              this.setState({loading: false,
                successMessage: t('Attractions.saved')});
            }

          }, (error) => {
            if (this._isMounted) {
              errorMsg.dataError = t('Errors.generic_error');

              if (error.response && error.response.status) {
                let status = error.response.status;
                if(status===401){
                  return window.location.href = '/logout';            
                }
              }else{
                if(error.message!=null && error.message==='Network Error'){
                    errorMsg.dataError = t("Errors.connection_error");
                }
              }
              this.setState({errorMsg,
                loading: false});
              }
          });
      
        }
      
    }

    handleChange(e) {

      let target = e.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      let name = target.name;

      this.setState({
        [name]: value
      });


  }

    render() {
      const { t } = this.props;
      if(this.state.showAssignAttractionModal) {
        return (

          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>  {'Assing to user'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="app_aside">
              {this.state.loading && (
                <Loader></Loader>
              )}
              <Container fluid={true}>
                <Form onSubmit={this.handleSubmitAssignToUser}>
                    <input type="hidden" name="attractionId" value={this.state.plate.attractionId} ></input>
                    <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="assignedId" className="form_field">
                           <Form.Label className="form_field_label">{'Assigned user Id'}</Form.Label>
                           <Form.Control type="number" className="form_field_input" placeholder={'User id'} name="assignedId" value={this.state.assignedId} onChange={this.handleChange} required/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                    <Form.Group controlId="image" className="form_field">
                      <Form.Label className="form_field_label">{t('Attractions.image')}</Form.Label>
                      <Form.Control type="file" name="image" onChange={this.handleUploadFileImg}/>
                    </Form.Group>
                    <Form.Group className="form_field">
                      <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                      <SuccessMessage valid={true} message={this.state.successMessage} />
                  </Form.Group>
                    <Form.Row>
                       <Col className="text-center">
                         <Button variant="primary" type="submit">{t('Attractions.save')}</Button>
                       </Col>
                       <Col className="text-center">
                         <Button type="button" variant="secondary" onClick={(e) => this.deleteAttraction(e, this.state.attraction.attractionId)}>
                           {t('Admin.delete_attraction')}
                         </Button>
                       </Col>
                     </Form.Row>
                   </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.handleClose}>
                {t('Attractions.close')}
              </Button>
            </Modal.Footer>
          </Modal>

        );
      } else {
        return (

          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              {this.state.plate.attractionId==="" && (<Modal.Title>  {t('Admin.add_plate')}</Modal.Title>)}
              {this.state.plate.attractionId!=="" && (<Modal.Title>  {t('Admin.edit_plate')}
              <Button variant={(this.state.showPlateInfo ? 'primary' : 'secondary')} style={{ marginLeft: '10px' }} onClick={(e) => this
                .handleShowPlateInfo()}><i className="fa fa-info-circle" aria-hidden="true"></i></Button>
                <Button variant={'primary'} style={{ marginLeft: '10px' }} onClick={(e) => this
                  .handleShowAssignModal()}><i className="fa fa-user" aria-hidden="true"></i></Button>
                </Modal.Title>)}
            </Modal.Header>
            <Modal.Body className="app_aside">
              {this.state.loading && (
                <Loader></Loader>
              )}
              <Container fluid={true}>
                {!this.state.showPlateInfo && (
                  <Form onSubmit={this.handleSubmit}>
                    <input type="hidden" name="attractionId" value={this.state.plate.attractionId} ></input>
                    <Form.Row>
                       <Col sm={8}>
                         <Form.Group controlId="name" className="form_field">
                           <Form.Label className="form_field_label">{t('Plate.name')}</Form.Label>
                           <Form.Control type="text" className="form_field_input" placeholder={t('Plate.name')} name="name" value={this.state.plate.name} onChange={this.handleChangePlateData} required/>
                         </Form.Group>
                       </Col>
                     </Form.Row>
                    <Form.Group controlId="image" className="form_field">
                      <Form.Label className="form_field_label">{t('Plate.image')}</Form.Label>
                      <Form.Control type="file" name="image" onChange={this.handleUploadFileImg}/>
                    </Form.Group>
                    <Form.Group className="form_field">
                      <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                      <SuccessMessage valid={true} message={this.state.successMessage} />
                  </Form.Group>
                    <Form.Row>
                       <Col className="text-center">
                         <Button variant="primary" type="submit">{t('Plate.save')}</Button>
                       </Col>
                       <Col className="text-center">
                         <Button type="button" variant="secondary" onClick={(e) => this.deletePlate(e, this.state.plate.attractionId)}>
                           {t('Admin.delete_plate')}
                         </Button>
                       </Col>
                     </Form.Row>
                   </Form>
                 )}
                 {this.state.showPlateInfo && (
                   <Form onSubmit={this.handleSubmitInfo}>
                     <Form.Group controlId="languageId" className="form_field" >
                       <Form.Label className="form_field_label">{t('Common.languages')}</Form.Label>
                       <Form.Control as="select" name="languageId" required value={this.state.languageId} onChange={this.handleChangeLanguage}>
                         {this.state.languages.map(lang =>
                           <option key={lang} value={lang}>{lang}</option>
                         )}
                       </Form.Control>
                     </Form.Group>
                     <Form.Label className="form_field_label">{t('Plate.name')}</Form.Label>
                     <Form.Group controlId="name" className="form_field">
                       <Form.Control type="text" className="form_field_input" required placeholder={t('Plate.name')} name="nameInfo" value={this.state.plate.nameInfo} onChange={this.handleChangePlateData}  />
                     </Form.Group>
                     <Form.Group controlId="description" className="form_field">
                       <Form.Label className="form_field_label">{t('Plate.description')}</Form.Label>
                       <Form.Control as="textarea" placeholder={t('Plate.description')} maxLength="800" name="description" value={this.state.plate.description} onChange={this.handleChangePlateData}  />
                     </Form.Group>
                     <Form.Group className="form_field">
                       <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                       <SuccessMessage valid={true} message={this.state.successMessage} />
                     </Form.Group>
                     <Form.Row>
                       <Col className="text-center">
                         <Button variant="primary" type="submit" className="btn-login">{t('Common.save')}</Button>
                       </Col>
                      </Form.Row>
                    </Form>


                 )}

                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.handleClose}>
                {t('Plate.close')}
              </Button>
            </Modal.Footer>
          </Modal>

        );
      }
    }
}

export default withTranslation()(PlateManagePage);
