import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal, ListGroup, Image} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT } from '../Constants.js';
import NumberFormat from 'react-number-format';
import dusty from '../img/dusty.svg';
import axios from 'axios';
import LevelPage from '../components/LevelPage';
import BackButtonTitle from '../components/BackButtonTitle';

import { withTranslation } from 'react-i18next';

class Levels extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        selectedLevel: null,
        showModal: false,
        levels: []
      }
      this.getLevels = this.getLevels.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showLevelPage = this.showLevelPage.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getLevels();
  }

  handleClose(){
    this.setState({selectedLevel: null,
      showModal: false});
  }

  showLevelPage(selectedLevel){

    this.setState({selectedLevel,
      showModal: true});

  }


getLevels(){

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}

    errorMsg.dataError ='';
    this.setState({errorMsg,
      hasMore: true
    });

    axios.get(API_BASE_URL+'/discovery/getLevels', config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({
          levels: response.data
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body>
                <BackButtonTitle handleClose={this.props.handleClose} title={t('Profile.levels')}></BackButtonTitle>

                <Container fluid={true} className="infinite-scroll-container" height={window.innerHeight-MODAL_MENU_HEIGHT}>
                  <ListGroup className="app-background">
                    <Row>
                      <Col md={12}>
                        {this.state.levels.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Level.not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.levels && this.state.levels.map((level, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.showLevelPage(level)}>
                              <span className={"link-icon user-text-level-"+level.levelId}>{t('Level.'+level.levelId)}<i style={{color: 'white'}} className={"fa fa-check button-icon wished-check "+(level.levelId!==this.props.user.level? "hidden" : "")} aria-hidden="true"></i></span>
                              <br/><span className="level-points">{(level.points!==0)?<NumberFormat value={level.points} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />: ''}</span>
                              <Image src={dusty} alt="Dusty" className="level-dusty-img center-block" fluid/>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <LevelPage level={this.state.selectedLevel} user={this.props.user} showModal={true} handleClose={this.handleClose}></LevelPage>
                  )}
                </Container>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(Levels);
