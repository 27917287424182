import React, { PureComponent } from 'react';
import dustyGlasses from '../img/dusty_glasses.jpg';
import {Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';


class UserImage extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            class_name: (this.props.className)? this.props.className: 'profile-img'
        };


    }


    render() {
      let classImage = (this.props.className)? this.props.className: 'profile-img'
      return (
          <Image className={classImage+" list-img user-icon-level-"+this.props.user.level} 
          src={(this.props.user && this.props.user.image)?this.props.user.image:dustyGlasses}
          />
        );
    }
}

export default withTranslation()(UserImage);
