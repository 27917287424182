import React, { PureComponent } from 'react';

import { Image, Col, NavDropdown, NavItem, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { withTranslation } from 'react-i18next';

class WDLCompanyMenuItem extends PureComponent {
    
    render() {
        const { t } = this.props;
        return (
          
          <Container fluid className={"wdl-company-menu-item"} style={{ backgroundColor: this.props.color, maxWidth: this.props.maxWidth?this.props.maxWidth:null}}>
            <a href={this.props.link} >
                <div>
                  <Image fluid={true} src={this.props.image} className={"wdl-menu-img"}/>
                </div>
                <span className="wdl-menu-text">{this.props.text}</span>
            </a>
          </Container>
          
        );
    }
}

export default withTranslation()(WDLCompanyMenuItem);
