import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Modal, Button} from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';
import dusty from '../img/dusty.svg';
import { API_BASE_URL, ACCESS_TOKEN, GOOGLE_MAPS_URL } from '../Constants.js';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import UsersLikesPage from '../components/UsersLikesPage';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'


class AttractionPage extends PureComponent {
      _isMounted = false;

      _locales = {
        en: enUS,
        fr: fr,
        it: it,
        es: es
      }

    constructor(props) {
        super(props);
        this.state = {
          attraction: this.props.attraction,
          showDescription: false,
          showLikesPage: false,
          likePageNumber: 0
        }
        this.handleShowDescription = this.handleShowDescription.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showUsersLikesPage = this.showUsersLikesPage.bind(this);
        this.wishAttraction = this.wishAttraction.bind(this);
        this.getAttractionData = this.getAttractionData.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

     componentDidMount() {
       this._isMounted = true;
       this.getAttractionData();
     }

    handleShowDescription(){
      var showDescription = !this.state.showDescription;
      this.setState({showDescription});
    }

    handleClose(){
      this.setState({likePageNumber: 0,
        showLikesPage: false});
    }


    showUsersLikesPage(){
      let attraction = this.state.attraction;
      if(attraction.likes>0){
        this.setState({likePageNumber: 0,
          showLikesPage: true});
      }
    }

    getAttractionData() {
      var config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
      };
      const { t } = this.props;

      let errorMsg = {};
  
      this.setState({errorMsg});
      
      axios
        .get(
          API_BASE_URL +
            '/attraction/getOne/' +
            this.state.attraction.attractionId +
            '?lang=' +
            i18n.language,
          config,
        )
        .then(
          response => {
            if (this._isMounted) {
              let attraction = response.data;
              attraction.position = [attraction.latitude, attraction.longitude];
              attraction.nameInfo = attraction.info ? attraction.info.name : '';
              attraction.description = attraction.info
                ? attraction.info.description
                : '';
              attraction.languageId = attraction.info
                ? attraction.info.languageId
                : '';
              attraction.linkInfo = attraction.info
                ? attraction.info.linkInfo
                : '';
  
              attraction.inactive = false;
              
              this.setState({attraction});
            }
          },
          error => {
            if (this._isMounted) {
              errorMsg.dataError = t('Errors.generic_error');

              if (error.response && error.response.status) {
                let status = error.response.status;
                if(status===401){
                  return window.location.href = '/logout';            
                }
              }else{
                if(error.message!=null && error.message==='Network Error'){
                    errorMsg.dataError = t("Errors.connection_error");
                }
              }
              this.setState({errorMsg});
              }
          },
        );
    }

    wishAttraction() {
     let attraction = {...this.state.attraction};

     let errorMsg = {...this.state.errorMsg}
     const { t } = this.props;

     var config = {
         headers: {
           'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
       }
     };


     this.setState({errorMsg: {},
       successMessage: ''
     });

     var data = {};
     data['user']=this.props.user;
     data['attraction']=attraction;
     data['isAttraction']=true;
     data['wished']=attraction.wished;
     data.attraction.userAttraction=null;
     let wishedUrl = attraction.wished? 'delete_wish' : 'wish';

     axios.post(API_BASE_URL+'/discovery/'+wishedUrl, data, config)
     .then((response) => {
       if (this._isMounted) {
        let discovery = response.data;
        attraction.wished = discovery.wished;
        this.setState({attraction});
        if(this.props.wishCallback){
          this.props.wishCallback(attraction.wished)
        }
       }
     }, (error) => {
       if (this._isMounted) {
         errorMsg.dataError = t('Errors.generic_error');

         if (error.response && error.response.status) {
           let status = error.response.status;
           if(status===401){
            return window.location.href = '/logout';            
          }
         }else{
           if(error.message!=null && error.message==='Network Error'){
               errorMsg.dataError = t("Errors.connection_error");
           }
         }
         this.setState({errorMsg});
         }
       });

   }

    render() {

      const { t } = this.props;
        return (
          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton className={"attraction-lev-"+this.state.attraction.level}>
              <Modal.Title>
                <div className="attraction-title">
                  {(this.state.attraction.nameInfo)?this.state.attraction.nameInfo: this.state.attraction.name}
                  <span className="raiting-number">(<NumberFormat value={this.state.attraction.visitors} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />)</span>

                  <div className="attraction-rank">
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={this.state.attraction.votes}
                      editing={false}
                      starColor={"white"}
                    />
                  </div>

                </div>
                <div className="attraction-info-container">
                  <div className="attraction-date">
                   {this.state.attraction.city.info ? this.state.attraction.city.info.name: this.state.attraction.city.name}
                  </div>
                  <div className="level-text">
                    {t('Attractions.level')} {this.state.attraction.level}
                  </div>
                </div>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                  {!this.state.showDescription && (
                    <Row>
                      <Col md={12} className="text-center">
                          <Image fluid={true} src={(this.state.attraction.userAttraction?this.state.attraction.userAttraction.image:this.state.attraction.image)} className={"attraction-image"}/>
                      </Col>
                    </Row>
                  )}
                    {!this.state.showDescription && this.state.attraction.userAttraction && (
                    <Row>
                      <Col md={12}>
                          <Image src={dusty} alt="Dusty" className="like-info-img center-block" fluid/>
                          <span onClick={(e) => this.showUsersLikesPage()} className="like-info"><NumberFormat value={this.state.attraction.likes} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></span>
                          <span className="float-right wish-label"><i className="fa fa-check button-icon" aria-hidden="true"></i>{t('Discovery.visited_attraction')}</span>
                      </Col>
                    </Row>
                    )}
                    {!this.state.showDescription && !this.state.attraction.userAttraction && (

                    <Row>
                      <Col md={12} className="wish-button-container">
                          <Button onClick={(e) => this.wishAttraction()}  className={"float-right "+(this.state.attraction.wished?"btn-primary":"btn-secondary")}>
                            <i className="fa fa-bookmark button-icon" aria-hidden="true"></i>{t('Discovery.attraction_to_visit')}
                          </Button>
                      </Col>
                    </Row>
                  )}
                  {!this.state.showDescription && this.state.attraction.userAttraction && (
                    <Row className="attraction-text-container">
                      <Col md={12}>
                        <span className="visit-date">{
                        format(
                          new Date(this.state.attraction.userAttraction.visitDate),
                          'd LLLL uuuu p',
                          {locale: this._locales[i18n.language]}
                        )}</span>
                      </Col>
                    </Row>
                  )}
                  {this.state.showDescription && (
                    <Row>
                      <Col md={12}>
                        <p className="attraction-description">
                          {this.state.attraction.info.description}
                        </p>
                        <a className="btn btn-primary attraction-info" target="_blank"  rel="noopener noreferrer" href={this.state.attraction.info.linkInfo}>{(this.state.attraction.info.linkInfo)?t('Attractions.more_info'):''}</a>
                      </Col>
                    </Row>
                  )}
                </Container>
            </Modal.Body>
            <Modal.Footer className={"attraction-lev-"+this.state.attraction.level}>
              <Container>
                <Row>
                  <div className="button-modal-space">

                  </div>

                  <div className="button-modal-space text-center">
                    {this.state.attraction.info && this.state.attraction.info.description && (
                      <Button variant={(this.state.showDescription ? 'primary' : 'secondary')} onClick={this.handleShowDescription} >
                        <i className="fa fa-info fa-2x" aria-hidden="true"></i>
                      </Button>
                    )}
                  </div>
                  <div className="button-modal-space">
                    <a target="_blank"  rel="noopener noreferrer" className="btn btn-primary float-right" href={GOOGLE_MAPS_URL+this.state.attraction.latitude+','+this.state.attraction.longitude}>
                      <i className="fa fa-location-arrow fa-2x" aria-hidden="true"></i>
                    </a>

                  </div>

                </Row>
                {this.state.showLikesPage && (
                  <UsersLikesPage discoveryId={this.state.attraction.attractionId} isAttraction={true} user={this.props.user} showModal={this.state.showLikesPage} handleClose={this.handleClose}></UsersLikesPage>
                )}
              </Container>

            </Modal.Footer>
          </Modal>


        );
    }
}

export default withTranslation()(AttractionPage);
