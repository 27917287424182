import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Table, Button} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { Link } from 'react-router-dom';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { withTranslation } from 'react-i18next';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLClients extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
      this.state = {
        companyId: this.props.match.params.companyId,
        user: user,
        isAdmin: isAdmin,
        query: '',
        page: 0,
        hasMore: false,
        clients: [],
        viewHeight: window.innerHeight,
      }
      this.getClients = this.getClients.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getClients();
  }



getClients(refresh, newSearch){
    const requestId = 'getClientsRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let clients = this.state.clients;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      clients=[];
      page = 0;
      this.setState({errorMsg, clients, query,
        hasMore: true
      });
    }else if(newSearch){
      clients=[];
      page = 0;
      this.setState({errorMsg, clients, page,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client'+'?page='+page;

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }
    

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newVectors = response.data;
        if((newVectors && newVectors.length===0) || (newVectors && newVectors[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          clients: clients.concat(newVectors)
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }


  onFormSubmit(e) {
    e.preventDefault();
    this.getClients(false, true);
  }


    render() {
      const { t } = this.props;
      
        return (

            <div className="wdl-container">
              <InfiniteScroll
                dataLength={this.state.clients.length}
                next={this.getClients}
                hasMore={this.state.hasMore}
                refreshFunction={(e) => this.getClients(true)}
                pullDownToRefresh={true}
                pullDownToRefreshThreshold={10}
                pullDownToRefreshContent={
                  <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                }
                releaseToRefreshContent={
                  <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                }
                height={this.state.viewHeight}
                className="white-background"
                loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
              >
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} link={'/logistic/company/'+this.state.companyId} title={'WDL.clients'}></WDLBackButtonTitle>
              
              <Container fluid={true}>
                <Row className="justify-content-md-center">
                  <Col md={8}>
                    <Row className="justify-content-md-center" style={{marginBottom: 80, marginTop: 30}}>
                      <Col className="text-center form-group">
                        <Button style={{backgroundColor: '#247b5d'}} variant="primary" as={Link} to={"/logistic/company/"+this.state.companyId+"/clients/add"} className="btn-login" >{t('WDL.add_client')}</Button>
                      </Col>
                    </Row>
                    <Row className="form_field wdl-search-container">
                      <Col>
                      <Form onSubmit={this.onFormSubmit}>
                        <Row>
                          <Form.Group as={Col}  controlId="query" className="form_field">
                            <Form.Label className="form_field_label">{t('WDL.search')}</Form.Label>
                            <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.text_ph')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                          </Form.Group>
                        </Row>
                        <Button style={{padding: 10}} variant="primary" type="submit" >
                          <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                        </Button>
                      </Form>
                      </Col>
                      </Row>
                      

                          <Table striped borderless hover responsive className="wdl-table">
                            <thead>
                              <tr style={{backgroundColor: '#247b5d', color: 'white'}}>
                                <th>{t('WDL.name')}</th>
                                <th>Email</th>
                                <th>{t('WDL.sdi')}</th>
                                <th>IVA</th>
                              </tr>
                            </thead>
                            <tbody>
                                {this.state.clients && this.state.clients.map((client, index) => (
                                <tr key={index} >
                                  <td>
                                    <a href={'/logistic/company/'+this.state.companyId+'/clients/manage/'+client.clientId}>{client.name} </a>
                                  </td>
                                  <td>
                                    <span>{client.email} </span>
                                  </td>
                                  <td>
                                    <span>{client.sdCode}</span>
                                  </td>
                                  <td>
                                    <span>{client.pIva}</span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {(this.state.clients.length===0 && this.state.query.length>0) && 
                          
                          <div className="separator info-not-found-container">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('WDL.info_not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                          }
                            
                    
                  </Col>
                  </Row>
              </Container>
              
            </InfiniteScroll>
            </div>
        );
    }
}

export default withTranslation()(WDLClients);
