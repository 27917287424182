import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal} from 'react-bootstrap';
import axios from 'axios';
import {WriteAttractionHtmlWordpress, WritePlateHtmlWordpress, WriteCityHtmlWordpress} from '../functions/FormUtils.js';
import i18n from 'i18next';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';

import { withTranslation } from 'react-i18next';

class HtmlCityPage extends PureComponent {
  _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            cityId: this.props.cityId,
            city: this.props.city,
            errorMsg: {}
        };
        this.getCityDiscoveries = this.getCityDiscoveries.bind(this);

    }

    componentDidMount() {
      this._isMounted = true;
      this.getCityDiscoveries();
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    getCityDiscoveries(){
      var data = null;

      data={};
      data.cityId = this.state.cityId;
      data.lang = i18n.language;
     
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {...this.state.errorMsg}

      errorMsg.dataError ='';
      axios.post(API_BASE_URL+'/discovery/getMapDiscoveries', data, config)
      .then((response) => {
        let attractionsHtml="";
        let platesHtml="";
        let cityHtml="";
        if (this._isMounted) {
          if(response!==null && response.data!==null && response.data!==""){
              let cityDiscovery=response.data;
              if(cityDiscovery.attractions){
                let attractions=cityDiscovery.attractions;
                if(attractions){
                  attractionsHtml=WriteAttractionHtmlWordpress(attractions, i18n.language, this.props.city);
                }
              }
              if(cityDiscovery.plates){
                let plates=cityDiscovery.plates;
                if(plates){
                  platesHtml=WritePlateHtmlWordpress(plates,i18n.language, this.props.city);
                }

              }
              if(this.props.city!=null){
                cityHtml=WriteCityHtmlWordpress(this.props.city, i18n.language, this.props.city);
                
               
              }

             
          }

          this.setState({
            attractionsHtml,
            platesHtml,
            cityHtml,
            errorMsg,
          });
        }
      }, (error) => {
        if (this._isMounted) {
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
              return window.location.href = '/logout';            
            }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
        this.setState({errorMsg,
          loading: false});
        }
      });

      


    }

    render() {
      const { t } = this.props;
        return (
          <Modal show={true} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="attraction-title">
                  {this.state.cityId}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
              <Row>
                  <Col md={12}>
                    <p className="attraction-description">
                      {(this.state.cityHtml)?this.state.cityHtml : t("City.info_not_found")}
                    </p>
                    <br/>
                    <br/>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="attraction-description">
                      {(this.state.attractionsHtml)?this.state.attractionsHtml : t("City.info_not_found")}
                    </p>
                    <br/>
                    <br/>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="attraction-description">
                      {(this.state.platesHtml)?this.state.platesHtml : t("City.info_not_found")}
                    </p>
                  </Col>
                </Row>
                </Container>
            </Modal.Body>

          </Modal>


        );
    }
}

export default withTranslation()(HtmlCityPage);
