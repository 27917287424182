import React from 'react';
import { WDL_ACCESS_TOKEN } from '../Constants.js';
import { Route, Redirect } from 'react-router-dom';


const WDLPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem(WDL_ACCESS_TOKEN)
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/logistic/login'
        }} />
  )} />
)

export default WDLPrivateRoute;
