import React, { PureComponent } from 'react';
import WDLLogoSection from '../wdl/WDLLogoSection';
import { Row, Col, Container} from 'react-bootstrap';
import LanguageChooser from '../components/LanguageChooser';
import { withTranslation } from 'react-i18next';
import {CONTACT_EMAIL} from '../Constants.js';
import WDLSignInForm from './WDLSignInForm';

class WDLLogin extends PureComponent {


    render() {
        const { t } = this.props;

        return (
          <Container fluid={true}>
            <Row className="maxHeight">
                <Col sm={6} className="app_aside">
                  <WDLLogoSection></WDLLogoSection>
                </Col>
                <Col sm={6} className="wdl_app_form">
                    <div className="page_switcher">
                      <div>
                        <LanguageChooser></LanguageChooser>
                      </div>
                    </div>

                    <div className="form_title">
                        <h4>{t('Login_page.signin')}</h4>
                    </div>
                    <WDLSignInForm></WDLSignInForm>
                    <div className="contact-container">
                      <span>{CONTACT_EMAIL}</span>
                    </div>
                </Col>
              </Row>
              
            </Container>
        );
    }
}

export default withTranslation()(WDLLogin);
