import React, { PureComponent } from 'react';
import { Row, Col, Container, Button} from 'react-bootstrap';
import { WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { Link } from 'react-router-dom';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import WDLCompanyMenuItem from './WDLCompanyMenuItem';
import vectorInvoice from '../img/wdl/vector_invoice.png';
import clientInvoice from '../img/wdl/client_invoice.png';


import axios from 'axios';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { withTranslation } from 'react-i18next';

import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLInvoicesPage extends PureComponent {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.getItem(WDL_USER));
    var isAdmin = false;
    if(user.role === WDL_ROLE_ADMIN){
      isAdmin = true;
    }
    this.state = {
      companyId: this.props.match.params.companyId,
      user,
    }
    
}
  

    render() {
      const { t } = this.props;
        return (
              
          <div className="wdl-container">
            
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} link={'/logistic/company/'+this.state.companyId} title={!this.state.expired?'WDL.invoices':'WDL.expired_invoices'}></WDLBackButtonTitle>
              
              <Container fluid={true}>
                <Row className="justify-content-md-center">
                  <Col md={8}>
                  <Row className="wdl-menu-row" style={{marginTop: 70}}>
                    <Col>
                      <WDLCompanyMenuItem image={clientInvoice} text={t('WDL.invoice_clients')} color="#ba1b13" link={"/logistic/company/"+this.state.companyId+"/invoices/clients"}/>
                     </Col>
                    <Col>
                      <WDLCompanyMenuItem image={vectorInvoice} text={t('WDL.invoice_vectors')} color="#065870" link={"/logistic/company/"+this.state.companyId+"/invoices/vectors"}/>
                    </Col>
                  </Row>
                  </Col>
                  </Row>
              </Container>
            </div>
            
        );
    }
}

export default withTranslation()(WDLInvoicesPage);
