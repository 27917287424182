import React, { PureComponent } from 'react';
import axios from 'axios';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import WDLFileManager from './WDLFileManager';

import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN, WDL_COMPANY_NOTIFICATIONS} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal, Tab, Tabs } from 'react-bootstrap';


class WDLManageClient extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
        
        this.state = {
          companyId: this.props.match.params.companyId,
          clientId: this.props.match.params.clientId,
          client: null,
          user: user,
          isAdmin: isAdmin,
          nameValid: true,
          pecValid: true,
          emailValid: true,
          loading: false,
          formValid: false,
          errorMsg: {},
          successMessage: '',
          showDeleteModal: false,
          selectedTab: 'info',
          toSave: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getClient = this.getClient.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      if(this.state.clientId) {
        this.getClient();
      }
    }

    handleDateChange(date) {
      let client = {...this.state.client};
      if(date) {
        client.durcExpirationDateMillis = date.getTime();
      } else {
        client.durcExpirationDateMillis = null;
      }

      this.setState({client, toSave: true, successMessage: '', errorMsg: {}});
    }


    getClient(){
      const requestId = 'getClientRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true
      });
      
  
      let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client/'+this.state.clientId;
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let client = response.data;
          
          this.setState({
            client,
            loading: false,
            formValid: true,
          });
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg}, this.validateForm);

      }

      if(name==="email"){
        let emailValid = true;
        errorMsg.email = '';
        // checks for format _@_._
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
          emailValid = false;
          errorMsg.email = t('Errors.invalid_email');
        }

        this.setState({emailValid, errorMsg}, this.validateForm);
      }

    }





  validateForm = () => {
    const {nameValid, pecValid, emailValid} = this.state;
    this.setState({
      formValid: nameValid && pecValid && emailValid
    })
  }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let client = {...this.state.client};
        client[name]= value;
        this.setState({
          client,
          toSave: true,
          successMessage: '', 
          errorMsg: {}
        }, this.validateFields(name, value));


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = this.state.client;
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true, successMessage: ''});

      axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client', data, config)
      .then((response) => {
        if (this._isMounted) {
          localStorage.removeItem(WDL_COMPANY_NOTIFICATIONS);
          this.setState({client: response.data, clientId: response.data.clientId, successMessage: t('WDL.saved'), loading: false, toSave: false });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };
    
    handleDelete = () => {
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, successMessage: ''});
      axios.delete(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client/'+this.state.clientId, config)
      .then((response) => {
        if (this._isMounted) {
          localStorage.removeItem(WDL_COMPANY_NOTIFICATIONS);
          return window.location.href = '/logistic/company/'+this.state.companyId+'/vectors'; 
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg, showDeleteModal: false});
        }
      });
    };

    handleClose(){
      this.setState({showDeleteModal: false });
    }
  
    handleShowDeleteModal(){
      this.setState({showDeleteModal: true});
    }
  



    render() {
        const { t } = this.props;
        
        return (
          <div className="wdl-container">
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle toSave={this.state.toSave} history={this.props.history} title={'WDL.client'}></WDLBackButtonTitle>
              <Container fluid={true}>
                {
                (this.state.clientId && !this.state.client)? (
                  this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :
                  <div className="separator">
                    <Row className="justify-content-md-center">
                      <Col sm={12} className="text-center">
                        <span>{t('WDL.info_not_found')}</span>
                      </Col>
                    </Row>
                  </div>
                ):
                <div>
                  <Row className="justify-content-md-center">
                      <Col md={8} className="form_center">
                        <Tabs
                            id="vector-tabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(k) => this.setState({selectedTab: k})}
                            className="mb-3"
                          >
                          <Tab eventKey="info" title={t('WDL.info')}>
                          <Row>
                            <Col md={12}>
                              {this.state.loading ? 
                                <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                              :
                              <Form onSubmit={this.handleSubmit}>
                                <Form.Group className="form_field wdl-message-container">
                                  <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                                  <SuccessMessage valid={true} message={this.state.successMessage} />
                                </Form.Group>
                                <Form.Group controlId="name" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.name')}</Form.Label>
                                  <Form.Control type="text" className="form_field_input" name="name" value={this.state.client?.name || ''} onChange={this.handleChange}/>
                                  <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                                </Form.Group>
                                <Form.Group controlId="email" className="form_field">
                                  <Form.Label className="form_field_label">{'E-mail'}</Form.Label>
                                  <Form.Control type="text" plaintext  className="form_field_input" name="email" value={this.state.client?.email || ''} onChange={this.handleChange}/>
                                  <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                                </Form.Group>
                                <Form.Group controlId="pec" className="form_field">
                                  <Form.Label className="form_field_label">{'PEC'}</Form.Label>
                                  <Form.Control type="text" plaintext  className="form_field_input" name="pec" value={this.state.client?.pec || ''} onChange={this.handleChange}/>
                                  <ValidationMessage valid={this.state.pecValid} message={this.state.errorMsg.pec} />
                                </Form.Group>
                                <Form.Group controlId="phone" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.phone')}</Form.Label>
                                  <Form.Control type="text" plaintext  className="form_field_input" name="phone" value={this.state.client?.phone || ''} onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group controlId="pIva" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.p_iva')}</Form.Label>
                                  <Form.Control type="text" className="form_field_input" name="pIva" value={this.state.client?.pIva || ''} onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group controlId="fiscalCode" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.cf')}</Form.Label>
                                  <Form.Control type="text" className="form_field_input" name="fiscalCode" value={this.state.client?.fiscalCode || ''} onChange={this.handleChange}/>
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col} controlId="address" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.address')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="address" value={this.state.client?.address || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="city" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.city')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="city" value={this.state.client?.city || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  </Row>
                                  <Row>
                                    <Form.Group as={Col} controlId="cap" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.cap')}</Form.Label>
                                      <Form.Control type="text" className="form_field_input" name="cap" value={this.state.client?.cap || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="province" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.province')}</Form.Label>
                                      <Form.Control maxLength={2} type="text" className="form_field_input" name="province" value={this.state.client?.province || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="country" className="form_field">
                                      <Form.Label className="form_field_label">{t('WDL.country')}</Form.Label>
                                      <Form.Control maxLength={2} type="text" className="form_field_input" name="country" value={this.state.client?.country || ''} onChange={this.handleChange}/>
                                    </Form.Group>
                                  </Row>
                                <Form.Group controlId="sdCode" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.sdi')}</Form.Label>
                                  <Form.Control type="text" className="form_field_input" name="sdCode" value={this.state.client?.sdCode || ''} onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group controlId="paymentDays" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.payment_days')}</Form.Label>
                                  <Form.Control type="number" min="1" className="form_field_input" name="paymentDays" value={this.state.client?.paymentDays} onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Row>
                                  <Col className="text-center">
                                    <Button style={{backgroundColor: '#247b5d'}} variant="primary" type="submit" className="btn-login" disabled={!(this.state.formValid)}>{t('WDL.save')}</Button>
                                  </Col>
                                  {this.state.client?.clientId &&
                                  <Col className="text-center">
                                    <Button variant="secondary" type="button" onClick={this.handleShowDeleteModal} className="btn-login">{t('WDL.remove')}</Button>
                                  </Col>}
                                </Form.Row>
                                
                              </Form>
                              }
                            </Col>
                          </Row>
                        
                        </Tab>
                        <Tab eventKey="profile" title={t('WDL.documents')}>
                          {this.state.client?.clientId &&
                            <WDLFileManager user={this.state.user} apiUrl={'/logistic/company/'+this.state.companyId+'/client/'+this.state.client.clientId+'/uploads'}/>
                          }
                          </Tab>
                        </Tabs>
                      </Col>
                    </Row>
                  </div>
                  }
                  
              </Container>
              <Modal show={this.state.showDeleteModal} animation={false} onHide={this.handleClose}>
                <Modal.Header className="wdl"  closeButton>
                </Modal.Header>
                <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}}>
                    {t('WDL.confirm_delete_client')}
                </Modal.Body>
                <Modal.Footer className="wdl">
                  <Button variant="primary" onClick={this.handleDelete}>
                    {t('Common.confirm')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>




        );
    }
}

export default withTranslation()(WDLManageClient);
