import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Button} from 'react-bootstrap';
import dusty from '../img/dusty.svg';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';
import axios from 'axios';
import PlatePage from '../components/PlatePage';
import AttractionPage from '../components/AttractionPage';
import UserImage from './UserImage';
import UserProfile from '../components/UserProfile';
import NumberFormat from 'react-number-format';
import UsersLikesPage from '../components/UsersLikesPage';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'


class UserDiscovery extends PureComponent {

    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    constructor(props) {
        super(props);
        this.state = {
          user: this.props.user,
          discovery: this.props.discovery,
          showModalInfo: false,
          showLikesPage: false,
          showProfilePage: false,
          likePageNumber: 0
        }
        this.likeDiscovery = this.likeDiscovery.bind(this);
        this.showUsersLikesPage = this.showUsersLikesPage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showDiscoveryInfo = this.showDiscoveryInfo.bind(this);
    }

    showDiscoveryInfo = () => {
      this.setState({
        showModalInfo: true,
      });
    };

    handleClose(){
      this.setState({likePageNumber: 0,
        showProfilePage: false,
        showModalInfo: false,
        showLikesPage: false});
    }

    showUsersLikesPage(){
      let discovery = this.state.discovery;
      if(discovery.likes>0){
        this.setState({likePageNumber: 0,
          showLikesPage: true});
      }

    }

    showProfilePage(){

      this.setState({ showProfilePage: true,
          showLikesPage: false});
    }




  componentWillUnmount() {
      this._isMounted = false;
   }

   componentDidMount() {
     this._isMounted = true;
   }

   likeDiscovery() {
     let discovery = {...this.state.discovery};
     if(!discovery.liked && this.state.user && this.state.user.userId!==discovery.user.userId){
       let errorMsg = {...this.state.errorMsg}
       const { t } = this.props;

       var config = {
           headers: {
             'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
         }
       };


       this.setState({errorMsg: {},
         successMessage: ''
       });

       axios.post(API_BASE_URL+'/discovery/like', discovery, config)
       .then((response) => {
         if (this._isMounted) {
           discovery.liked = !discovery.liked;
           discovery.likes += 1;
           this.setState({discovery});
         }


       }, (error) => {
         if (this._isMounted) {
           errorMsg.dataError = t('Errors.generic_error');

           if (error.response && error.response.status) {
             let status = error.response.status;
             if(status===401){
              return window.location.href = '/logout';            
             }
           }else{
             if(error.message!=null && error.message==='Network Error'){
                 errorMsg.dataError = t("Errors.connection_error");
             }
           }
           this.setState({errorMsg});
           }
         });
     }



   }

   wishDiscovery() {
     let discovery = {...this.state.discovery};

     let errorMsg = {...this.state.errorMsg}
     const { t } = this.props;

     var config = {
         headers: {
           'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
       }
     };


     this.setState({errorMsg: {},
       successMessage: ''
     });
     let wishedUrl = discovery.wished? 'delete_wish' : 'wish';

     axios.post(API_BASE_URL+'/discovery/'+wishedUrl, discovery, config)
     .then((response) => {
       if (this._isMounted) {
        let discoveryResp = response.data;
         discovery.wished = discoveryResp.wished;
         this.setState({discovery});
       }


     }, (error) => {
       if (this._isMounted) {
         errorMsg.dataError = t('Errors.generic_error');

         if (error.response && error.response.status) {
           let status = error.response.status;
           if(status===401){
            return window.location.href = '/logout';            
          }
         }else{
           if(error.message!=null && error.message==='Network Error'){
               errorMsg.dataError = t("Errors.connection_error");
           }
         }
         this.setState({errorMsg});
         }
       });

   }


    render() {
      let discovery = this.state.discovery;
      if(discovery.isAdvertising){
        const { t } = this.props;
          return (
            <Container fluid={true} className="discovery-box">
              <Row>
                <Col md={12} className="discovery-text-container">
                  <Row className="row-link-profile">
                    <Col sm={12}>
                      <UserImage user={this.state.discovery.user} onClick={(e) => this.showProfilePage()}></UserImage>
                      <span className="link-icon user-item-list" onClick={(e) => this.showProfilePage()}>
                        {this.state.discovery.user.name+" "+(this.state.discovery.user.surname!==' '?this.state.discovery.user.surname+" ":"")}
                        {this.state.user.userId!==this.state.discovery.user.userId && this.state.discovery.user.isVoyager && (
                          <i className="fa fa-suitcase voyager-icon" aria-hidden="true"></i>
                        )}
                      </span>
  
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={12} className="discovery-text">
                      {this.state.discovery.advertising.text}
                    </Col>
                  </Row>
                  <Row className="discovery-info">
                    <Col sm={12} className="text-right">
                      <span className="no-pointer">
                        {t('Advertising.sponsorized')}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {
                this.state.discovery.advertising.image && (
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                        <Image src={this.state.discovery.image} className="center-block discovery-img" fluid/>
                    </Col>
                  </Row>
                )
              }
              <Row className="discovery-footer-container">
                  <Col md={12} className="text-right">
                  {
                    this.state.discovery.advertising.link != null &&
                    this.state.discovery.advertising.link !== '' && (
                    
                        <a target="_blank" className="btn btn-primary" rel="noopener noreferrer" href={this.state.discovery.advertising.link}>{t('Advertising.more_info')}</a>
                      
                    )
                  }
                </Col>
              </Row>
              {this.state.showProfilePage && (
                <UserProfile user={this.state.discovery.user} handleClose={this.handleClose}></UserProfile>
                )}
            </Container>
  
  
          );
      }else{
        var attractionName = "";
        if(discovery.isAttraction){
          if(discovery.attraction.info){
            attractionName=discovery.attraction.info.name;
          }else{
            attractionName=discovery.attraction.name;
          }
        }else{
  
          if(discovery.plate.info){
            attractionName=discovery.plate.info.name;
          }else{
            attractionName=discovery.plate.name;
          }
        }
  
        const { t } = this.props;
          return (
            <Container fluid={true} className="discovery-box">
              <Row>
                <Col md={12} className="discovery-text-container">
                  <Row className="row-link-profile">
                    <Col sm={12}>
                      <UserImage user={this.state.discovery.user} onClick={(e) => this.showProfilePage()}></UserImage>
                      <span className="link-icon user-item-list" onClick={(e) => this.showProfilePage()}>
                        {this.state.discovery.user.name+" "+this.state.discovery.user.surname+" "}
                        {this.state.user.userId!==this.state.discovery.user.userId && this.state.discovery.user.isVoyager && (
                          <i className="fa fa-suitcase voyager-icon" aria-hidden="true"></i>
                        )}
                      </span>
  
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="discovery-text">
                      {this.state.discovery.text}
                    </Col>
                  </Row>
                  <Row className="discovery-info">
                    <Col sm={12} className="text-right">
                      <span className="no-pointer">
                        {(this.state.discovery.city.info)?this.state.discovery.city.info.name+", ":this.state.discovery.city.name+", "}
                      </span>
                      <span onClick={(e) => this.showDiscoveryInfo()}>
                        {attractionName}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col md={12}>
                    <Image src={this.state.discovery.image} className="center-block discovery-img" fluid/>
                </Col>
              </Row>
              <Row className="discovery-text-container">
                <Col md={12} className="like-info-container">
                  <Image src={dusty} alt="Dusty" className={"like-info-img center-block "+(this.state.discovery.liked ? "" : "not-liked")} onClick={(e) => this.likeDiscovery()} fluid/>
                  <span onClick={(e) => this.showUsersLikesPage()} className="like-info">{(this.state.discovery.likes)?<NumberFormat value={this.state.discovery.likes} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />:0}</span>
                  {!this.state.discovery.discovered && (
                    <Button onClick={(e) => this.wishDiscovery()}  className={"float-right "+(this.state.discovery.wished?"btn-primary":"btn-secondary")}>
                      <i className="fa fa-bookmark button-icon" aria-hidden="true"></i>{(this.state.discovery.isAttraction)?t('Discovery.attraction_to_visit'):t('Discovery.plate_to_taste')}
                    </Button>
                  )}
                  {this.state.discovery.discovered && (
                    <span className="float-right wish-label"><i className="fa fa-check button-icon" aria-hidden="true"></i>{(this.state.discovery.isAttraction)?t('Discovery.visited_attraction'):t('Discovery.tasted_plate')}</span>
                  )}
                </Col>
              </Row>
              <Row className="discovery-footer-container">
                <Col md={12}>
                  <span className="visit-date">
                    {
                      format(
                        new Date(this.state.discovery.visitDate),
                        'd LLLL uuuu p',
                        {locale: this._locales[i18n.language]}
                      )
                    }
                  </span>
                </Col>
              </Row>
              {this.state.showLikesPage && (
                <UsersLikesPage discoveryId={(this.state.discovery.isAttraction? this.state.discovery.attraction.attractionId: this.state.discovery.plate.attractionId)} user={this.state.discovery.user} isAttraction={this.state.discovery.isAttraction} showModal={this.state.showLikesPage} handleClose={this.handleClose}></UsersLikesPage>
              )}
              {this.state.showProfilePage && (
                <UserProfile user={this.state.discovery.user} handleClose={this.handleClose}></UserProfile>
                )}
                {this.state.showModalInfo && !this.state.discovery.isAttraction && (
                  <PlatePage plate={this.state.discovery.plate} showModal={this.state.showModalInfo} handleClose={this.handleClose} showUserAttraction={false}></PlatePage>
                )}
                {this.state.showModalInfo && this.state.discovery.isAttraction && (
                  <AttractionPage attraction={this.state.discovery.attraction} showModal={this.state.showModalInfo} handleClose={this.handleClose}></AttractionPage>
                )}
            </Container>
  
  
          );
      }
      
    }
}

export default withTranslation()(UserDiscovery);
