import React, { PureComponent } from 'react';
import axios from 'axios';
import dustyGlasses from '../img/dusty_glasses.jpg';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import Loader from '../components/Loader';
import ChangePassword from './ChangePassword';
import BackButtonTitle from '../components/BackButtonTitle';

import { API_BASE_URL, ACCESS_TOKEN, USER, ROLE_ADVERTISER} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Image, Modal } from 'react-bootstrap';
import Resizer from 'react-image-file-resizer';


class ManageProfile extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        let image_profile = dustyGlasses;
        if(this.props.user.image){
          image_profile = this.props.user.image;
        }
        this.state = {
            name: this.props.user.name, nameValid: true,
            surname: this.props.user.surname, surnameValid: true,
            email: this.props.user.email,
            image: image_profile,
            imageData: null,
            fileName: '',
            loading: false,
            password: '', passwordValid: false,
            confirmpassword: '', confirmpasswordValid: false,
            formValid: true,
            errorMsg: {},
            successMessage: '',
            showChangePasswordPage: false,
        };
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showChangePasswordPage = this.showChangePasswordPage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
    }

    showChangePasswordPage(){
      this.setState({showChangePasswordPage: true});
    }

    handleClose(){
      this.setState({showChangePasswordPage: false});
    }

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg}, this.validateForm);

      }if(name==="surname"){
        let surnameValid = true;
        errorMsg.surname = '';
        if(value.startsWith(" ")) {
          surnameValid = false;
          errorMsg.surname = t('Errors.invalid_field')
        } else if (value.trim().length < 3) {
          surnameValid = false;
          errorMsg.surname = t('Errors.field_dimension', {number: 3});
        }

        this.setState({surnameValid, errorMsg}, this.validateForm);

      }

    }





  validateForm = () => {
    const {nameValid, surnameValid} = this.state;
    this.setState({
      formValid: nameValid && surnameValid
    })
  }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        }, this.validateFields(name, value));


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["name"]=this.state.name;
      data["surname"]=this.state.surname;
      data["email"]=this.state.email;
      if(this.state.fileName!==''){
        data["imageData"]=this.state.imageData;
      }

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true});

      axios.post(API_BASE_URL+'/user/saveProfile', data, config)
      .then((response) => {
        if (this._isMounted) {
          let image_profile = dustyGlasses;
          if(response.data.image){
            image_profile = response.data.image;
          }
          this.setState({image: image_profile, successMessage: t('Profile.saved'), loading: false });
          localStorage.setItem(USER, JSON.stringify(response.data));
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };

    handleUploadFile = (event) => {
      let target = event.target;
      let value =  target.value;

      this.setState({ successMessage: ''});
      this.setState({fileName: value});
      var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                600,
                600,
                'JPEG',
                100,
                0,
                uri => {
                  this.setState({
                    imageData: uri
                  });
                },
                'base64'
            );
        }
    };




    render() {
        const { t } = this.props;

        return (
          <Modal show={true} className="fake-page-modal" animation={false} onHide={this.props.handleClose}>
            <Modal.Body>
              <Container fluid={true} className="menu-container">
                {this.state.loading && (
                  <Loader></Loader>
                )}
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.manage_profile'}></BackButtonTitle>

                <Container fluid={true} className="tab-container">
                  <Row className="justify-content-md-center max-height">
                    <Col md={6} className="form_center">
                      <Row>
                        <Col md={12} className="text-center">
                            <Image src={(this.state.imageData)?this.state.imageData:this.state.image} className="profile-image"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="text-center hidden-filechooser">
                          <input type="file" id="image_profile" value={this.state.fileName} onChange={this.handleUploadFile} />
                          <label htmlFor="image_profile" title={t('Profile.upload_profile_image')}><i className="fa fa-camera fa-2x" aria-hidden="true"></i></label>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId="name" className="form_field">
                              <Form.Label className="form_field_label">{t('SignUpForm.name')}</Form.Label>
                              <Form.Control type="text" className="form_field_input" name="name" value={this.state.name} onChange={this.handleChange}/>
                              <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                            </Form.Group>
                            {
                              this.props.user.role !== ROLE_ADVERTISER && (
                                <Form.Group controlId="surname" className="form_field">
                                  <Form.Label className="form_field_label">{t('SignUpForm.surname')}</Form.Label>
                                  <Form.Control type="text" className="form_field_input" name="surname" value={this.state.surname} onChange={this.handleChange}/>
                                  <ValidationMessage valid={this.state.surnameValid} message={this.state.errorMsg.surname} />
                                </Form.Group>
                              )
                            }
                            
                            <Form.Group controlId="email" className="form_field">
                              <Form.Label className="form_field_label">{t('SignUpForm.email')}</Form.Label>
                              <Form.Control type="text" plaintext readOnly  className="form_field_input" name="email" value={this.state.email}/>
                            </Form.Group>
                            <Form.Group className="form_field">
                              <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                              <SuccessMessage valid={true} message={this.state.successMessage} />
                            </Form.Group>
                            <Form.Row>
                               <Col className="text-center">
                                <Button variant="primary" type="submit" className="btn-login" disabled={!(this.state.formValid)}>{t('Profile.save')}</Button>
                               </Col>
                             </Form.Row>
                             <Form.Row>
                                <Col className="text-center">
                                 <span onClick={(e) => this.showChangePasswordPage()} className="form_field_link">{t('Profile.change_password')}</span>
                                 </Col>
                              </Form.Row>
                           </Form>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {this.state.showChangePasswordPage && (
                    <ChangePassword user={this.props.user} handleClose={this.handleClose}></ChangePassword>
                  )}

                </Container>
              </Container>
            </Modal.Body>
          </Modal>




        );
    }
}

export default withTranslation()(ManageProfile);
