import React, { PureComponent } from 'react';
import axios from 'axios';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import WDLFileManager from './WDLFileManager';
import CurrencyInput from 'react-currency-input-field';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import WDLManageLoads from './WDLManageLoads.js';
import WDLManageUnloads from './WDLManageUnloads.js';
import i18n from 'i18next';
import WDLDownloadFileLink from './WDLDownloadFileLink.js';
import format from 'date-fns/format';

class WDLManageShipment extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
        
        const { t } = this.props;
        let shipmentStates = [
          {label: t('WDL.shipment_state_missing'), value: 0}, 
          {label: t('WDL.shipment_state_scanned'), value: 1},
          {label: t('WDL.shipment_state_send'), value: 2},
          {label: t('WDL.shipment_state_original'), value: 3},
        ];
        this.state = {
          companyId: this.props.match.params.companyId,
          shipmentId: this.props.match.params.shipmentId,
          shipment: {
            loads: [],
            unloads: [],
            shipDateMillis: new Date().getTime(),
          },
          user: user,
          isAdmin: isAdmin,
          vectors: [{label: t('Common.select'), value: null}],
          selectedVector: {label: t('Common.select'), value: null},
          clients: [{label: t('Common.select'), value: null}],
          selectedClient: {label: t('Common.select'), value: null},
          selectedState: shipmentStates[0],
          nameValid: true,
          loading: false,
          formValid: false,
          errorMsg: {},
          successMessage: '',
          showDeleteModal: false,
          selectedTab: 'info',
          copyShipmentId: null,
          toSave: false,
          contractName: null,
          shipmentStates,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getShipment = this.getShipment.bind(this);
        this.getCompanyInfo = this.getCompanyInfo.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);
        this.handleSelectVector = this.handleSelectVector.bind(this);
        this.handleSelectClient = this.handleSelectClient.bind(this);
        this.handleSelectState = this.handleSelectState.bind(this);
        this.handleDeleteLoad = this.handleDeleteLoad.bind(this);
        this.handleAddLoad = this.handleAddLoad.bind(this);
        this.handleDeleteUnload = this.handleDeleteUnload.bind(this);
        this.handleAddUnload = this.handleAddUnload.bind(this);
        this.handleMoneyChange = this.handleMoneyChange.bind(this);
        this.generateContractName = this.generateContractName.bind(this);
        this.tableLoadRef = React.createRef();
        this.tableUnloadRef = React.createRef();

    }

    

    handleAddLoad(load) {
      let shipment = {...this.state.shipment};
      if(load.index!=null) {
        let index = load.index;
        delete load.index;
        shipment.loads[index] = load;
      } else { 
        delete load.index;
        shipment.loads=shipment.loads.concat(load);
      }
      this.setState({shipment, toSave: true}, ()=>this.tableLoadRef.current.scrollIntoView());
    }

    handleDeleteLoad(index) {
      let shipment = {...this.state.shipment};
        
      if(index!=null && shipment.loads.length>=index) {
        shipment.loads.splice(index, 1);
        this.setState({shipment, toSave: true}, ()=>this.tableLoadRef.current.scrollIntoView());
      }
      
    }

    handleAddUnload(unload) {
      let shipment = {...this.state.shipment};
      if(unload.index!=null) {
        let index = unload.index;
        delete unload.index;
        shipment.unloads[index] = unload;
      } else { 
        delete unload.index;
        shipment.unloads=shipment.unloads.concat(unload);
      }
      this.setState({shipment, toSave: true}, ()=>this.tableUnloadRef.current.scrollIntoView());
    }

    handleDeleteUnload(index) {
      let shipment = {...this.state.shipment};
        
      if(index!=null && shipment.unloads.length>=index) {
        shipment.unloads.splice(index, 1);
        this.setState({shipment, toSave: true}, ()=>this.tableUnloadRef.current.scrollIntoView());
      }
      
    }

    handleSelectVector(item) {
      let shipment = {...this.state.shipment};
      shipment.vector = {
        vectorId: item.value,
        name: item.label
      };
      this.setState({selectedVector: item, shipment, toSave: true});
    }


    handleSelectClient(item) {
      let shipment = {...this.state.shipment};
      if(item?.value){
        shipment.client = {
          clientId: item.value,
          name: item.label
        };
      } else {
        shipment.client = null;
      } 
     
      this.setState({selectedClient: item, shipment, toSave: true});
    }

    handleSelectState(item) {
      let shipment = {...this.state.shipment};
      shipment.status = item.value;
      this.setState({selectedState: item, shipment, toSave: true});
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      
      this.getCompanyInfo();
    }


    getCompanyInfo() {
      const requestId = 'getCompanyRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true});
      
  
      let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/getAllInfo';
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let companyInfo = response.data;
          let vectors = this.state.vectors.concat(companyInfo.vectors.map((item) => ({label: item.name, value: item.vectorId})));
          let clients = this.state.clients.concat(companyInfo.clients.map((item) => ({label: item.name, value: item.clientId})));
          this.setState({
            vectors,
            clients,
            loading: !this.state.shipmentId?false:true
          },  this.state.shipmentId && this.getShipment());
          
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    

    
    handleDateChange(date) {
      let shipment = {...this.state.shipment};
      if(date) {
        shipment.shipDateMillis = date.getTime();
      } else {
        shipment.shipDateMillis = null;
      }

      this.setState({shipment, toSave: true, successMessage: '', errorMsg: {}});
    }


    getShipment(isCopy){
      const requestId = 'getShipmentRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true
      });

      let url;
      if(isCopy) {
        url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/number/'+this.state.copyShipmentId;
      
      } else {
        url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.shipmentId;
      }
  
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let shipment = response.data;
          if(!shipment.loads) {
            shipment.loads = [];
          }

          if(!shipment.loads) {
            shipment.loads = [];
          }

          if(isCopy) {
            shipment.shipmentId = null;
          }
          
          this.setState({
            shipment,
            selectedVector: shipment?.vector?{label: shipment.vector.name, value:shipment.vector.vectorId}:this.state.vectors[0],
            selectedClient: shipment?.client?{label: shipment.client.name, value:shipment.client.clientId}:this.state.clients[0],
            loading: false,
            formValid: true,
            shipmentId: shipment.shipmentId,
            selectedState: this.state.shipmentStates[shipment.status],
          }, ()=>this.generateContractName());
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg}, this.validateForm);

      }

      
    }





  validateForm = () => {
    const {nameValid} = this.state;
    this.setState({
      formValid: nameValid
    })
  }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let shipment = {...this.state.shipment};
        shipment[name]= value;
        if((name === 'epal' || name === 'ceph' || name === 'epalLost' || name === 'epalTicket' ) && value<0){
          shipment[name]= 0;
        } 
        this.setState({
          shipment,
          toSave: true,
          successMessage: '', errorMsg: {}
        }, this.validateFields(name, value));


    }

    handleMoneyChange(name, value) {

      let shipment = {...this.state.shipment};
      shipment[name]= value;
      this.setState({
        shipment});


  }

    generateContractName() {
      const { t } = this.props;
      let shipment = this.state.shipment;
      let contractName = "";
      
      if(shipment.vector!=null) {
        contractName += shipment.vector.name+"-";
      }
      contractName += (" N "+shipment.number)+"-";
      
      contractName +=  format(
        new Date(shipment.shipDateMillis),
        'dd-MM-yyyy',
        {locale: this._locales[i18n.language]}
      );

      contractName += ".pdf";

      this.setState({contractName});
    }


    handleSubmit = (e) => {
      e.preventDefault();
      var data = this.state.shipment;
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;
      if(data.payment && typeof data.payment !== 'number') {
        data.payment = Number(data.payment.replace('.', '').replace(',', '.'));
      }
      if(data.commission && typeof data.commission !== 'number') {
        data.commission = Number(data.commission.replace('.', '').replace(',', '.'));
      }
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      
      errorMsg.appError ='';
      this.setState({errorMsg, loading: true, successMessage: ''});
      axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment', data, config)
      .then((response) => {
        if (this._isMounted) {

          this.setState({shipment: response.data, shipmentId: response.data.shipmentId, successMessage: t('WDL.saved'), loading: false, toSave: false },()=> this.generateContractName());
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };
    
    handleDelete = () => {
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
      errorMsg.appError ='';
      this.setState({errorMsg, successMessage: ''});
      axios.delete(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.shipmentId, config)
      .then((response) => {
        if (this._isMounted) {
          return window.location.href = '/logistic/company/'+this.state.companyId+'/shipments'; 
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }

          }
          this.setState({errorMsg, showDeleteModal: false});
        }
      });
    };

    handleClose(){
      this.setState({showDeleteModal: false });
    }
  
    handleShowDeleteModal(){
      this.setState({showDeleteModal: true});
    }
  



    render() {
     
        const { t } = this.props;
        
        return (
          <div className="wdl-container">
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}/>
              <WDLBackButtonTitle history={this.props.history} title={'WDL.shipment'} toSave={this.state.toSave}/>
              <Container fluid={true}>
                {(this.state.shipmentId && !this.state.shipment)? (
                  this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :
                  <div className="separator">
                    <Row className="justify-content-md-center">
                      <Col sm={12} className="text-center">
                        <span>{t('WDL.info_not_found')}</span>
                      </Col>
                    </Row>
                  </div>
                ):
                <div>
                   <Row className="justify-content-md-center">
                      <Col md={8} className="form_center">
                        <Tabs
                            id="shipment-tabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(k) => this.setState({selectedTab: k})}
                            className="mb-3" 
                          >
                          <Tab eventKey="info" title={t('WDL.info')}>
                            { this.state.loading ? 
                              <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                            :
                  
                            <Row className="justify-content-md-center">
                              <Col md={12} className="form_center">
                                <Row className="justify-content-md-end">
                                  {this.state.shipment.shipmentId && this.state.shipment.number ?
                                    <Col md={12}>
                                      <span style={{float: 'right'}}>{': '+this.state.shipment.number}</span>
                                      <span style={{float: 'right', fontWeight: 'bold'}}>{t('WDL.number')}</span>
                                    </Col>
                                    :
                                    <Form inline>
                                      <Row>
                                        <Col>
                                          <Form.Control style={{marginLeft: 20}} type="number" placeholder={t('WDL.number')} onChange={(e)=>this.setState({copyShipmentId: e.target.value})}/>
                                        </Col>
                                        <Col>
                                        <Button variant="primary" type="button" onClick={()=>this.getShipment(true)}>
                                          {t('WDL.copy_shipment')}
                                        </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                    }
                                  
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <Form onSubmit={this.handleSubmit}>
                                      <Form.Group className="form_field wdl-message-container">
                                        <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                                        <SuccessMessage valid={true} message={this.state.successMessage} />
                                      </Form.Group>
                                      <Form.Group controlId="name" className="form_field">
                                        <Form.Label className="form_field_label">{t('WDL.name')}</Form.Label>
                                        <Form.Control type="text" className="form_field_input" name="name" value={this.state.shipment?.name || ''} onChange={this.handleChange}/>
                                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.name} />
                                      </Form.Group>
                                      <Row className="form_field">
                                        <Col>
                                          <Form.Label className="form_field_label">{t('WDL.commission')}</Form.Label>
                                          <CurrencyInput
                                            key={'commission_'+this.state.shipment.shipmentId}
                                            id="commission"
                                            name="commission"
                                            prefix="€"
                                            precision="2"
                                            allowNegativeValue={false}
                                            value={this.state.shipment?.commission || 0}
                                            decimalsLimit={2}
                                            onValueChange={(value)=>this.handleMoneyChange("commission", value)}
                                            className="form_field_input form-control-plaintext"
                                          />
                                        </Col>
                                        <Col>
                                          <Form.Label className="form_field_label">{t('WDL.payment')}</Form.Label>
                                          <CurrencyInput
                                            key={'payment_'+this.state.shipment.shipmentId}
                                            id="payment"
                                            name="payment"
                                            precision="2"
                                            prefix="€"
                                            allowNegativeValue={false}
                                            decimalsLimit={2}
                                            value={this.state.shipment?.payment || 0}
                                            onValueChange={(value)=>this.handleMoneyChange("payment", value)}
                                            className="form_field_input form-control-plaintext"
                                          />
                                        </Col>
                                      </Row>
                                      <Form.Group className="form_field">
                                        <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                                        <DatePicker
                                          locale={this._locales[i18n.language]}
                                          onChange={this.handleDateChange}
                                          selected={new Date(this.state.shipment?.shipDateMillis)}
                                          dateFormat="dd/MM/yyyy"
                                        />
                                      </Form.Group>
                                      <Form.Group controlId="client" className="form_field">
                                        <Form.Label className="form_field_label">{t('WDL.client')}</Form.Label>
                                        <Select
                                          value={this.state.selectedClient}
                                          onChange={this.handleSelectClient}
                                          options={this.state.clients}
                                          placeholder={t('Common.select')}
                                        />                        
                                      </Form.Group>
                                      <Form.Group controlId="vector" className="form_field">
                                        <Form.Label className="form_field_label">{t('WDL.vector')}</Form.Label>
                                        <Select
                                          value={this.state.selectedVector || ''}
                                          onChange={this.handleSelectVector}
                                          options={this.state.vectors}
                                          placeholder={t('Common.select')}
                                        />                        
                                      </Form.Group>
                                      <Row ref={this.tableLoadRef}>
                                        <Col>
                                        <WDLManageLoads
                                          shipment={this.state.shipment}
                                          handleAddLoad={this.handleAddLoad}
                                          handleDelete={this.handleDeleteLoad}
                                          scrollUp={()=>{this.tableLoadRef.current.scrollIntoView()}}
                                        />
                                        </Col>
                                      </Row>
                                      <Row ref={this.tableUnloadRef}>
                                        <Col>
                                        <WDLManageUnloads 
                                          shipment={this.state.shipment}
                                          handleAddUnload={this.handleAddUnload}
                                          handleDelete={this.handleDeleteUnload}
                                          scrollUp={()=>{this.tableUnloadRef.current.scrollIntoView()}}
                                        />
                                        </Col>
                                      </Row>
                                      <Form.Group controlId="notes" className="form_field">
                                        <Form.Label className="form_field_label">{t('WDL.notes')}</Form.Label>
                                        <textarea
                                          className="form-control"
                                          id="notes"
                                          name="notes"
                                          rows="5"
                                          value={this.state.shipment.notes || ''}
                                          onChange={this.handleChange}
                                          />
                                      </Form.Group>
                                      <Row className="form_field">
                                        <Col>
                                          <Form.Group controlId="epal" className="form_field">
                                            <Form.Label className="form_field_label">{t('WDL.epal')}</Form.Label>
                                            <Form.Control type="number" min="0" className="form_field_input" name="epal" value={Number(this.state.shipment.epal|0).toString()} onChange={this.handleChange}/>
                                          </Form.Group>
                                        </Col>
                                        <Col>
                                          <Form.Group controlId="chep" className="form_field">
                                            <Form.Label className="form_field_label">{t('WDL.chep')}</Form.Label>
                                            <Form.Control type="number" min="0" className="form_field_input" name="chep" value={Number(this.state.shipment.chep|0).toString()} onChange={this.handleChange}/>
                                          </Form.Group>
                                        </Col>
                                        <Col>
                                          <Form.Group controlId="epalLost" className="form_field">
                                            <Form.Label className="form_field_label">{t('WDL.epal_lost')}</Form.Label>
                                            <Form.Control type="number" min="0" className="form_field_input" name="epalLost" value={Number(this.state.shipment.epalLost|0).toString()} onChange={this.handleChange}/>
                                          </Form.Group>
                                        </Col>
                                        <Col>
                                          <Form.Group controlId="epalTicket" className="form_field">
                                            <Form.Label className="form_field_label">{t('WDL.epal_tickets')}</Form.Label>
                                            <Form.Control type="number" min="0" className="form_field_input" name="epalTicket" value={Number(this.state.shipment.epalTicket|0).toString()} onChange={this.handleChange}/>
                                          </Form.Group>
                                        </Col>
                                        
                                      </Row>
                                    
                                      <Form.Group controlId="state" className="form_field" style={{marginBottom: 100}}>
                                        <Form.Label className="form_field_label">{t('WDL.shipment_state')}</Form.Label>
                                        <Select
                                          value={this.state.selectedState}
                                          onChange={this.handleSelectState}
                                          options={this.state.shipmentStates}
                                          placeholder={t('Common.select')}
                                        />                        
                                      </Form.Group>
                                      <Form.Row>
                                        <Col className="text-center">
                                          <Button style={{backgroundColor: '#486cb5'}} variant="primary" type="submit" className="btn-login" disabled={!(this.state.formValid)}>{t('WDL.save')}</Button>
                                        </Col>
                                        {this.state.shipment?.shipmentId &&
                                        <Col className="text-center">
                                          <Button variant="secondary" type="button" onClick={this.handleShowDeleteModal} className="btn-login">{t('WDL.remove')}</Button>
                                        </Col>}
                                      </Form.Row>
                                      
                                    </Form>
                                  </Col>
                                </Row>
                                </Col>
                              </Row>
                              }
                              </Tab>
                              {this.state.shipment?.shipmentId &&
                              <Tab eventKey="profile" title={t('WDL.documents')}>
                                <WDLDownloadFileLink type={"pdf"} filename={this.state.contractName} link={API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.shipmentId+'/contract?lang='+i18n.language} name={t('WDL.transport_contract')} />
                                <WDLFileManager user={this.state.user} apiUrl={'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.shipment.shipmentId+'/uploads'}/>
                              </Tab>
                            }
                          </Tabs>
                            </Col>
                          </Row>
                          
                        </div>
                  }
                  
              </Container>
              <Modal show={this.state.showDeleteModal} animation={false} onHide={this.handleClose}>
                <Modal.Header className="wdl" closeButton>
                </Modal.Header>
                <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}}>
                    {t('WDL.confirm_delete_shipment')}
                </Modal.Body>
                <Modal.Footer className="wdl">
                  <Button variant="primary" onClick={this.handleDelete}>
                    {t('Common.confirm')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>




        );
    }
}

export default withTranslation()(WDLManageShipment);
