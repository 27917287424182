import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';

import { API_BASE_URL} from '../Constants.js';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

import { withTranslation } from 'react-i18next';


class ForgotPasswordForm extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            email: '', emailValid: false,
            formValid: false,
            errorMsg: {},
            successMessage: '',
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }


    validateFields = (name, value) => {
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      errorMsg.appError = '';

      if(name==="email"){
        let emailValid = true;
        errorMsg.email = '';
        if (value.length < 3) {
          emailValid = false;
          errorMsg.email = t('Errors.field_dimension', {number: 3})
        }

        this.setState({emailValid, errorMsg}, this.validateForm);
      }

    }


  validateForm = () => {
    const {emailValid} = this.state;
    this.setState({
      formValid: emailValid
    })
  }


    handleChange(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        },   this.validateFields(name, value));

    }

    handleSubmit(e) {
        e.preventDefault();
        let errorMsg = {};
        const { t } = this.props;
        var data = {};
        data["email"] = this.state.email;

        this.setState({errorMsg, successMessage:'',
          loading: true
        });

        axios.post(API_BASE_URL+'/auth/forgot_password', data)
        .then((response) => {
          if (this._isMounted) {
            this.setState({ successMessage: t('Messages.forgot_password_link'),
            loading: false});
          }
        }, (error) => {
          if (this._isMounted) {
            errorMsg.appError = t('Errors.generic_error');
            if (error.response && error.response.status) {
              let status = error.response.status;
              if(status===401){
                return window.location.href = '/logout';            
              }

            }
            this.setState({errorMsg, loading: false});
          }
        });

    }

    render() {

      const { t } = this.props;

      return (
         <Container fluid={true}>
           {this.state.loading && (
             <Loader></Loader>
           )}

           <Row>
             <Col sm={12} className="form_center">
               <Form onSubmit={this.handleSubmit}>
                 <Form.Group controlId="email" className="form_field">
                   <Form.Label className="form_field_label">{t('SignInForm.email')}</Form.Label>
                   <Form.Control type="text" className="form_field_input" placeholder={t('SignInForm.email_ph')} name="email" value={this.state.email} onChange={this.handleChange}  />
                   <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                 </Form.Group>
                 <Form.Group className="form_field">
                   <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                   <SuccessMessage valid={true} message={this.state.successMessage} />
                 </Form.Group>
                 <Form.Row>
                    <Col sm={12} className="text-center" style={{ marginTop: 10 }}>
                      <Button variant="primary" type="submit" className="btn-login" disabled={!this.state.formValid}>{t('Common.confirm')}</Button>
                    </Col>
                  </Form.Row>
                  <Form.Row style={{ marginTop: 40 }}>
                     <Col sm={12} className="text-center">
                      <Link to="/loginDiscover" className="form_field_link">{t('Login_page.signin')}</Link>
                     </Col>
                   </Form.Row>
                </Form>
              </Col>
           </Row>
         </Container>

        );

    }
}

export default withTranslation()(ForgotPasswordForm);
