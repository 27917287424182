import React, { PureComponent } from 'react'
import { Row, Col, Button, ListGroup, Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import HtmlCityPage from '../components/HtmlCityPage';

class AttractionsList extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);

      let cityId = null;
      if(this.props.cityId){
        cityId = this.props.cityId;
      }

      this.state = {
        cityId: cityId,
        position: this.props.position,
        isAdmin: this.props.isAdmin,
        languages: [],
        errorMsg: {},
        successMessage: '',
        showHtmlPage:false
      }

      this.showAttrInfo = this.showAttrInfo.bind(this);
      this.handleAddAttraction = this.handleAddAttraction.bind(this);
      this.handleShowHtmlPage = this.handleShowHtmlPage.bind(this);
      this.handleClose = this.handleClose.bind(this);

  }

  componentWillUnmount(){
      this._isMounted = false;
  }


  componentDidMount() {
    this._isMounted = true;
  }

  handleClose() {
    this.setState({showHtmlPage: false});
  }


  handleAddAttraction(){
    var newMarker = {
      position: [],
      latitude: '',
      longitude: '',
      name: "",
      attractionId: "",
      level: 1,
      date: '',
      image: '',
      nameInfo: '',
      nameInfoValid: false,
      description: '',
      languageId: '',
      linkInfo: ''
    };

    this.props.handleShowAttractionModal(newMarker, true);
      
  }

  handleShowHtmlPage() {
    this.setState({showHtmlPage: true});
  }


  showAttrInfo = (attraction) => {


      if(this.props.adminMode){
        this.setState({
          successMessage: '',
          errorMsg: {}
         }, this.props.handleShowAttractionModal(attraction, true));
      }else{
        this.setState({
          successMessage: '',
          errorMsg: {}
         }, this.props.handleShowAttractionModal(attraction, false));
      }


  }


  render() {
    const { t } = this.props;
    return (
      <div className="tab-content-container">
        <div className="tab-content-menu justify-content-md-center text-center">
            <span style={{ borderBottom: 'solid 2px ' + this.props.cityColor }}  className="city-title" onClick={(e) => this.props.showCityInfo()}>{(this.props.cityInfo)?this.props.cityInfo.cityName:""}</span>
        </div>
        <div className="tab-content-body" style={{ height: this.props.height }}>
          <ListGroup className="max-height-container">
            {this.props.attractions.length===0 && (
              <div className="separator">
                <Row className="justify-content-md-center">
                  <Col sm={12} className="text-center">
                    <span>{t('Attractions.not_found')}</span>
                  </Col>
                </Row>
              </div>
            )}
          {this.props.attractions && (this.props.attractions.map(attraction =>
            <ListGroup.Item key={attraction.attractionId}>
                <div className="row-link-container"  onClick={(e) => this.showAttrInfo(attraction)}>
                  {
                    !attraction.userAttraction ? (
                      <Image fluid={true} src={attraction.imageIcon} className={"list-img marker-lev-"+attraction.level+" locked-attraction"}/>
                    ) : (
                      <Image fluid={true} src={attraction.imageIcon} className={"list-img marker-lev-"+attraction.level+""}/>
                    )
                  }
                  {
                    !attraction.userAttraction ? (
                      <span className="link-icon locked-text">{(attraction.nameInfo)?attraction.nameInfo: attraction.name} <i className={"fa fa-check button-icon wished-check "+(!attraction.wished ? "hidden" : "")} aria-hidden="true"></i></span>
                    ) : (
                      <span className="link-icon unlocked-text">{(attraction.nameInfo)?attraction.nameInfo: attraction.name}</span>
                    ) 
                  }
                </div>
              </ListGroup.Item>
            ))}
            
            </ListGroup>
            {this.state.isAdmin && this.props.cityInfo && this.props.cityInfo.cityId && this.props.adminMode && (
              <>
                <Button variant={'primary'} type="button" onClick={(e) => this.handleAddAttraction()} className="add-button">
                  <i className="fa fa-plus fa-2x" aria-hidden="true"></i>
                </Button>
                <Button variant={'secondary'} type="button" onClick={(e) => this.handleShowHtmlPage()} className="html-button">
                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
              </Button>
              </>
            )}
          </div>
          {this.state.showHtmlPage && <HtmlCityPage cityId={this.state.cityId} city={this.props.cityInfo} handleClose={this.handleClose}/>}
      </div>


    )
  }
}

export default withTranslation()(AttractionsList);
