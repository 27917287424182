import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Modal, Button} from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';
import dusty from '../img/dusty.svg';
import { API_BASE_URL, ACCESS_TOKEN } from '../Constants.js';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import UsersLikesPage from '../components/UsersLikesPage';
import i18n from 'i18next';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale';

class PlatePage extends PureComponent {

    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    constructor(props) {
        super(props);
        this.state = {
          plate: this.props.plate,
          showDescription: false,
          showLikesPage: false,
          likePageNumber: 0

        }
        this.handleShowDescription = this.handleShowDescription.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showUsersLikesPage = this.showUsersLikesPage.bind(this);
        this.wishPlate = this.wishPlate.bind(this);
        this.getPlateData = this.getPlateData.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

     componentDidMount() {
       this._isMounted = true;
       this.getPlateData();
     }


     getPlateData() {

      const { t } = this.props;
      var config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
      };
  
      let errorMsg = {};
  
      this.setState({errorMsg});
  
      axios
        .get(
          API_BASE_URL +
            '/plate/getOne/' +
            this.state.plate.attractionId +
            '?lang=' +
            i18n.language,
          config,
        )
        .then(
          response => {
            if (this._isMounted) {
              let plate = response.data;
              plate['nameInfo'] = plate.info ? plate.info.name : '';
              plate['description'] = plate.info ? plate.info.description : '';
              plate['languageId'] = plate.info ? plate.info.languageId : '';
              plate['linkInfo'] = plate.info ? plate.info.linkInfo : '';
              plate['userAttraction'] = plate.userAttraction ? plate.userAttraction : '';
  
              this.setState({plate});
            }
          },
          error => {
            if (this._isMounted) {
              errorMsg.dataError = t('Errors.generic_error');

              if (error.response && error.response.status) {
                let status = error.response.status;
                if(status===401){
                 return window.location.href = '/logout';            
               }
              }else{
                if(error.message!=null && error.message==='Network Error'){
                    errorMsg.dataError = t("Errors.connection_error");
                }
              }
              this.setState({errorMsg});
              
            }
          },
        );
    }

    handleShowDescription(){
      var showDescription = !this.state.showDescription;
      this.setState({showDescription});
    }

    handleClose(){
      this.setState({likePageNumber: 0,
        showLikesPage: false});
    }

    showUsersLikesPage(){
      let plate = this.state.plate;
      if(plate.likes>0){
        this.setState({likePageNumber: 0,
          showLikesPage: true});
      }
    }

    wishPlate() {
     let plate = {...this.state.plate};

     let errorMsg = {...this.state.errorMsg}
     const { t } = this.props;

     var config = {
         headers: {
           'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
       }
     };


     this.setState({errorMsg: {},
       successMessage: ''
     });

     var data = {};
     data['user']=this.props.user;
     data['plate']=plate;
     data['isAttraction']=false;
     data['wished']=plate.wished;
     data.plate.userAttraction=null;
     let wishedUrl = plate.wished? 'delete_wish' : 'wish';

     axios.post(API_BASE_URL+'/discovery/'+wishedUrl, data, config)
     .then((response) => {
       if (this._isMounted) {
        let discovery = response.data;
        plate.wished = discovery.wished;
        this.setState({plate});
       }
     }, (error) => {
       if (this._isMounted) {
         errorMsg.dataError = t('Errors.generic_error');

         if (error.response && error.response.status) {
           let status = error.response.status;
           if(status===401){
            return window.location.href = '/logout';            
          }
         }else{
           if(error.message!=null && error.message==='Network Error'){
               errorMsg.dataError = t("Errors.connection_error");
           }
         }
         this.setState({errorMsg});
         }
       });

   }


    render() {
      const { t } = this.props;
        return (
          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="attraction-title">
                  {(this.state.plate.nameInfo)?this.state.plate.nameInfo: this.state.plate.name}
                  <span className="raiting-number">(<NumberFormat value={this.state.plate.visitors} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />)</span>
                  <div className="attraction-rank">
                    <StarRatingComponent
                      name="rate1"
                      starCount={5}
                      value={this.state.plate.votes}
                      editing={false}
                    />

                  </div>
                </div>
                <div className="attraction-info-container">
                  <div className="attraction-date">
                    {this.state.plate.city.info ? this.state.plate.city.info.name: this.state.plate.city.name}
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>

                  {!this.state.showDescription && (
                    <Row>
                      <Col md={12} className="text-center">
                          <Image fluid={true} src={(this.state.plate.userAttraction)?this.state.plate.userAttraction.image:this.state.plate.image} className={"plate-image"}/>
                      </Col>
                    </Row>
                  )}
                  {!this.state.showDescription && this.state.plate.userAttraction && (
                  <Row>
                    <Col md={12}>
                        <Image src={dusty} alt="Dusty" className="like-info-img center-block" fluid/>
                        <span onClick={(e) => this.showUsersLikesPage()} className="like-info">
                          <NumberFormat value={this.state.plate.likes} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                        </span>
                        <span className="float-right wish-label"><i className="fa fa-check button-icon" aria-hidden="true"></i>{t('Discovery.tasted_plate')}</span>
                    </Col>
                  </Row>
                  )}
                  {!this.state.showDescription && !this.state.plate.userAttraction && (

                  <Row>
                    <Col md={12} className="wish-button-container">
                      {this.state.plate.info && this.state.plate.info.linkInfo && (
                        <a className="btn btn-primary float-left" target="_blank" rel="noopener noreferrer" href={this.state.plate.info.linkInfo}>{(this.state.plate.info.linkInfo)?t('Plate.search_restaurant'):''}</a>
                      )}

                        <Button onClick={(e) => this.wishPlate()}  className={"float-right "+(this.state.plate.wished?"btn-primary":"btn-secondary")}>
                          <i className="fa fa-bookmark button-icon" aria-hidden="true"></i>{t('Discovery.plate_to_taste')}
                        </Button>
                    </Col>
                  </Row>
                )}
                {!this.state.showDescription && this.state.plate.userAttraction && (
                  <Row className="plate-text-container">
                    <Col md={12}>
                      <span className="visit-date">
                        {
                          format(
                            new Date(this.state.plate.userAttraction.visitDate),
                            'd LLLL uuuu p',
                            {locale: this._locales[i18n.language]}
                          )
                        }
                      </span>
                    </Col>
                  </Row>
                )}
                  {this.state.showDescription && (
                    <Row>
                      <Col md={12}>
                        <p className="plate-description">
                          {this.state.plate.info.description}
                        </p>
                      </Col>
                    </Row>
                    )}
                </Container>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <Row>
                  
                  <Col md={12}>
                    {this.state.plate.info && this.state.plate.info.description && (
                      <Button variant={(this.state.showDescription ? 'primary' : 'secondary')} onClick={this.handleShowDescription} className="float-right">
                        <i className="fa fa-info fa-2x" aria-hidden="true"></i>
                      </Button>
                    )}
                  </Col>

                </Row>
                {this.state.showLikesPage && (
                  <UsersLikesPage discoveryId={this.state.plate.attractionId} isAttraction={false} user={this.props.user} showModal={this.state.showLikesPage} handleClose={this.handleClose}></UsersLikesPage>
                )}
              </Container>

            </Modal.Footer>
          </Modal>


        );
    }
}

export default withTranslation()(PlatePage);
