import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';

import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserImage from './UserImage';
import UserProfile from './UserProfile';

import { withTranslation } from 'react-i18next';

class UsersLikesPage extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        user: this.props.user,
        discoveryId: this.props.discoveryId,
        likePageNumber: 0,
        hasMore: false,
        selected: null,
        usersLikes: []

      }
      this.getUserslikes = this.getUserslikes.bind(this);
      this.showUserInfo = this.showUserInfo.bind(this);
      this.handleClose = this.handleClose.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getUserslikes();
  }

  showUserInfo(user){
      this.setState({selected: user,
        showModal: true});
  }

  handleClose(){
      this.setState({selected: null,
        showModal: false});
  }

  
getUserslikes(){
    let discoveryId = this.state.discoveryId;
    var parameters="getUsersAttractionLikes?userAttractionId="+this.state.user.userId+"&attractionId="+discoveryId+"&page="+this.state.likePageNumber;

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}

    errorMsg.dataError ='';
    this.setState({errorMsg,
      hasMore: true
    });

    axios.get(API_BASE_URL+'/discovery/'+parameters, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newUsersLikes = response.data;
        if((newUsersLikes && newUsersLikes.length===0) || (newUsersLikes && newUsersLikes[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          likePageNumber: this.state.likePageNumber+1,
          usersLikes: this.state.usersLikes.concat(newUsersLikes)
        });
      }

    }, (error) => {
      if (this._isMounted) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
        return (

          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} className="user-like-page" centered>
            <Modal.Header className="white-background" closeButton>
            </Modal.Header>
            <Modal.Body>
              <Container fluid={true}>
                <InfiniteScroll
                  dataLength={this.state.usersLikes.length}
                  next={this.getUserslikes}
                  hasMore={this.state.hasMore}
                  height={200}
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true}>
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.usersLikes.map((userLike, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.showUserInfo(userLike)}>
                              <UserImage user={userLike} className="list-img"></UserImage>
                              <span className="link-icon">{userLike.name+' '+userLike.surname}</span>
                                {this.state.user.userId!==userLike.userId && userLike.isVoyager && (
                                  <i className="fa fa-suitcase voyager-icon" aria-hidden="true"></i>
                                )}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                </Container>
                </InfiniteScroll>
                {this.state.showModal && (
                  <UserProfile user={this.state.selected} handleClose={this.handleClose}></UserProfile>
                )}
              </Container>
            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(UsersLikesPage);
