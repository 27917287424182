import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal} from 'react-bootstrap';

import { withTranslation } from 'react-i18next';

class LevelPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            level: this.props.level
        };

    }


    render() {
      const { t } = this.props;
        return (
          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="attraction-title">
                  {t("Profile.level")}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="app_aside">
              <Container>
                <Row className="justify-content-md-center">
                  <Col md={12} className="text-center">
                    <span className={"user-text-level-"+this.state.level.levelId}>{t('Level.'+this.state.level.levelId)}</span>
                  </Col>
                </Row>

                </Container>
            </Modal.Body>

          </Modal>


        );
    }
}

export default withTranslation()(LevelPage);
