import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Table, Button, Tabs, Tab, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN, WDL_COMPANY_NOTIFICATIONS, WDL_DEFAULT_IVA} from '../Constants.js';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import DatePicker from "react-datepicker";
import moment from 'moment';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';

import subMonths from 'date-fns/subMonths';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import WDLDownloadFileLink from './WDLDownloadFileLink';

import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLManageInvoice extends PureComponent {

  _isMounted = false;

  _locales = {
    en: enUS,
    fr: fr,
    it: it,
    es: es
  }

  _moment_locales = {
    en: 'en-gb',
    fr: 'fr',
    it: 'it',
    es: 'es'
  }

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }

      
      const { t } = this.props;
      let momentLocale = moment().locale(this._moment_locales[i18n.language]).localeData();
      let locale = {
        daysOfWeek: momentLocale._weekdaysShort,
        monthNames: momentLocale._monthsShort,
      };

      const search = props.location.search;
      const startDateMillis = new URLSearchParams(search).get('startDate');
      const endDateMillis = new URLSearchParams(search).get('endDate');
      const expired = new URLSearchParams(search).get('expired');
      let startDate = null;
      let endDate = null;
      if(startDateMillis) {
        startDate = new Date(Number(startDateMillis));
      } else {
        startDate = startOfMonth(subMonths(new Date(), 1));
      }

      if(endDateMillis) {
        endDate = new Date(Number(endDateMillis));
      } else {
        endDate = endOfMonth(subMonths(new Date(), 1))
      }
        
      this.state = {
        companyId: this.props.match.params.companyId,
        clientId: this.props.match.params.clientId,
        invoiceId: this.props.match.params.invoiceId,
        isFromInvoicePage: this.props.match.params.invoiceId?true:false,
        user: user,
        isAdmin: isAdmin,
        query: '',
        hasMore: false,
        shipments: [],
        selectedShipments: [],
        startDate,
        endDate,
        locale,
        totals: {
          numbers: 0,
          payment: 0,
          commission: 0,
          iva: 0,
          epal: 0,
          chep: 0,
        },
        client: null,
        selectedTab: 'info',
        invoice: {
          invoiceDateMillis: new Date().getTime(),
          iva: WDL_DEFAULT_IVA,
        },
        errorMsg: {},
        successMessage: '',
        showDeleteModal: false,
        loadingTable: false,
        viewHeight: window.innerHeight,
        toSave: false,
        contractName: null,
        expired,
      }
      this.getShipments = this.getShipments.bind(this);
      this.getClient = this.getClient.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSelectAll = this.handleSelectAll.bind(this);
      this.handleSelectShipment = this.handleSelectShipment.bind(this);
      this.handleUnselectShipment = this.handleUnselectShipment.bind(this);
      this.calculateTotals = this.calculateTotals.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.getInvoice = this.getInvoice.bind(this);
      this.handleAddShipmentNotes = this.handleAddShipmentNotes.bind(this);
      this.generateContractName = this.generateContractName.bind(this);
      this.handleMoneyChange = this.handleMoneyChange.bind(this);

  }

  handleMoneyChange(name, value) {

    let invoice = {...this.state.invoice};
    invoice[name]= value;
    this.setState({
      invoice,
      toSave: true});


}

  generateContractName() {
    const { t } = this.props;
    let invoice = this.state.invoice;
    let contractName = invoice.number+"-";
    
    if(this.state.client!=null) {
      contractName += this.state.client.name+"-";
    }
    
    contractName +=  format(
      new Date(invoice.invoiceDateMillis),
      'dd-MM-yyyy',
      {locale: this._locales[i18n.language]}
    );

    this.setState({contractName});
  }

  calculateTotals(generateContract) {
    let totals = {
      numbers: this.state.selectedShipments.length,
      commission: 0,
    }

    for(let i=0; i<this.state.selectedShipments.length; i++) {
      totals.commission+=this.state.selectedShipments[i].commission;
    }
    let iva = this.state.invoice.iva!=null?this.state.invoice.iva:WDL_DEFAULT_IVA;
    totals.iva = totals.commission*iva/100;
    this.setState({totals}, ()=>generateContract && this.generateContractName());
  }

  handleSelectAll() {
    let selectedShipments = [];
    let shipments = [];
    if(!this.state.selectAll){
      selectedShipments = [...new Map(this.state.selectedShipments.concat(this.state.shipments).map(item => [item["shipmentId"], item])).values()];
    } else {
      shipments = [...new Map(this.state.shipments.concat(this.state.selectedShipments).map(item => [item["shipmentId"], item])).values()];
    }
    this.setState({
      shipments,
      selectedShipments,
      selectAll: !this.state.selectAll,
      toSave: true
    }, ()=>this.calculateTotals());
  }

  handleSelectShipment(index) {
    if(this.state.shipments.length>index); {
        this.setState({
          selectedShipments: this.state.selectedShipments.concat(this.state.shipments[index]),
          shipments: this.state.shipments.filter(item=>item.shipmentId!=this.state.shipments[index].shipmentId),
          toSave: true,
          selectAll: false,
        }, this.calculateTotals)
    }
  }

  handleUnselectShipment(index) {
    if(this.state.selectedShipments.length>index) {
        this.setState({
          shipments: this.state.shipments.concat(this.state.selectedShipments[index]),
          selectedShipments: this.state.selectedShipments.filter(item=>item.shipmentId!=this.state.selectedShipments[index].shipmentId),
          toSave: true,
          selectAll: false,
        }, this.calculateTotals)
    }
  }

  handleAddShipmentNotes(index, value) {
    let selectedShipments = [...this.state.selectedShipments];
    if(selectedShipments.length>index) {
      selectedShipments[index].invoiceNotes = value;
      this.setState({selectedShipments, toSave: true});
    }
  }

  handleChangeDateRange(start, end) {
    this.setState({startDate: moment(start).toDate(), endDate: moment(end).toDate()})
  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      toSave: true,
      successMessage: '', 
      errorMsg: {}
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if(this.state.invoiceId) {
      this.getInvoice();
    } else {
      this.getClient();
    }
    
  }

  getInvoice(){
    const requestId = 'getInvoiceRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/invoice/'+this.state.invoiceId;

    

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };

    this.setState({errorMsg, loading: true
    });
    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        
        let invoice = response.data;
        this.setState({
          invoice,
          client: invoice.client,
          clientId: invoice.client.clientId,
          selectedShipments: invoice.shipments,
          loading: false,
        }, ()=>this.calculateTotals(true));
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false, loading: false,});
      }
    });
  }

  

  getClient(){
    const requestId = 'getClientRequest';
    axios.cancel(requestId);
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client/'+this.state.clientId;

    

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };

    this.setState({errorMsg, loading: true
    });
    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        

        this.setState({
          client: response.data,
          loading: false,
        }, this.getShipments());
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false, loading: false,});
      }
    });
  }


  getShipments(){
    const requestId = 'getShipmentsRequest';
    axios.cancel(requestId);
    let query = this.state.query;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    
    this.setState({errorMsg, loadingTable: true,
      hasMore: true
    });
    
    
    let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/shipment/'+this.state.clientId+'/invoice'+'?startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newShipments = response.data;
        if((newShipments && newShipments.length===0) || (newShipments && newShipments[0].itemInLastPage)){
          hasMore=false;
        }
        
        let shipments = newShipments.filter(({ shipmentId: id1 }) => !this.state.selectedShipments.some(({ shipmentId: id2 }) => id2 === id1));

        this.setState({hasMore,
          shipments,
          loadingTable: false,
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false, loadingTable: false,});
      }
    });
  }

  handleChange(e) {

    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let invoice = {...this.state.invoice};
    invoice[name]= value;
    
    this.setState({
      invoice,
      toSave: true,
      successMessage: '', 
      errorMsg: {}
    }, ()=>name==='iva' && this.calculateTotals());
  }

  handleDateChange(date) {
    let invoice = {...this.state.invoice};
    if(date) {
      invoice.invoiceDateMillis = date.getTime();
    } else {
      invoice.invoiceDateMillis = null;
    }

    this.setState({invoice, toSave: true, successMessage: '', errorMsg: {}});
  }

  handleDelete = () => {
      
    let errorMsg = {...this.state.errorMsg}
    const { t } = this.props;

    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };
    errorMsg.appError ='';
    this.setState({errorMsg, successMessage: ''});
    axios.delete(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/invoice/'+this.state.invoiceId, config)
    .then((response) => {
      if (this._isMounted) {
        localStorage.removeItem(WDL_COMPANY_NOTIFICATIONS);
        if(this.state.expired) {
          return window.location.href = '/logistic/company/'+this.state.companyId+'/invoices/clients?expired=true'; 
        } else {
          return window.location.href = '/logistic/company/'+this.state.companyId+'/invoices/clients'; 
        }
        
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }

        }
        this.setState({errorMsg, showDeleteModal: false});
      }
    });
  };

  handleClose(){
    this.setState({showDeleteModal: false });
  }

  handleShowDeleteModal(){
    this.setState({showDeleteModal: true});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var data = this.state.invoice;
    let errorMsg = {...this.state.errorMsg}
    const { t } = this.props;
    if(data.payment && typeof data.payment !== 'number') {
      data.payment = Number(data.payment.replace('.', '').replace(',', '.'));
    }
    data.client={
      clientId: this.state.clientId
    }
    data.shipments=this.state.selectedShipments;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };
    errorMsg.appError ='';
    this.setState({errorMsg, loading: true, successMessage: ''});
    axios.post(API_BASE_URL+'/logistic/company/'+this.state.companyId+'/invoice', data, config)
    .then((response) => {
      if (this._isMounted) {
        localStorage.removeItem(WDL_COMPANY_NOTIFICATIONS);
        this.setState({invoice: response.data, invoiceId: response.data.invoiceId, successMessage: t('WDL.saved'), loading: false, toSave: false }, ()=>this.generateContractName());
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  };

    render() {
      const { t } = this.props;

      let formValid = this.state.selectedShipments.length>0 && this.state.invoice.number?true:false;
      let backUrl = '/logistic/company/'+this.state.companyId+'/invoices';

      if(!this.state.isFromInvoicePage) {
        backUrl = '/logistic/company/'+this.state.companyId+'/invoices/clients/generate';
      } else if(this.state.expired) {
        backUrl = '/logistic/company/'+this.state.companyId+'/invoices/clients?expired=true';
      }
        return (
              
          <div className="wdl-container">
            <InfiniteScroll
                    dataLength={this.state.shipments.length}
                    next={this.state.invoiceId?this.getInvoice:this.getClient}
                    hasMore={this.state.hasMore}
                    refreshFunction={(e) => this.state.invoiceId?this.getInvoice():this.getClient()}
                    pullDownToRefresh={true}
                    pullDownToRefreshThreshold={10}
                    pullDownToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    releaseToRefreshContent={
                      <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                    }
                    height={this.state.viewHeight}
                    className="white-background"
                    loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                  >
              <WDLMenu companyId={this.state.companyId} user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle toSave={this.state.toSave} 
                history={this.props.history}
                title={this.state.invoiceId?'WDL.manage_invoice':'WDL.generate_invoice'}/>
              
              <Container fluid={true}>
                {
                  this.state.loading ? 
                    <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                  :

                  <Row className="justify-content-md-center">
                    <Col md={11}>
                      <Row className="justify-content-md-center" style={{marginBottom: 20, marginTop: 20}}>
                        <Col className="text-center">
                          <span className="wdl-title">{this.state.client?.name}</span>
                        </Col>
                      </Row>
                      <Tabs
                        id="invoice-tabs"
                        activeKey={this.state.selectedTab}
                        onSelect={(k) => this.setState({selectedTab: k})}
                        className="mb-3"
                      >
                      <Tab eventKey="info" title={t('WDL.info')}>
                          <Row>
                            <Col md={12}>
                              <Form onSubmit={this.handleSubmit}>
                                <Form.Group className="form_field wdl-message-container">
                                  <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                                  <SuccessMessage valid={true} message={this.state.successMessage} />
                                </Form.Group>
                                <Row>
                                  <Form.Group as={Col} className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                                    <DatePicker
                                      locale={this._locales[i18n.language]}
                                      onChange={this.handleDateChange}
                                      selected={(this.state.invoice?.invoiceDateMillis)?new Date(this.state.invoice?.invoiceDateMillis):new Date()}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} controlId="number" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.number')}</Form.Label>
                                    <Form.Control type="number" min={1} className="form_field_input" name="number" value={this.state.invoice?.number || ''} onChange={this.handleChange}/>
                                  </Form.Group>
                                  <Form.Group as={Col} md={2} controlId="iva" className="form_field">
                                    <Form.Label className="form_field_label">{'IVA %'}</Form.Label>
                                    <Form.Control type="number" min={0} max={100} className="form_field_input" name="iva" value={this.state.invoice?.iva || 0} onChange={this.handleChange}/>
                                  </Form.Group>
                                </Row>
                                <Form.Group controlId="notes" className="form_field">
                                  <Form.Label className="form_field_label">{t('WDL.notes')}</Form.Label>
                                  <textarea
                                    className="form-control"
                                    id="notes"
                                    name="notes"
                                    rows="5"
                                    value={this.state.invoice.notes || ''}
                                    onChange={this.handleChange}
                                    />
                                </Form.Group>
                            
                              <Row className="justify-content-md-center" style={{marginBottom: 20, marginTop: 20}}>
                                <Col className="text-center">
                                  <span className="wdl-title">{t('WDL.shipments')}</span>
                                </Col>
                              </Row>
                              <Row className="form_field wdl-search-container">
                              <Col>
                                <Row>
                                  <Form.Group as={Col}>
                                    <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                                    <DateRangePicker
                                      initialSettings={{ startDate: this.state.startDate, 
                                        endDate: this.state.endDate, 
                                        locale: this.state.locale,
                                        autoApply: true, 
                                      }}
                                      onCallback={this.handleChangeDateRange}
                                    >
                                      <input type="text" className="form-control" />
                                    </DateRangePicker>
                                  </Form.Group>
                                  <Col></Col>
                                </Row>
                                <Row>
                                  <Form.Group as={Col}  controlId="query" className="form_field">
                                    <Form.Label className="form_field_label">{t('WDL.search')}</Form.Label>
                                    <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.text_ph')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                                  </Form.Group>
                                </Row>
                                <Button style={{padding: 10}} variant="primary" type="button" onClick={()=>this.getShipments()}>
                                  <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                                </Button>
                              </Col>
                              </Row>
                              
                                  <Table striped borderless hover responsive className="wdl-table">
                                    <thead>
                                      <tr style={{backgroundColor: '#486cb5', color: 'white'}}>
                                        
                                        <th>
                                        <Form.Group controlId="selectAll" className="form_field">
                                          <Form.Check type="checkbox">
                                            <Form.Check.Input type="checkbox" name="selectAll" checked={this.state.selectAll} value={this.state.selectAll || ''} onChange={this.handleSelectAll}/>
                                          </Form.Check>
                                        </Form.Group>
                                        </th>
                                        <th>{t('WDL.number')}</th>
                                        <th>{t('WDL.date')}</th>
                                        <th>{t('WDL.name')}</th>
                                        <th>{t('WDL.invoice_notes')}</th>
                                        <th>{t('WDL.vector')}</th>
                                        <th>{t('WDL.commission')}</th>
                                        <th>{t('WDL.notes')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      {this.state.loadingTable ? 
                                        <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                                      :
                                    
                                        this.state.shipments && this.state.shipments.map((shipment, index) => (
                                        <tr key={index} >
                                          <td>
                                          <Form.Group controlId={"select_"+index} className="form_field">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input type="checkbox" name={"select_"+index} checked={false}  onChange={e=>this.handleSelectShipment(index)}/>
                                            </Form.Check>
                                          </Form.Group>
                                          </td>
                                          <td>
                                            <a href={'/logistic/company/'+this.state.companyId+'/shipments/manage/'+shipment.shipmentId}>{shipment.number}</a>
                                          </td>
                                          <td>
                                            {shipment.shipDateMillis &&
                                              <span>{
                                                format(
                                                  new Date(shipment.shipDateMillis),
                                                  'dd/MM/yyyy',
                                                  {locale: this._locales[i18n.language]}
                                                )}</span>
                                                }
                                          </td>
                                          <td>
                                            <span>{shipment.name} </span>
                                          </td>
                                          <td>
                                            <span>{shipment.invoiceNotes} </span>
                                          </td>
                                          <td>
                                            <span>{shipment.vector?.name} </span>
                                          </td>
                                          <td>
                                            <span>{'€'}<NumberFormat value={shipment.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                          </td>
                                          <td>
                                            <span>{shipment.notes} </span>
                                          </td>
                                        </tr>
                                      ))}
                                      {this.state.selectedShipments && this.state.selectedShipments.map((shipment, index) => (
                                        <tr key={index}  className={index==0 ? 'wdl-selected-table':''}>
                                          <td>
                                            <Form.Group controlId={"unselect_"+index} className="form_field">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input type="checkbox" name={"unselect_"+index} checked={true}  onChange={e=>this.handleUnselectShipment(index)}/>
                                            </Form.Check>
                                          </Form.Group>
                                          </td>
                                          <td>
                                            <a href={'/logistic/company/'+this.state.companyId+'/shipments/manage/'+shipment.shipmentId}>{shipment.number}</a>
                                          </td>
                                          <td>
                                            {shipment.shipDateMillis &&
                                              <span>{
                                                format(
                                                  new Date(shipment.shipDateMillis),
                                                  'dd/MM/yyyy',
                                                  {locale: this._locales[i18n.language]}
                                                )}</span>
                                                }
                                          </td>
                                          <td>
                                            <span>{shipment.name} </span>
                                          </td>
                                          <td>
                                            <Form.Group controlId={"note_"+index}>
                                              <Form.Control type="text" name={"note_"+index} value={shipment.invoiceNotes || ''} onChange={(e)=>this.handleAddShipmentNotes(index, e.target.value)}/>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <span>{shipment.vector?.name} </span>
                                          </td>
                                          <td>
                                            <span>{'€'}<NumberFormat value={shipment.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                          </td>
                                          <td>
                                            <span>{shipment.notes} </span>
                                          </td>
                                          
                                        </tr>
                                      ))}
                                    </tbody>
                                    {this.state.selectedShipments.length>0 &&
                                    <tfoot>
                                        <tr className="wdl-total">
                                          <td>
                                            <span>{this.state.totals.numbers}</span>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                          </td>
                                          <td>
                                            <span>{'€'}<NumberFormat value={this.state.totals.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/> </span>
                                          </td>
                                          <td>
                                          </td>
                                        </tr>
                                      </tfoot>
                                      }
                                  </Table>

                                  {(this.state.shipments.length===0 && this.state.selectedShipments.length===0) && 
                                    <Container fluid={true} className="infinite-scroll-container">
                                  
                                    <div className="separator info-not-found-container">
                                      <Row className="justify-content-md-center">
                                        <Col sm={12} className="text-center">
                                          <span>{t('WDL.info_not_found')}</span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Container>
                                  }
                                  <Row style={{marginTop: 100}}>
                                    <Col></Col>
                                    <Col>
                                        <ListGroup as="ul" style={{textAlign: 'right'}}>
                                          <ListGroup.Item as="li" style={{color: 'black'}}>
                                          {t('WDL.subtotal')+': €'}<NumberFormat value={this.state.totals.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" style={{color: 'black'}}>
                                          {'IVA: €'}<NumberFormat value={this.state.totals.iva} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" style={{color: 'black', fontWeight: 'bold'}}>
                                          {t('WDL.total')+': €'}<NumberFormat value={this.state.totals.iva+this.state.totals.commission} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2}/>
                                          </ListGroup.Item>
                                      </ListGroup>
                                      </Col>
                                  </Row>
                                  <Form.Row>
                                  <Col></Col>
                                      <Form.Group as={Col} className="form_field" style={{marginTop: 10}}>
                                        <Form.Label className="form_field_label">{t('WDL.payment')}</Form.Label>
                                        <CurrencyInput
                                          key={'payment_'+this.state.invoice.shipmentId}
                                          id="payment"
                                          name="payment"
                                          precision="2"
                                          prefix="€"
                                          allowNegativeValue={false}
                                          decimalsLimit={2}
                                          value={this.state.invoice?.payment || ''}
                                          onValueChange={(value)=>this.handleMoneyChange("payment", value)}
                                          className="form_field_input form-control-plaintext"
                                        />
                                      </Form.Group>
                                      
                                  </Form.Row>
                                  
                                  <Form.Row style={{marginTop: 50}}>
                                    <Col className="text-center">
                                      <Button style={{backgroundColor: '#ba1b13'}} variant="primary" type="submit" className="btn-login" disabled={!(formValid)}>{t('WDL.save')}</Button>
                                    </Col>
                                    {this.state.invoice?.invoiceId &&
                                    <Col className="text-center">
                                      <Button variant="secondary" type="button" onClick={this.handleShowDeleteModal} className="btn-login">{t('WDL.remove')}</Button>
                                    </Col>}
                                  </Form.Row>
                                </Form>
                                </Col>
                          </Row>
                        </Tab>
                        {this.state.invoice?.invoiceId &&
                          <Tab eventKey="documents" title={t('WDL.documents')}>
                            <WDLDownloadFileLink type={"pdf"} filename={this.state.contractName+".pdf"} link={API_BASE_URL+'/logistic/company/'+this.state.companyId+'/invoice/'+this.state.invoiceId+'/contract?lang='+i18n.language} name={t('WDL.invoice')} />
                            <WDLDownloadFileLink type={"xml"} filename={this.state.contractName+".xml"} link={API_BASE_URL+'/logistic/company/'+this.state.companyId+'/invoice/'+this.state.invoiceId+'/xml?lang='+i18n.language} name={t('WDL.invoice')+" XML"} />
                          </Tab>
                        }
                      </Tabs>  
                    </Col>
                    </Row>
                      
                }
                
              </Container>
              <Modal show={this.state.showDeleteModal} animation={false} onHide={this.handleClose}>
                <Modal.Header className="wdl"  closeButton>
                </Modal.Header>
                <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}}>
                    {t('WDL.confirm_delete_invoice')}
                </Modal.Body>
                <Modal.Footer className="wdl">
                  <Button variant="primary" onClick={this.handleDelete}>
                    {t('Common.confirm')}
                  </Button>
                </Modal.Footer>
              </Modal>
              
            </InfiniteScroll>
            </div>
            
        );
    }
}

export default withTranslation()(WDLManageInvoice);
