import React, { PureComponent } from 'react';
import {LANGUAGES} from '../Constants.js';

import i18n from 'i18next';

import { withTranslation } from 'react-i18next';

class LanguageChooser extends PureComponent {
    constructor() {
        super();
        this.state = {
            language:i18n.language,
            languages:LANGUAGES
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(lang) {
      i18n.changeLanguage(lang);
    }


    handleChange(e) {
        let target = e.target;
        let value = target.value;
        this.setState({
          language: value
        },   this.changeLanguage(value));

    }


    render() {
        return (

              <select className={this.props.isSite?"language-chooser-site":"language-chooser"} name="lang" value={this.state.language} onChange={this.handleChange}>
                {this.state.languages.map(lang =>
                  <option key={lang} value={lang}>{lang}</option>
                )}
              </select>

        );
    }
}

export default withTranslation()(LanguageChooser);
