export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const ACCESS_TOKEN = 'accessToken';
export const USER = 'currentUser';
export const HOME_TAB = 'homeTab';
export const LANGUAGES = ['en', 'it', 'fr', 'es' ];
export const CURRENT_CITY = 'current_city';
export const ADMIN_MODE = 'admin_mode';
export const MENU_HEIGHT = 180;
export const MODAL_MENU_HEIGHT = 75;
export const SEARCH_HEIGHT = 90;
export const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/?api=1&query=';
export const GOOGLE_KEY = 'AIzaSyBWxAVq5wjCUebw-mA4BqdLU6DZuIuCoR4';
export const SEARCH_CITIES = 'cities';
export const SEARCH_USERS = 'users';
export const SEARCH_ATTRACTIONS = 'attractions';
export const SEARCH_PLATES = 'plates';
export const DUSTY_HELPER_CONTAINER = 150; 
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_ADVERTISER = 'ROLE_ADVERTISER';
export const TRIPADVISOR_URL = 'https://www.tripadvisor.com/';
export const REVERSE_GEOCODING_URL = 'https://api.bigdatacloud.net/data/reverse-geocode-client';
export const CONTACT_EMAIL = 'info@worldust.com';
export const FACEBOOK_URL = 'https://fb.me/WorldustDiscovery';
export const INSTAGRAM_URL = 'https://www.instagram.com/worldust_discovery/';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.worldust_mobile';
export const APPLE_STORE_URL = 'https://itunes.apple.com/app/id1526163581';
export const WDL_ACCESS_TOKEN = 'WDLaccessToken';
export const WDL_USER = 'WDLcurrentUser';
export const WDL_ROLE_ADMIN = 'WDL_ROLE_ADMIN';
export const WDL_COMPANY_NOTIFICATIONS = 'WDL_COMPANY_NOTIFICATIONS';
export const WDL_COMPANY = 'WDL_COMPANY';
export const WDL_DEFAULT_IVA = 22;
export const WDL_START_YEAR = 2021;