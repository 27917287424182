import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT, SEARCH_HEIGHT} from '../Constants.js';
import BackButtonTitle from '../components/BackButtonTitle';

import axios from 'axios';
import PlatePage from '../components/PlatePage';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class UnlockedPlates extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        query: '',
        selectedPlate: null,
        showModal: false,
        user: this.props.user,
        page: 0,
        hasMore: false,
        plates: []
      }
      this.handleInputChange = this.handleInputChange.bind(this);
      this.getUnlockedPlates = this.getUnlockedPlates.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showPlatePage = this.showPlatePage.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getUnlockedPlates();
  }

  handleClose(){
    this.setState({selectedPlate: null,
      showModal: false});
  }

  showPlatePage(selectedPlate){
    this.setState({selectedPlate,
      showModal: true});

  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      plates: [],
      page: 0
    },   () => {
      this.getUnlockedPlates();
    });
  }


getUnlockedPlates(refresh){
    const requestId = 'getUnlockedPlatesRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let plates = this.state.plates;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      page=0;
      plates=[];
      this.setState({errorMsg, page, query, plates,
        hasMore: true
      });
    } else {
      this.setState({errorMsg,
        hasMore: true
      });
    }
    let url = API_BASE_URL+'/plate/getAll/'+this.state.user.userId+'?page='+page+'&lang='+i18n.language;

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    

    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newUnlockedPlates = response.data;
        if((newUnlockedPlates && newUnlockedPlates.length===0) || (newUnlockedPlates && newUnlockedPlates[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          plates: plates.concat(newUnlockedPlates)
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body className="white-modal">
                <BackButtonTitle handleClose={this.props.handleClose} title={'Profile.unloked_plates'}></BackButtonTitle>
                <Form>
                  <Form.Group controlId="query" className="form_field">
                    <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.search')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                  </Form.Group>
                </Form>
                <InfiniteScroll
                  dataLength={this.state.plates.length}
                  next={this.getUnlockedPlates}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getUnlockedPlates(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT-SEARCH_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.plates.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Plate.not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.plates && this.state.plates.map((plate, index) => (
                          <ListGroup.Item key={index}>
                              <div className="row-link-container"  onClick={(e) => this.showPlatePage(plate)}>
                              <Row>
                                <Col className="list-image-container">
                                  <Image fluid={true} src={plate.userAttraction.image} className={"list-img"}/>
                                </Col>
                                <Col className="info-list-container">
                                  <Row>
                                    <Col>
                                      <span className="link-icon">{(plate.nameInfo)?plate.nameInfo: plate.name}</span>
                             
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                    <span className="city-info-label">{plate.city.info ? plate.city.info.name+ ', '+ plate.city.countryCode : plate.city.name+ ', '+ plate.city.countryCode}</span>
                                  </Col>
                                  </Row>
                                </Col>
                              </Row>
                              </div>
                            </ListGroup.Item>

                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <PlatePage plate={this.state.selectedPlate} user={this.props.user} showModal={this.state.showModal} handleClose={this.handleClose}></PlatePage>
                  )}
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(UnlockedPlates);
