import React, { PureComponent } from 'react';
import RankingPage from './RankingPage';
import Settings from './Settings';
import ManageProfile from './ManageProfile';
import UserProfile from './UserProfile';
import Notifications from './Notifications';
import WishedAttractionsPage from './WishedAttractionsPage';
import WishedPlatesPage from './WishedPlatesPage';
import NumberFormat from 'react-number-format';
import { Row, Col, ListGroup} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserImage from './UserImage';


import { withTranslation } from 'react-i18next';

class UserAppManage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
          height: this.props.height,
          pageToShow: ''
        }
        this.showPage = this.showPage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    showPage(pageName){
      this.setState({pageToShow: pageName});
    }

    handleClose(){
      this.setState({pageToShow: ''});
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
    }






    render() {
        const { t } = this.props;

        return (
          <div className="tab-content-container">
            <div className="tab-content-menu">
              <Row className="row-link-profile" onClick={(e) => this.showPage('profile')}>
                <Col sm={12}>
                  <UserImage user={this.props.user}></UserImage>
                  <span className="link-icon user-item-list">{this.props.user.name+(this.props.user.surname? ' '+this.props.user.surname:'')}</span>
                </Col>
              </Row>
            </div>
            <div className="tab-content-body"  style={{overflow: 'auto'}}>
              <ListGroup className="max-height-container manage-item-container" style={{ height: this.state.height }}>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('notifications')}>
                    <i className="fa fa-bell-o" aria-hidden="true"></i>
                    <span className="link-icon">{t('Profile.notifications')}</span>
                    <span className={(this.props.user.notificationsToRead>0 ? "notifications-to-read" : "notifications-readed")}>
                      <NumberFormat value={this.props.user.notificationsToRead} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                    </span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('wished_attractions')}>
                    <i className="fa fa-bookmark" aria-hidden="true"></i>
                    <span className="link-icon">{t('Discovery.attractions_to_visit')}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('wished_plates')}>
                    <i className="fa fa-spoon" aria-hidden="true"></i>
                    <span className="link-icon">{t('Discovery.plates_to_taste')}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('ranking')}>
                    <i className="fa fa-list-ol" aria-hidden="true"></i>
                    <span className="link-icon">{t('Profile.ranking')}</span>
                  </div>
                </ListGroup.Item>
                <div className="separator">
                </div>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('manage_profile')}>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <span className="link-icon">{t('Profile.manage_profile')}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="row-link-container" onClick={(e) => this.showPage('settings')}>
                    <i className="fa fa-cog" aria-hidden="true"></i>
                    <span className="link-icon">{t('Common.settings')}</span>
                  </div>
                </ListGroup.Item>
                {this.props.isAdmin && (
                  <ListGroup.Item>
                    <Link to="/manage_cities" >
                      <div className="row-link-container">
                          <i className="fa fa-building" aria-hidden="true"></i>
                          <span className="link-icon">{t('Admin.manage_city')}</span>
                      </div>
                    </Link>
                  </ListGroup.Item>
                )}
                {this.props.isAdmin && (
                  <ListGroup.Item>
                    <Link to="/manage_users" >
                      <div className="row-link-container">
                          <i className="fa fa-users" aria-hidden="true"></i>
                          <span className="link-icon">{t('Admin.manage_users')}</span>
                      </div>
                    </Link>
                </ListGroup.Item>
                )}
                {this.props.isAdmin && (
                  <ListGroup.Item>
                    <Link to="/manage_skins" >
                      <div className="row-link-container">
                          <i className="fa fa-book" aria-hidden="true"></i>
                          <span className="link-icon">{'Skins'}</span>
                      </div>
                    </Link>
                  </ListGroup.Item>
                )}
                {this.props.isAdmin && (
                  <ListGroup.Item>
                    <Link to="/manage_textures" >
                      <div className="row-link-container">
                          <i className="fa fa-photo" aria-hidden="true"></i>
                          <span className="link-icon">{'Textures'}</span>
                      </div>
                    </Link>
                  </ListGroup.Item>
                )}
                {(this.props.isAdvertiser || this.props.isAdmin) && (
                  <ListGroup.Item>
                    <Link to="/manage_advertising" >
                      <div className="row-link-container">
                          <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                          <span className="link-icon">{t('Admin.manage_advertising')}</span>
                      </div>
                  </Link>
                  </ListGroup.Item>
                )}
                <div className="separator">
                </div>
                <ListGroup.Item>
                  <Link to="/logout" >
                    <div className="row-link-container">
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                      <span className="link-icon">{t('Profile.logout')}</span>
                    </div>
                </Link>
              </ListGroup.Item>
              </ListGroup>

            </div>
            {this.state.pageToShow==="profile" && (
              <UserProfile user={this.props.user} handleClose={this.handleClose} handleChangeCity={this.props.handleChangeCity}></UserProfile>
            )}
            {this.state.pageToShow==="manage_profile" && (
              <ManageProfile user={this.props.user} handleClose={this.handleClose}></ManageProfile>
            )}
            {this.state.pageToShow==="settings" && (
              <Settings user={this.props.user} handleClose={this.handleClose}></Settings>
            )}
            {this.state.pageToShow==="notifications" && (
              <Notifications user={this.props.user} handleClose={this.handleClose} getNotificationsToRead={this.props.getUserInfo}></Notifications>
            )}
            {this.state.pageToShow==="wished_attractions" && (
              <WishedAttractionsPage user={this.props.user} handleClose={this.handleClose}></WishedAttractionsPage>
            )}
            {this.state.pageToShow==="wished_plates" && (
              <WishedPlatesPage user={this.props.user} handleClose={this.handleClose}></WishedPlatesPage>
            )}
            {this.state.pageToShow==="ranking" && (
              <RankingPage user={this.props.user} handleClose={this.handleClose}></RankingPage>
            )}

          </div>
        );
    }
}

export default withTranslation()(UserAppManage);
