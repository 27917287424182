import React, { PureComponent } from 'react';
import logo from '../img/logo.svg';
import Search from '../components/Search';

import { Navbar, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { withTranslation } from 'react-i18next';

class Menu extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            user: this.props.user,
            isAdmin: this.props.isAdmin,
            city: '',
            cities: []
        };

        this.showChangeCityModal = this.showChangeCityModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.goToCity = this.goToCity.bind(this);
    }

    showChangeCityModal() {
      this.setState({
        showModal: true
      });
    }

    handleChangeCity = selectedOption => {
      this.setState(
        { city: selectedOption }
      );
    };

    goToCity (cityId) {
      this.setState({showModal: false },
      this.props.handleChangeCity(cityId));
    };

    handleClose(){
      this.setState({showModal: false });
    }


    render() {
        const { t } = this.props;

        return (
          <div>
            <Navbar collapseOnSelect expand="lg" className="nav_menu top-menu">
              <Navbar.Brand as={Link} to="/letMeDiscover"><Image src={logo} className="menu-logo" /></Navbar.Brand>
              <div className="discovery-button-container">
                <Button type="button" variant="primary" onClick={(e) => this.showChangeCityModal(e)}  disabled={this.state.loading}>
                <i className="fa fa-search" aria-hidden="true"></i> {t('Common.discover')}
                </Button>
              </div>
            </Navbar>
             <Search goToCity={this.goToCity} showModal={this.state.showModal} handleClose={this.handleClose} user={this.state.user} isAdmin={this.state.isAdmin}></Search>
          </div>
        );
    }
}

export default withTranslation()(Menu);
