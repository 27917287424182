import React, { PureComponent } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import WDLMenu from './WDLMenu';
import WDLBackButtonTitle from './WDLBackButtonTitle';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';


class WDLGenerateInvoices extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
        const { t } = this.props;
        this.state = {
          companyId: this.props.match.params.companyId,
          invoice: null,
          user: user,
          isAdmin: isAdmin,
          clients: [{label: t('Common.select'), value: null}],
          selectedClient: {label: t('Common.select'), value: null},
          startDate: startOfMonth(subMonths(new Date(), 1)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
          nameValid: true,
          loading: false,
          formValid: false,
          errorMsg: {},
          successMessage: '',
          showDeleteModal: false,
          selectedTab: 'info',
        };
        this.handleChange = this.handleChange.bind(this);
        this.getInvoiceClients = this.getInvoiceClients.bind(this);
        this.handleSelectClient = this.handleSelectClient.bind(this);
        this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);


    }

    handleChangeDateRange(start, end, label) {
      this.setState({startDate: moment(start).toDate(), endDate: moment(end).toDate()})
    }

  

    handleSelectClient(item) {
      let invoice = {...this.state.invoice};
      if(item?.value){
        invoice.client = {
          clientId: item.value,
          name: item.label
        };
      } else {
        invoice.client = null;
      } 
     
      this.setState({selectedClient: item, invoice});
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      this.getInvoiceClients();
    }


    getInvoiceClients() {
      const requestId = 'getInvoiceClientsRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true});
      
  
      let url = API_BASE_URL+'/logistic/company/'+this.state.companyId+'/client/getAllToInvoice'+'?startDate='+this.state.startDate.getTime()+'&endDate='+this.state.endDate.getTime();
      
      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
        }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          let clients = [{label: t('Common.select'), value: null}];
          let newClients = response.data;
          clients = clients.concat(newClients.map((item) => ({label: item.name, value: item.clientId})));
          this.setState({
            clients,
            loading: false
          });
          
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }

    

    
    

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg});

      }

      
    }




    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let invoice = {...this.state.invoice};
        invoice[name]= value;
        this.setState({
          invoice,
        }, this.validateFields(name, value));


    }


    onFormSubmit(e) {
      e.preventDefault();
      this.getInvoiceClients();
    }
    


    render() {
     
        const { t } = this.props;
        return (
          <div className="wdl-container">
              <WDLMenu user={this.state.user} isAdmin={this.state.isAdmin}></WDLMenu>
              <WDLBackButtonTitle history={this.props.history} link={'/logistic/company/'+this.state.companyId+'/invoices/clients'} title={'WDL.generate_invoices'}></WDLBackButtonTitle>
              <Container fluid={true}>
              <Row className="justify-content-md-center">
                  <Col md={8}>
                    <Row className="wdl-search-container">
                      <Col>
                      <Form onSubmit={this.onFormSubmit}>
                      <Row>
                        <Col>
                        <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                        <DateRangePicker
                          initialSettings={{ startDate: this.state.startDate, 
                            endDate: this.state.endDate, 
                            locale: this.state.locale,
                            autoApply: true, 
                          }}
                          onCallback={this.handleChangeDateRange}
                        >
                          <input type="text" className="form-control" />
                        </DateRangePicker>
                      </Col>
                      <Col>
                        <Button style={{padding: 10, marginTop:30}} variant="primary" type="submit" >
                            <i className="fa fa-search" aria-hidden="true"></i> {t('WDL.search')}
                          </Button>
                      </Col>
                      </Row>
                      </Form>
                      </Col>
                      
                    </Row>
                    {this.state.loading &&
                      <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
                     }
                    {this.state.clients && !this.state.loading &&
                    <Form style={{marginTop: 50}}>
                      <Row>
                        <Form.Group as={Col} controlId="client" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.client')}</Form.Label>
                        <Select
                          value={this.state.selectedClient}
                          onChange={this.handleSelectClient}
                          options={this.state.clients}
                          placeholder={t('Common.select')}
                        />                        
                      </Form.Group>
                      </Row>
                        <Row>
                        <Col className="text-center">
                          {this.state.selectedClient?.value &&
                            <Button style={{backgroundColor: '#ba1b13'}} variant="primary" type="button" className="btn-login" as={Link} to={"/logistic/company/"+this.state.companyId+"/client/"+this.state.selectedClient.value+"/invoices/add?startDate="+this.state.startDate.getTime()+"&endDate="+this.state.endDate.getTime()}>{t('WDL.generate_invoice')}</Button>
                          }
                        </Col>
                      </Row>
                    </Form>
                    }
                  </Col>
                </Row>
                
                  
              </Container>
            </div>




        );
    }
}

export default withTranslation()(WDLGenerateInvoices);
