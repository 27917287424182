import React, { PureComponent } from 'react';
import {Col, Form, Container, Modal, Button} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, LANGUAGES } from '../Constants.js';

import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import Loader from '../components/Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';

import { withTranslation } from 'react-i18next';

class AttractionManagePage extends PureComponent {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
          cityId: this.props.cityId,
          showDescription: false,
          showAttrInfo: false,
          attraction: this.props.attraction,
          loading: false,
          languageId: LANGUAGES[0],
          languages: LANGUAGES,
          errorMsg: {},
          showAssignAttractionModal: false,
          successMessage: '',
          assignedId: null,
        }
        this.handleShowDescription = this.handleShowDescription.bind(this);
        this.handleShowAttrInfo = this.handleShowAttrInfo.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleUploadFileImg = this.handleUploadFileImg.bind(this);
        this.deleteAttraction = this.deleteAttraction.bind(this);
        this.handleSubmitInfo = this.handleSubmitInfo.bind(this);
        this.handleShowAssignModal = this.handleShowAssignModal.bind(this);
        this.handleSubmitAssignToUser = this.handleSubmitAssignToUser.bind(this);
        this.handleChange = this.handleChange.bind(this);

        
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleShowAssignModal() {
      this.setState({showAssignAttractionModal: !this.state.showAssignAttractionModal});
    }

    handleChangeLanguage (e) {
      let target = e.target;
      let value = target.value;
      this.getAttractionInfo(this.state.attraction.attractionId, value);
    };

    handleShowAttrInfo() {
        this.setState({
          showAttrInfo: !this.state.showAttrInfo}, this.getAttractionInfo(this.state.attraction.attractionId, this.state.languages[0]));
    }

    handleSubmitInfo(e){
      e.preventDefault();
      let attraction = {...this.state.attraction};
      
      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      data["attractionId"]=attraction.attractionId;
      data["languageId"]=this.state.languageId;
      data["description"]=attraction.description;
      data["linkInfo"]=attraction.linkInfo;
      data["name"]=attraction.nameInfo;

      this.setState({errorMsg: {},
          successMessage: '',
        loading: true
      });

      axios.post(API_BASE_URL+'/attraction/admin/saveInfo', data, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({loading: false, successMessage: t('Attractions.saved')}, this.props.updateAttractionList(attraction, true));
        }


      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
        });

    }

    

    getAttractionInfo = (attrId, lang) => {

      const { t } = this.props;
      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      let errorMsg = {}

      errorMsg.dataError ='';
      this.setState({errorMsg,
        successMessage: '',
        loading: true,
        languageId: lang
      });

      axios.get(API_BASE_URL+'/attraction/getInfo/'+attrId+'/'+lang, config)
      .then((response) => {
        if (this._isMounted) {
          let attrInfo = response.data;
          let attraction = {...this.state.attraction};
          if(attrInfo!==null && attrInfo!==""){
            attraction.nameInfo=attrInfo.name;
            attraction.description=attrInfo.description;
            attraction.linkInfo=attrInfo.linkInfo;

          }else{
            attraction.nameInfo='';
            attraction.description='';
            attraction.linkInfo='';

          }
          this.setState({
            attraction,
            loading: false
          });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    }

    

    handleUploadFileImg = (event) => {
        let imageSize = 600;
        if(this.state.showAssignAttractionModal){
          imageSize = 1280;
        }
        let target = event.target;
        let attraction = {...this.state.attraction};
        this.setState({attraction});
        var fileInput = false
          if(event.target.files[0]) {
              fileInput = true
          }
          if(fileInput) {
              Resizer.imageFileResizer(
                  target.files[0],
                  imageSize,
                  imageSize,
                  'JPEG',
                  100,
                  0,
                  uri => {
                    attraction.imageData = uri;
                    this.setState({attraction});
                  },
                  'base64'
              );
          }
      };
      

    handleShowDescription(){
      var showDescription = !this.state.showDescription;
      this.setState({showDescription});
    }

    handleSubmit(e){
      e.preventDefault();
      let errorMsg = {...this.state.errorMsg}
      let attraction = this.state.attraction;
      const { t } = this.props;
      if(!attraction.imageData && !attraction.image){
        errorMsg.dataError = t('Errors.invalid_field');
        this.setState({errorMsg});
      }else{
        this.props.saveNewAttraction(attraction);
      }
      
    }


    handleSubmitAssignToUser(e){
      e.preventDefault();
      let errorMsg = {...this.state.errorMsg}
      let attraction = {...this.state.attraction};
      const { t } = this.props;
      if(!attraction.imageData){
        errorMsg.dataError = t('Errors.invalid_field');
        this.setState({errorMsg});
      }else{
          var config = {
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
            }
          };
          var data = {};
          data.userId = this.state.assignedId;
          data.attractionId = attraction.attractionId;
          data.imageData = attraction.imageData;
          data.city = attraction.city;
          data.level = attraction.level;
          const { t } = this.props;
          errorMsg.dataError = '';

          this.setState({errorMsg,
            loading: true,
            successMessage: ''
          });

          axios.post(API_BASE_URL+'/attraction/admin/assignUserAttraction', data, config)
          .then((response) => {
            if (this._isMounted) {
              
              
              this.setState({loading: false,
                successMessage: t('Attractions.saved')});
            }

          }, (error) => {
            if (this._isMounted) {
              errorMsg.dataError = t('Errors.generic_error');

              if (error.response && error.response.status) {
                let status = error.response.status;
                if(status===401){
                  return window.location.href = '/logout';            
                }
              }else{
                if(error.message!=null && error.message==='Network Error'){
                    errorMsg.dataError = t("Errors.connection_error");
                }
              }
              this.setState({errorMsg,
                loading: false});
              }
          });
      
        }
      
    }


    


    handleChangeText(e) {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let attraction = {...this.state.attraction};

        attraction[name] = value;
        this.setState({
          attraction
        });

    }

    handleChange(e) {

      let target = e.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      let name = target.name;

      this.setState({
        [name]: value
      });


  }

    deleteAttraction = (event, id) => {

      let errorMsg = {...this.state.errorMsg}
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };

      var data = {};
      this.setState({errorMsg,
        loading: true
      });

      axios.post(API_BASE_URL+'/attraction/admin/delete/'+id, data, config)
      .then((response) => {
        if (this._isMounted) {
          this.setState({
            loading: false
          }, this.props.handleDelete(id));
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.dataError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
          this.setState({errorMsg,
            loading: false});
          }
      });

    }

    render() {
      const { t } = this.props;
        if(this.state.showAssignAttractionModal) {
          return (

            <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>  {'Assing to user'}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="app_aside">
                {this.state.loading && (
                  <Loader></Loader>
                )}
                <Container fluid={true}>
                  <Form onSubmit={this.handleSubmitAssignToUser}>
                      <input type="hidden" name="attractionId" value={this.state.attraction.attractionId} ></input>
                      <Form.Row>
                         <Col sm={8}>
                           <Form.Group controlId="assignedId" className="form_field">
                             <Form.Label className="form_field_label">{'Assigned user Id'}</Form.Label>
                             <Form.Control type="number" className="form_field_input" placeholder={'User id'} name="assignedId" value={this.state.assignedId} onChange={this.handleChange} required/>
                           </Form.Group>
                         </Col>
                       </Form.Row>
                      <Form.Group controlId="image" className="form_field">
                        <Form.Label className="form_field_label">{t('Attractions.image')}</Form.Label>
                        <Form.Control type="file" name="image" onChange={this.handleUploadFileImg}/>
                      </Form.Group>
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                    </Form.Group>
                      <Form.Row>
                         <Col className="text-center">
                           <Button variant="primary" type="submit">{t('Attractions.save')}</Button>
                         </Col>
                         <Col className="text-center">
                           <Button type="button" variant="secondary" onClick={(e) => this.deleteAttraction(e, this.state.attraction.attractionId)}>
                             {t('Admin.delete_attraction')}
                           </Button>
                         </Col>
                       </Form.Row>
                     </Form>
                  </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.handleClose}>
                  {t('Attractions.close')}
                </Button>
              </Modal.Footer>
            </Modal>
  
          );
        } else {
          return (

            <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
              <Modal.Header closeButton>
                {this.state.attraction.attractionId==="" && (<Modal.Title>  {t('Admin.add_attraction')}</Modal.Title>)}
                {this.state.attraction.attractionId!=="" && (<Modal.Title>  {t('Admin.edit_attraction')}
                <Button variant={(this.state.showAttrInfo ? 'primary' : 'secondary')} style={{ marginLeft: '10px' }} onClick={(e) => this
                  .handleShowAttrInfo()}><i className="fa fa-info-circle" aria-hidden="true"></i></Button>
                  <Button variant={'primary'} style={{ marginLeft: '10px' }} onClick={(e) => this
                  .handleShowAssignModal()}><i className="fa fa-user" aria-hidden="true"></i></Button>
                  </Modal.Title>)}
              </Modal.Header>
              <Modal.Body className="app_aside">
                {this.state.loading && (
                  <Loader></Loader>
                )}
                <Container fluid={true}>
                  {!this.state.showAttrInfo && (
                    <Form onSubmit={this.handleSubmit}>
                      <input type="hidden" name="attractionId" value={this.state.attraction.attractionId} ></input>
                      <Form.Row>
                         <Col sm={8}>
                           <Form.Group controlId="name" className="form_field">
                             <Form.Label className="form_field_label">{t('Attractions.name')}</Form.Label>
                             <Form.Control type="text" className="form_field_input" placeholder={t('Attractions.name')} name="name" value={this.state.attraction.name} onChange={this.handleChangeText} required/>
                           </Form.Group>
                         </Col>
                         <Col sm={4}>
                           <Form.Group controlId="level" className="form_field">
                             <Form.Label className="form_field_label">{t('Attractions.level')}</Form.Label>
                             <Form.Control type="number" className="form_field_input" placeholder={t('Attractions.level')} name="level" value={this.state.attraction.level}  onChange={this.handleChangeText} required min="1" max="3"/>
                           </Form.Group>
                         </Col>
                       </Form.Row>
                      <Form.Group controlId="image" className="form_field">
                        <Form.Label className="form_field_label">{t('Attractions.image')}</Form.Label>
                        <Form.Control type="file" name="image" onChange={this.handleUploadFileImg}/>
                      </Form.Group>
                      <Form.Group controlId="latitude" className="form_field">
                        <Form.Label className="form_field_label">{t('Common.latitude')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('Common.latitude')} name="latitude" value={this.state.attraction.latitude} onChange={this.handleChangeText} required />
                      </Form.Group>
                      <Form.Group controlId="longitude" className="form_field">
                        <Form.Label className="form_field_label">{t('Common.longitude')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" placeholder={t('Common.longitude')} name="longitude" value={this.state.attraction.longitude} onChange={this.handleChangeText} required />
                      </Form.Group>
                      <Form.Group controlId="closed" className="form_field">
                        <Form.Check type="checkbox">
                          <Form.Check.Input type="checkbox" name="closed" checked={this.state.attraction.closed} value={this.state.attraction.closed} onChange={this.handleChangeText}/>
                          <Form.Check.Label>{'Closed'}</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                    </Form.Group>
                      <Form.Row>
                         <Col className="text-center">
                           <Button variant="primary" type="submit">{t('Attractions.save')}</Button>
                         </Col>
                         <Col className="text-center">
                           <Button type="button" variant="secondary" onClick={(e) => this.deleteAttraction(e, this.state.attraction.attractionId)}>
                             {t('Admin.delete_attraction')}
                           </Button>
                         </Col>
                       </Form.Row>
                     </Form>
                   )}
                   {this.state.showAttrInfo && (
                     <Form onSubmit={this.handleSubmitInfo}>
                       <Form.Group controlId="languageId" className="form_field" >
                         <Form.Label className="form_field_label">{t('Common.languages')}</Form.Label>
                         <Form.Control as="select" name="languageId" required value={this.state.languageId} onChange={this.handleChangeLanguage}>
                           {this.state.languages.map(lang =>
                             <option key={lang} value={lang}>{lang}</option>
                           )}
                         </Form.Control>
                       </Form.Group>
                       <Form.Label className="form_field_label">{t('Attractions.name')}</Form.Label>
                       <Form.Group controlId="name" className="form_field">
                         <Form.Control type="text" className="form_field_input" required placeholder={t('Attractions.name')} name="nameInfo" value={this.state.attraction.nameInfo} onChange={this.handleChangeText}  />
                       </Form.Group>
                       <Form.Group controlId="description" className="form_field">
                         <Form.Label className="form_field_label">{t('Attractions.description')}</Form.Label>
                         <Form.Control as="textarea" placeholder={t('Attractions.description')} maxLength="800" name="description" value={this.state.attraction.description} onChange={this.handleChangeText}  />
                       </Form.Group>
                       <Form.Group controlId="linkInfo" className="form_field">
                         <Form.Label className="form_field_label">{t('Common.link')}</Form.Label>
                         <Form.Control type="text" className="form_field_input" placeholder={t('Common.link')} name="linkInfo" value={this.state.attraction.linkInfo} onChange={this.handleChangeText}  />
                       </Form.Group>
                       <Form.Group className="form_field">
                         <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                         <SuccessMessage valid={true} message={this.state.successMessage} />
                       </Form.Group>
                       <Form.Row>
                         <Col className="text-center">
                           <Button variant="primary" type="submit" className="btn-login">{t('Common.save')}</Button>
                         </Col>
                        </Form.Row>
                      </Form>
  
  
                   )}
  
                  </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.handleClose}>
                  {t('Attractions.close')}
                </Button>
              </Modal.Footer>
            </Modal>
  
          );
        }
        
    }
}

export default withTranslation()(AttractionManagePage);
