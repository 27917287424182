import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, ACCESS_TOKEN, MODAL_MENU_HEIGHT, SEARCH_HEIGHT} from '../Constants.js';

import axios from 'axios';
import ObjectivePage from '../components/ObjectivePage';
import InfiniteScroll from 'react-infinite-scroll-component';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class Objectives extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = {
        query: '',
        selectedObjective: null,
        showModal: false,
        page: 0,
        unlockedFilter: false,
        hasMore: false,
        objectives: []
      }
      this.getObjectives = this.getObjectives.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.showObjectivePage = this.showObjectivePage.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleUnlockedFilter = this.handleUnlockedFilter.bind(this);

  }

  handleInputChange(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value,
      objectives: [],
      page: 0
    },   () => {
      this.getObjectives();
    });
  }

  handleUnlockedFilter(){
    let unlockedFilter = this.state.unlockedFilter;
    this.setState({unlockedFilter: !unlockedFilter}, () => {
      this.getObjectives(true);
    });
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getObjectives();
  }

  handleClose(){
    this.setState({selectedObjective: null,
      showModal: false});
  }

  showObjectivePage(selectedObjective){

    this.setState({selectedObjective,
      showModal: true});

  }


getObjectives(refresh){
    const requestId = 'getObjectivesRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let objectives = this.state.objectives;
    let unlockedFilter = this.state.unlockedFilter;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      page=0;
      objectives=[];
      this.setState({errorMsg, page, objectives, query,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/discovery/getObjectives/'+this.props.user.userId+'?page='+page+'&lang='+i18n.language+'&unlockedFilter='+unlockedFilter;

    if (query.length > 0) {
      url += '&query=' + this.state.query;
    }
    

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newObjectives = response.data;
        if((newObjectives && newObjectives.length===0) || (newObjectives && newObjectives[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          objectives: objectives.concat(newObjectives)
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (

          <Modal show={true} animation={false} onHide={this.props.handleClose}  className="fake-page-modal">
            <Modal.Body className="white-modal">
                <Container fluid={true} className="title-bar-page">
                <Row>
                  <Col md={12} className="text-center">
                    <i className="fa fa-chevron-left fa-2x back-button" onClick={(e) => this.props.handleClose()} aria-hidden="true"></i>
                    <span className="fake-modal-title">{t('Profile.objectives')}</span>
                    {this.state.unlockedFilter===true? (
                        <i className="fa fa-unlock fa-2x unlocked-objectives-icon" onClick={(e) => this.handleUnlockedFilter()} aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-lock fa-2x locked-objectives-icon" onClick={(e) => this.handleUnlockedFilter()} aria-hidden="true"></i>
                    )}
                  
                  </Col>
                </Row>
              </Container>
                <Form>
                  <Form.Group controlId="query" className="form_field">
                    <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.search')} name="query" value={this.state.query} onChange={query => this.handleInputChange(query)} />
                  </Form.Group>
                </Form>
                <InfiniteScroll
                  dataLength={this.state.objectives.length}
                  next={this.getObjectives}
                  hasMore={this.state.hasMore}
                  refreshFunction={(e) => this.getObjectives(true)}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={10}
                  pullDownToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  releaseToRefreshContent={
                    <div className="scroll-loader"><div className="lds-ripple white"><div></div><div></div></div></div>
                  }
                  height={window.innerHeight-MODAL_MENU_HEIGHT-SEARCH_HEIGHT}
                  className="white-background"
                  loader={<div className="scroll-loader"><div className="lds-ripple"><div></div><div></div></div></div>}
                >
                <Container fluid={true} className="infinite-scroll-container">
                  <ListGroup>
                    <Row>
                      <Col md={12}>
                        {this.state.objectives.length===0 && (
                          <div className="separator">
                            <Row className="justify-content-md-center">
                              <Col sm={12} className="text-center">
                                <span>{t('Objectives.not_found')}</span>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {this.state.objectives && this.state.objectives.map((objective, index) => (
                          <ListGroup.Item key={index}>
                            <div className="row-link-container" onClick={(e) => this.showObjectivePage(objective)}>
                              <Image fluid={true} src={objective.image} className={"list-img"}/>
                              <span className="link-icon">{(objective.nameInfo)?objective.nameInfo: objective.name} <i className={"fa fa-check button-icon wished-check "+(!objective.unlocked ? "hidden" : "")} aria-hidden="true"></i></span>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </Col>
                    </Row>
                  </ListGroup>
                  {this.state.showModal && (
                    <ObjectivePage objective={this.state.selectedObjective} user={this.props.user} showModal={this.state.showModal} handleClose={this.handleClose}></ObjectivePage>
                  )}
                </Container>
                </InfiniteScroll>

            </Modal.Body>
          </Modal>

        );
    }
}

export default withTranslation()(Objectives);
