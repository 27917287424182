import React, { PureComponent } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet';

import { DUSTY_HELPER_CONTAINER } from '../Constants.js';
import marker from '../img/localization_marker.png';

import DustyHelper from '../components/DustyHelper';
import 'leaflet/dist/leaflet.css';
import { Col, Form, Button, ButtonGroup, Image } from 'react-bootstrap';
import Select from 'react-select';

import { withTranslation } from 'react-i18next';


class WorldustMap extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);

      let cityId = null;
      if(this.props.cityId){
        cityId = this.props.cityId;
      }


      let userIcon = new L.Icon({
        className : 'user-marker',
        iconUrl: require('../img/user_marker.png'),
        iconRetinaUrl: require('../img/user_marker.png'),
        popupAnchor: [10, -44],
        iconSize: [30, 30]
      });

      this.state = {
        cityId: cityId,
        position: this.props.position,
        isAdmin: this.props.isAdmin,
        map: null,
        dragging: false,
        zoom: 3,
        showMarkers: 100,
        wakeDusty: false,
        keyMAP: Math.random(),
        userIcon: userIcon,
        errorMsg: {},
        successMessage: '',
        newIconSize: 60,
      }

      this.updatePosition = this.updatePosition.bind(this);
      this.updateMarker = this.updateMarker.bind(this);
      this.handleMapClick = this.handleMapClick.bind(this);
      this.handleMapZoom = this.handleMapZoom.bind(this);
      this.handleShowDustyMenu = this.handleShowDustyMenu.bind(this);
      this.showAttractionOnMap = this.showAttractionOnMap.bind(this);
  }


  componentWillUnmount() {
      this._isMounted = false;
      if(this.state.map){
        this.state.map.off('click', this.handleMapClick);
        this.state.map.off('zoomend', this.handleMapZoom);
        this.map = null;
      }
   }

  componentDidMount() {
    this._isMounted = true;
    let zoom = 3;
    if(this.state.cityId!=null || this.props.userPosition!=null){
      zoom = 15;
    }
    const leafletMap = this.leafletMap.leafletElement;

    this.setState({map: leafletMap, zoom});
    leafletMap.on('zoomend', (e) => this.handleMapZoom(e));
    leafletMap.on('click', (e) => this.handleMapClick(e));

  }

  showAttractionOnMap = selectedOption => {
    this.setState({position: selectedOption.position});
  };

  handleMapClick = (e) => {
    var coords = e.latlng;

    var lat = coords.lat;
    var lng = coords.lng;
    if(this.state.isAdmin && this.props.adminMode){
      var newMarker = {
        position: coords,
        latitude: lat,
        longitude: lng,
        name: "",
        attractionId: "",
        level: 1,
        date: '',
        image: null,
        nameInfo: '', nameInfoValid: false,
        description: '',
        languageId: '',
        linkInfo: ''
      };
      this.props.handleShowAttractionModal(newMarker, true);
    }
  }

  handleMapZoom = () => {
    if(this.state.map.getZoom()>10){
      let newIconSize = (this.state.map.getZoom()*10)-100;
      this.setState({showMarkers : 100, newIconSize});
    }else{
      this.setState({showMarkers : 0});
    }
  }

 
  handleShowDustyMenu() {
    this.setState({wakeDusty: !this.state.wakeDusty});
  }

  updateMarker = (event, currentMarker) => {
    
      if(!this.state.dragging){
        if(currentMarker!=null){
          if(this.state.isAdmin && this.props.adminMode){
            this.setState({
              successMessage: '',
              errorMsg: {}}, this.props.handleShowAttractionModal(currentMarker, true));
          }else{
            this.setState({
              successMessage: '',
              errorMsg: {},}, this.props.handleShowAttractionModal(currentMarker, false));
          }
  
        }
      }else{
        this.setState({dragging: false});
      }
      

  }

  updatePosition = (event, currentMarker) => {
    let target = event.target;
    let newCoords = target._latlng;
    var lat = newCoords.lat;
    var lng = newCoords.lng;
    
    if(currentMarker){
      currentMarker.position=newCoords;
      currentMarker.latitude=lat;
      currentMarker.longitude=lng;
      this.setState({dragging: true}, ()=>this.props.saveNewAttraction(currentMarker, true));
    }

  }

  render() {

    const { t } = this.props;
    return (
      <div className="tab-content-container">
        <div className="tab-content-menu justify-content-md-center text-center">
            <span style={{ borderBottom: 'solid 2px ' + this.props.cityColor }}  className="city-title" onClick={() => this.props.showCityInfo()}>{(this.props.cityInfo)?this.props.cityInfo.cityName:""}</span>
        </div>
        <div className="tab-content-body">
          <div className="map-container" style={{ height: this.props.height }}>
                {this.state.wakeDusty && (
                  <div className="dusty-menu" style={{ height: this.props.height-DUSTY_HELPER_CONTAINER }}>
                    <div className="dusty-menu-content">
                      <div className="dusty-buttons-container">
                        {this.state.isAdmin && this.props.cityInfo && this.props.cityInfo.cityId && (
                        <Button variant={(this.props.adminMode ? 'primary' : 'secondary')} type="button" onClick={() => this.props.enableAdminMode()}>
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </Button>
                        )}
                      </div>
                      <div className="dusty-menu-form-container">
                        <Form>
                          <Form.Group controlId="showAttractions" className="form_field" >
                            <Form.Row>
                               <Col>
                                 <Form.Label style={{ marginRight: '20px' }}>{t('Common.attractionShowed')}</Form.Label>
                                  <ButtonGroup>
                                    <Button active={this.props.buttonSearchMap === 'all'} onClick={() => this.props.handleChangeSearchOnMap('all')}>{t('Common.all')}
                                    </Button>
                                    <Button active={this.props.buttonSearchMap === 'unlocked'} onClick={() => this.props.handleChangeSearchOnMap('unlocked')}>{t('Attractions.unlocked')}
                                    </Button>
                                    <Button active={this.props.buttonSearchMap === 'to_unlock'} onClick={() => this.props.handleChangeSearchOnMap('to_unlock')}>{t('Attractions.to_unlock')}
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </Form.Row>
                          </Form.Group>
                          <Form.Group controlId="attraction" className="form_field" style={{ marginTop: '20px' }}>
                            <Form.Label>{t('Common.search_attraction')}</Form.Label>
                              <Select placeholder={t('Common.select')} disabled={this.state.loading}
                                  onChange={this.showAttractionOnMap}
                                  options={this.props.attractions}
                                />
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                )}
                <Map key={this.state.keyMAP} center={this.state.position} zoom={this.state.zoom} className="map-style"
                  ref={m => { this.leafletMap = m; }}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {this.props.attractions && (this.props.attractions.map(marker =>
                      <Marker key={marker.attractionId+'_'+this.state.newIconSize} className='marker-container' position={marker.position} 
                          icon={ marker.image? new L.Icon({
                            className : "marker-lev-"+marker.level+" "+ (marker.userAttraction ? "unlocked-attraction-lev-"+marker.level : "locked-attraction"),
                            iconUrl: marker.userAttraction ? marker.userAttraction.image: marker.image,
                            iconRetinaUrl: marker.userAttraction ? marker.userAttraction.image: marker.image,
                            popupAnchor: [10, -44],
                            iconSize: [this.state.newIconSize, this.state.newIconSize]
                          }) 
                          :
                          new L.Icon({
                            className : 'marker-new',
                            iconUrl: require('../img/marker-default.png'),
                            iconRetinaUrl: require('../img/marker-default.png'),
                            popupAnchor: [10, -44],
                            iconSize: [this.state.newIconSize, this.state.newIconSize]
                          })
                        } 
                        opacity={(marker.inactive)?0:this.state.showMarkers}
                        draggable={this.state.isAdmin && this.props.adminMode} onDragend={(e) => this.updatePosition(e, marker)} onClick={(e) => this.updateMarker(e, marker)}>
                      </Marker>
                    )
                  )};
                  {this.props.userPosition && (
                    <Marker position={this.props.userPosition} icon={this.state.userIcon} opacity={this.state.showMarkers}>
                    </Marker>
                    )};
                {this.props.selectedAttraction && this.props.selectedAttraction.attractionId=="" && 
                  this.props.selectedAttraction.latitude!=="" && (
                  <Marker position={this.props.selectedAttraction.position} 
                    icon={
                      new L.Icon({
                        className : 'marker-new',
                        iconUrl: require('../img/marker-default.png'),
                        iconRetinaUrl: require('../img/marker-default.png'),
                        popupAnchor: [10, -44],
                        iconSize: [this.state.newIconSize, this.state.newIconSize]
                      })
                      } 
                      opacity={this.state.showMarkers}>
                  </Marker>
                )}

                </Map>
              </div>
              <DustyHelper key={this.state.keyMAP} wakeDusty={this.state.wakeDusty} handleClick={() => this.handleShowDustyMenu()}></DustyHelper>
              <Button variant="secondary" onClick={this.props.getUserPosition} className="locator-button">
                <Image src={marker} alt="Localization" className="center-block" fluid/>
              </Button>
          </div>
      </div>


    )
  }
}

export default withTranslation()(WorldustMap);
