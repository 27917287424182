import React, { PureComponent } from 'react';
import { Form, Row, Col, Container, Image, Modal, ListGroup} from 'react-bootstrap';
import { API_BASE_URL, WDL_ACCESS_TOKEN, WDL_COMPANY,  WDL_USER, WDL_ROLE_ADMIN} from '../Constants.js';

import axios from 'axios';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import WDLMenu from '../wdl/WDLMenu';

import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});
class WDLCompanyList extends PureComponent {

  _isMounted = false;

  constructor(props) {
      super(props);
      let user = JSON.parse(localStorage.getItem(WDL_USER));
        var isAdmin = false;
        if(user.role === WDL_ROLE_ADMIN){
          isAdmin = true;
        }
      this.state = {
        page: 0,
        hasMore: false,
        companies: [],
        user: user,
        isAdmin: isAdmin,
        viewHeight: window.innerHeight,
      }
      this.getCompanies = this.getCompanies.bind(this);
      this.selectCompany = this.selectCompany.bind(this);
  }

  componentWillUnmount(){
      this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    this.getCompanies();
  }

  selectCompany(company) {
    localStorage.setItem(WDL_COMPANY, JSON.stringify(company));
    return window.location.href="/logistic/company/"+company.companyId;
  }
  
  

getCompanies(refresh){
    const requestId = 'getCompaniesRequest';
    axios.cancel(requestId);
    let page = this.state.page;
    let query = this.state.query;
    let companies = this.state.companies;
    let errorMsg = {...this.state.errorMsg}
    errorMsg.dataError ='';
    
    if(refresh){
      query='';
      page=0;
      companies=[];
      this.setState({errorMsg, page, companies, query,
        hasMore: true
      });
    }else{
      this.setState({errorMsg,
        hasMore: true
      });
    }

    let url = API_BASE_URL+'/logistic/company/getAllManaged?page='+page+'&lang='+i18n.language;

    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN)
      }
    };


    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        let hasMore = true;
        let newCompanies = response.data;
        if((newCompanies && newCompanies.length===0) || (newCompanies && newCompanies[0].itemInLastPage)){
          hasMore=false;
        }
        this.setState({hasMore,
          page: page+1,
          companies: companies.concat(newCompanies)
        });
      }

    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {

        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logistic/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
          this.setState({errorMsg, hasMore:false});
      }
    });
  }



    render() {
      const { t } = this.props;
        return (
          
            <div className="wdl-container">
              <InfiniteScroll
            dataLength={this.state.companies.length}
            next={this.getCompanies}
            hasMore={this.state.hasMore}
            refreshFunction={() => this.getCompanies(true)}
            pullDownToRefresh={true}
            pullDownToRefreshThreshold={10}
            pullDownToRefreshContent={
              <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
            }
            releaseToRefreshContent={
              <div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>
            }
            height={this.state.viewHeight}
            className="white-background"
            loader={<div className="scroll-loader"><div className="lds-ripple wdl"><div></div><div></div></div></div>}
          >
            <WDLMenu user={this.state.user} isAdmin={this.state.isAdmin}/>
              <Container fluid={true}>
                  
                  <Container fluid={true} className="infinite-scroll-container">
                    <ListGroup className="wdl-list-container">
                      <Row>
                        <Col md={12}>
                          {!this.state.hasMore && this.state.companies.length===0 && (
                            <div className="separator">
                              <Row className="justify-content-md-center">
                                <Col sm={12} className="text-center">
                                  <span>{t('WDL.info_not_found')}</span>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {this.state.companies && this.state.companies.map((company, index) => (
                            <ListGroup.Item key={index} onClick={()=>this.selectCompany(company)}>
                              <div className="row-link-container" >
                                <Image fluid={true} src={company.image} className={"list-img"}/>
                                <span className="link-icon">{company.name} </span>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </Col>
                      </Row>
                    </ListGroup>
                  </Container>

              </Container>
              
            </InfiniteScroll>
            </div>
        );
    }
}

export default withTranslation()(WDLCompanyList);
