import React, { PureComponent } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

import { WDL_ACCESS_TOKEN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col } from 'react-bootstrap';


class WDLDownloadFileLink extends PureComponent {
    _isMounted = false;

    _types = {'pdf': 'application/pdf', 
              'xml': 'text/xml',
            }
    
    constructor(props) {
        super(props);
        
        this.state = {
          loading: false,
          blob: null,
        };
        this.getContract = this.getContract.bind(this);

    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
    }

    getContract() {
      const requestId = 'getContractRequest';
      axios.cancel(requestId);
      let errorMsg = {...this.state.errorMsg}
      errorMsg.dataError ='';
      
      
      this.setState({errorMsg, loading: true, blob: null});
      
  
      let url = this.props.link;
      
      const { t } = this.props;
      var config = {
        
        responseType: 'blob',
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(WDL_ACCESS_TOKEN),
      }
      };
  
  
      axios.get(url, config)
      .then((response) => {
        if (this._isMounted) {
          const file = new Blob(
            [response.data], 
            {type: this._types[this.props.type]});
          const fileURL = URL.createObjectURL(file);
          this.setState({loading: false, blob: {url: fileURL, name: this.props.filename?this.props.filename:null}}, ()=>this.pdfRef.click())
          window.URL.revokeObjectURL(fileURL);
        }
  
      }, (error) => {
        if (this._isMounted && !axios.isCancel(error)) {
  
          errorMsg.dataError = t('Errors.generic_error');
  
          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logistic/logout';            
            }
          }else{
            if(error.message!=null && error.message==='Network Error'){
                errorMsg.dataError = t("Errors.connection_error");
            }
          }
            this.setState({errorMsg, loading:false});
        }
      });
    }



    render() {
        const { t } = this.props;
        let fileIcon = "fa-file-pdf-o";
        if(this.props.type!='pdf') {
          fileIcon = "fa-file-code-o";
        }
        return (
          <div className="container wdl-contract">
            {this.state.loading ? (
              <div className="scroll-loader">
                <div className="lds-ripple wdl">
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <Row>
                <Col md={8}>
                  <a className="file-url" onClick={this.getContract}>
                    <i className={"fa "+fileIcon+" fa-2x"} aria-hidden="true">
                      <span className="link-icon locked-text file-name">
                        {this.props.name}
                      </span>
                    </i>
                  </a>
                  
                    <a style={{visibility: 'hidden'}} target="_blank" ref={m => { this.pdfRef = m; }} download={this.state.blob?.name} href={this.state.blob?.url}/>
                  
                </Col>
              </Row>
            )}
          </div>
        );
    }
}

export default withTranslation()(WDLDownloadFileLink);
