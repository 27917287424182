import React, { PureComponent } from 'react';
import dusty from '../img/dusty.svg';
import descriptionMap from '../img/description_map.jpg';
import descriptionSocial from '../img/description_social.jpg';
import descriptionUnlock from '../img/description_unlock.jpg';

import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Button, Image, Modal} from 'react-bootstrap';


class Welcome extends PureComponent {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
          pageToShow: 'welcome',
          loading: false,
          errorMsg: {},
          successMessage: '',
        };
        this.showPage = this.showPage.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
    }

    showPage(pageName){
      this.setState({pageToShow: pageName});
    }



    render() {
        const { t } = this.props;

        return (
          <Modal show={true} className="welcome-page-modal" animation={false} onHide={this.props.handleClose}>
            <Modal.Body>
                <Container fluid={true} className="title-bar-page">
                  <Row>
                    <Col md={12} className="text-center">
                      <h5 className="title-text">{t("Common.welcome")}</h5>
                    </Col>
                  </Row>
                </Container>
                {this.state.pageToShow==="welcome" && (
                  <Container fluid={true} className="tab-container">
                    <Row className="justify-content-md-center">
                      <Col sm={4} className="message-column">
                        <div className="message-container">
                          <div className="dusty_message" >
                            {t("Messages.welcome")+" "+this.props.user.name+", "+t("Messages.dusty_presentation")}
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}><Image src={dusty} alt="Dusty" className="center-block welcome-img" fluid/></Col>
                    </Row>
                  <Row>
                    <Col sm={12}>
                      <Button variant='secondary' onClick={this.props.handleClose} className="float-left" style={{marginBottom: 10}}>
                        {t("Common.close")}
                      </Button>
                      <Button variant='primary' onClick={() => this.showPage('description_map')} className="float-right" style={{marginBottom: 10}}>
                        {t("Common.next")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                )}
                {this.state.pageToShow==="description_map" && (
                  <Container fluid={true} className="tab-container">
                    <Row className="justify-content-md-center">
                      <Col sm={4} className="message-column">
                        <div className="message-container">
                          <div className="dusty_message" >
                            {t("Messages.description_map")}
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}><Image src={descriptionMap} className="center-block welcome-img" fluid/></Col>
                    </Row>
                  <Row>
                    <Col sm={12}>
                      <Button variant='secondary' onClick={() => this.showPage('welcome')} className="float-left" style={{marginBottom: 10}}>
                        {t("Common.prev")}
                      </Button>
                      <Button variant='primary' onClick={() => this.showPage('description_unlock')} className="float-right" style={{marginBottom: 10}}>
                        {t("Common.next")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                )}
                  {this.state.pageToShow==="description_unlock" && (
                  <Container fluid={true} className="tab-container">
                    <Row className="justify-content-md-center">
                      <Col sm={4} className="message-column">
                        <div className="message-container">
                          <div className="dusty_message" >
                            {t("Messages.description_unlock")}
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}><Image src={descriptionUnlock} className="center-block welcome-img" fluid/></Col>
                    </Row>
                  <Row>
                    <Col sm={12}>
                      <Button variant='secondary' onClick={() => this.showPage('description_map')} className="float-left" style={{marginBottom: 10}}>
                        {t("Common.prev")}
                      </Button>
                      <Button variant='primary' onClick={() => this.showPage('description_social')} className="float-right" style={{marginBottom: 10}}>
                        {t("Common.next")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                )}
                {this.state.pageToShow==="description_social" && (
                  <Container fluid={true} className="tab-container">
                    <Row className="justify-content-md-center">
                      <Col sm={4} className="message-column">
                        <div className="message-container">
                          <div className="dusty_message" >
                            {t("Messages.description_social")}
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}><Image src={descriptionSocial} className="center-block welcome-img" fluid/></Col>
                    </Row>
                  <Row>
                    <Col sm={12}>
                      <Button variant='secondary' onClick={() => this.showPage('description_unlock')} className="float-left" style={{marginBottom: 10}}>
                        {t("Common.prev")}
                      </Button>
                      <Button variant='primary' onClick={() => this.showPage('description_end')} className="float-right" style={{marginBottom: 10}}>
                        {t("Common.next")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                )}
                {this.state.pageToShow==="description_end" && (
                  <Container fluid={true} className="tab-container">
                    <Row className="justify-content-md-center">
                      <Col sm={12} className="message-column">
                        <div className="message-container">
                          <div className="dusty_message" >
                            {t("Messages.description_end")}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  <Row>
                    <Col sm={12}>
                      <Button variant='secondary' onClick={() => this.showPage('description_social')} className="float-left" style={{marginBottom: 10}}>
                        {t("Common.prev")}
                      </Button>
                      <Button variant='primary' onClick={this.props.handleClose} className="float-right" style={{marginBottom: 10}}>
                        {t("Common.close")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                )}
            </Modal.Body>
          </Modal>




        );
    }
}

export default withTranslation()(Welcome);
