import React from 'react';

export function ValidationMessage(props) {
  if (!props.valid) {
    return <div className='error-msg'>{props.message}</div>
  }
  return null;
}

export function SuccessMessage(props) {
  if (props.valid) {
    return <div className='success-msg'>{props.message}</div>
  }
  return null;
}

export function GenerateColor(str) {
  var hash = 0;
  var i = 0;
  for (i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}


export function WriteAttractionHtmlWordpress(attractions, lang, city) {
  let html = "";
  html+="<h2 class='section-title'>";
  if(lang==='it'){
    html+="Cosa vedere a ";
  } else {
    html+="What to see in "
  }
  html+=city.cityName;
  html+="</h2>";
  for(var i=0; i<attractions.length; i++){
    
    if(attractions[i].level==3){
      html+="<h3 class='attraction-title'>";
      html+=attractions[i].info.name;
      html+="</h3>";
      html+="<p class='attraction-description'>";
      html+=attractions[i].info.description;
      html+="</p>"
      if(attractions[i].info.linkInfo){
        html+="<p class='more-info'>";
        html+="<a href='";
        html+=attractions[i].info.linkInfo;
        html+="' target='_blank'>";
        if(lang==='it'){
          html+="maggiori informazioni"
        } else{
          html+="more info"
        }
        html+="</a>";
      }
    }
    
  }
  return html;
}

export function WritePlateHtmlWordpress(plates, lang, city) {
  let platesHtml="";
  platesHtml+="<h2 class='section-title'>";
  if(lang==='it'){
    platesHtml+="Cosa mangiare a ";
  } else {
    platesHtml+="What to eat in "
  }
  platesHtml+=city.cityName;
  platesHtml+="</h2>";
  for(var j=0; j<plates.length; j++){
    platesHtml+="<h3 class='plate-title'>";
    platesHtml+=plates[j].info.name;
    platesHtml+="</h3>";
    platesHtml+="<p class='attraction-description'>";
    platesHtml+=plates[j].info.description;
    platesHtml+="</p>"
  }
  
  return platesHtml;
}

export function WriteCityHtmlWordpress(city, lang) {
  let cityHtml="";
  cityHtml+="<p class='attraction-description'>";
  cityHtml+=city.description;
  cityHtml+="</p>"
  if(city.linkInfo){
    cityHtml+="<p class='more-info'>";
    cityHtml+="<a href='";
    cityHtml+=city.linkInfo;
    cityHtml+="' target='_blank'>";
    if(lang==='it'){
      cityHtml+="maggiori informazioni"
    } else{
      cityHtml+="more info"
    }
    cityHtml+="</a>";
  }
  
  return cityHtml;
}

