import React, { PureComponent } from 'react';
import { Row, Col, Container, Image, Accordion, Card, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import logo from '../img/logo_desc.svg';

import LanguageChooser from '../components/LanguageChooser';
import { CONTACT_EMAIL, INSTAGRAM_URL, FACEBOOK_URL } from '../Constants.js';

class Home extends PureComponent {
    

   
    render() {
      const { t } = this.props;
      let questionNumber = 10;
      
      let faqs = [];
      let i=1;
      for(i; i<questionNumber+1; i++) {
        faqs.push(
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey={i}>
                {t('faq.faq_'+i)}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={i}>
              <Card.Body>{t('answers.answer_'+i)}</Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      }
        return (
            <Container fluid={true} className="site-container">
               <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col>
                <a href={'/'}>
                  <Image src={logo} alt="Worldust" className="center-block logo-site-small" fluid/>
                </a>
                </Col>
                <Col className="site-language">
                  <LanguageChooser isSite={true}/>
                </Col>
               </Row> 
               <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col sm={12} className="centered-cell">
                  <h1 className={"site-text"}>
                    FAQ
                  </h1>
                </Col>
                </Row>
                
               <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                <Col sm={12} className="centered-cell">
                <Accordion defaultActiveKey="0" className={"custom-accordion"}>
                  {faqs}
                </Accordion>
                  
                </Col>
              </Row>
              
              
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={4} className="centered-cell">
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col>
                      <p className="centered-text footer-text">
                        {t('Site.contacts')}<br/>
                        <span className="simple-footer-text">
                          {CONTACT_EMAIL}   
                        </span>  
                      </p>
                      <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                        <Col className="social-container">
                        <a href={FACEBOOK_URL} target="_blank">
                          <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                        <a href={INSTAGRAM_URL} target="_blank">
                          <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
                        </a>
                        
                      </Col>
                      </Row>
                    </Col>
                      
                  </Row>
                  
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col className="footer-text">
                        {t('Common.more_info')}<br/>
                        <a href={'/terms-of-service'} target="_blank">
                          <span className="simple-footer-text">
                            {t('Common.terms')}   
                          </span>
                        </a>
                        <a href={'/faq'} target="_blank">
                          <span className="simple-footer-text">
                            FAQ   
                          </span>
                        </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={12} className="centered-cell" style={{color: 'white', marginTop: '10px',  marginBottom: '10px'}}>
                  {t('Site.all_right_reserved')}
                </Col>
              </Row>
            </Container>

        );
    }
}

export default withTranslation()(Home);
