import React, { PureComponent } from 'react';
import { Row, Col, Container, Modal} from 'react-bootstrap';

import { withTranslation } from 'react-i18next';

class CityPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city
        };

    }


    render() {
      const { t } = this.props;
        return (
          <Modal show={this.props.showModal} animation={false} onHide={this.props.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="attraction-title">
                  {this.state.city.cityName}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col md={12}>
                    <p className="attraction-description">
                      {(this.state.city.description)?this.state.city.description : t("City.info_not_found")}
                    </p>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={12} className="text-center">
                    {this.state.city.linkInfo && (
                      <a className="btn btn-primary restaurant-info" target="_blank" rel="noopener noreferrer" href={this.state.city.linkInfo}>{(this.state.city.linkInfo)?t('City.more_info'):''}</a>
                    )}
                    </Col>
                  </Row>
                </Container>
            </Modal.Body>

          </Modal>


        );
    }
}

export default withTranslation()(CityPage);
