import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Image} from 'react-bootstrap';
import LanguageChooser from '../components/LanguageChooser';
import logo from '../img/logo_desc.svg';
import foto1 from '../img/foto1.jpg';
import foto2 from '../img/foto2.jpg';
import happyDusty from '../img/happy_dusty.png';

import { CONTACT_EMAIL, INSTAGRAM_URL, FACEBOOK_URL } from '../Constants.js';

class AboutUs extends PureComponent {

    render() {
        const { t } = this.props;

        return (
          <div className="welcome-page-modal">
            <div >
            <Container fluid={true} className="tab-container" style={{padding: '0px'}}>
                <Row className="site-row" style={{ backgroundColor: '#f4f8f9' }}>
                    <Col>
                        <a href={'/'}>
                        <Image src={logo} alt="Worldust" className="center-block logo-site-small" fluid/>
                        </a>
                    </Col>
                        <Col className="site-language">
                        <LanguageChooser isSite={true}/>
                    </Col>
                </Row> 
                  <Row className="site-row">
                    <Col md={12} className="text-center" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                      <h5 className="title-text">{t("Common.aboutUs")}</h5>
                    </Col>
                  </Row>
                </Container>
                <Container fluid={true} className="tab-container white-background" >
                    <Row className="justify-content-md-center" >
                      <Col sm={8} className="message-column" style={{textAlign: 'left', marginTop: '20px'}}>
                        
                        <Row className="site-row">
                            <Col style={{padding: '0px'}} >
                            <p>
                                {t("AboutUs.message_1")}
                                </p>
                            </Col>
                            <Col className="image-site-container">
                            <Image src={foto2} alt="Worldust" className="center-block images-site-small" fluid/>
                            
                            <Image src={foto1} alt="Worldust" className="center-block images-site-small" fluid/>
                            </Col>
                        </Row> 
                        <p style={{marginTop: '40px'}}>
                        {t("AboutUs.message_2")} 
                        </p>
                        <p>
                        {t("AboutUs.message_3")} 
                        </p>
                        <p>
                        {t("AboutUs.message_4")} 
                        </p>
                        <p>
                        {t("AboutUs.message_5")} 
                        </p>
                        <p>
                        {t("AboutUs.message_6")} 
                        </p>
                        <p >
                        {t("AboutUs.message_7")} 
                        </p>
                    </Col>
                      
                    </Row>
                    <Row className="site-row" style={{paddingBottom: '40px'}}>
                        <Col>
                        </Col>
                        <Col className="image-site-container">
                            <Image src={happyDusty} alt="Worldust" className="center-block happy-dusty" fluid/>
                        </Col>
                    </Row> 
                </Container>
                
            </div>
            <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={4} className="centered-cell">
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col>
                      <p className="centered-text footer-text">
                        {t('Site.contacts')}<br/>
                        <span className="simple-footer-text">
                          {CONTACT_EMAIL}   
                        </span>  
                      </p>
                      <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                        <Col className="social-container">
                        <a href={FACEBOOK_URL} target="_blank">
                          <i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                        <a href={INSTAGRAM_URL} target="_blank">
                          <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
                        </a>
                        
                      </Col>
                      </Row>
                    </Col>
                      
                  </Row>
                  
                </Col>
                <Col sm={4} className="centered-cell">
                  <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                    <Col className="footer-text">
                        {t('Common.more_info')}<br/>
                        <a href={'/terms-of-service'}>
                          <span className="simple-footer-text">
                            {t('Common.terms')}   
                          </span>
                        </a>
                        <a href={'/faq'}>
                          <span className="simple-footer-text">
                            FAQ   
                          </span>
                        </a>
                        <a href={'/about_us'}>
                          <span className="simple-footer-text">
                          {t('Common.aboutUs')}    
                          </span>
                        </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="site-row" style={{ backgroundColor: 'rgb(96 101 105)' }}> 
                <Col sm={12} className="centered-cell" style={{color: 'white', marginTop: '10px',  marginBottom: '10px'}}>
                  {t('Site.all_right_reserved')}
                </Col>
              </Row>
          </div>




        );
    }
}

export default withTranslation()(AboutUs);
