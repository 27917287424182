import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Menu_admin from '../components/Menu_admin';
import Loader from '../components/Loader';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import { API_BASE_URL, ACCESS_TOKEN} from '../Constants.js';
import { withTranslation } from 'react-i18next';
import {Row, Col, Container, Form, Button, Modal } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Select from 'react-select';
import axiosCancel from 'axios-cancel';
axiosCancel(axios, {
  debug: false
});

class ManageUsers extends PureComponent {

  _isMounted = false;

    constructor() {
        super();

        this.state = {
          userId: "",
          name: '',
          surname: '',
          email: '',
          banned: false,
          role: 'ROLE_USER',
          roles: [
            { value: 'ROLE_USER', label: 'USER' },
            { value: 'ROLE_ADMIN', label: 'ADMIN' },
            { value: 'ROLE_ADVERTISER', label: 'ADVERTISER' }
          ],
          users: [],
          advMaxNum: 0,
          showAdwManager: false,
          query: '',
          formValid: false,
          errorMsg: {},
          showModal: false,
          successMessage: '',
          loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }


    resetState(){
      this.setState({
          userId: "",
          name: '',
          role: 'ROLE_USER',
          tableKey: Math.random(),
          query: '',
          errorMsg: {},
          showModal: false,
          successMessage: '',
          advMaxNum: 0,
          banned: false,
          showAdwManager: false,
          loading: false
        });
    }

    handleChangeRole = role => {
        let showAdwManager = false;
        let advMaxNum = this.state.advMaxNum;
        if(role.label==='ADVERTISER'){
          showAdwManager = true;
        } else {
          advMaxNum=0;
        }
        this.setState(
          { role,  showAdwManager, advMaxNum}
        );
      };


    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount(){
      this._isMounted = false;
    }

   

  updateTableData = () => {
    const requestId = 'updateTableDataRequest';
    axios.cancel(requestId);
    const { t } = this.props;
    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    let errorMsg = {...this.state.errorMsg}

    errorMsg.dataError ='';
    this.setState({errorMsg,
      loading: true

    });

    let url = API_BASE_URL+'/user/admin/getAll';

    if (this.state.query.length > 0) {
      url += '?query=' + this.state.query;
    }

    axios.get(url, config)
    .then((response) => {
      if (this._isMounted) {
        this.setState({ users: response.data,
          loading: false });
      }
    }, (error) => {
      if (this._isMounted && !axios.isCancel(error)) {
        errorMsg.dataError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }
        }else{
          if(error.message!=null && error.message==='Network Error'){
              errorMsg.dataError = t("Errors.connection_error");
          }
        }
        this.setState({errorMsg,
          loading: false});
      }
    });
  }


  handleEdit(row) {
    var user = row;
    let role = null;
    let roles = this.state.roles;
    for(var j=0; j<roles.length; j++){
      if(roles[j].value === user.role){
        role = roles[j];
        break;
      }
    }

    let showAdwManager = false;
    if(role.label==='ADVERTISER'){
      showAdwManager = true;
    }

    this.setState({
      userId: user.userId,
      name: user.name,
      surname: user.surname,
      email: user.email,
      advMaxNum: user.advMaxNum,
      role,
      banned: user.isBanned,
      errorMsg: {},
      showModal: false,
      successMessage: '',
      showAdwManager,
    });

  }

  deleteConfirm(e) {
    let userId = this.state.userId;
    let errorMsg = {};
    const { t } = this.props;

    var config = {
        headers: {
          'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      }
    };

    this.setState({errorMsg,
      loading: true});

    axios.post(API_BASE_URL+'/user/admin/delete/'+userId, null, config)
    .then((response) => {

      let users = this.state.users.filter(item => item.userId !== userId);
        
      if (this._isMounted) {
        this.setState({
          users,
          tableKey: Math.random(),
          userId: "",
          name: '',
          role: 'user',
          query: '',
          errorMsg: {},
          advMaxNum: 0,
          showAdwManager: false,
          showModal: false,
          successMessage: '',
          loading: false
        });
      }
    }, (error) => {
      if (this._isMounted) {
        errorMsg.appError = t('Errors.generic_error');

        if (error.response && error.response.status) {
          let status = error.response.status;
          if(status===401){
            return window.location.href = '/logout';            
          }

        }
        this.setState({errorMsg,
          loading: false});
      }
    });



  }

  handleDelete(row) {
    var user = row;
    let role = null;
    let roles = this.state.roles;
    for(var j=0; j<roles.length; j++){
      if(roles[j].value === user.role){
        role = roles[j];
        break;
      }
    }
    let showAdwManager = false;
    if(role.label==='ADVERTISER'){
      showAdwManager = true;
    }
    this.setState({
      userId: user.userId,
      name: user.name,
      surname: user.surname,
      email: user.email,
      advMaxNum: user.advMaxNum,
      role,
      errorMsg: {},
      showModal: true,
      successMessage: '',
      showAdwManager,
    });

  }

  handleClose(){
    this.setState({showModal: false });
  }

    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        this.setState({
          [name]: value
        });


    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {};
      data["userId"]=this.state.userId;
      data["role"]=this.state.role.value;
      data["advMaxNum"]=this.state.advMaxNum;
      data["isBanned"]=this.state.banned;
      let errorMsg = {};
      errorMsg.appError = '';
      const { t } = this.props;

      var config = {
          headers: {
            'Authorization': "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        }
      };
      
      this.setState({errorMsg,
        loading: true});
        
      axios.post(API_BASE_URL+'/user/admin/save', data, config)
      .then((response) => {
        if (this._isMounted) {
          let newUser = response.data;
          let users = [...this.state.users];
          if(data.userId){
            for(var j=0; j<users.length; j++){
              if(users[j].userId === data.userId){
                users[j] = newUser;
                break;
              }
            }
          }else{
            users.push(newUser);
          }
          this.setState({users, tableKey: Math.random(), successMessage: t('Profile.saved'),
            loading: false });
        }
      }, (error) => {
        if (this._isMounted) {
          errorMsg.appError = t('Errors.generic_error');

          if (error.response && error.response.status) {
            let status = error.response.status;
            if(status===401){
              return window.location.href = '/logout';            
            }

          }
          this.setState({errorMsg,
            loading: false});
        }
      });
    };



    render() {
        const { SearchBar } = Search;
        const { t } = this.props;

        const columns = [{
          dataField: 'userId',
          text: 'ID'
        }, {
          dataField: 'name',
          text: t('SignUpForm.name')
        }, {
          dataField: 'surname',
          text: t('SignUpForm.surname')
        }, {
          dataField: 'email',
          text: t('SignUpForm.email')
        },
        {
          dataField: 'role',
          text: t('Admin.role')
        },
        {
          dataField: 'id',
          text: t('Common.actions'),
          formatter: (cell, row, rowIndex) => (
            <div className="buttons-container">
              <Button variant="primary" type="button" onClick={(e) => this.handleEdit(row)}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </Button>
              <Button variant="primary" type="button" onClick={(e) => this.handleDelete(row)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </Button>


            </div>
          )
        }];


        
          return (
            <Container fluid={true} className="menu-container">
            {this.state.loading && (
              <Loader></Loader>
            )}
            <Menu_admin></Menu_admin>
              <Container fluid={true} className="title-bar-page">
                <Row>
                  <Col md={2}>
                    <Link to="/letMeDiscover">
                      <div className="back-button"><i className="fa fa-chevron-left fa-2x " aria-hidden="true"></i></div>
                    </Link>
                  </Col>
                  <Col md={8} className="text-center">
                    <h5 className="title-text">{t('Admin.manage_users')}</h5>
                  </Col>
                </Row>
              </Container>
              <Container fluid={true} className="tab-container">
                <Form>
                  <Form.Row className="justify-content-md-center">
                    <Col sm={6}>
                      <Form.Group controlId="query" className="form_field">
                        <Form.Control type="text" className="form_field_input dark_text" placeholder={t('Common.search')} name="query" value={this.state.query} onChange={this.handleChange} />
                      </Form.Group>
                    </Col>
                  <Col sm={2}>
                    <Button variant="primary" onClick={this.updateTableData} style={{textAlign: 'center'}}>
                      {t('Common.confirm')}
                    </Button>
                  </Col>
                  </Form.Row>
                </Form>
                <Row className="justify-content-md-center">
                  <Col sm={8} className="form_center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group controlId="userId" className="form_field">
                        <Form.Label className="form_field_label">Id</Form.Label>
                        <Form.Control type="number" className="form_field_input" placeholder={"Id"} name="userId" value={this.state.userId} readOnly />
                      </Form.Group>
                      <Form.Group controlId="name" className="form_field">
                        <Form.Label className="form_field_label">{t('SignUpForm.name')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="name" value={this.state.name} readOnly />
                      </Form.Group>
                      <Form.Group controlId="surname" className="form_field">
                        <Form.Label className="form_field_label">{t('SignUpForm.surname')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="surname" value={this.state.surname} readOnly />
                      </Form.Group>
                      <Form.Group controlId="email" className="form_field">
                        <Form.Label className="form_field_label">{t('SignUpForm.email')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="email" value={this.state.email} readOnly />
                      </Form.Group>
                      <Form.Group controlId="role" className="form_field">
                        <Form.Label className="form_field_label">{t('Admin.role')}</Form.Label>
                          <Select
                              value={this.state.role}
                              onChange={this.handleChangeRole}
                              options={this.state.roles}
                            />
                      </Form.Group>
                      <Form.Group controlId="closed" className="form_field">
                        <Form.Check type="checkbox">
                          <Form.Check.Input type="checkbox" name="banned" checked={this.state.banned} value={this.state.banned} onChange={this.handleChange}/>
                          <Form.Check.Label>{'Banned'}</Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      {
                        this.state.showAdwManager && (
                          <Form.Group controlId="advMaxNum" className="form_field">
                            <Form.Label className="form_field_label">ADV N.</Form.Label>
                            <Form.Control type="number" className="form_field_input" name="advMaxNum" value={this.state.advMaxNum} min="0" onChange={this.handleChange} />
                          </Form.Group>
                        )
                      }
                      
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                      </Form.Group>
                      <Form.Row>
                        {this.state.userId!=="" && (
                          <Col className="text-center">
                            <Button variant="primary" type="submit" className="btn-login">{t('Common.save')}</Button>
                          </Col>
                        )}
                        <Col className="text-center">
                          <Button variant="secondary" type="button" className="btn-login" onClick={this.resetState}>{t('Common.clear')}</Button>
                        </Col>

                       </Form.Row>
                     </Form>
                   </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={12} className="form_center">
                    <ValidationMessage valid={false} message={this.state.errorMsg.dataError} />
                    <ToolkitProvider
                      keyField="userId"
                      data={ this.state.users }
                      columns={ columns }
                      search

                      >
                      {
                        props => (
                          <div className="wd-tables-container">
                            <SearchBar { ...props.searchProps } />
                            <hr />
                            <BootstrapTable key={this.state.tableKey} pagination={ paginationFactory()}
                              { ...props.baseProps }
                            />
                          </div>
                        )
                      }
                      </ToolkitProvider>
                    </Col>
                  </Row>
                </Container>
                <Modal show={this.state.showModal} animation={false} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>  {this.state.email}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="app_aside modal-message">
                      {t('Admin.user_delete_confirm')}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={this.deleteConfirm}>
                      {t('Common.confirm')}
                    </Button>
                  </Modal.Footer>
                </Modal>
            </Container>
          );
        


    }
}

export default withTranslation()(ManageUsers);
