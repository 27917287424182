import React, { PureComponent } from 'react';
import logo from '../img/wdl/wd_camion.png';

import { Navbar, Image, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { withTranslation } from 'react-i18next';

class WDLMenu extends PureComponent {
    
    render() {
        const { t } = this.props;

        return (
          
          <div>
            <Navbar collapseOnSelect expand="lg" className="wdl_nav_menu top-menu">
              <div className="container-fluid">
                <Navbar.Brand as={Link} to={this.props.companyId?"/logistic/company/"+this.props.companyId:"/logistic"}><Image src={logo} className="menu-logo wdl-menu-logo" /><span className="wdl-title-text">{this.props.companyId?'Home':'Logistic'}</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
              </div>
              <Navbar.Collapse>
              <Nav>
                <NavDropdown className="wdl-menu-item" alignRight title={<i className="fa fa-user" aria-hidden="true">{' '+this.props.user.name}</i>} id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/logistic/logout">Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
              
            </Navbar>
            
          </div>
          
        );
    }
}

export default withTranslation()(WDLMenu);
